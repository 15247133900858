import React from 'react';
import { connect } from 'react-redux';
import { get3DWinPercent } from '../../redux/actions/3DWinPercent/get3DWinPercent';
import { toggleUpdateModal } from '../../redux/actions/3DWinPercent/toggleUpdateModal';
import { Card, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';

class ThreeDWinPercent extends React.Component {
   async componentDidMount() {
      this.props.onLoad(this.props.token)
   }
   _openEditModal = () => {
      this.props.onOpen();
   }
   render() {
      return (
         <Row>
            <Col lg={{ span: 8 }} md={{ span: 20 }} sm={{ span: 24 }} xs={{ span: 24 }}>
               <Card
                  span={24}
                  type="inner"
                  title={<div style={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                     width: '100%'
                  }}>
                     <span>Three D Win Percent</span>
                     <EditOutlined onClick={this._openEditModal} />
                  </div>}
                  loading={this.props.loading}
               >
                  <div>Win Percent - {this.props.threeDWinPercent?.winPercent}</div>
                  <div>Special Number Win Percent - {this.props.threeDWinPercent?.specialNumberWinPercent}</div>
               </Card>
            </Col>
         </Row>
      );
   }
}

const mapStateToProps = state => ({
   token: state.auth.token,
   loading: state.threeDWinPercent.loading,
   threeDWinPercent: state.threeDWinPercent.one
})

const mapDispatchToProps = dispatch => ({
   onLoad: (token) => dispatch(get3DWinPercent(token)),
   onOpen: () => dispatch(toggleUpdateModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(ThreeDWinPercent);