import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, InputNumber } from 'antd';
import { update4DCommission } from '../../redux/actions/seniormaster/updateSeniormaster';
import { toggle4DCommissionUpdate } from '../../redux/actions/seniormaster/toggle4DCommissionUpdate';

const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
};

class Update4DCommissionModal extends React.Component {
      constructor(props) {
            super(props);
            this.form = React.createRef();
      }
      onFinish = async (values) => {
            await this.props.onUpdate({
                  id: this.props.smaster?.id,
                  commission: values.fourDCommission
            },
                  this.props.token);
            if (this.props.error) {
                  message.error(this.props.error);
            }
            if (this.props.message) {
                  message.success(this.props.message);
                  this.form.current.resetFields();
                  this.props.onClose();
            }
      }
      onFinishFailed = (errors) => {
            console.log(errors)
      }
      handleCancel = () => {
            this.props.onClose();
      }
      render() {
            return (
                  <Modal
                        title="Update FourD Commission"
                        key={this.props.smaster?.id}
                        centered
                        visible={this.props.visible}
                        okButtonProps={{ form: 'update-4dc-form', key: 'submit', htmlType: 'submit' }}
                        confirmLoading={this.props.loading}
                        onCancel={this.handleCancel}
                        okText="Save"
                        loading={this.props.loading}>
                        <Form
                              {...layout}
                              ref={this.form}
                              style={{ margin: 25 }}
                              id="update-4dc-form"
                              name="basic"
                              initialValues={{
                                    fourDCommission: this.props.smaster?.fourDCommission??0,
                              }}
                              onFinish={this.onFinish}
                              onFinishFailed={this.onFinishFailed}
                        >
                              <Form.Item
                                    name="fourDCommission"
                                    label="Commission"
                                    rules={[
                                          {
                                                required: true,
                                                message: 'Require!',
                                          }
                                    ]}
                                    hasFeedback
                              >
                                    <InputNumber style={{ width: 150 }} />
                              </Form.Item>
                        </Form>
                  </Modal>
            );
      }
}

const mapStateToProps = state => ({
      visible: state.seniorMaster.update4dCommissionVisible,
      loading: state.seniorMaster.loading,
      token: state.auth.token,
      error: state.seniorMaster.error,
      message: state.seniorMaster.message,
      smaster: state.seniorMaster.one
})

const mapDispatchToProps = dispatch => ({
      onUpdate: (obj, token) => dispatch(update4DCommission(obj, token)),
      onClose: () => dispatch(toggle4DCommissionUpdate()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Update4DCommissionModal);