import React from 'react';
import { connect } from 'react-redux';
import { getPriceGap } from '../../redux/actions/priceGap/getPriceGap';
import { togglePriceGap } from '../../redux/actions/priceGap/togglePriceGap';
import { Card, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';

class PriceGap extends React.Component {
    async componentDidMount() {
        this.props.onLoad(this.props.token)
    }
    _openEditModal = () => {
        this.props.onOpen();
    }
    render() {
        return (
            <Row>
                <Col lg={{span:8}} md={{span:20}} sm={{span:24}} xs={{span:24}}>
                    <Card
                        type="inner"
                        title={<div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                        }}>
                            <span>Price Gaps</span>
                            <EditOutlined onClick={this._openEditModal} />
                        </div>}
                        loading={this.props.loading}
                    >
                        <div>Body Gap - {this.props.priceGap?.bodyGap}</div>
                        <div>Goal Gap - {this.props.priceGap?.goalGap}</div>
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    loading: state.priceGap.loading,
    priceGap: state.priceGap.one
})

const mapDispatchToProps = dispatch => ({
    onLoad: (token) => dispatch(getPriceGap(token)),
    onOpen: () => dispatch(togglePriceGap())
})

export default connect(mapStateToProps, mapDispatchToProps)(PriceGap);