import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    GET_AGREEMENTS_START,
    GET_AGREEMENTS_SUCCESS,
    GET_AGREEMENTS_FAIL,
    UPDATE_AGREEMENT_START,
    UPDATE_AGREEMENT_SUCCESS,
    UPDATE_AGREEMENT_FAIL,
    TOGGLE_UPDATE_AGREEMENT_MODAL
} from '../actions/agreement/actionTypes';

const initialState={
    loading:false,
    error:null,
    message:null,
    one:null,
    updateVisible:false
}

const getAgreements=(state,action)=>{
    return update(state,{
        loading:false,
        one:action.payload
    })
}

const updateAgreement=(state,action)=>{
    return update(state,{
        loading:false,
        message:'updated agreement successfully',
        one:action.payload
    })
}

const toggleUpdateModal=(state,action)=>{
    return update(state,{
        updateVisible:!state.updateVisible
    })
}

const agreementReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case GET_AGREEMENTS_START:
            return loading(state,action);
        case GET_AGREEMENTS_SUCCESS:
            return getAgreements(state,action);
        case GET_AGREEMENTS_FAIL:
            return error(state,action);
        case UPDATE_AGREEMENT_START:
            return loading(state,action);
        case UPDATE_AGREEMENT_SUCCESS:
            return updateAgreement(state,action);
        case UPDATE_AGREEMENT_FAIL:
            return error(state,action);
        case TOGGLE_UPDATE_AGREEMENT_MODAL:
            return toggleUpdateModal(state,action);
        default:
            return state;
    }
}

export default agreementReducer;