import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    GET_ANNOUNCEMENTS_START,
    GET_ANNOUNCEMENTS_SUCCESS,
    GET_ANNOUNCEMENTS_FAIL,
    UPDATE_ANNOUNCEMENT_START,
    UPDATE_ANNOUNCEMENT_SUCCESS,
    UPDATE_ANNOUNCEMENT_FAIL,
    TOGGLE_ANNOUNCEMENT_UPDATE
} from '../actions/announcement/actionTypes';

const initialState={
    loading:false,
    error:null,
    message:null,
    all:null,
    announcementUpdateVisible:false,
}

const getAnnouncements=(state,action)=>{
    return update(state,{
        loading:false,
        all:action.payload
    })
}

const updateAnnouncement=(state,action)=>{
    return update(state,{
        loading:false,
        message:'update success',
        all:action.payload
    })
}

const toggleAnnouncementUpdate=(state,action)=>{
    return update(state,{
        announcementUpdateVisible:!state.announcementUpdateVisible
    })
}

const announcementReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case GET_ANNOUNCEMENTS_START:
            return loading(state,action);
        case GET_ANNOUNCEMENTS_SUCCESS:
            return getAnnouncements(state,action);
        case GET_ANNOUNCEMENTS_FAIL:
            return error(state,action);
        case UPDATE_ANNOUNCEMENT_START:
            return loading(state,action);
        case UPDATE_ANNOUNCEMENT_SUCCESS:
            return updateAnnouncement(state,action);
        case UPDATE_ANNOUNCEMENT_FAIL:
            return error(state,action);
        case TOGGLE_ANNOUNCEMENT_UPDATE:
            return toggleAnnouncementUpdate(state,action);
        default:
            return state;
    }
}

export default announcementReducer;