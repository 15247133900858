import React, { useState, useEffect } from 'react';
import { Row, Col, Breadcrumb, Form, Input, Button, Typography, message } from 'antd';
import {
      CheckOutlined,
} from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get4DResult } from '../../redux/actions/4DCollection/get4DResult';
import { save4DResult } from '../../redux/actions/4DCollection/save4DResult';
import {
      toggle1stPrizeFinishModal,
      toggle2ndPrizeFinishModal,
      toggle3rdPrizeFinishModal,
      toggleStarterPrizeFinishModal,
      toggleConsolPrizeFinishModal,
} from '../../redux/actions/4DCollection/toggleFinish4DModal';
import {
      toggleSubDigitFinishModal
} from '../../redux/actions/4DCollection/toggleSubDigitFinishModal'

const { Title } = Typography;


class FourDResult extends React.Component {
      state = {}
      async componentDidMount() {
            const { id } = this.props.match.params;
            const { token, error } = this.props;
            await this.props.onLoaded(id, token);
            if (error) {
                  message.error(error);
            } else {
                  this.props.result && this.update({
                        ...this.props.result
                  })
            }
      }
      update = (newObj) => {
            this.setState(prevState => {
                  return {
                        ...prevState,
                        ...newObj
                  }
            })
      }
      changeSmallPrize = (id, key, number) => {
            const newArr = this.state[key]
                  .map(v => {
                        if (v.id === +id) {
                              return {
                                    ...v,
                                    number
                              }
                        }
                        return v;
                  });
            this.update({
                  [key]: newArr
            })
      }
      changeBigPrize = (key, number) => {
            this.update({
                  [key]: {
                        ...this.state[key],
                        number
                  }
            });
      }
      onChange = (k, v) => {
            if (k.includes("starter")) this.changeSmallPrize(k.replace("starter", ""),
                  "starterPrizes", v);
            if (k.includes("consol")) this.changeSmallPrize(k.replace("consol", ""),
                  "consolationPrizes", v);
            if (k === "firstPrize") this.changeBigPrize("firstPrize", v);
            if (k === "secondPrize") this.changeBigPrize("secondPrize", v);
            if (k === "thirdPrize") this.changeBigPrize("thirdPrize", v);
      }
      onFinish = (k) => {

      }
      onSave = async () => {
            await this.props.onSave(this.state, this.props.token);
            if (this.props.message) message.success(this.props.message);
            if (this.props.error) message.error(this.props.error);
      }
      render() {
            const { result } = this.props;
            const starterKeysValues = result?.starterPrizes?.reduce((p, c, i) => {
                  return {
                        ...p,
                        [`starter${result?.starterPrizes[i]['id']}`]: result?.starterPrizes[i]['number']
                  }
            }, {});

            const consolKeysValues = result?.consolationPrizes?.reduce((p, c, i) => {
                  return {
                        ...p,
                        [`consol${result?.consolationPrizes[i]['id']}`]: result?.consolationPrizes[i]['number']
                  }
            }, {});

            const isFinishedMainPrizes=!(result?.firstPrize?.finished && result?.secondPrize?.finished
                                          && result?.thirdPrize?.finished);

            const starterInputs = [];
            for (let key in starterKeysValues) {
                  const prize = result?.starterPrizes
                        ?.find(v => v.id === +key.replace("starter", ""));
                  starterInputs.push(
                        <Row gutter={[6, 6]} key={key}>
                              <Col span={16}>
                                    <Form.Item
                                          key={key + 'fi'}
                                          rules={[{
                                                required: true,
                                                message: 'Please enter the prize',
                                                min: 4,
                                                max: 4
                                          }]}
                                          name={key}>
                                          <Input onChange={(e) => this.onChange(key, e.target.value)} />
                                    </Form.Item>
                              </Col>
                              <Col span={6}>
                                    <Button
                                          disabled={isFinishedMainPrizes || prize["finished"]}
                                          icon={<CheckOutlined />}
                                          onClick={() =>
                                                this.props.onOpenStarterPrizeFinishModal({
                                                      number: prize['number'],
                                                      id: prize['id']
                                                })} />
                              </Col>
                        </Row>)
            }

            const consolInputs = [];
            for (let key in consolKeysValues) {
                  const prize = result?.consolationPrizes
                        ?.find(v => v.id === +key.replace("consol", ""));
                  consolInputs.push(
                        <Row gutter={[6, 6]} key={key}>
                              <Col span={16}>
                                    <Form.Item
                                          key={key + 'fi'}
                                          rules={[{
                                                required: true,
                                                message: 'Please enter the prize',
                                                min: 4,
                                                max: 4
                                          }]}
                                          name={key}>
                                          <Input onChange={(e) => this.onChange(key, e.target.value)} />
                                    </Form.Item>
                              </Col>
                              <Col span={6}>
                                    <Button
                                          disabled={isFinishedMainPrizes || prize["finished"]}
                                          icon={<CheckOutlined />}
                                          onClick={
                                                () => this.props.onOpenConsolPrizeFinishModal({
                                                      number: prize['number'],
                                                      id: prize['id']
                                                })} />
                              </Col>
                        </Row>
                  )
            }
      
            return result ? (
                  <Form
                        onFinish={this.onSave}
                        labelAlign="left"
                        key={result?.id + 'form'}
                        initialValues={{
                              firstPrize: result?.firstPrize.number,
                              secondPrize: result?.secondPrize.number,
                              thirdPrize: result?.thirdPrize.number,
                              ...starterKeysValues,
                              ...consolKeysValues
                        }}>
                        <Row justify="start" gutter={[16, 16]}>
                              <Col span={24}>
                                    <Breadcrumb style={{ margin: '16px 0' }}>
                                          <Breadcrumb.Item>
                                                <Link to="/fourDCollections">FourD Collections</Link>
                                          </Breadcrumb.Item>
                                          <Breadcrumb.Item>FourD Results</Breadcrumb.Item>
                                    </Breadcrumb>
                              </Col>
                              <Col span={24}>
                                    <Row gutter={[6, 6]}>
                                          <Col xs={20}
                                                sm={10}
                                                md={10}
                                                lg={10}
                                                xl={10}
                                                xxl={8}>
                                                <Form.Item
                                                      key="firstPrize"
                                                      rules={[{
                                                            min: 4,
                                                            max: 4,
                                                            required: true,
                                                            message: 'Please enter first prize'
                                                      }]}
                                                      name="firstPrize"
                                                      labelCol={{ span: 8 }}
                                                      label="First Prize">
                                                      <Input onChange={
                                                            (e) => this.onChange("firstPrize",
                                                                  e.target.value)} />
                                                </Form.Item>
                                          </Col>
                                          <Col xs={20}
                                                sm={3}
                                                md={2}
                                                lg={1}
                                                xl={1}
                                                xxl={1}>
                                                <Button
                                                      disabled={result?.firstPrize?.finished}
                                                      onClick={this.props.onOpen1stPrizeFinishModal}
                                                      icon={<CheckOutlined />}
                                                      style={{ width: '100%' }} />
                                          </Col>
                                          <Col xs={20}
                                                sm={5}
                                                md={5}
                                                lg={3}
                                                xl={3}
                                                xxl={3}>
                                                <Button
                                                      disabled={
                                                            result?.firstPrize?.finished ?
                                                                  result?.firstPrize?.threeDigitsFinished : true
                                                      }
                                                      onClick={
                                                            () => this.props.onOpenSubDigitPrizeFinishModal({
                                                                  type_digit: "FIRST_THREE"
                                                            })
                                                      }
                                                      icon={<CheckOutlined />}
                                                      style={{ width: '100%' }}>3 Digits</Button>
                                          </Col>
                                          <Col xs={20}
                                                sm={5}
                                                md={5}
                                                lg={3}
                                                xl={3}
                                                xxl={3}>
                                                <Button
                                                      disabled={
                                                            result?.firstPrize?.threeDigitsFinished
                                                                  ? result?.firstPrize?.twoDigitsFinished : true
                                                      }
                                                      onClick={
                                                            () => this.props.onOpenSubDigitPrizeFinishModal({
                                                                  type_digit: "FIRST_TWO"
                                                            })
                                                      }
                                                      icon={<CheckOutlined />}
                                                      style={{ width: '100%' }} >2 Digits</Button>
                                          </Col>
                                    </Row>
                                    <Row gutter={[6, 6]}>
                                          <Col xs={20}
                                                sm={10}
                                                md={10}
                                                lg={10}
                                                xl={10}
                                                xxl={8}>
                                                <Form.Item
                                                      key="secondPrize"
                                                      rules={[{
                                                            min: 4,
                                                            max: 4,
                                                            required: true,
                                                            message: 'Please enter second prize'
                                                      }]}
                                                      name="secondPrize"
                                                      labelCol={{ span: 8 }}
                                                      label="Second Prize">
                                                      <Input onChange={
                                                            (e) => this.onChange("secondPrize",
                                                                  e.target.value)} />
                                                </Form.Item>
                                          </Col>
                                          <Col xs={20}
                                                sm={3}
                                                md={2}
                                                lg={1}
                                                xl={1}
                                                xxl={1}>
                                                <Button
                                                      disabled={
                                                            result?.firstPrize?.finished
                                                            ?result?.secondPrize?.finished:true}
                                                      onClick={this.props.onOpen2ndPrizeFinishModal}
                                                      icon={<CheckOutlined />}
                                                      style={{ width: '100%' }} />
                                          </Col>
                                          <Col xs={20}
                                                sm={5}
                                                md={5}
                                                lg={3}
                                                xl={3}
                                                xxl={3}>
                                                <Button
                                                      disabled={result?.secondPrize?.finished
                                                      ?result?.secondPrize?.twoDigitsFinished:true}
                                                      onClick={
                                                            () => this.props.onOpenSubDigitPrizeFinishModal({
                                                                  type_digit: "SECOND_TWO"
                                                            })
                                                      }
                                                      icon={<CheckOutlined />}
                                                      style={{ width: '100%', padding: '0 8px' }} >2 Digits</Button>
                                          </Col>
                                    </Row>
                                    <Row gutter={[6, 6]}>
                                          <Col xs={20}
                                                sm={10}
                                                md={10}
                                                lg={10}
                                                xl={10}
                                                xxl={8}>
                                                <Form.Item
                                                      key="thirdPrize"
                                                      rules={[{
                                                            min: 4,
                                                            max: 4,
                                                            required: true,
                                                            message: 'Please enter third prize'
                                                      }]}
                                                      name="thirdPrize"
                                                      labelCol={{ span: 8 }}
                                                      label="Third Prize">
                                                      <Input onChange={
                                                            (e) => this.onChange("thirdPrize",
                                                                  e.target.value)} />
                                                </Form.Item>
                                          </Col>
                                          <Col xs={20}
                                                sm={3}
                                                md={2}
                                                lg={1}
                                                xl={1}
                                                xxl={1}>
                                                <Button
                                                      disabled={
                                                            result?.secondPrize?.finished
                                                            ?result?.thirdPrize?.finished:true}
                                                      onClick={this.props.onOpen3rdPrizeFinishModal}
                                                      icon={<CheckOutlined />}
                                                      style={{ width: '100%' }} />
                                          </Col>
                                          <Col xs={20}
                                                sm={5}
                                                md={5}
                                                lg={3}
                                                xl={3}
                                                xxl={3}>
                                                <Button
                                                      disabled={result?.thirdPrize?.finished
                                                      ?result?.thirdPrize?.twoDigitsFinished:true}
                                                      onClick={
                                                            () => this.props.onOpenSubDigitPrizeFinishModal({
                                                                  type_digit: "THIRD_TWO"
                                                            })
                                                      }
                                                      icon={<CheckOutlined />}
                                                      style={{ width: '100%', padding: '0 8px' }} >2 Digits</Button>
                                          </Col>
                                    </Row>
                              </Col >
                              <Col xs={24}
                                    sm={18}
                                    md={16}
                                    lg={12}
                                    xl={12}
                                    xxl={8}>
                                    <Title level={3}>Starter Prizes</Title>
                                    {starterInputs}
                              </Col>
                              <Col xs={24}
                                    sm={18}
                                    md={16}
                                    lg={12}
                                    xl={12}
                                    xxl={8}>
                                    <Title level={3}>Consolation Prizes</Title>
                                    {consolInputs}
                              </Col>
                              <Col span={24}>
                                    <Button htmlType="submit" loading={this.props.loading}>Save</Button>
                              </Col>
                        </Row >
                  </Form>
            ) : <Row justify="start" gutter={[16, 16]}>
                  <Col span={24}>
                        <Breadcrumb style={{ margin: '16px 0' }}>
                              <Breadcrumb.Item>
                                    <Link to="/fourDCollections">FourD Collections</Link>
                              </Breadcrumb.Item>
                              <Breadcrumb.Item>FourD Results</Breadcrumb.Item>
                        </Breadcrumb>
                  </Col></Row>
      }
}

const mapStateToProps = state => ({
      token: state.auth.token,
      result: state.fourDCollection.result,
      loading: state.fourDCollection.loading,
      message: state.fourDCollection.message,
      error: state.fourDCollection.error
})

const mapDispatchToProps = dispatch => ({
      onLoaded: (id, token) => dispatch(get4DResult(id, token)),
      onSave: (req, token) => dispatch(save4DResult(req, token)),
      onOpen1stPrizeFinishModal: () => dispatch(toggle1stPrizeFinishModal()),
      onOpen2ndPrizeFinishModal: () => dispatch(toggle2ndPrizeFinishModal()),
      onOpen3rdPrizeFinishModal: () => dispatch(toggle3rdPrizeFinishModal()),
      onOpenStarterPrizeFinishModal: (obj) => dispatch(toggleStarterPrizeFinishModal(obj)),
      onOpenConsolPrizeFinishModal: (obj) => dispatch(toggleConsolPrizeFinishModal(obj)),
      onOpenSubDigitPrizeFinishModal: (obj) => dispatch(toggleSubDigitFinishModal(obj))
})

const Connected4DResultWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(FourDResult));
export { Connected4DResultWithRouter as FourDResult };