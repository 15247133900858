export const UPDATE_LEAGUE_START='UPDATE_LEAGUE_START';
export const UPDATE_LEAGUE_SUCCESS='UPDATE_LEAGUE_SUCCESS';
export const UPDATE_LEAGUE_FAIL='UPDATE_LEAGUE_FAIL';

export const GET_LEAGUE_START='GET_LEAGUE_START';
export const GET_LEAGUE_SUCCESS='GET_LEAGUE_SUCCESS';
export const GET_LEAGUE_FAIL='GET_LEAGUE_FAIL';

export const OPEN_UPDATE_LEAGUE='OPEN_UPDATE_LEAGUE';
export const CLOSE_UPDATE_LEAGUE='CLOSE_UPDATE_LEAGUE';

export const SET_ID_TO_UPDATE='SET_ID_TO_UPDATE';