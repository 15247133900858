export const GET_PARTNER23C_START='GET_PARTNER23C_START';
export const GET_PARTNER23C_SUCCESS='GET_PARTNER23C_SUCCESS';
export const GET_PARTNER23C_FAIL='GET_PARTNER23C_FAIL';

export const UPDATE_PARTNER23C_START='UPDATE_PARTNER23C_START';
export const UPDATE_PARTNER23C_SUCCESS='UPDATE_PARTNER23C_SUCCESS';
export const UPDATE_PARTNER23C_FAIL='UPDATE_PARTNER23C_FAIL';

export const TOGGLE_P23C_UPDATE='TOGGLE_P23C_UPDATE';

export const GET_PARTNER_SINGLE_COMMISSION_START='GET_PARTNER_SINGLE_COMMISSION_START';
export const GET_PARTNER_SINGLE_COMMISSION_SUCCESS='GET_PARTNER_SINGLE_COMMISSION_SUCCESS';
export const GET_PARTNER_SINGLE_COMMISSION_FAIL='GET_PARTNER_SINGLE_COMMISSION_FAIL';


export const UPDATE_PARTNER_SINGLE_COMMISSION_START='UPDATE_PARTNER_SINGLE_COMMISSION_START';
export const UPDATE_PARTNER_SINGLE_COMMISSION_SUCCESS='UPDATE_PARTNER_SINGLE_COMMISSION_SUCCESS';
export const UPDATE_PARTNER_SINGLE_COMMISSION_FAIL='UPDATE_PARTNER_SINGLE_COMMISSION_FAIL';

export const TOGGLE_PSC_UPDATE='TOGGLE_PSC_UPDATE';

export const GET_PARTNER_COMMISSIONS_START='GET_PARTNER_COMMISSIONS_START';
export const GET_PARTNER_COMMISSIONS_SUCCESS='GET_PARTNER_COMMISSIONS_SUCCESS';
export const GET_PARTNER_COMMISSIONS_FAIL='GET_PARTNER_COMMISSIONS_FAIL';

export const CREATE_PARTNER_COMMISSION_START='CREATE_PARTNER_COMMISSION_START';
export const CREATE_PARTNER_COMMISSION_SUCCESS='CREATE_PARTNER_COMMISSION_SUCCESS';
export const CREATE_PARTNER_COMMISSION_FAIL='CREATE_PARTNER_COMMISSION_FAIL';

export const UPDATE_PARTNER_COMMISSION_START='UPDATE_PARTNER_COMMISSION_START';
export const UPDATE_PARTNER_COMMISSION_SUCCESS='UPDATE_PARTNER_COMMISSION_SUCCESS';
export const UPDATE_PARTNER_COMMISSION_FAIL='UPDATE_PARTNER_COMMISSION_FAIL';

export const DELETE_PARTNER_COMMISSION_START='DELETE_PARTNER_COMMISSION_START';
export const DELETE_PARTNER_COMMISSION_SUCCESS='DELETE_PARTNER_COMMISSION_SUCCESS';
export const DELETE_PARTNER_COMMISSION_FAIL='DELETE_PARTNER_COMMISSION_FAIL';

export const TOGGLE_PC_CREATE='TOGGLE_PC_CREATE';

export const TOGGLE_PC_UPDATE='TOGGLE_PC_UPDATE';

export const SET_PARTNER_COMMISSION_TO_UPDATE='SET_PARTNER_COMMISSION_TO_UPDATE';
