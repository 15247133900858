import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, InputNumber, Switch,DatePicker } from 'antd';
import { create3DCollection } from '../../redux/actions/3DCollection/create3DCollection';
import * as actionTypes from '../../redux/actions/3DCollection/actionTypes';
import moment from 'moment';
import {
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
};

class AddThreeDCollectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    onFinish = async (values) => {
        await this.props.onCreate({
            ...values,
            date: values.date.format("YYYY-MM-DD")
        },
            this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        const dateFormat = "DD/MM/YYYY";
        return (
            <Modal
                width={800}
                title="Create 3D Collection"
                key="c3dc"
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'create-3dc-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    {...layout}
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="create-3dc-form"
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    initialValues={{
                        copyFromPreviousCollection:false,
                        date:moment().utc()
                    }}
                >
                    <Form.Item
                        name="collectionId"
                        label="Collection ID"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{ width: 200 }} />
                    </Form.Item>
                    <Form.Item
                        name="copyFromPreviousCollection"
                        label="Copy From Previous Collection"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                    >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        />
                    </Form.Item>
                    <Form.Item
                        name="date"
                        label="Date"
                        rules={[
                            {
                                required: true,
                                message: 'require',
                            },
                        ]}
                    >
                        <DatePicker format={dateFormat} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.threeDCollection.addCollectionVisible,
    loading: state.threeDCollection.loading,
    token: state.auth.token,
    error: state.threeDCollection.error,
    message: state.threeDCollection.message,
})

const mapDispatchToProps = dispatch => ({
    onCreate: (obj, token) => dispatch(create3DCollection(obj, token)),
    onClose: () => dispatch({
        type: actionTypes.TOGGLE_3D_COLLECTION_ADD
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddThreeDCollectionModal);