
import { actionTypes } from "./actionTypes";
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const createLoseMessage= ({message}, token) => async dispatch => {
      start();
      const options = {
            url: `${api}/loseMessages`,
            method: 'POST',
            headers: {
                  Authorization: `Bearer ${token}`
            },
            data: {message}
      }
      await axios(options)
            .then(response => success(response.data))
            .catch(error => fail(error))

      function start() {
            dispatch({
                  type: actionTypes.CREATE_LOSE_MESSAGE_START
            })
      }
      function success(data) {
            dispatch({
                  type: actionTypes.CREATE_LOSE_MESSAGE_SUCCESS,
                  payload: data
            })
      }
      function fail(err) {
            dispatch({
                  type: actionTypes.CREATE_LOSE_MESSAGE_FAIL,
                  payload: getMessage(err)
            })
      }
}