import React from 'react';
import { Row, Col, Form, Button, Input, InputNumber, Switch, message } from 'antd';
import { connect } from 'react-redux';
import { createSeniormaster } from '../../redux/actions/seniormaster/createSeniormaster';
import {
   PlusOutlined,
   MinusOutlined
} from '@ant-design/icons';
import { containsOnlyAlphanumeric } from '../../helpers/containsOnlyAlphanumeric';

const COLUMN_WIDTH = {
   lg: 12,
   md: 12,
   sm: 24,
   xs: 24
}

const layout = {
   wrapperCol: { span: 24 },
};
const commmissionLayout = {
   wrapperCol: {
      span: 24
   }
}

class CreateSeniorMaster extends React.Component {
   state = {
      isMobile: false,
   }

   constructor(props) {
      super(props);
      const lastCreatedSmaster = localStorage.getItem('smaster');
      this.smaster = JSON.parse(lastCreatedSmaster);
      this.form = React.createRef();
      this.state = {
         commission: this.smaster?.commissions || [
            {
               matchCount: 0,
               betCommission: 0,
               winCommission: 0
            }
         ],
         isMobile: false
      }
   }

   async componentDidMount() {
      const query = window.matchMedia(`(max-width: 480px)`);
      query.onchange = (e) => {
         this.setState(prevState => {
            return {
               ...prevState,
               isMobile: e.matches
            }
         })
      }
      this.setState({
         isMobile: query.matches
      })
   }

   handleAddCommission = () => {
      this.setState(prevState => {
         const commission = prevState.commission
         commission.push({
            key: Math.random() * 10 - 1,
            matchCount: 0,
            betCommission: 0,
            winCommission: 0,
         });
         return {
            commission
         }
      })
   }
   handleRemove = (index) => {
      if (this.state.commission.length > 1) {
         this.setState(prevState => {
            const commission = prevState.commission;
            commission.splice(index, 1);
            return {
               commission
            }
         })
      }
   }
   onFinish = async (values) => {
      const request = {
         name: values.name,
         username: values.username,
         password: values.password,
         mobile: values.mobile,
         partnerShare: values.partnerShare,
         partnerThreeDShare:values.partnerThreeDShare,
         // partnerFourDShare:values.partnerFourDShare,
         twoThreeAllowed: true,
         extraCommissionAllowed: values.extraCommissionAllowed,
         twoDCommission: values.twoDCommission??0,
         threeDCommission: values.threeDCommission,
         betLimitation: {
            smasterMixMax: values.smasterMixMax,
            smasterSingleMax: values.smasterSingleMax,
            masterMixMax: values.masterMixMax,
            masterSingleMax: values.masterSingleMax,
            agentMixMax: values.agentMixMax,
            agentSingleMax: values.agentSingleMax,
            maxForSingleBet: values.maxForSingleBet,
            maxForMixBet: values.maxForMixBet,
            maxForTwoD: values.maxForTwoD || 0,
            maxForThreeD: values.maxForThreeD,
            // maxForFourD:values.maxForFourD
         },
         shareDetail: {
            sharePercentage: values.sharePercentage,
            threeDSharePercentage:values.threeDSharePercentage,
            // fourDSharePercentage:values.fourDSharePercentage,
         },
         singleBetCommission: {
            betCommission: values.betCommission,
            highBetCommission: values.highBetCommission,
            winCommission: values.winCommission,
            highWinCommission: values.highWinCommission
         },
         commissions: this.state.commission
      }
      await this.props.onCreate(request, this.props.token);
      if (this.props.message) {
         message.success(this.props.message);
         localStorage.setItem('smaster', JSON.stringify(request));
         this.form.current.resetFields();
      }
      if (this.props.error) {
         message.error(this.props.error)
      }
   }
   onFinishFailed = (errors) => {

   }
   handleBetCommission = (index, value) => {
      const commission = this.state.commission.map(
         (ele, i) => {
            if (i === index) return {
               ...ele,
               betCommission: value
            }
            return ele
         }
      )
      this.setState({ commission })
   }
   handleWinCommission = (index, value) => {
      const commission = this.state.commission.map(
         (ele, i) => {
            if (i === index) return {
               ...ele,
               winCommission: value
            }
            return ele
         }
      )
      this.setState({ commission })
   }
   handleMatchCount = (index, value) => {
      const commission = this.state.commission.map(
         (ele, i) => {
            if (i === index) return {
               ...ele,
               matchCount: value
            }
            return ele
         }
      )
      this.setState({ commission })
   }
   render() {
      const { isMobile } = this.state;
      const COMMISSION_INPUT = {
         width: isMobile ? '50px' : '100%'
      }
      return (
         <Row>
            <Col xxl={{ span: 18 }}
               xl={{ span: 18 }}
               lg={{ span: 18 }}
               md={{ span: 24 }}
               sm={{ span: 24 }}
               xs={{ span: 24 }}>
               <h2>Senior Master</h2>
               <Form
                  layout="vertical"
                  key="sm"
                  ref={this.form}
                  {...layout}
                  loading={this.props.loading}
                  name="basic"
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                  initialValues={{
                     extraCommissionAllowed: this.smaster?.extraCommissionAllowed || false,
                     smasterMixMax: this.smaster?.betLimitation?.smasterMixMax || 0,
                     smasterSingleMax: this.smaster?.betLimitation?.smasterSingleMax || 0,
                     masterMixMax: this.smaster?.betLimitation?.masterMixMax || 0,
                     masterSingleMax: this.smaster?.betLimitation?.masterSingleMax || 0,
                     agentMixMax: this.smaster?.betLimitation?.agentMixMax || 0,
                     agentSingleMax: this.smaster?.betLimitation?.agentSingleMax || 0,
                     maxForSingleBet: this.smaster?.betLimitation?.maxForSingleBet || 0,
                     maxForMixBet: this.smaster?.betLimitation?.maxForMixBet || 0,
                     maxForTwoD: this.smaster?.betLimitation?.maxForTwoD || 0,
                     maxForThreeD: this.smaster?.betLimitation?.maxForThreeD ?? 0,
                     // maxForFourD:this.smaster?.betCommission?.maxForFourD ?? 0,
                     sharePercentage: this.smaster?.shareDetail.sharePercentage || 0,
                     partnerShare: this.smaster?.partnerShare || 0,
                     partnerThreeDShare:this.smaster?.partnerThreeDShare || 0,
                     // partnerFourDShare:this.smaster?.partnerFourDShare || 0,
                     highBetCommission: this.smaster?.singleBetCommission.highBetCommission || 0,
                     highWinCommission: this.smaster?.singleBetCommission.highWinCommission || 0,
                     betCommission: this.smaster?.singleBetCommission.betCommission || 0,
                     winCommission: this.smaster?.singleBetCommission.winCommission || 0,
                     twoDCommission: this.smaster?.twoDCommission || 0,
                     threeDCommission: this.smaster?.threeDCommission || 0,
                     // fourDCommission:this.smaster?.fourDCommission || 0,
                     threeDSharePercentage:this.smaster?.threeDSharePercentage || 0,
                     // fourDSharePercentage:this.smaster?.fourDSharePercentage || 0,
                  }}
               >
                  <h3>Basic Info</h3>
                  <Row gutter={20}>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="username"
                           label="Username"
                           rules={[
                              {
                                 required: true,
                                 message: '3 Characters Exactly',
                                 max: 3,
                                 min: 3
                              },
                              () => ({
                                 validator(_, value) {
                                    if (value && containsOnlyAlphanumeric(value)) {
                                       return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Can only use A-Za-z0-9 characters.'));
                                 },
                              })
                           ]}
                        >
                           <Input />
                        </Form.Item>
                     </Col>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="name"
                           label="Name"
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <Input />
                        </Form.Item>
                     </Col>
                  </Row>
                  <Row gutter={20}>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="password"
                           label="Password"
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <Input />
                        </Form.Item>
                     </Col>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="mobile"
                           label="Mobile"
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <Input />
                        </Form.Item>
                     </Col>
                  </Row>
                  <Row gutter={20}>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="partnerShare"
                           label={<label style={{ whiteSpace: 'normal' }}>Partner Share</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} />
                        </Form.Item>
                     </Col>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                          md={{ span: COLUMN_WIDTH.md }}
                          sm={{ span: COLUMN_WIDTH.sm }}
                          xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                            name="partnerThreeDShare"
                            label={<label style={{ whiteSpace: 'normal' }}>Partner Three D Share</label>}
                            rules={[
                               {
                                  required: true,
                                  message: 'require',
                               },
                            ]}
                        >
                           <InputNumber style={styles.inputNumber} />
                        </Form.Item>
                     </Col>
                     {/* <Col lg={{ span: COLUMN_WIDTH.lg }}
                          md={{ span: COLUMN_WIDTH.md }}
                          sm={{ span: COLUMN_WIDTH.sm }}
                          xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                            name="partnerFourDShare"
                            label={<label style={{ whiteSpace: 'normal' }}>Partner Four D Share</label>}
                            rules={[
                               {
                                  required: true,
                                  message: 'require',
                               },
                            ]}
                        >
                           <InputNumber style={styles.inputNumber} />
                        </Form.Item>
                     </Col> */}
                  </Row>
                  <Row gutter={20}>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="extraCommissionAllowed"
                           label={<label style={{ whiteSpace: 'normal' }}>Extra  Commission Allowed</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <Switch />
                        </Form.Item>
                     </Col>
                  </Row>
                  <h3>Bet Limitation</h3>
                  <Row gutter={20}>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="smasterMixMax"
                           label={<label style={{ whiteSpace: 'normal' }}>SMaster Mix Max</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="smasterSingleMax"
                           label={<label style={{ whiteSpace: 'normal' }}>SMaster Single Max</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>

                  </Row>
                  <Row gutter={20}>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="masterMixMax"
                           label={<label style={{ whiteSpace: 'normal' }}>Master Mix Max</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="masterSingleMax"
                           label={<label style={{ whiteSpace: 'normal' }}>Master Single Max</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>

                  </Row>
                  <Row gutter={20}>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="agentMixMax"
                           label={<label style={{ whiteSpace: 'normal' }}>Agent Mix Max</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="agentSingleMax"
                           label={<label style={{ whiteSpace: 'normal' }}>Agent Single Max</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>
                  </Row>
                  <Row gutter={20}>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="maxForMixBet"
                           label={<label style={{ whiteSpace: 'normal' }}>Member Mix Max</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="maxForSingleBet"
                           label={<label style={{ whiteSpace: 'normal' }}>Member Single Max</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>

                  </Row>
                  <Row gutter={20}>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="maxForTwoD"
                           label={<label style={{ whiteSpace: 'normal' }}>Max For Two D</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="maxForThreeD"
                           label={<label style={{ whiteSpace: 'normal' }}>Max For Three D</label>}
                           rules={[{ required: true, type: "number", min: 10000, message:'minimum 10000' }]}
                        >
                           <InputNumber style={styles.inputNumber} />
                        </Form.Item>
                     </Col>
                     {/* <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="maxForFourD"
                           label={<label style={{ whiteSpace: 'normal' }}>Max For Four D</label>}
                           rules={[{ required: true, type: "number", min: 10000, message:'minimum 10000' }]}
                        >
                           <InputNumber style={styles.inputNumber} />
                        </Form.Item>
                     </Col> */}
                  </Row>
                  <h3>Share Detail</h3>
                  <Row gutter={20}>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="sharePercentage"
                           label={<label style={{ whiteSpace: 'normal' }}>Share Percentage</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                          md={{ span: COLUMN_WIDTH.md }}
                          sm={{ span: COLUMN_WIDTH.sm }}
                          xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                            name="threeDSharePercentage"
                            label={<label style={{ whiteSpace: 'normal' }}>Three D Share Percentage</label>}
                            rules={[
                               {
                                  required: true,
                                  message: 'require',
                               },
                            ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>
                     {/* <Col lg={{ span: COLUMN_WIDTH.lg }}
                          md={{ span: COLUMN_WIDTH.md }}
                          sm={{ span: COLUMN_WIDTH.sm }}
                          xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                            name="fourDSharePercentage"
                            label={<label style={{ whiteSpace: 'normal' }}>Four D Share Percentage</label>}
                            rules={[
                               {
                                  required: true,
                                  message: 'require',
                               },
                            ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col> */}
                  </Row>
                  <h3>Single Bet Commission</h3>
                  <Row gutter={20}>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="betCommission"
                           label="Commission"
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="highBetCommission"
                           label={<label style={{ whiteSpace: 'normal' }}>High Commission</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>
                  </Row>
                  <Row gutter={20}>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="winCommission"
                           label={<label style={{ whiteSpace: 'normal' }}>Tax</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="highWinCommission"
                           label={<label style={{ whiteSpace: 'normal' }}>High Tax</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>
                  </Row>
                  <h3>Three Four Commission</h3>
                  <Row gutter={20}>
                     <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="threeDCommission"
                           label={<label style={{ whiteSpace: 'normal' }}>ThreeD Commission</label>}
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col>
                     {/* <Col lg={{ span: COLUMN_WIDTH.lg }}
                        md={{ span: COLUMN_WIDTH.md }}
                        sm={{ span: COLUMN_WIDTH.sm }}
                        xs={{ span: COLUMN_WIDTH.xs }}>
                        <Form.Item
                           name="fourDCommission"
                           label="FourD Commission"
                           rules={[
                              {
                                 required: true,
                                 message: 'require',
                              },
                           ]}
                        >
                           <InputNumber style={styles.inputNumber} min={0} />
                        </Form.Item>
                     </Col> */}
                  </Row>
                  <h3>Mix Bet Commissions &nbsp;<Button
                     type="dashed"
                     icon={<PlusOutlined />}
                     onClick={this.handleAddCommission}
                  /></h3>
                  {
                     this.state.commission.map((commission, index) => {
                        return (
                           <Row gutter={{ xs: 6, sm: 10, md: 15, lg: 20 }}
                              style={{ marginTop: 10 }} align="center">
                              <Col span={8}>
                                 <Form.Item
                                    {...commmissionLayout}
                                    label="Match Count"
                                    name={`matchCount${commission.key}`}
                                 >
                                    <InputNumber
                                       onChange={(value) => this.handleMatchCount(index, value)}
                                       style={COMMISSION_INPUT}
                                       min={0}
                                       defaultValue={commission.matchCount}
                                    />
                                 </Form.Item>
                              </Col>
                              <Col span={6}>
                                 <Form.Item
                                    {...commmissionLayout}
                                    name={`winCommission${commission.key}`}
                                    label="Tax"
                                 >
                                    <InputNumber
                                       style={COMMISSION_INPUT}
                                       min={0}
                                       defaultValue={commission.winCommission}
                                       onChange={(value) => this.handleWinCommission(index, value)}
                                    />
                                 </Form.Item>
                              </Col>
                              <Col span={8}>
                                 <Form.Item
                                    {...commmissionLayout}
                                    name={`betCommission${commission.key}`}
                                    label="Commission"
                                 >
                                    <InputNumber
                                       style={COMMISSION_INPUT}
                                       min={0}
                                       onChange={(value) => this.handleBetCommission(index, value)}
                                       defaultValue={commission.betCommission}
                                    />
                                 </Form.Item>
                              </Col>

                              <Col span={2} style={{
                                 paddingTop: 30
                              }}>
                                 <Form.Item
                                    noStyle
                                 >
                                    <Button
                                       type="dashed"
                                       onClick={() => this.handleRemove(index)}
                                       icon={<MinusOutlined />}
                                    />
                                 </Form.Item>
                              </Col>
                           </Row>
                        );
                     })
                  }
                  <Form.Item>
                     <Button type="primary" htmlType="submit" loading={this.props.loading}>
                        Create
                     </Button>
                  </Form.Item>
               </Form>
            </Col>
         </Row>
      );
   }

}

const styles = {
   inputNumber: {
      width: '100%'
   },
}

const mapStateToProps = state => ({
   token: state.auth.token,
   message: state.seniorMaster.message,
   error: state.seniorMaster.error,
   loading: state.seniorMaster.loading
})

const mapDispatchToProps = dispatch => ({
   onCreate: (request, token) => dispatch(createSeniormaster(request, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSeniorMaster);