import React from 'react';
import { connect } from 'react-redux';
import { getPartner23Commission } from '../../redux/actions/partner/getPartner23Commission';
import { togglePartner23cUpdate, togglePCUpdate, togglePSCUpdate, togglePCCreate } from '../../redux/actions/partner/toggleModals';
import { Card, Row, Col, Table, PageHeader, Button, Space, Modal } from 'antd';
import { getPartnerSingleCommission } from '../../redux/actions/partner/getPartnerSingleCommission';
import { getPartnerCommission } from '../../redux/actions/partner/getPartnerCommission';
import { deletePartnerCommission } from '../../redux/actions/partner/deletePartnerCommission';
import { setPartnerCommissionToUpdate } from '../../redux/actions/partner/setPartnerCommissionToUpdate';
import {
   PlusSquareOutlined,
   DeleteOutlined,
   EditOutlined,
   ExclamationCircleOutlined
} from '@ant-design/icons';

class Partner extends React.Component {
   async componentDidMount() {
      await this.props.onLoad(this.props.token);
      await this.props.onGetSingleCommission(this.props.token);
      await this.props.onGetCommissions(this.props.token);
   }
   _handleDelete = (id) => {
      const { token, onDeleteCommission } = this.props;
      Modal.confirm({
         title: 'Delete Commission',
         icon: <ExclamationCircleOutlined />,
         content: 'Are you sure to delete?',
         okText: 'Yes',
         okType: 'danger',
         cancelText: 'No',
         onOk() {
            onDeleteCommission(id, token);
         },
      });
   }
   render() {
      const {
         twoDCommission,
         threeDCommission,
         // fourDCommission,
      } = this.props.twothreeCommission;
      const columns = [
         {
            title: 'Match Count',
            dataIndex: 'matchCount',
            key: 'matchCount',
         },
         {
            title: 'Commission',
            dataIndex: 'commission',
            key: 'commission'
         },
         {
            title: 'Action',
            render: (text, record) => (
               <Space size="middle">
                  <DeleteOutlined onClick={() => this._handleDelete(record.id)} />
                  <EditOutlined onClick={async () => {
                     await this.props.onSet(record);
                     await this.props.onOpenPCUpdate();
                  }} />
               </Space>
            )
         }
      ]
      return (
         <>
            <Row gutter={[16,20]}>
               <Col span={24}>
                  <Card
                     type="inner"
                     title={<div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                     }}>
                        <h3>Partner Two Three Four Commissions</h3>
                        <EditOutlined onClick={() => this.props.onOpen23CommissionUpdate()} />
                     </div>}
                     loading={this.props.loading}
                  >
                     <div>Two D Commission - {`${twoDCommission??0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                     <div>Three D Commission - {`${threeDCommission??0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                     {/* <div>Four D Commission - {`${fourDCommission??0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div> */}
                  </Card>
               </Col>
               <Col span={24}>
                  <Card
                     type="inner"
                     title={<div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                     }}>
                        <h3>Partner Single Commissions</h3>
                        <EditOutlined onClick={() => this.props.onOpenPSCUpdate()} />
                     </div>}
                     loading={this.props.loading}
                  >
                     <div>Commission - {`${this.props.singleCommission?.commission}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                     <div>High Commission - {`${this.props.singleCommission?.highCommission}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                  </Card>
               </Col>
               <Col span={24}>
                  <PageHeader
                     style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        border: '1px solid rgb(235, 237, 240)',
                     }}
                     title={<h5>Partner Commissions</h5>}
                  >
                     <Button
                        icon={<PlusSquareOutlined />}
                        style={{ background: '#3071b9', color: 'white' }}
                        onClick={() => this.props.onOpenPCCreate()}
                     />
                  </PageHeader>
                  <Row>
                     <Col span={24} style={{ overflowX: 'auto' }}>
                        <Table dataSource={this.props.commissions} columns={columns} />
                     </Col>
                  </Row>
               </Col>
            </Row>
         </>
      );
   }
}

const mapStateToProps = state => ({
   token: state.auth.token,
   loading: state.partner.loading,
   twothreeCommission: state.partner.twothreeCommission,
   singleCommission: state.partner.singleCommission,
   commissions: state.partner.commissions
})

const mapDispatchToProps = dispatch => ({
   onLoad: (token) => dispatch(getPartner23Commission(token)),
   onGetSingleCommission: (token) => dispatch(getPartnerSingleCommission(token)),
   onGetCommissions: (token) => dispatch(getPartnerCommission(token)),
   onDeleteCommission: (id, token) => dispatch(deletePartnerCommission(id, token)),
   onOpenPSCUpdate: () => dispatch(togglePSCUpdate()),
   onOpenPCUpdate: () => dispatch(togglePCUpdate()),
   onOpenPCCreate: () => dispatch(togglePCCreate()),
   onSet: (commission) => dispatch(setPartnerCommissionToUpdate(commission)),
   onOpen23CommissionUpdate: () => dispatch(togglePartner23cUpdate())
})

export default connect(mapStateToProps, mapDispatchToProps)(Partner);