import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

const updatePriceStart = () => ({
    type: actionTypes.UPDATE_PRICE_START
})

const updatePriceSuccess = (data) => ({
    type: actionTypes.UPDATE_PRICE_SUCCESS,
    payload: data
})

const updatePriceFail = (error) => ({
    type: actionTypes.UPDATE_PRICE_FAIL,
    payload: error
})

export const updatePrice = (priceInfo,matchId, token) => async dispatch => {
    dispatch(updatePriceStart());
    console.log('matchid',matchId)
    console.log('Priceinfo inside action',priceInfo)
    const options = {
        url: `${api}/matches/${matchId}/updatePrice`,
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: priceInfo
    }
    await axios(options)
        .then(response => dispatch(
            updatePriceSuccess(response.data)
        ))
        .catch(error => dispatch(
            updatePriceFail(
                getMessage(error)
            )
        ))
}