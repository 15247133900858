import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const getPayment = (token) => async dispatch => {
    dispatch({
        type: actionTypes.GET_PAYMENT_START
    });
    const options = {
        url: `${api}/payments`,
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    await axios(options)
        .then(response => {
            dispatch({
                type: actionTypes.GET_PAYMENT_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => dispatch({
            type: actionTypes.GET_PAYMENT_FAIL,
            payload: getMessage(error)
        }))
}