import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const getMatchCount=({startDate,endDate},token)=>async dispatch=>{
    dispatch({
        type:actionTypes.MATCH_COUNT_START,
    })
    const options={
        url:`${api}/reports/matchCount?startDate=${startDate}&endDate=${endDate}`,
        method:'GET',
        headers:{
            'Authorization':`Bearer ${token}`
        },
        data:{}
    }
    await axios(options)
        .then(response=> dispatch({
            type:actionTypes.MATCH_COUNT_SUCCESS,
            payload:response.data
        }))
        .catch(error=>dispatch({
            type:actionTypes.MATCH_COUNT_FAIL,
            payload:getMessage(error)
        }))
}