export const GET_COLLECTION_REPORT_START='GET_COLLECTION_REPORT_START';
export const GET_COLLECTION_REPORT_SUCCESS='GET_COLLECTION_REPORT_SUCCESS';
export const GET_COLLECTION_REPORT_FAIL='GET_COLLECTION_REPORT_FAIL';

export const GET_SMASTER_REPORT_START='GET_SMASTER_REPORT_START';
export const GET_SMASTER_REPORT_SUCCESS='GET_SMASTER_REPORT_SUCCESS';
export const GET_SMASTER_REPORT_FAIL='GET_SMASTER_REPORT_FAIL';

export const SMASTER_RP_BY_COLLECTION_START='SMASTER_RP_BY_COLLECTION_START';
export const SMASTER_RP_BY_COLLECTION_SUCCESS='SMASTER_RP_BY_COLLECTION_SUCCESS';
export const SMASTER_RP_BY_COLLECTION_FAIL='SMASTER_RP_BY_COLLECTION_FAIL';

export const MASTER_REPORT_START='MASTER_REPORT_START';
export const MASTER_REPORT_SUCCESS='MASTER_REPORT_SUCCESS';
export const MASTER_REPORT_FAIL='MASTER_REPORT_FAIL';

export const AGENT_BY_COLLECTION_AND_MASTER='AGENT_BY_COLLECTION_AND_MASTER';
export const AGENT_BY_COLLECTION_AND_MASTER_SUCCESS='AGENT_BY_COLLECTION_AND_MASTER_SUCCESS';
export const AGENT_BY_COLLECTION_AND_MASTER_FAIL='AGENT_BY_COLLECTION_AND_MASTER_FAIL';

export const MEMBER_BY_COLLECTION_AND_AGENT='MEMBER_BY_COLLECTION_AND_AGENT';
export const MEMBER_BY_COLLECTION_AND_AGENT_SUCCESS='MEMBER_BY_COLLECTION_AND_AGENT_SUCCESS';
export const MEMBER_BY_COLLECTION_AND_AGENT_FAIL='MEMBER_BY_COLLECTION_AND_AGENT_FAIL';

export const BET_REPORT_START='BET_REPORT_START';
export const BET_REPORT_SUCCESS='BET_REPORT_SUCCESS';
export const BET_REPORT_FAIL='BET_REPORT_FAIL';

export const MATCH_COUNT_START='MATCH_COUNT_START';
export const MATCH_COUNT_SUCCESS='MATCH_COUNT_SUCCESS';
export const MATCH_COUNT_FAIL='MATCH_COUNT_FAIL';

export const SET_BET_TYPE='SET_BET_TYPE';


