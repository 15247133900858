import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, message } from 'antd';
import { finishSubDigitPrize } from '../../redux/actions/4DCollection/finish4D';
import { toggleSubDigitFinishModal } from '../../redux/actions/4DCollection/toggleSubDigitFinishModal';

class FinishSubDigitPrizeModal extends React.Component {
      constructor(props) {
            super(props);
            this.form = React.createRef();
      }
      update = (newObj) => {
            this.setState(prevState => {
                  return {
                        ...prevState,
                        ...newObj
                  }
            })
      }
      onFinish = async ({number,id}) => {
            await this.props.onFinish({
                  type_digit: this.props.type_digit,
                  number,
                  id
            }, this.props.token);
            if (this.props.message) {
                  message.success(this.props.message);
                  this.props.onClose();
            }
            if (this.props.error) {
                  message.error(this.props.error);
            }
      }
      onFinishFailed = (errors) => {
            console.log(errors)
      }
      handleCancel = () => {
            this.props.onClose();
      }
      render() {
            const { type_digit, result } = this.props;
            let obj={title:"",number:null,id:null};
            switch (type_digit) {
                  case "FIRST_THREE":
                        obj = {
                              id: result?.firstPrize.id,
                              number: result?.firstPrize.number,
                              title: "Finish First Three Digit Prize",
                        }
                        break;
                  case "FIRST_TWO":
                        obj = {
                              id: result?.firstPrize.id,
                              number: result?.firstPrize.number,
                              title: "Finish First Two Digit Prize",
                        }
                        break;
                  case "SECOND_TWO":
                        obj = {
                              id: result?.secondPrize.id,
                              number: result?.secondPrize.number,
                              title: "Finish Second Two Digit Prize",
                        }
                        break;
                  case "THIRD_TWO":
                        obj = {
                              id: result?.thirdPrize.id,
                              number: result?.thirdPrize.number,
                              title: "Finish Third Two Digit Prize",
                        }
                        break;
                  default:
                        console.warn("no match found");
            }
            const {title,number,id}=obj;
            return (
                  <Modal
                        title={title}
                        key={title}
                        centered
                        visible={this.props.visible}
                        okButtonProps={{ form: 'finish-subdigit-form', key: 'submit', htmlType: 'submit' }}
                        confirmLoading={this.props.loading}
                        onCancel={this.handleCancel}
                        okText="Save"
                        loading={this.props.loading}
                  >
                        <Form
                              key={number + "form"}
                              layout="vertical"
                              ref={this.form}
                              id="finish-subdigit-form"
                              name="basic"
                              onFinish={()=>this.onFinish({number,id})}
                              onFinishFailed={this.onFinishFailed}>

                              <Form.Item
                                    key={number}
                                    name="number"
                                    label="Prize Number"
                                    rules={[
                                          {
                                                required: true,
                                                message: 'Require!',
                                          },
                                          {
                                                pattern: new RegExp(`^${number}$`),
                                                message: "Need to confirm the prize"
                                          }
                                    ]}>
                                    <Input />
                              </Form.Item>
                        </Form>
                  </Modal>
            );
      }
}

const mapStateToProps = state => ({
      visible: state.fourDCollection.finishSubDigitPrizeVisible,
      loading: state.fourDCollection.loading,
      token: state.auth.token,
      error: state.fourDCollection.error,
      message: state.fourDCollection.message,
      result: state.fourDCollection.result,
      type_digit: state.fourDCollection.type_digit
})

const mapDispatchToProps = dispatch => ({
      onFinish: (obj, token) => dispatch(finishSubDigitPrize(obj, token)),
      onClose: () => dispatch(toggleSubDigitFinishModal({ type_digit: null })),
})

export default connect(mapStateToProps, mapDispatchToProps)(FinishSubDigitPrizeModal);