import React from 'react';
import {connect} from 'react-redux';
import {Form, InputNumber, message, Modal} from 'antd';
import * as actionTypes from '../../redux/actions/seniormaster/actionTypes';
import {updatePartnerThreeDShare} from '../../redux/actions/seniormaster/updateSeniormaster';

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
};

class UpdatePartner3DShareModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    onFinish = async (values) => {
        await this.props.onUpdate({
                id: this.props.smaster?.id,
                sharePercentage: values.sharePercentage
            },
            this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }

    render() {
        return (
            <Modal
                title="Update Partner 3D Share"
                key={`3dShare` + this.props.smaster?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{form: 'update-p3ds-form', key: 'submit', htmlType: 'submit'}}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    {...layout}
                    ref={this.form}
                    style={{margin: 25}}
                    id="update-p3ds-form"
                    name="basic"
                    initialValues={{
                        sharePercentage: this.props.smaster?.partnerThreeDShare,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="sharePercentage"
                        label="Share Percentage"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{width: 150}}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.seniorMaster.updatePartner3dShareVisible,
    loading: state.seniorMaster.loading,
    token: state.auth.token,
    error: state.seniorMaster.error,
    message: state.seniorMaster.message,
    smaster: state.seniorMaster.one
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (share, token) => dispatch(updatePartnerThreeDShare(share, token)),
    onClose: () => dispatch({
        type: actionTypes.TOGGLE_PARTNER_3D_SHARE_UPDATE
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePartner3DShareModal);