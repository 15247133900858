export const SIGNUP_EMPLOYEE_START = 'SIGNUP_EMPLOYEE_START';
export const SIGNUP_EMPLOYEE_SUCCESS = 'SIGNUP_EMPLOYEE_SUCCESS';
export const SIGNUP_EMPLOYEE_FAIL = 'SIGNUP_EMPLOYEE_FAIL';

export const GET_EMPLOYEES_START = 'GET_EMPLOYEES_START';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_FAIL = 'GET_EMPLOYEES_FAIL';

export const UPDATE_EMPLOYEE_START = 'UPDATE_EMPLOYEE_START';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAIL = 'UPDATE_EMPLOYEE_FAIL';

export const RESET_EMPLOYEE_PASSWORD_START = 'RESET_EMPLOYEE_PASSWORD_START';
export const RESET_EMPLOYEE_PASSWORD_SUCCESS = 'RESET_EMPLOYEE_PASSWORD_SUCCESS';
export const RESET_EMPLOYEE_PASSWORD_FAIL = 'RESET_EMPLOYEE_PASSWORD_FAIL';