import {actionTypes} from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const get4DCollection=(id,token)=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_4D_COLLECTION_START
    })
    const options={
        url:`${api}/fourDCollections/${id}`,
        method:'GET',
        headers:{
            'Authorization':`Bearer ${token}`
        }
    }
    await axios(options)
        .then(response=>dispatch({
            type:actionTypes.GET_4D_COLLECTION_SUCCESS,
            payload:response.data
        }))
        .catch(error=>dispatch({
            type:actionTypes.GET_4D_COLLECTION_FAIL,
            payload:getMessage(error)
        }))
}