import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    GET_RULES_START,
    GET_RULES_SUCCESS,
    GET_RULES_FAIL,
    UPDATE_RULE_START,
    UPDATE_RULE_SUCCESS,
    UPDATE_RULE_FAIL,
    SET_RULE_TO_UPDATE,
    TOGGLE_UPDATE_RULE,
    CREATE_RULE_START,
    CREATE_RULE_SUCCESS,
    CREATE_RULE_FAIL,
    DELETE_RULE_START,
    DELETE_RULE_SUCCESS,
    DELETE_RULE_FAIL,
    TOGGLE_ADD_RULE
} from '../actions/rule/actionTypes';

const initialState = {
    loading: false,
    error: null,
    message: null,
    ruleUpdateVisible: false,
    ruleAddVisible:false,
    all: [],
    ruleToUpdate: null
}

const getRules = (state, action) => {
    return update(state, {
        loading: false,
        all: action.payload
    })
}

const setRuleToUpdate = (state, action) => {
    return update(state, {
        ruleToUpdate: action.payload
    })
}

const updateRule = (state, action) => {
    return update(state, {
        loading: false,
        all: state.all?.map(rule => {
            if (rule?.id === action.payload.id) {
                return action.payload
            }
            return rule
        })
    })
}

const toggleUpdateRule = (state, action) => {
    return update(state, {
        ruleUpdateVisible: !state.ruleUpdateVisible
    })
}

const createRule=(state,action)=>{
    return update(state,{
        loading:false,
        all:[...state.all,action.payload],
        message:'create success'
    })
}

const deleteRule=(state,action)=>{
    return update(state,{
        loading:false,
        all:state.all?.filter(rule=>rule.id!==action.payload)
    })
}

const toggleAddRule=(state,action)=>{
    return update(state,{
        ruleAddVisible:!state.ruleAddVisible
    })
}

const ruleReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RULES_START:
            return loading(state, action);
        case GET_RULES_SUCCESS:
            return getRules(state, action);
        case GET_RULES_FAIL:
            return error(state, action);
        case SET_RULE_TO_UPDATE:
            return setRuleToUpdate(state,action);
        case UPDATE_RULE_START:
            return loading(state,action);
        case UPDATE_RULE_SUCCESS:
            return updateRule(state,action);
        case UPDATE_RULE_FAIL:
            return error(state,action);
        case TOGGLE_UPDATE_RULE:
            return toggleUpdateRule(state,action);
        case CREATE_RULE_START:
            return loading(state,action);
        case CREATE_RULE_SUCCESS:
            return createRule(state,action);
        case CREATE_RULE_FAIL:
            return error(state,action);
        case DELETE_RULE_START:
            return loading(state,action);
        case DELETE_RULE_SUCCESS:
            return deleteRule(state,action);
        case DELETE_RULE_FAIL:
            return error(state,action);
        case TOGGLE_ADD_RULE:
            return toggleAddRule(state,action);
        default:
            return state;
    }
}

export default ruleReducer;