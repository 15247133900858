import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const updateSingleBet = (matchId, singleBet, token) => async dispatch => {
    dispatch({
        type: actionTypes.UPDATE_SINGLE_BET_START
    });
    const options = {
        url: `${api}/matches/${matchId}/updateSingleBet`,
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: {
            singleBet
        }
    }
    await axios(options)
        .then(response => dispatch({
            type: actionTypes.UPDATE_SINGLE_BET_SUCCESS,
            payload: response.data
        }))
        .catch(error => dispatch({
            type: actionTypes.UPDATE_SINGLE_BET_FAIL,
            payload: getMessage(error)
        }))
}