
export const createLeagues = (lsport) => {
    let leagueSet = new Set();
    if (lsport) {
        lsport.map(match => {
            leagueSet.add(`${match.leagueName}`);
            return void 0;
        });
    }
    return createArray(leagueSet);
}

function createArray(leagueStrSet){
    let leagueArray=[];
    let splittedArray;
    let league;
    for(let value of leagueStrSet){
        splittedArray=value.split(',');
        league=createLeageObj(splittedArray);
        leagueArray.push(league);
    }
    return leagueArray;
}

function createLeageObj(splittedArray){
    return {
        id:splittedArray[0],
        name:splittedArray[1],
        location:splittedArray[2]
    }
}