import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const createPayment = ({ name, imageUrl, type }, token) => async dispatch => {
   dispatch({
      type: actionTypes.CREATE_PAYMENT_START
   });
   const options = {
      url: `${api}/payments`,
      method: 'POST',
      headers: {
         "Authorization": `Bearer ${token}`
      },
      data: {
         name, imageUrl, type
      }
   }
   await axios(options)
      .then(response => {
         dispatch({
            type: actionTypes.CREATE_PAYMENT_SUCCESS,
            payload: response.data
         });
      })
      .catch(error => dispatch({
         type: actionTypes.CREATE_PAYMENT_FAIL,
         payload: getMessage(error)
      }))
}