import { useEffect } from "react";
import useSSE from "../../hooks/useSSE";
import baseUrl from "../../redux/utilities/api";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateRecalculateStatus } from "../../redux/actions/collection/updateRecalculateStatus";
import { useNetwork } from "@mantine/hooks";
import { MdWifiOff, MdWifi } from "react-icons/md";

export const CalculateSSE = () => {
    const networkStatus = useNetwork();

    const dispatch = useDispatch();

    const [api, contextHolder] = notification.useNotification();

    const token = useSelector((state) => state.auth.token);

    const url = `${baseUrl}/sse/subscribe`;

    const eventName = "CALCULATING_SUCCESS";

    const options = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        heartbeatTimeout: 86400000,
    };

    const [event, error] = useSSE({ url, eventName, options });

    useEffect(() => {
        if (event) {
            api.success({
                key:event.data.matchId,
                message: "Calculation Success Match #" + event.data.matchId,
                description: event.message,
                placement: "bottomRight",
                duration: 2,
            });
            dispatch(updateRecalculateStatus(event.data));
        }
    }, [event]);

    useEffect(() => {
        if (networkStatus.online) {
            api.success({
                key: 'network-success',
                icon: <MdWifi color="green" />,
                message: "Connected to network",
                placement: "bottomLeft",
                duration: 2,
            });
        } else {
            api.error({
                key: 'network-error',
                icon: <MdWifiOff color="red" />,
                message: "Disconnected network",
                placement: "bottomLeft",
                duration: 1,
            });
        }
    }, [networkStatus.online]);

    useEffect(() => {
        if (error) {
            api.error({
                key:'event-error',
                message: error,
                placement: "bottomRight",
                duration: 0,
            });
        }
    }, [error]);

    return <>{contextHolder}</>;
};
