import { useEffect } from 'react';
import { Row, Col, message, Spin } from 'antd';
import { connect } from 'react-redux';
import { getSingleBetPattern } from '../../redux/actions/betPattern/getSingleBetPattern';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const SingleBetPattern = ({
   loading,
   token,
   singleBets,
   error,
   onGet,
}) => {
   const history = useHistory();
   useEffect(async () => {
      await onGet(token);
      if (error) {
         message.error(error);
      }
   }, []);
   const bodyAbsolute = (num) => <span style={styles.exceed}>{num}</span>;
   const handlePush = (matchId, key) => {
      history.push({
         pathname: '/betsByPattern',
         state: {
            pattern: `${matchId}.${key}`
         }
      });
   };
   const element = loading ? (<Row>
      <Col span={24} style={{ height: '100vh' }}>
         <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center'
         }}>
            <Spin size="large" />
         </div>
      </Col></Row>) :
      (<Row>
         <Col span={24}>
            {singleBets.map(obj => (
               <div style={styles.box}>
                  <div style={styles.top}>
                     {
                        moment(obj.matchTime).locale('my').format('DD-MM-YYYY hh:mm A')
                     }, ID:{obj.matchId}
                  </div>
                  <div style={styles.bottom}>
                     <div style={styles.home}>
                        <span style={styles.homeText}>Home</span>
                        <span style={styles.teamName}>
                           {obj?.homeTeam.name}{obj?.homeUpper ? `(${obj?.odds}${obj?.price >= 0 ? '+' + obj?.price : obj?.price})` : null}
                        </span>
                        <div style={styles.totalWrapper}>
                           <span style={styles.total}
                              onClick={() => handlePush(obj?.matchId, "BH")}>
                              {obj?.homeTotal}
                           </span>
                           {obj?.homeTotal > obj?.awayTotal ? bodyAbsolute(obj?.bodyAbsoluteAmount) : null}
                        </div>
                     </div>
                     <div style={styles.home}>
                        <span style={styles.homeText}>Goal Score</span>
                        <span style={styles.teamName}>
                           {obj?.homeScore}-{obj?.awayScore}
                        </span>
                     </div>
                     <div style={styles.home}>
                        <span style={styles.homeText}>
                           Away
                        </span>
                        <span style={styles.teamName}>
                           {obj?.awayTeam.name}{obj?.homeUpper ? null : `(${obj?.odds}${obj?.price >= 0 ? '+' + obj?.price : obj?.price})`}
                        </span>
                        <div style={styles.totalWrapper}>
                           <span style={styles.total}
                              onClick={() => handlePush(obj?.matchId, "BA")}>
                              {obj?.awayTotal}
                           </span>
                           {obj?.homeTotal < obj?.awayTotal ? bodyAbsolute(obj?.bodyAbsoluteAmount) : null}
                        </div>
                     </div>
                     {/* over */}
                     <div style={styles.home}>
                        <span style={styles.homeText}>Over</span>
                        <div style={styles.totalWrapper}>
                           <span style={styles.total}
                              onClick={() => handlePush(obj?.matchId, "GU")}
                           >
                              {obj?.overTotal}
                           </span>
                           {obj?.overTotal > obj?.underTotal ? bodyAbsolute(obj?.goalsAbsoluteAmount) : null}
                        </div>
                     </div>
                     <div style={styles.home}>
                        <span style={styles.homeText}>Over/Under</span>
                        <span style={styles.teamName}>
                           {obj?.goalTotal}{obj?.goalTotalPrice >= 0 ? `+${obj?.goalTotalPrice}` : obj?.goalTotalPrice}
                        </span>
                     </div>
                     <div style={styles.home}>
                        <span style={styles.homeText}>
                           Under
                        </span>
                        <div style={styles.totalWrapper}>
                           <span style={styles.total}
                              onClick={() => handlePush(obj?.matchId, "GL")}>
                              {obj?.underTotal}
                           </span>
                           {obj?.overTotal < obj?.underTotal ? bodyAbsolute(obj?.goalsAbsoluteAmount) : null}
                        </div>
                     </div>
                  </div>
               </div>
            ))}
         </Col>
      </Row>);
   return (
      <>
         {element}
      </>
   );
}

const styles = {
   box: {
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      boxSizing: 'border-box',
      boxShadow: "0 2px 6px rgba(140,140,140,0.3)",
      borderRadius: '5px',
      margin: '10px'
   },
   top: {
      marginRight: 'auto',
      padding: '3px 10px',
      borderRadius: '30px',
      backgroundColor: '#009688',
      color: 'white'
   },
   bottom: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
      marginTop: '10px',
      gridGap: '15px'
   },
   home: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
   },
   homeText: {
      color: 'gray'
   },
   teamName: {
      color: '#666'
   },
   total: {
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: '30px',
      backgroundColor: '#E0E0E0',
      cursor: 'pointer'
   },
   totalWrapper: {
      display: 'flex',
   },
   exceed: {
      marginLeft: 10,
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: '30px',
      backgroundColor: '#FF5252',
      color: 'white'
   }
}

const mapStateToProps = state => ({
   loading: state.betPattern.loading,
   token: state.auth.token,
   singleBets: state.betPattern.singleBet,
   error: state.betPattern.error,
})

const mapDispatchToProps = dispatch => ({
   onGet: (token) => dispatch(getSingleBetPattern(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SingleBetPattern);