export const CREATE_SENIOR_MASTER_START='CREATE_SENIOR_MASTER_START';
export const CREATE_SENIOR_MASTER_SUCCESS='CREATE_SENIOR_MASTER_SUCCESS';
export const CREATE_SENIOR_MASTER_FAIL='CREATE_SENIOR_MASTER_FAIL';

export const GET_SENIOR_MASTER_START='GET_SENIOR_MASTER_START';
export const GET_SENIOR_MASTER_SUCCESS='GET_SENIOR_MASTER_SUCCESS';
export const GET_SENIOR_MASTER_FAIL='GET_SENIOR_MASTER_FAIL';

export const UPDATE_SENIOR_MASTER_START='UPDATE_SENIOR_MASTER_START';
export const UPDATE_SENIOR_MASTER_SUCCESS='UPDATE_SENIOR_MASTER_SUCCESS';
export const UPDATE_SENIOR_MASTER_FAIL='UPDATE_SENIOR_MASTER_FAIL';

export const SET_COMMISSION_TO_UPDATE='SET_COMMISSION_TO_UPDATE';

export const TOGGLE_BASIC_INFO_UPDATE='TOGGLE_BASIC_INFO_UPDATE';
export const TOGGLE_BET_LIMIT_UPDATE='TOGGLE_BET_LIMIT_UPDATE';
export const TOGGLE_COMMISSION_UPDATE='TOGGLE_COMMISSION_UPDATE';
export const TOGGLE_SHARE_DETAIL_UPDATE='TOGGLE_SHARE_DETAIL_UPDATE';
export const TOGGLE_SINGLE_BET_COMMISSION_UPDATE='TOGGLE_SINGLE_BET_COMMISSION_UPDATE';
export const TOGGLE_SINGLE_BET_UPDATE='TOGGLE_SINGLE_BET_UPDATE';
export const TOGGLE_EXTRA_COMMISSION_UPDATE='TOGGLE_EXTRA_COMMISSION_UPDATE';
export const TOGGLE_PARTNER_SHARE_UPDATE='TOGGLE_PARTNER_SHARE_UPDATE';
export const TOGGLE_PARTNER_3D_SHARE_UPDATE='TOGGLE_PARTNER_3D_SHARE_UPDATE';
export const TOGGLE_PARTNER_4D_SHARE_UPDATE='TOGGLE_PARTNER_4D_SHARE_UPDATE';
export const TOGGLE_2D_COMMISSION_UPDATE='TOGGLE_2D_COMMISSION_UPDATE';
export const TOGGLE_3D_COMMISSION_UPDATE='TOGGLE_3D_COMMISSION_UPDATE';
export const TOGGLE_4D_COMMISSION_UPDATE='TOGGLE_4D_COMMISSION_UPDATE';
export const TOGGLE_ADD_COMMISSION_MODAL='TOGGLE_ADD_COMMISSION_MODAL';
export const TOGGLE_RESET_PASSWORD_MODAL='TOGGLE_RESET_PASSWORD_MODAL';

export const GET_ONE_SMASTER_START='GET_ONE_SMASTER_START';
export const GET_ONE_SMASTER_SUCCESS='GET_ONE_SMASTER_SUCCESS';
export const GET_ONE_SMASTER_FAIL='GET_ONE_SMASTER_FAIL';

export const UPDATE_SUSPEND_START='UPDATE_SUSPEND_START';
export const UPDATE_SUSPEND_SUCCESS='UPDATE_SUSPEND_SUCCESS';
export const UPDATE_SUSPEND_FAIL='UPDATE_SUSPEND_FAIL';

export const ADD_COMMISSION_START='ADD_COMMISSION_START';
export const ADD_COMMISSION_SUCCESS='ADD_COMMISSION_SUCCESS';
export const ADD_COMMISSION_FAIL='ADD_COMMISSION_FAIL';

export const RESET_PASSWORD_START='RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS='RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL='RESET_PASSWORD_FAIL';

export const UPDATE_SMASTER_BALANCE='UPDATE_SMASTER_BALANCE';

export const GET_SM_TRANSACTIONS_START='GET_SM_TRANSACTIONS_START';
export const GET_SM_TRANSACTIONS_SUCCESS='GET_SM_TRANSACTIONS_SUCCESS';
export const GET_SM_TRANSACTIONS_FAIL='GET_SM_TRANSACTIONS_FAIL';

export const GET_SM_TRANSACTION_DETAIL_START='GET_SM_TRANSACTION_DETAIL_START';
export const GET_SM_TRANSACTION_DETAIL_SUCCESS='GET_SM_TRANSACTION_DETAIL_SUCCESS';
export const GET_SM_TRANSACTION_DETAIL_FAIL='GET_SM_TRANSACTION_DETAIL_FAIL';