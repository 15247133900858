import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { searchBetByUsernameAndAmount } from "../../redux/actions/searchBet/searchBetByUsernameAndAmount";
import { Table, Row, Col, Button, Input, InputNumber, message } from "antd";
import {
    EyeOutlined
} from '@ant-design/icons';
import currency from "currency.js";
import { useEffect, useState } from "react";
import { clearBetByUsername } from "../../redux/actions/searchBet/clearBets";

const { Column, ColumnGroup } = Table;

const SearchByUsername = () => {
    const history = useHistory();
    const token = useSelector(state => state.auth.token);
    const loading = useSelector(state => state.searchBet.loading);
    const bets = useSelector(state => state.searchBet.resultByUsername);
    const error = useSelector(state=>state.searchBet.error);

    useEffect(()=>{
        if(error){
            message.error(error);
        }
    },[error])

    const [username, setUsername] = useState("");
    const [amount, setAmount] = useState(null);

    const dispatch = useDispatch();

    const handleSearch = () => {
        const bet = {
            username,
            amount,
        };
        dispatch(searchBetByUsernameAndAmount(bet, token))
    }

    useEffect(()=>{
        dispatch(clearBetByUsername());
    },[])

    return (
        <>
            <h1 style={{ marginBlockEnd: 20 }}>Search Bets By Username</h1>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap', marginBlock: 20 }}>
                <Input value={username} onChange={e => setUsername(e.target.value)} style={{ width: 200 }} placeholder="Username" />
                <InputNumber value={amount} onChange={value => setAmount(value)} style={{ minWidth: 200 }} placeholder="Bet Amount" />
                <Button type="primary" onClick={handleSearch}>
                    Search
                </Button>
            </div>
            <Row gutter={[24, 24]} justify="left">
                <Col lg={24} md={24} sm={24}>
                    <Table
                        bordered
                        dataSource={bets}
                        loading={loading}
                    >
                        <Column
                            title="Bet Id"
                            dataIndex="id"
                            key="id"
                            align="center"
                        />
                         <Column
                            title="Username"
                            dataIndex="username"
                            key="username"
                        />
                        <ColumnGroup title="Partner">
                            <Column
                                title="Bet"
                                dataIndex="partnerBetAmount"
                                key="partnerBetAmount"
                                align="right"
                            />
                            <Column
                                title="Commission"
                                dataIndex="partnerCommissionAmount"
                                key="partnerCommissionAmount"
                                align="right"
                            />
                            <Column
                                title="W/L"
                                render={(text, {
                                    partnerBetAmount,
                                    partnerWinAmount,
                                    partnerCommissionAmount }) =>
                                    currency(partnerBetAmount).subtract(currency(partnerWinAmount).add(currency(partnerCommissionAmount))).value
                                }
                            />
                        </ColumnGroup>

                        <ColumnGroup title="Senior Master">
                            <Column
                                title="Bet"
                                dataIndex="smasterBetAmount"
                                key="smasterBetAmount"
                                align="right"
                            />
                            <Column
                                title="Commission"
                                dataIndex="smasterCommissionAmount"
                                key="smasterCommissionAmount"
                                align="right"
                            />
                            <Column
                                title="W/L"
                                render={(text, {
                                    smasterBetAmount,
                                    smasterWinAmount,
                                    smasterCommissionAmount }) =>
                                    currency(smasterBetAmount).subtract(currency(smasterWinAmount).add(smasterCommissionAmount)).value
                                }
                            />
                        </ColumnGroup>
                        <Column
                            title="Detail"
                            key="detail"
                            render={(text, record) => <EyeOutlined
                                onClick={() => {
                                    history.push(`/bet_detail/${record.id}`)
                                }}
                            />}
                        />
                    </Table>
                </Col>
            </Row>
        </>
    )
}

export default SearchByUsername;