import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, Input, Upload, Select } from 'antd';
import { toggleAddPaymentModal } from '../../redux/actions/payment/toggleModal';
import { createPayment } from '../../redux/actions/payment/createPayment';
import {
   UploadOutlined,
} from '@ant-design/icons';

class AddPaymentModal extends React.Component {
   constructor(props) {
      super(props);
      this.form = React.createRef();
      this.state = {
         file: null,
         errorMsg: null,
         validateStatus: null,
         loading: false,
      }
   }
   layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
   };
   handleUpload = async (file) => {
      const url = "https://api.cloudinary.com/v1_1/mix555/image/upload/";
      const formData = new FormData();
      formData.append("file", file);
      formData.append("folder", "payments");
      formData.append("upload_preset", "vygiuxi9");

      const result = await fetch(url, {
         method: "POST",
         body: formData
      });
      return result.json();
   }
   onFinish = async (values) => {
      if (this.state.file) {
         this.setState(prevState => {
            return {
               ...prevState,
               loading: true
            }
         });
         const uploadedFile = await this.handleUpload(this.state.file);
         console.log('url', uploadedFile.secure_url);
         await this.props.onAdd({
            name: values.name,
            imageUrl: uploadedFile?.secure_url,
            type: values.type
         },
            this.props.token);
         this.setState(prevState => {
            return {
               ...prevState,
               loading: false
            }
         });
         if (this.props.error) {
            message.error(this.props.error);
         }
         if (this.props.message) {
            message.success(this.props.message);
         }
         this.setState(value => ({
            ...value,
            errorMsg: null,
            validateStatus: null
         }))
         this.form.current.resetFields();
         this.props.onClose();
      } else {
         this.setState(value => ({
            ...value,
            errorMsg: 'Select an image',
            validateStatus: 'error'
         }))
      }
   }
   onFinishFailed = (errors) => {
      console.log(errors)
   }
   handleCancel = () => {
      this.props.onClose();
   }
   beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
         message.error('You can only upload JPG/PNG file!');
      }
      return isJpgOrPng;
   }
   handleChange = info => {
      if (info.fileList[0]?.originFileObj) {
         this.setState(prevState => ({
            ...prevState,
            file: info.fileList[0]?.originFileObj,
            validateStatus: 'success',
            errorMsg: null
         }))
      } else {
         this.setState(prevState => ({
            ...prevState,
            file: null,
            validateStatus: 'error',
            errorMsg: 'Select an image'
         }))
      }
   };
   render() {
      const { Option } = Select;
      return (
         <Modal
            title="Add New Payment"
            key={"add-payment-modal"}
            centered
            visible={this.props.visible}
            okButtonProps={{ form: 'add-payment-form', key: 'submit', htmlType: 'submit' }}
            confirmLoading={this.state.loading}
            onCancel={this.handleCancel}
            okText="Save"
            loading={this.props.loading}
         >
            <Form
               {...this.layout}
               ref={this.form}
               style={{ margin: 25 }}
               id="add-payment-form"
               name="basic"
               onFinish={this.onFinish}
               onFinishFailed={this.onFinishFailed}
            >
               <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                     {
                        required: true,
                        message: 'Require!',
                     }
                  ]}
                  hasFeedback
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  name="type"
                  label="Type"
                  rules={[
                     {
                        required: true,
                        message: 'Require!',
                     }
                  ]}
               >
                  <Select>
                     <Option value="BANK">BANK</Option>
                     <Option value="PAY">PAY</Option>
                  </Select>
               </Form.Item>
               <Form.Item
                  name="image"
                  label=' '
                  validateStatus={this.state.validateStatus}
                  help={this.state.errorMsg}
               >
                  <Upload
                     accept=".png,.jpg,image/png,image/jpeg"
                     maxCount={1}
                     multiple={false}
                     onChange={this.handleChange}
                     beforeUpload={this.beforeUpload}>
                     <div style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        border: '1px solid #D9D9D9',
                        padding: '5px 10px',
                        borderRadius: '2px',
                        cursor: 'pointer'
                     }}>
                        <UploadOutlined />
                        <span>Select</span>
                     </div>
                  </Upload>
               </Form.Item>

            </Form>
         </Modal>
      );
   }
}

const mapStateToProps = state => ({
   visible: state.payment.addVisible,
   loading: state.payment.loading,
   token: state.auth.token,
   error: state.payment.error,
   message: state.payment.message,
})

const mapDispatchToProps = dispatch => ({
   onAdd: (obj, token) => dispatch(createPayment(obj, token)),
   onClose: () => dispatch(toggleAddPaymentModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentModal);