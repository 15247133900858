import React from 'react';
import {Button, Col, DatePicker, Form, message, Row, Select, Table} from 'antd';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getCollectionReport} from '../../redux/actions/report/getCollectionReport';
import {setBetType} from '../../redux/actions/report/setBetType';
import moment from 'moment';
import currency from 'currency.js';
import './index.css';
import {EyeOutlined} from '@ant-design/icons';
import {ParseIntAndFormat} from "./ParseIntAndFormat";

class CollectionReport extends React.Component {
    state = {
        startDate: null,
        endDate: null
    }
    dateHandler = (value, dateString) => {
        this.setState(prevState => {
            return {
                ...prevState,
                startDate: dateString[0],
                endDate: dateString[1]
            }
        });
    }
    onFinish = async (values) => {
        const {startDate, endDate} = this.state;
        await this.props.onGetReport({startDate, endDate, type: this.props.type}, this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
    }
    onFinishFailed = (errors) => {
        console.warn(errors);
    }

    render() {
        const {RangePicker} = DatePicker;
        const {Option} = Select;
        const {Column, ColumnGroup} = Table;
        return (
            <Row>
                <Col span={24}>
                    <h1>Daily Collections</h1>
                    <Form
                        name="dates"
                        layout="inline"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}>
                        <Row gutter={[16, 16]}>
                            <Col>
                                <Select
                                    defaultValue={this.props.type}
                                    onChange={(value) => this.props.onSetBetType(value)}>
                                    <Option value="SOCCER">Soccer</Option>
                                    {/* <Option value="TWOD">Two D</Option> */}
                                    <Option value="THREED">Three D</Option>
                                    {/* <Option value="FOURD">Four D</Option> */}
                                </Select>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="dates"
                                    validateStatus="warning"
                                >
                                    <RangePicker onChange={this.dateHandler} format="YYYY-MM-DD"/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Load
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Row style={{marginTop: '20px'}}>
                        <Col span={24} style={{overflowX: 'auto'}}>
                            <Table
                                bordered
                                loading={this.props.loading}
                                dataSource={this.props.collectionReport}>
                                <Column
                                    title="Date"
                                    dataIndex="date"
                                    key="date"
                                    align="center"
                                    render={(text, record) => moment(record.date).locale('my').format('DD-MM-YYYY')}
                                />
                                <Column title="Total Bet" dataIndex="userBetAmount"
                                        render={(text,record)=>ParseIntAndFormat(record.userBetAmount??0)}
                                        key="userBetAmount" align="right"/>
                                <ColumnGroup title="Partner">
                                    <Column title="Bet" dataIndex="partnerBetAmount"
                                            render={(text,record)=>ParseIntAndFormat(record.partnerBetAmount??0)}
                                            key="partnerBetAmount"
                                            align="right"/>
                                    <Column title="Commission" dataIndex="partnerCommissionAmount"
                                            key="partnerCommissionAmount" align="right"
                                             render={(text,record)=>ParseIntAndFormat(record.partnerCommissionAmount??0)}/>
                                    <Column
                                        title="W/L"
                                        key="p_win_lose"
                                        render={(text, record) => {
                                          let sum=currency(record.partnerBetAmount)
                                              .subtract(currency(record.partnerWinAmount))
                                              .subtract(currency(record.partnerCommissionAmount))
                                              .value;
                                          return ParseIntAndFormat(sum??0);
                                        }
                                        }
                                        align="right"
                                    />
                                </ColumnGroup>
                                <ColumnGroup title="Company">
                                    <Column title="Bet" dataIndex="smasterBetAmount"
                                            render={(text,record)=>ParseIntAndFormat(record.smasterBetAmount??0)}
                                            key="smasterBetAmount"
                                            align="right"/>
                                    <Column title="Commission" dataIndex="smasterCommissionAmount"
                                            key="smasterCommissionAmount" align="right"
                                            render={(text,record)=>ParseIntAndFormat(record.smasterCommissionAmount??0)}/>
                                    <Column
                                        title="W/L"
                                        key="s_win_lose"
                                        render={(text, record) => {
                                           let sum=currency(record.smasterBetAmount)
                                               .subtract(currency(record.smasterWinAmount))
                                               .subtract(currency(record.smasterCommissionAmount))
                                               .value;
                                           return ParseIntAndFormat(sum??0);
                                        }
                                        }
                                        align="right"
                                    />
                                </ColumnGroup>
                                <Column title="Action" key="action" align="center" render={
                                    (text, record) => <EyeOutlined onClick={
                                        () => this.props.history.push(`/report/daily/${record.date}/smasters`)
                                    }/>
                                }/>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.report.loading,
    token: state.auth.token,
    error: state.report.error,
    collectionReport: state.report.collection,
    type: state.report.type,
})

const mapDispatchToProps = dispatch => ({
    onGetReport: (obj, token) => dispatch(getCollectionReport(obj, token)),
    onSetBetType: (type) => dispatch(setBetType(type))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollectionReport));