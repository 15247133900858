import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, InputNumber } from 'antd';
import { toggleUpdateModal } from '../../redux/actions/3DWinPercent/toggleUpdateModal';
import { update3DWinPercent } from '../../redux/actions/3DWinPercent/update3DWinPercent';

class UpdateThreeDWinPercentModal extends React.Component {
    
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    onFinish = async (values) => {
        await this.props.onUpdate(values,
            this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        return (
            <Modal
                title="Update Three D Win Percent"
                key={this.props.threeDWinPercent?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'update-3wp-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    layout="vertical"
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="update-3wp-form"
                    name="basic"
                    initialValues={{
                        winPercent:this.props.threeDWinPercent?.winPercent,
                        specialNumberWinPercent:this.props.threeDWinPercent?.specialNumberWinPercent,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="winPercent"
                        label="Win Percent"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        name="specialNumberWinPercent"
                        label="Special Number Win Percent"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber />
                    </Form.Item>
                    
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.threeDWinPercent.updateVisible,
    loading: state.threeDWinPercent.loading,
    token: state.auth.token,
    error: state.threeDWinPercent.error,
    message: state.threeDWinPercent.message,
    threeDWinPercent: state.threeDWinPercent.one
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (obj, token) => dispatch(update3DWinPercent(obj, token)),
    onClose: () => dispatch(toggleUpdateModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateThreeDWinPercentModal);