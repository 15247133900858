import React from 'react';
import { Row, Col,message, Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {getAgreements} from '../../redux/actions/agreement/getAgreements';
import {
    EditOutlined,
} from '@ant-design/icons';
import { toggleAgreementUpdateModal } from '../../redux/actions/agreement/toggleModals';



class Agreements extends React.Component {
    componentDidMount() {
        this.props.onGetAll(this.props.token);
    }
    componentDidUpdate() {
        if (this.props.error) {
            message.error(this.props.error);
        }
    }
    _openEditModal =  () => {
        this.props.onOpen();
    }
    render() {
        return (
            <Row>
                <Col span={24}>
                    <Card 
                    type="inner" 
                    span={24}
                    title={<div style={{
                        display:'flex',
                        justifyContent:'space-between',
                        alignItems:'center',
                        width:'100%'
                    }}>
                        <span>Agreements</span>
                        <EditOutlined onClick={this._openEditModal}/>
                    </div>} 
                    loading={this.props.loading}
                    >
                        {this.props.agreement?.statement}
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    agreement: state.agreement.one,
    error: state.agreement.error,
    message: state.agreement.message,
    loading:state.agreement.loading
})

const mapDispatchToProps = dispatch => ({
    onGetAll:(token)=>dispatch(getAgreements(token)),
    onOpen:()=>dispatch(toggleAgreementUpdateModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Agreements));