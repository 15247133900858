import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message,DatePicker } from 'antd';
import {closeUpdateStartTime} from '../../redux/actions/collection/toggleModals';
import {updateStartTime} from '../../redux/actions/collection/updateTime';
import moment from 'moment';

class UpdateStartTime extends React.Component {
    constructor(props){
        super(props);
        this.form=React.createRef();
    }
    layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 12,
        },
    };
    tailLayout = {
        wrapperCol: {
            offset: 12,
            span: 16,
        },
    };
    onFinish = async (values) => {
        await this.props.onUpdate(values.startTime?.utc(), this.props.match?.id,this.props.token);
        if(this.props.error){
            message.error(this.props.error);
        }
        if(this.props.message){
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        return (
            <Modal
                title="Update StartTime"
                key={this.props.match?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'update-startTime-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="update-startTime-form"
                    {...this.layout}
                    name="basic"
                    initialValues={{
                        startTime:moment(this.props.match?.startTime).locale('my'),
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="startTime"
                        label="Start Time"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <DatePicker showTime format="DD-MM-YYYY hh:mm:ss A" locale="my"/>
                    </Form.Item>
                    
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.collection.updateStartTimeVisible,
    loading: state.collection.loading,
    match: state.collection.match,
    token: state.auth.token,
    error:state.collection.error,
    message:state.collection.message
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (startTime,matchId, token) => dispatch(updateStartTime(startTime,matchId, token)),
    onClose: () => dispatch(closeUpdateStartTime()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateStartTime);