import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const updatePartnerCommission=({
    id,
    matchCount,
    commission
},token)=>async dispatch=>{
    dispatch({
        type:actionTypes.UPDATE_PARTNER_COMMISSION_START
    })
    const options={
        url:`${api}/partnerCommissions/${id}`,
        method:'PUT',
        headers:{
            'Authorization':`Bearer ${token}`
        },
        data:{
            matchCount,
            commission
        }
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.UPDATE_PARTNER_COMMISSION_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.UPDATE_PARTNER_COMMISSION_FAIL,
                payload:getMessage(error)
            }))
}