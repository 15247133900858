import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, InputNumber, message } from 'antd';
import { toggleFinishMatch } from '../../redux/actions/collection/toggleModals';
import { finishMatch } from '../../redux/actions/collection/finishMatch';
import { finishTypes } from '../../redux/actions/collection/finishTypes';

class FinishMatchModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 12,
        },
    };
    tailLayout = {
        wrapperCol: {
            offset: 12,
            span: 16,
        },
    };
    onFinish = async (values) => {
        const match = this.props.match
        if (match.homeScore === values.homeScore && match.awayScore === values.awayScore) {
            await this.props.onFinish({
                id: match.id,
                type: this.props.finishType,
                ...values
            }, this.props.token);
            this.form.current.resetFields();
            this.props.onClose();
            if (this.props.error) {
                message.error(this.props.error);
            }
            if (this.props.message) {
                message.success(this.props.message);
            }
        } else {
            message.info("Scores do not match");
        }
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        return (
            <Modal
                title={getTitle(this.props.finishType)}
                key={`${this.props.finishType}-${this.props.match?.matchId}`}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'finish-match-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="finish-match-form"
                    {...this.layout}
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >

                    <Form.Item
                        name="homeScore"
                        label="Home Score"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        name="awayScore"
                        label="Away Score"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.collection.finishMatchVisible,
    loading: state.collection.loading,
    match: state.collection.match,
    token: state.auth.token,
    error: state.collection.error,
    message: state.collection.message,
    finishType: state.collection.finishType,
})

const mapDispatchToProps = dispatch => ({
    onFinish: ({ id, homeScore, awayScore, type }, token) => dispatch(finishMatch({ id, homeScore, awayScore, type }, token)),
    onClose: () => dispatch(toggleFinishMatch(false)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FinishMatchModal);

const getTitle = (type) => {
    switch (type) {
        case finishTypes.HDP:
            return "Finish HDP Match";
        case finishTypes.OU:
            return "Finish OU Match";
        case finishTypes.PARLAY:
            return "Finish PARLAY Match";
        default:
            return "Finish Match";
    }
}