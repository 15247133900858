import React from 'react';
import { Row, Col, PageHeader, Table, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getLeagues } from '../../redux/actions/league/getLeagues';
import { setIdToUpdate } from '../../redux/actions/league/setIdToUpdate';
import {
   EditOutlined,
} from '@ant-design/icons';
import { openUpdateLeague } from '../../redux/actions/league/toggleEditLeague';

class LeagueList extends React.Component {
   componentDidMount() {
      this.props.onGetAll(this.props.token);
   }
   componentDidUpdate() {
      if (this.props.error) {
         message.error(this.props.error);
      }
   }
   _openEditModal = async (id) => {
      await this.props.onSetId(id);
      this.props.onOpen();
   }

   render() {
      const columns = [
         {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
         },
         {
            title: 'League ID',
            dataIndex: 'leagueId',
            key: 'leagueId',
         },
         {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
         },
         {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (text, record) => (
               <a onClick={() => this._openEditModal(record.id)}><EditOutlined /> Edit</a>
            )
         }
      ]
      return (
         <Row>
            <Col span={24}>
               <PageHeader
                  style={{
                     display: 'flex',
                     flexDirection: 'row',
                     justifyContent: 'space-between',
                     border: '1px solid rgb(235, 237, 240)'
                  }}
                  title="Leagues"
               />
               <Row>
                  <Col span={24} style={{
                     width: '100%',
                     overflowX: 'auto',
                  }}>
                     <Table dataSource={this.props.leagues} columns={columns} loading={this.props.loading} />
                  </Col>
               </Row>
            </Col>
         </Row>

      );
   }
}

const mapStateToProps = state => ({
   token: state.auth.token,
   leagues: state.league.all,
   error: state.league.error,
   message: state.league.message,
   loading: state.league.loading
})

const mapDispatchToProps = dispatch => ({
   onGetAll: (token) => dispatch(getLeagues(token)),
   onOpen: () => dispatch(openUpdateLeague()),
   onSetId: (id) => dispatch(setIdToUpdate(id))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeagueList));