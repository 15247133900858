import React from 'react';
import { Row, Col, PageHeader, Table, message, Breadcrumb, Button, Space } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
   TableOutlined,
   PlusSquareOutlined,
   CheckCircleOutlined,
} from '@ant-design/icons';
import { getTwoDCollections } from '../../redux/actions/twoDcollection/getTwoDCollection';
import * as actionTypes from '../../redux/actions/twoDcollection/actionTypes';
import { toggleFinishModal } from '../../redux/actions/twoDcollection/toggleFinishModal';

class TwoDCollection extends React.Component {
   componentDidMount() {
      this.props.onGetAll(this.props.token);
   }
   componentDidUpdate() {
      if (this.props.error) {
         message.error(this.props.error);
      }
   }
   _showNumbers = (id) => {
      this.props.history.push(`/twoDCollections/${id}`);
   }
   render() {
      const columns = [
         {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
         },
         {
            title: 'Collection ID',
            dataIndex: 'collectionId',
            key: 'collectionId',
         },
         {
            title: 'Period',
            dataIndex: 'period',
            key: 'period',
         },
         {
            title: 'Numbers',
            align: 'center',
            render: (text, record) => (
               <Space size="middle" direction="horizontal" style={{ color: '#23ACFD' }}>
                  <TableOutlined onClick={() => this._showNumbers(record.id)} />
                  <CheckCircleOutlined onClick={() => this.props.onOpenFinish(record.id)} />
               </Space>
            )
         }
      ]
      return (
         <Row>
            <Col span={24}>
               <Breadcrumb style={{ margin: '16px 0' }}>
                  <Breadcrumb.Item>Two D Collections</Breadcrumb.Item>
               </Breadcrumb>
               <PageHeader
                  style={{
                     display: 'flex',
                     flexDirection: 'row',
                     justifyContent: 'space-between',
                     border: '1px solid rgb(235, 237, 240)',
                  }}
                  title="Two D Collection"
               >
                  <Button
                     icon={<PlusSquareOutlined />}
                     style={{ background: '#3071b9', color: 'white' }}
                     onClick={() => this.props.onOpenAdd()}
                  />
               </PageHeader>
               <Row>
                  <Col span={24} style={{ overflowX: 'auto' }}>
                     <Table dataSource={this.props.twoDcollections} columns={columns} loading={this.props.loading} />
                  </Col>
               </Row>
            </Col>
         </Row>
      );
   }
}

const mapStateToProps = state => ({
   token: state.auth.token,
   twoDcollections: state.twoDCollection.all,
   error: state.twoDCollection.error,
   loading: state.twoDCollection.loading
})

const mapDispatchToProps = dispatch => ({
   onGetAll: (token) => dispatch(getTwoDCollections(token)),
   onOpenAdd: () => dispatch({
      type: actionTypes.TOGGLE_2D_COLLECTION_ADD
   }),
   onOpenFinish: (id) => dispatch(toggleFinishModal(id))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TwoDCollection));