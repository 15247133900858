import React from "react";
import {
    Breadcrumb,
    Table,
    Row,
    Col,
    Descriptions,
    Space,
    Button,
    Modal,
    message,
    Badge,
} from "antd";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getCollectionById } from "../../redux/actions/collection/getCollectionById";
import { setMatch } from "../../redux/actions/collection/setMatch";
import { toggleFinishMatch } from "../../redux/actions/collection/toggleModals";
import { checkActiveBet } from "../../redux/actions/collection/checkActiveBet";
import { recalculate } from "../../redux/actions/collection/recalculate";
import { refreshGoalScore } from "../../redux/actions/collection/refreshGoalScore";
import { EyeOutlined, RedoOutlined } from "@ant-design/icons";
import moment from "moment";
import { finishTypes } from "../../redux/actions/collection/finishTypes";

class CollectionDetail extends React.Component {
    componentDidMount() {
        this.props.onGetDetail(this.props.match.params.id, this.props.token);
    }
    handleClick = async (record) => {
        await this.props.onSetMatch({
            match: record,
            finishType: null
        });
        this.props.history.push(`/matches/${record.id}`);
    };
    handleFinishMatch = async (record, type) => {
        await this.props.onSetMatch({
            match: record,
            finishType: type
        });
        this.props.onOpenFinishMatchModal();
    };
    checkActiveBet = async () => {
        await this.props.onCheckActiveBet(
            this.props.collection?.id,
            this.props.token
        );
        if (this.props.response) {
            Modal.info({
                title: "Active Bet",
                content: (
                    <p style={{ textAlign: "left" }}>
                        {this.props.response.activeBetCount} active bets
                    </p>
                ),
            });
        }
        if (this.props.error) {
            message.error(this.props.error);
        }
    };
    recalculate = async () => {
        Modal.confirm({
            title: "Confirm recalculation",
            content: <p>Are you sure to recalculate?</p>,
            onOk: async () => {
                await this.props.onRecalculate(
                    this.props.collection?.id,
                    this.props.token
                );
                if (this.props.response) {
                    Modal.info({
                        title: "Recalculated Bet",
                        content: (
                            <p style={{ textAlign: "left" }}>
                                Recalculated{" "}
                                {this.props.response.calculatedBetCount} bets
                            </p>
                        ),
                    });
                }
                if (this.props.error) {
                    message.error(this.props.error);
                }
            },
        });
    };
    render() {
        const columns = [
            {
                title: "Home",
                dataIndex: "home",
                key: "home",
                render: (text, record) => {
                    return {
                        props: {
                            style: {
                                borderLeft: record?.finished
                                    ? "3px solid green"
                                    : "0",
                            },
                        },
                        children: (
                            <span>
                                {record?.home?.name}
                                {record?.homeUpper ? (
                                    <span
                                        style={{
                                            color: "#00c853",
                                            fontWeight: 600,
                                        }}
                                    >
                                        ({record?.odds}
                                        {record?.price >= 0
                                            ? `+${record?.price}`
                                            : record?.price}
                                        )
                                    </span>
                                ) : null}
                            </span>
                        ),
                    };
                },
            },
            {
                title: "Goal Score",
                key: "goalScore",
                render: (text, record) =>
                    record?.homeScore + "-" + record?.awayScore,
            },
            {
                title: "Away",
                dataIndex: "away",
                key: "away",
                render: (text, record) => {
                    return (
                        <span>
                            {record?.away?.name}
                            {record?.homeUpper ? null : (
                                <span
                                    style={{
                                        color: "#00c853",
                                        fontWeight: 600,
                                    }}
                                >
                                    ({record?.odds}
                                    {record?.price >= 0
                                        ? `+${record?.price}`
                                        : record?.price}
                                    )
                                </span>
                            )}
                        </span>
                    );
                },
            },
            {
                title: "Goal Price",
                render: (text, record) => (
                    <span style={{ color: "#c44536" }}>
                        {record?.goalTotal}
                        {record?.goalTotalPrice >= 0 ? "+" : ""}
                        {record?.goalTotalPrice}
                    </span>
                ),
            },
            {
                title: "Date",
                dataIndex: "startTime",
                key: "startTime",
                render: (text, record) =>
                    moment(record?.startTime).format("DD/MM/YYYY, hh:mma"),
            },
            {
                title: "Status",
                render: (_, { calculating }) => {
                    const status = calculating ? "processing" : "default";
                    const text = calculating ? "Calculating" : "-";
                    return (
                        <span>
                            <Badge status={status} text={text} />
                        </span>
                    );
                },
            },
            {
                title: "Action",
                align: "center",
                render: (text, record) => (
                    <div
                        style={{
                            color: "#0a9396",
                            display: "flex",
                            gap: 15,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            type="link"
                            style={{ color: "#0a9396" }}
                            icon={<EyeOutlined />}
                            onClick={() => this.handleClick(record)}
                        />

                        <Button
                            disabled={record?.hdpFinished}
                            style={{ color: "#0a9396" }}
                            onClick={() => this.handleFinishMatch(record, finishTypes.HDP)}
                        >
                            HDP
                        </Button>

                        <Button
                            disabled={record?.ouFinished}
                            style={{ color: "#0a9396" }}
                            onClick={() => this.handleFinishMatch(record, finishTypes.OU)}
                        >
                            OU
                        </Button>

                        <Button
                            disabled={!(record?.hdpFinished && record?.ouFinished) || record?.finished}
                            style={{ color: "#0a9396" }}
                            onClick={() => this.handleFinishMatch(record, finishTypes.PARLAY)}
                        >
                            PARLAY
                        </Button>

                    </div>
                ),
            },
        ];
        return (
            <Row>
                <Col span={24}>
                    <Breadcrumb style={{ margin: "16px 0" }}>
                        <Breadcrumb.Item>
                            <Link to="/soccer_collections">Collections</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>CollectionDetail</Breadcrumb.Item>
                    </Breadcrumb>
                    <Descriptions
                        column={{ xxl: 5, xl: 5, lg: 5, md: 3, sm: 2, xs: 1 }}
                        title="CollectionDetail"
                        layout="vertical"
                        bordered
                    >
                        <Descriptions.Item label="GoalScore">
                            <Button
                                icon={<RedoOutlined />}
                                style={{ color: "#38b000" }}
                                loading={this.props.refreshLoading}
                                onClick={() =>
                                    this.props.onRefresh(
                                        this.props.collection?.id,
                                        this.props.token
                                    )
                                }
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Collection ID">
                            {this.props.collection?.id}
                        </Descriptions.Item>
                        <Descriptions.Item label="Date">
                            {moment(this.props.collection?.date)
                                .utc()
                                .locale("my")
                                .format("DD-MM-YYYY hh:mm A")}
                        </Descriptions.Item>
                        <Descriptions.Item label="Total match count">
                            {this.props.collection?.matches?.length}
                        </Descriptions.Item>
                        <Descriptions.Item label="Action">
                            <Space>
                                <Button
                                    loading={this.props.checkActiveBetLoading}
                                    onClick={this.checkActiveBet}
                                >
                                    Check Active Bet
                                </Button>
                                <Button
                                    loading={this.props.recalculateLoading}
                                    onClick={this.recalculate}
                                >
                                    Re-calculate
                                </Button>
                            </Space>
                        </Descriptions.Item>
                    </Descriptions>
                    <Row>
                        <Col span={24} style={{ overflowX: "auto" }}>
                            <Table
                                dataSource={this.props.collection?.matches}
                                columns={columns}
                                loading={this.props.loading}
                                pagination={false}
                                rowKey={(record) => record?.id}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    loading: state.collection.loading,
    collection: state.collection.one,
    error: state.collection.error,
    message: state.collection.message,
    response: state.collection.response,
    checkActiveBetLoading: state.collection.checkActiveBetLoading,
    recalculateLoading: state.collection.recalculateLoading,
    refreshLoading: state.collection.refreshLoading,
});

const mapDispatchToProps = (dispatch) => ({
    onGetDetail: (id, token) => dispatch(getCollectionById(id, token)),
    onSetMatch: (matchAndType) => dispatch(setMatch(matchAndType)),
    onCheckActiveBet: (id, token) => dispatch(checkActiveBet(id, token)),
    onRecalculate: (id, token) => dispatch(recalculate(id, token)),
    onRefresh: (id, token) => dispatch(refreshGoalScore(id, token)),
    onOpenFinishMatchModal: () => dispatch(toggleFinishMatch(true)),
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CollectionDetail)
);
