import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input } from 'antd';
import { resetPassword } from '../../redux/actions/employee/resetPassword';

class ResetEmployeePasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 12,
        },
    };
    tailLayout = {
        wrapperCol: {
            offset: 12,
            span: 16,
        },
    };
    onFinish = async (values) => {
        this.props.onUpdate({
            id: this.props.employee.id,
            ...values
        }, this.props.token);
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    render() {
        return (
            <Modal
                title='Reset Employee Password'
                key='reset-employee-pw-modal'
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'reset-employee-pw-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.props.onClose}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="reset-employee-pw-form"
                    {...this.layout}
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >

                    <Form.Item
                        name="newPassword"
                        label="New Password"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.employee.loading,
    token: state.auth.token,
    error: state.employee.error,
    message: state.employee.message,
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (request, token) => dispatch(resetPassword(request, token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetEmployeePasswordModal);