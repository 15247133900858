import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const getMemberReport=({
    date,
    agentName,
    type
},token)=>async dispatch=>{
    dispatch({
        type:actionTypes.MEMBER_BY_COLLECTION_AND_AGENT,
    })
    const options={
        url:`${api}/reports/daily/users?date=${date}&agentName=${agentName}&type=${type}`,
        method:'GET',
        headers:{
            'Authorization':`Bearer ${token}`
        },
        data:{}
    }
    await axios(options)
            .then(response=> dispatch({
                type:actionTypes.MEMBER_BY_COLLECTION_AND_AGENT_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.MEMBER_BY_COLLECTION_AND_AGENT_FAIL,
                payload:getMessage(error)
            }))
}