import * as actionTypes from './actionTypes';
import axios from 'axios';
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const changeAutoUpdate=({id,autoUpdate},token)=>async dispatch=>{
    dispatch({
        type:actionTypes.CHANGE_AUTO_UPDATE_START
    })
    const options={
        url:`${api}/matches/${id}/autoUpdate`,
        method:'PUT',
        headers:{
            'Authorization':`Bearer ${token}`
        },
        data:{autoUpdate}
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.CHANGE_AUTO_UPDATE_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.CHANGE_AUTO_UPDATE_FAIL,
                payload:getMessage(error)
            }))
}