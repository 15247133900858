import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getPatternDetail } from '../../redux/actions/betPattern/getPatternDetail';
import { useLocation, useHistory } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import moment from 'moment';

const PatternDetail = ({
    error,
    detail,
    token,
    onGet
}) => {
    const { state: { pattern } } = useLocation();
    const history=useHistory();
    useEffect(
        async () => {
            await onGet(pattern, token);
            if(error){
                message.error(error)
            }
        }
        , []);
    const showBet=()=>{
        history.push({
            pathname:'/betsByPattern',
            state:{
                pattern
            }
        })
    }
    return (
        <Row justify="left">
            <Col lg={24} md={12} sm={24}>
                <div>
                    Total Amount : {detail?.totalAmount} <span style={{
                        color:'#009688',
                        fontWeight:'600',
                        marginLeft:'15px',
                        textTransform:'uppercase',
                        textDecoration:'none',
                        cursor:'pointer'
                    }} onClick={showBet}>Show Bets</span>
                </div>
                {
                    detail?.betPatternItems.map(pattern => {
                        return (<div style={styles.pattern}>
                            <div style={styles.section}>
                                <div style={styles.name}>Match Time</div>
                                <div>{moment(pattern?.match?.startTime)
                                    .locale('my').format('DD-MM-YYYY hh:mm a')}</div>
                            </div>
                            <div style={styles.section}>
                                <div style={styles.name}>Home</div>
                                <div>{pattern?.match?.home?.name}</div>
                                <div style={styles.oddPrice}>{pattern?.match?.homeUpper ?
                                    `(${pattern?.match?.odds}${pattern?.match?.price >= 0 ?
                                        '+' + pattern?.match?.price : pattern?.match?.price})`
                                    : null}</div>
                            </div>
                            <div style={styles.section}>
                                <div style={styles.name}>Goal Score</div>
                                <div>{pattern?.match?.homeScore}-{pattern?.match?.awayScore}</div>
                                <div style={styles?.goalTP}>({pattern?.match?.goalTotal}
                                    {pattern?.match?.goalTotalPrice >= 0 ?
                                        `+${pattern?.match?.goalTotalPrice}` :
                                        pattern?.match?.goalTotalPrice})</div>
                            </div>
                            <div style={styles.section}>
                                <div style={styles.name}>Away</div>
                                <div>{pattern?.match?.away?.name}</div>
                                <div style={styles.oddPrice}>{!pattern?.match?.homeUpper ?
                                    `(${pattern?.match?.odds}${pattern?.match?.price >= 0 ?
                                        '+' + pattern?.match?.price : pattern?.match?.price})`
                                    : null}</div>
                            </div>
                            <div style={styles.section}>
                                <div style={styles.name}>Bet Type</div>
                                <div>{pattern?.betType}</div>
                            </div>
                            <div style={styles.section}>
                                <div style={styles.name}>Bet Choice</div>
                                <div>{pattern?.betChoice}</div>
                            </div>
                        </div>)
                    })
                }
            </Col>
        </Row>
    );
}

const styles = {
    pattern: {
        width: '100%',
        display: 'flex',
        padding: 20,
        boxSizing: 'border-box',
        boxShadow: "0 2px 6px rgba(140,140,140,0.3)",
        borderRadius: '5px',
        marginTop: '10px'
    },
    section: {
        flex: 1,
        color: '#666',
        marginRight: '15px'
    },
    name: {
        color: 'gray'
    },
    goalTP: {
        marginTop: '20px'
    },
    oddPrice: {
        marginTop: '20px'
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    error: state.betPattern.error,
    detail: state.betPattern.detail,
    loading: state.betPattern.loading,
})

const mapDispatchToProps = dispatch => ({
    onGet: (pattern, token) => dispatch(getPatternDetail(pattern, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(PatternDetail);