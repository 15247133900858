import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
      GET_BET_PATTERN_START,
      GET_BET_PATTERN_SUCCCESS,
      GET_BET_PATTERN_FAIL,
      GET_SINGLE_BET_PATTERN_START,
      GET_SINGLE_BET_PATTERN_SUCCESS,
      GET_SINGLE_BET_PATTERN_FAIL,
      GET_PATTERN_BY_MATCH_COUNT_START,
      GET_PATTERN_BY_MATCH_COUNT_SUCCESS,
      GET_PATTERN_BY_MATCH_COUNT_FAIL,
      SET_ID_AND_COUNT,
      GET_PATTERN_DETAIL_START,
      GET_PATTERN_DETAIL_SUCCESS,
      GET_PATTERN_DETAIL_FAIL,
      GET_BETS_BY_PATTERN_START,
      GET_BETS_BY_PATTERN_SUCCESS,
      GET_BETS_BY_PATTERN_FAIL,
      GET_BET_DETAIL_START,
      GET_BET_DETAIL_SUCCESS,
      GET_BET_DETAIL_FAIL,
      REJECT_BET_START,
      REJECT_BET_SUCCESS,
      REJECT_BET_FAIL,
      UPDATE_PAGINATION,
      MARK_AS_CHEATED_START,
      MARK_AS_CHEATED_SUCCESS,
      MARK_AS_CHEATED_FAIL,
      GET_CHEATED_BETS_START,
      GET_CHEATED_BETS_SUCCESS,
      GET_CHEATED_BETS_FAIL,
} from '../actions/betPattern/actionTypes';

const initialState = {
      loading: false,
      error: null,
      message: null,
      all: [],
      one: [],
      singleBet: [],
      byMatchCount: [],
      detail: null,
      betsByPattern: [],
      betDetail: null,
      pattern:null,
      currentPage:1,
      pageSize:10,
      cheatedBets:[]
}

const getBetPattern = (state, action) => {
      return update(state, {
            loading: false,
            all: action.payload
      })
}

const getSingleBetPattern = (state, action) => {
      return update(state, {
            loading: false,
            singleBet: action.payload
      })
}

const getByMatchCount = (state, action) => {
      return update(state, {
            loading: false,
            byMatchCount: action.payload
      })
}

const setIdAndCount = (state, action) => {
      return update(state, {
            one: action.payload
      })
}

const getPatternDetail = (state, action) => {
      return update(state, {
            loading: false,
            detail: action.payload
      })
}

const getBetsByPattern = (state, action) => {
      return update(state, {
            loading: false,
            betsByPattern: action.payload.data,
            pattern:action.payload.pattern
      })
}

const getBetDetail = (state, action) => {
      return update(state, {
            loading: false,
            betDetail: action.payload
      })
}

const rejectBet = (state, action) => {
      return update(state, {
            loading: false,
            betDetail: action.payload
      })
}

const updatePagination = (state, action) => {
      return update(state, action.payload);
}

const markAsCheated = (state,action) => {
      return update(state,{ 
            loading:false,
            betDetail:action.payload 
      });
}

const getCheatedBets = (state,action) => {
      return update(state,{
            loading:false,
            cheatedBets:action.payload
      })
}

const betPatternReducer = (state = initialState, action) => {
      switch (action.type) {
            case GET_BET_PATTERN_START:
                  return loading(state, action);
            case GET_BET_PATTERN_SUCCCESS:
                  return getBetPattern(state, action);
            case GET_BET_PATTERN_FAIL:
                  return error(state, action);
            case GET_SINGLE_BET_PATTERN_START:
                  return loading(state, action);
            case GET_SINGLE_BET_PATTERN_SUCCESS:
                  return getSingleBetPattern(state, action);
            case GET_SINGLE_BET_PATTERN_FAIL:
                  return error(state, action);
            case GET_PATTERN_BY_MATCH_COUNT_START:
                  return loading(state, action);
            case GET_PATTERN_BY_MATCH_COUNT_SUCCESS:
                  return getByMatchCount(state, action);
            case GET_PATTERN_BY_MATCH_COUNT_FAIL:
                  return error(state, action);
            case SET_ID_AND_COUNT:
                  return setIdAndCount(state, action);
            case GET_PATTERN_DETAIL_START:
                  return loading(state, action);
            case GET_PATTERN_DETAIL_SUCCESS:
                  return getPatternDetail(state, action);
            case GET_PATTERN_DETAIL_FAIL:
                  return error(state, action);
            case GET_BETS_BY_PATTERN_START:
                  return loading(state, action);
            case GET_BETS_BY_PATTERN_SUCCESS:
                  return getBetsByPattern(state, action);
            case GET_BETS_BY_PATTERN_FAIL:
                  return error(state, action);
            case GET_BET_DETAIL_START:
                  return loading(state, action);
            case GET_BET_DETAIL_SUCCESS:
                  return getBetDetail(state, action);
            case GET_BET_DETAIL_FAIL:
                  return error(state, action);
            case REJECT_BET_START:
                  return loading(state, action);
            case REJECT_BET_SUCCESS:
                  return rejectBet(state, action);
            case REJECT_BET_FAIL:
                  return error(state, action);
            case UPDATE_PAGINATION:
                  return updatePagination(state, action);
            case MARK_AS_CHEATED_START:
                  return loading(state,action);
            case MARK_AS_CHEATED_SUCCESS:
                  return markAsCheated(state,action);
            case MARK_AS_CHEATED_FAIL:
                  return error(state,action);
            case GET_CHEATED_BETS_START:
                  return loading(state,action);
            case GET_CHEATED_BETS_SUCCESS:
                  return getCheatedBets(state,action);
            case GET_CHEATED_BETS_FAIL:
                  return error(state,action);
            default:
                  return state;
      }
}

export default betPatternReducer;