import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const getSmasterReport=({
    startDate,
    endDate,
    type
},token)=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_SMASTER_REPORT_START,
    })
    const options={
        url:`${api}/reports/smasters?startDate=${startDate}&endDate=${endDate}&type=${type}`,
        method:'GET',
        headers:{
            'Authorization':`Bearer ${token}`
        },
        data:{}
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.GET_SMASTER_REPORT_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.GET_SMASTER_REPORT_FAIL,
                payload:getMessage(error)
            }))
}