import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    GET_THREE_D_WIN_PERCENT_START,
    GET_THREE_D_WIN_PERCENT_SUCCESS,
    GET_THREE_D_WIN_PERCENT_FAIL,
    UPDATE_THREE_D_WIN_PERCENT_START,
    UPDATE_THREE_D_WIN_PERCENT_SUCCESS,
    UPDATE_THREE_D_WIN_PERCENT_FAIL,
    TOGGLE_THREE_D_WIN_PERCENT_UPDATE
} from '../actions/3DWinPercent/actionTypes';

const initialState={
    loading:false,
    error:null,
    message:null,
    one:null,
    updateVisible:false,
}

const get3DWinPercent=(state,action)=>{
    return update(state,{
        loading:false,
        one:action.payload
    })
}

const update3DWinPercent=(state,action)=>{
    return update(state,{
        loading:false,
        one:action.payload,
        message:'update success'
    })
}

const toggleModal=(state,action)=>{
    return update(state,{
        updateVisible:!state.updateVisible
    })
}

const threeDWinPercentReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case GET_THREE_D_WIN_PERCENT_START:
            return loading(state,action);
        case GET_THREE_D_WIN_PERCENT_SUCCESS:
            return get3DWinPercent(state,action);
        case GET_THREE_D_WIN_PERCENT_FAIL:
            return error(state,action);
        case UPDATE_THREE_D_WIN_PERCENT_START:
            return loading(state,action);
        case UPDATE_THREE_D_WIN_PERCENT_SUCCESS:
            return update3DWinPercent(state,action);
        case UPDATE_THREE_D_WIN_PERCENT_FAIL:
            return error(state,action);
        case TOGGLE_THREE_D_WIN_PERCENT_UPDATE:
            return toggleModal(state,action);
        default:
            return state;
    }
}

export default threeDWinPercentReducer;