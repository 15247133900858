import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, InputNumber } from 'antd';
import { togglePCCreate } from '../../redux/actions/partner/toggleModals';
import { createPartnerCommission } from '../../redux/actions/partner/createPartnerCommission';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

class CreateCommissionModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    onFinish = async (values) => {
        await this.props.onCreate(values,
            this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        return (
            <Modal
                title="Create Partner Commission"
                key={this.props.commission ?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'create-pc-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                {...layout}
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="create-pc-form"
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="matchCount"
                        label="Match Count"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{width:150}}/>
                    </Form.Item>
                    <Form.Item
                        name="commission"
                        label="commission"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{width:150}}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.partner.addPCVisible,
    loading: state.partner.loading,
    token: state.auth.token,
    error: state.partner.error,
    message: state.partner.message,
})

const mapDispatchToProps = dispatch => ({
    onCreate: (commissions, token) => dispatch(createPartnerCommission(commissions, token)),
    onClose: () => dispatch(togglePCCreate()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateCommissionModal);