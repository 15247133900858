export const actionTypes = {
      CREATE_4D_COLLECTION_START: "CREATE_4D_COLLECTION_START",
      CREATE_4D_COLLECTION_SUCCESS: "CREATE_4D_COLLECTION_SUCCESS",
      CREATE_4D_COLLECTION_FAIL: "CREATE_4D_COLLECTION_FAIL",
      GET_4D_COLLECTIONS_START: "GET_4D_COLLECTIONS_START",
      GET_4D_COLLECTIONS_SUCCESS: "GET_4D_COLLECTIONS_SUCCESS",
      GET_4D_COLLECTIONS_FAIL: "GET_4D_COLLECTIONS_FAIL",
      GET_4D_COLLECTION_START: "GET_4D_COLLECTION_START",
      GET_4D_COLLECTION_SUCCESS: "GET_4D_COLLECTION_SUCCESS",
      GET_4D_COLLECTION_FAIL: "GET_4D_COLLECTION_FAIL",
      TOGGLE_ADD_4D_COLLECTION_MODAL: "TOGGLE_ADD_4D_COLLECTION_MODAL",
      SET_4D_NUMBER_TO_UPDATE: "SET_4D_NUMBER_TO_UPDATE",
      UPDATE_4D_START: "UPDATE_4D_START",
      UPDATE_4D_SUCCESS: "UPDATE_4D_SUCCESS",
      UPDATE_4D_FAIL: "UPDATE_4D_FAIL",
      TOGGLE_UPDATE_4D_MODAL: "TOGGLE_UPDATE_4D_MODAL",
      GET_4D_RESULT_START: "GET_4D_RESULT_START",
      GET_4D_RESULT_SUCCESS: "GET_4D_RESULT_SUCCESS",
      GET_4D_RESULT_FAIL: "GET_4D_RESULT_FAIL",
      SAVE_4D_RESULT_START: "SAVE_4D_RESULT_START",
      SAVE_4D_RESULT_SUCCESS: "SAVE_4D_RESULT_SUCCESS",
      SAVE_4D_RESULT_FAIL: "SAVE_4D_RESULT_FAIL",
      TOGGLE_1ST_PRIZE_FINISH_MODAL: "TOGGLE_1ST_PRIZE_FINISH_MODAL",
      TOGGLE_2ND_PRIZE_FINISH_MODAL: "TOGGLE_2ND_PRIZE_FINISH_MODAL",
      TOGGLE_3RD_PRIZE_FINISH_MODAL: "TOGGLE_3RD_PRIZE_FINISH_MODAL",
      TOGGLE_STARTER_PRIZE_FINISH_MODAL: "TOGGLE_STARTER_PRIZE_FINISH_MODAL",
      TOGGLE_CONSOL_PRIZE_FINISH_MODAL: "TOGGLE_CONSOL_PRIZE_FINISH_MODAL",
      FINISH_1ST_PRIZE_START: "FINISH_1ST_PRIZE_START",
      FINISH_1ST_PRIZE_SUCCESS: "FINISH_1ST_PRIZE_SUCCESS",
      FINISH_1ST_PRIZE_FAIL: "FINISH_1ST_PRIZE_FAIL",
      FINISH_2ND_PRIZE_START: "FINISH_2ND_PRIZE_START",
      FINISH_2ND_PRIZE_SUCCESS: "FINISH_2ND_PRIZE_SUCCESS",
      FINISH_2ND_PRIZE_FAIL: "FINISH_2ND_PRIZE_FAIL",
      FINISH_3RD_PRIZE_START: "FINISH_3RD_PRIZE_START",
      FINISH_3RD_PRIZE_SUCCESS: "FINISH_3RD_PRIZE_SUCCESS",
      FINISH_3RD_PRIZE_FAIL: "FINISH_3RD_PRIZE_FAIL",
      FINISH_STARTER_PRIZE_START: "FINISH_STARTER_PRIZE_START",
      FINISH_STARTER_PRIZE_SUCCESS: "FINISH_STARTER_PRIZE_SUCCESS",
      FINISH_STARTER_PRIZE_FAIL: "FINISH_STARTER_PRIZE_FAIL",
      FINISH_CONSOL_PRIZE_START: "FINISH_CONSOL_PRIZE_START",
      FINISH_CONSOL_PRIZE_SUCCESS: "FINISH_CONSOL_PRIZE_SUCCESS",
      FINISH_CONSOL_PRIZE_FAIL: "FINISH_CONSOL_PRIZE_FAIL",

      FINISH_1ST_PRIZE_THREE_DIGIT_START: "FINISH_1ST_PRIZE_THREE_DIGIT_START",
      FINISH_1ST_PRIZE_THREE_DIGIT_SUCCESS: "FINISH_1ST_PRIZE_THREE_DIGIT_SUCCESS",
      FINISH_1ST_PRIZE_THREE_DIGIT_FAIL: "FINISH_1ST_PRIZE_THREE_DIGIT_FAIL",

      FINISH_1ST_PRIZE_TWO_DIGIT_START: "FINISH_1ST_PRIZE_TWO_DIGIT_START",
      FINISH_1ST_PRIZE_TWO_DIGIT_SUCCESS: "FINISH_1ST_PRIZE_TWO_DIGIT_SUCCESS",
      FINISH_1ST_PRIZE_TWO_DIGIT_FAIL: "FINISH_1ST_PRIZE_TWO_DIGIT_FAIL",

      FINISH_2ND_PRIZE_TWO_DIGIT_START: "FINISH_2ND_PRIZE_TWO_DIGIT_START",
      FINISH_2ND_PRIZE_TWO_DIGIT_SUCCESS: "FINISH_2ND_PRIZE_TWO_DIGIT_SUCCESS",
      FINISH_2ND_PRIZE_TWO_DIGIT_FAIL: "FINISH_2ND_PRIZE_TWO_DIGIT_FAIL",

      FINISH_3RD_PRIZE_TWO_DIGIT_START: "FINISH_3RD_PRIZE_TWO_DIGIT_START",
      FINISH_3RD_PRIZE_TWO_DIGIT_SUCCESS: "FINISH_3RD_PRIZE_TWO_DIGIT_SUCCESS",
      FINISH_3RD_PRIZE_TWO_DIGIT_FAIL: "FINISH_3RD_PRIZE_TWO_DIGIT_FAIL",

      TOGGLE_SUB_DIGIT_FINISH: "TOGGLE_SUB_DIGIT_FINISH",

      CALCULATE_4D_REQUEST: 'CALCULATE_4D_REQUEST',
      CALCULATE_4D_SUCCESS: 'CALCULATE_4D_SUCCESS',
      CALCULATE_4D_FAIL:'CALCULATE_4D_FAIL',
}