import React from 'react';
import {Col, Row, Table} from 'antd';
import {connect} from 'react-redux';
import {getMasterReport} from '../../redux/actions/report/getMasterReport';
import {withRouter} from 'react-router-dom';
import currency from 'currency.js';
import {EyeOutlined} from '@ant-design/icons';
import {ParseIntAndFormat} from "./ParseIntAndFormat";

class MasterReport extends React.Component {
    componentDidMount() {
        const {
            match: {
                params: {
                    date,
                    smasterName
                }
            }
        } = this.props;
        this.props.onGetReport({date, smasterName, type: this.props.type}, this.props.token);
    }

    render() {
        const {Column, ColumnGroup} = Table;
        return (
            <Row>
                <Col span={24} style={{overflowX: 'auto'}}>
                    <Table
                        bordered
                        loading={this.props.loading}
                        dataSource={this.props.masters}>
                        <Column title="Master Name" dataIndex="masterName" key="masterName" align="center"/>
                        <ColumnGroup title="Partner">
                            <Column title="Bet" dataIndex="partnerBetAmount"
                                    render={(text, record) => ParseIntAndFormat(record.partnerBetAmount ?? 0)}
                                    key="partnerBetAmount" align="right"/>
                            <Column title="Commission" dataIndex="partnerCommissionAmount"
                                    render={(text, record) => ParseIntAndFormat(record.partnerCommissionAmount ?? 0)}
                                    key="partnerCommissionAmount"
                                    align="right"/>
                            <Column
                                title="W/L"
                                key="p_win_lose"
                                render={(text, record) => {
                                    let sum = currency(record.partnerBetAmount)
                                        .subtract(currency(record.partnerWinAmount))
                                        .subtract(currency(record.partnerCommissionAmount))
                                        .value;
                                    return ParseIntAndFormat(sum);
                                }
                                }
                                align="right"
                            />
                        </ColumnGroup>
                        <ColumnGroup title="Company">
                            <Column title="Bet" dataIndex="smasterBetAmount"
                                    render={(text, record) => ParseIntAndFormat(record.smasterBetAmount ?? 0)}
                                    key="smasterBetAmount" align="right"/>
                            <Column title="Commission" dataIndex="smasterCommissionAmount"
                                    render={(text, record) => ParseIntAndFormat(record.smasterCommissionAmount ?? 0)}
                                    key="smasterCommissionAmount"
                                    align="right"/>
                            <Column
                                title="W/L"
                                key="s_win_lose"
                                render={(text, record) => {
                                    let sum = currency(record.smasterBetAmount)
                                        .subtract(currency(record.smasterWinAmount))
                                        .subtract(currency(record.smasterCommissionAmount))
                                        .value;
                                    return ParseIntAndFormat(sum);
                                }
                                }
                                align="right"
                            />
                            <Column title="Action" key="action" align="center" render={
                                (text, record) => <EyeOutlined onClick={
                                    () => this.props.history.push(`/report/daily/${this.props.match.params.date}/masters/${record.masterName}/agents`)
                                }/>
                            }/>
                        </ColumnGroup>
                    </Table>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.report.loading,
    token: state.auth.token,
    error: state.report.error,
    masters: state.report.master,
    type: state.report.type
})

const mapDispatchToProps = dispatch => ({
    onGetReport: (id, token) => dispatch(getMasterReport(id, token)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MasterReport));