import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';

import {actionTypes} from '../actions/loseMessage/actionTypes';

const {
    CREATE_LOSE_MESSAGE_START,
    CREATE_LOSE_MESSAGE_SUCCESS,
    CREATE_LOSE_MESSAGE_FAIL,
    UPDATE_LOSE_MESSAGE_START,
    UPDATE_LOSE_MESSAGE_SUCCESS,
    UPDATE_LOSE_MESSAGE_FAIL,
    GET_LOSE_MESSAGES_START,
    GET_LOSE_MESSAGES_SUCCESS,
    GET_LOSE_MESSAGES_FAIL,
    TOGGLE_CREATE_LOSE_MESSAGE_MODAL,
    TOGGLE_UPDATE_LOSE_MESSAGE_MODAL,
} = actionTypes;

const initialState = {
    loading: false,
    updateVisible: false,
    createVisible: false,
    createMessage: null,
    updateMessage:null,
    error: null,
    all: [],
    toUpdate: null
}

const createLoseMessage = (state, action) => {
    return update(state, {
        loading: false,
        all: [...state.all, action.payload],
        createMessage: 'created successfully'
    })
}

const getLoseMessages = (state, action) => {
    return update(state, {
        loading: false,
        all: action.payload
    })
}

const updateLoseMessage = (state, {payload}) => {
    return update(state, {
        loading: false,
        all: state.all.map(v => +v.id === +payload.id ? payload : v),
        updateMessage:"updated successfully"
    })
}

const toggleUpdateModal = (state, {payload}) => {
    return update(state, {
        updateVisible: !state.updateVisible,
        toUpdate: payload
    })
}

const toggleCreateModal = (state, action) => {
    return update(state, {
        createVisible: !state.createVisible
    })
}

const LOSEMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_LOSE_MESSAGE_START:
            return loading(state, action);
        case CREATE_LOSE_MESSAGE_SUCCESS:
            return createLoseMessage(state, action);
        case CREATE_LOSE_MESSAGE_FAIL:
            return error(state, action);
        case GET_LOSE_MESSAGES_START:
            return loading(state, action);
        case GET_LOSE_MESSAGES_SUCCESS:
            return getLoseMessages(state, action);
        case GET_LOSE_MESSAGES_FAIL:
            return error(state, action);
        case UPDATE_LOSE_MESSAGE_START:
            return loading(state, action);
        case UPDATE_LOSE_MESSAGE_SUCCESS:
            return updateLoseMessage(state, action);
        case UPDATE_LOSE_MESSAGE_FAIL:
            return error(state, action);
        case TOGGLE_CREATE_LOSE_MESSAGE_MODAL:
            return toggleCreateModal(state, action);
        case TOGGLE_UPDATE_LOSE_MESSAGE_MODAL:
            return toggleUpdateModal(state, action);
        default:
            return state;
    }
}

export default LOSEMessageReducer;