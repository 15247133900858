import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form,Select } from 'antd';
import { toggleTwoDUpdate } from '../../redux/actions/twoDcollection/toggleModals';
import { updateTwoD } from '../../redux/actions/twoDcollection/updateTwoD';

class UpdateTwoDModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 12,
        },
    };
    tailLayout = {
        wrapperCol: {
            offset: 12,
            span: 16,
        },
    };
    onFinish = async (values) => {
        await this.props.onUpdate(
            this.props.number?.id,
            values.status
            , this.props.token);
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        const {Option}=Select;
        return (
            <Modal
                title="Update Status"
                key={this.props.number?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'update-status-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="update-status-form"
                    {...this.layout}
                    name="basic"
                    initialValues={{
                        status: this.props.number?.status
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="status"
                        label="Status"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                    >
                        <Select style={{width:200}}>
                            <Option value={1}>Normal</Option>
                            <Option value={2}>Special</Option>
                            <Option value={3}>Closed</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.twoDCollection.updateVisible,
    loading: state.twoDCollection.loading,
    token: state.auth.token,
    error: state.twoDCollection.error,
    message: state.twoDCollection.message,
    number: state.twoDCollection.toUpdate
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (id, status, token) => dispatch(updateTwoD(id, status, token)),
    onClose: () => dispatch(toggleTwoDUpdate()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTwoDModal);