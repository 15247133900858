import {Card, Col, Row, Space} from "antd";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import React from "react";

export default function MessageList({
                                        title,
                                        onOpenUpdateModal,
                                        onOpenCreateModal,
                                        all,
                                        loading
                                    }) {
    return (<>
        <Row>
            <Col span={24}>
                <Card span={24} title={
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        <span>{title}</span>
                        <PlusOutlined onClick={onOpenCreateModal}/>
                    </div>
                }>
                    {
                        all.map(msg => (
                            <Card
                                style={{marginTop: '15px'}}
                                key={msg?.id}
                                type="inner"
                                title={<div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}>
                                    <span>Message {msg?.id}</span>
                                    <Space direction="horizontal" size="middle">
                                        <EditOutlined onClick={() => onOpenUpdateModal(msg)}/>
                                    </Space>
                                </div>}
                                loading={loading}
                            >
                                {msg?.message}
                            </Card>
                        ))
                    }
                </Card>
            </Col>
        </Row>
    </>);
}