export const actionTypes={
      CREATE_LOSE_MESSAGE_START:"CREATE_LOSE_MESSAGE_START",
      CREATE_LOSE_MESSAGE_SUCCESS:"CREATE_LOSE_MESSAGE_SUCCESS",
      CREATE_LOSE_MESSAGE_FAIL:"CREATE_LOSE_MESSAGE_FAIL",

      GET_LOSE_MESSAGES_START:"GET_LOSE_MESSAGES_START",
      GET_LOSE_MESSAGES_SUCCESS:"GET_LOSE_MESSAGES_SUCCESS",
      GET_LOSE_MESSAGES_FAIL:"GET_LOSE_MESSAGES_FAIL",

      UPDATE_LOSE_MESSAGE_START:"UPDATE_LOSE_MESSAGE_START",
      UPDATE_LOSE_MESSAGE_SUCCESS:"UPDATE_LOSE_MESSAGE_SUCCESS",
      UPDATE_LOSE_MESSAGE_FAIL:"UPDATE_LOSE_MESSAGE_FAIL",

      TOGGLE_UPDATE_LOSE_MESSAGE_MODAL:"TOGGLE_UPDATE_LOSE_MESSAGE_MODAL",
      TOGGLE_CREATE_LOSE_MESSAGE_MODAL:"TOGGLE_CREATE_LOSE_MESSAGE_MODAL",
}