export const GET_RULES_START='GET_RULES_START';
export const GET_RULES_SUCCESS='GET_RULES_SUCCESS';
export const GET_RULES_FAIL='GET_RULES_FAIL';

export const CREATE_RULE_START='CREATE_RULE_START';
export const CREATE_RULE_SUCCESS='CREATE_RULE_SUCCESS';
export const CREATE_RULE_FAIL='CREATE_RULE_FAIL';

export const UPDATE_RULE_START='UPDATE_RULE_START';
export const UPDATE_RULE_SUCCESS='UPDATE_RULE_SUCCESS';
export const UPDATE_RULE_FAIL='UPDATE_RULE_FAIL';

export const DELETE_RULE_START='DELETE_RULE_START';
export const DELETE_RULE_SUCCESS='DELETE_RULE_SUCCESS';
export const DELETE_RULE_FAIL='DELETE_RULE_FAIL';

export const SET_RULE_TO_UPDATE='SET_RULE_TO_UPDATE';
export const TOGGLE_UPDATE_RULE='TOGGLE_UPDATE_RULE';
export const TOGGLE_ADD_RULE='TOGGLE_ADD_RULE';