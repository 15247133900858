import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const update23Limit=({
    twoSpecialNumberLimit,
    twoClosedNumberLimit,
    threeSpecialNumberLimit,
    threeClosedNumberLimit,
    fourDClosedNumberLimit
},token)=>async dispatch=>{
    dispatch({
        type:actionTypes.UPDATE_23LIMIT_START
    })
    const options={
        url:`${api}/twoThreeLimitations`,
        method:'PUT',
        headers:{
            'Authorization':`Bearer ${token}`
        },
        data:{
            twoSpecialNumberLimit,
            twoClosedNumberLimit,
            threeSpecialNumberLimit,
            threeClosedNumberLimit,
            fourDClosedNumberLimit
        }
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.UPDATE_23LIMIT_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.UPDATE_23LIMIT_FAIL,
                payload:getMessage(error)
            }))
}