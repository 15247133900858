export const CREATE_TWOD_COLLECTION_START='CREATE_TWOD_COLLECTION_START';
export const CREATE_TWOD_COLLECTION_SUCCESS='CREATE_TWOD_COLLECTION_SUCCESS';
export const CREATE_TWOD_COLLECTION_FAIL='CREATE_TWOD_COLLECTION_FAIL';

export const GET_TWOD_COLLECTIONS_START='GET_TWOD_COLLECTIONS_START';
export const GET_TWOD_COLLECTIONS_SUCCESS='GET_TWOD_COLLECTIONS_SUCCESS';
export const GET_TWOD_COLLECTIONS_FAIL='GET_TWOD_COLLECTIONS_FAIL';

export const GET_TWOD_COLLECTION_START='GET_TWOD_COLLECTION_START';
export const GET_TWOD_COLLECTION_SUCCESS='GET_TWOD_COLLECTION_SUCCESS';
export const GET_TWOD_COLLECTION_FAIL='GET_TWOD_COLLECTION_FAIL';

export const TOGGLE_TWOD_UPDATE='TOGGLE_TWOD_UPDATE';
export const TOGGLE_2D_COLLECTION_ADD='TOGGLE_2D_COLLECTION_ADD';


export const UPDATE_TWOD_START='UPDATE_TWOD_START';
export const UPDATE_TWOD_SUCCESS='UPDATE_TWOD_SUCCESS';
export const UPDATE_TWOD_FAIL='UPDATE_TWOD_FAIL';

export const SET_TO_UPDATE='SET_TO_UPDATE';

export const GET_WIN_PERCENT_START='GET_WIN_PERCENT_START';
export const GET_WIN_PERCENT_SUCCESS='GET_WIN_PERCENT_SUCCESS';
export const GET_WIN_PERCENT_FAIL='GET_WIN_PERCENT_FAIL';

export const UPDATE_WIN_PERCENT_START='UPDATE_WIN_PERCENT_START';
export const UPDATE_WIN_PERCENT_SUCCESS='UPDATE_WIN_PERCENT_SUCCESS';
export const UPDATE_WIN_PERCENT_FAIL='UPDATE_WIN_PERCENT_FAIL';

export const FINISH_TWO_D_COLLECTION_START='FINISH_TWO_D_COLLECTION_START';
export const FINISH_TWO_D_COLLECTION_SUCCESS='FINISH_TWO_D_COLLECTION_SUCCESS';
export const FINISH_TWO_D_COLLECTION_FAIL='FINISH_TWO_D_COLLECTION_FAIL';

export const TOGGLE_FINISH_MODAL='TOGGLE_FINISH_MODAL';