import React from 'react';
import { connect } from 'react-redux';
import { get23Limits } from '../../redux/actions/23limits/get23Limits';
import { toggle23LimitUpdate } from '../../redux/actions/23limits/toggle23LimitUpdate';
import { Card, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';

class TwoThreeLimits extends React.Component {
   async componentDidMount() {
      this.props.onLoad(this.props.token)
   }
   _openEditModal = () => {
      this.props.onOpen();
   }
   render() {
      const {
         twoSpecialNumberLimit,
         twoClosedNumberLimit,
         threeSpecialNumberLimit,
         threeClosedNumberLimit,
         // fourDClosedNumberLimit,
      } = this.props.limits
      return (
         <Row>
            <Col lg={{ span: 8 }} md={{ span: 20 }} sm={{ span: 24 }} xs={{ span: 24 }}>
               <Card
                  span={24}
                  type="inner"
                  title={<div style={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                     width: '100%'
                  }}>
                     <span>Two Three Limitations</span>
                     <EditOutlined onClick={this._openEditModal} />
                  </div>}
                  loading={this.props.loading}
               >
                  <div>Two Special Number Limit - {`${twoSpecialNumberLimit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                  <div>Two Closed Number Limit - {`${twoClosedNumberLimit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                  <div>Three Special Number Limit - {`${threeSpecialNumberLimit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                  <div>Three Closed Number Limit - {`${threeClosedNumberLimit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                  {/* <div>FourD Closed Number Limit - {`${fourDClosedNumberLimit}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div> */}
               </Card>
            </Col>
         </Row>
      );
   }
}

const mapStateToProps = state => ({
   token: state.auth.token,
   loading: state.twothreeLimits.loading,
   limits: state.twothreeLimits.all
})

const mapDispatchToProps = dispatch => ({
   onLoad: (token) => dispatch(get23Limits(token)),
   onOpen: () => dispatch(toggle23LimitUpdate())
})

export default connect(mapStateToProps, mapDispatchToProps)(TwoThreeLimits);