import React from 'react';
import {getWinMessages} from "../../redux/actions/winMessage/getWinMessages";
import {connect} from "react-redux";
import {message} from "antd";
import {toggleWinMessageUpdateModal} from "../../redux/actions/winMessage/toggleWinMessageUpdateModal";
import {toggleWinMessageCreateModal} from "../../redux/actions/winMessage/toggleWinMessageCreateModal";
import MessageList from "../Message/MessageList";

class WinMessages extends React.Component {
    async componentDidMount() {
        await this.props.onGetAll(this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
    }

    render() {
        return (
            <>
                <MessageList title="Win Messages"
                             loading={this.props.loading}
                             all={this.props.all}
                             onOpenUpdateModal={this.props.onOpenUpdateModal}
                             onOpenCreateModal={this.props.onOpenCreateModal}/>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    error: state.winMessage.error,
    all: state.winMessage.all,
    loading: state.winMessage.loading,
});

const mapDispatchToProps = dispatch => ({
    onGetAll: (token) => dispatch(getWinMessages(token)),
    onOpenUpdateModal: (payload) => dispatch(toggleWinMessageUpdateModal(payload)),
    onOpenCreateModal: () => dispatch(toggleWinMessageCreateModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(WinMessages);