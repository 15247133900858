import { useEffect } from "react";
import useSSE from "../../hooks/useSSE";
import baseUrl from "../../redux/utilities/api";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateDeleteStatus } from "../../redux/actions/bets/updateDeleteStatus";

export const DeleteDataSSE = () => {

    const dispatch = useDispatch();

    const [api, contextHolder] = notification.useNotification();

    const token = useSelector((state) => state.auth.token);

    const url = `${baseUrl}/sse/subscribe`;

    const eventName = "DELETING_SUCCESS";

    const options = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        heartbeatTimeout: 86400000,
    };

    const [event, error] = useSSE({ url, eventName, options });

    useEffect(() => {
        if (event) {
            api.success({
                key:"delete-success",
                message: "OLD DATA DELETION FINISHED",
                description: event.message,
                placement: "bottomRight",
                duration: 2,
            });
            dispatch(updateDeleteStatus(event.data.deleting));
        }
    }, [event]);

    useEffect(() => {
        if (error) {
            api.error({
                key:'event-error',
                message: error,
                placement: "bottomRight",
                duration: 0,
            });
        }
    }, [error]);

    return <>{contextHolder}</>;
};
