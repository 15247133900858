import PropTypes from 'prop-types';
import './Pagination.css';
export default function PaginateNumber({
    children,
    onClick,
    selected
}){
    return (
        <div style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            width:'30px',
            height:'30px',
            border:selected?'1px solid #1890FF':'1px solid #D9D9D9',
            borderRadius:'4px',
            margin:'5px',
            color:selected?'#1890FF':'black',
            cursor:'pointer'
        }}
        onClick={onClick}
        >
            {children}
        </div>
    );
}

PaginateNumber.defaultProps={
    selected:false
}

PaginateNumber.propTypes={
    children:PropTypes.any,
    onClick:PropTypes.func,
    selected:PropTypes.bool
}