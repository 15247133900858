import React from 'react';
import { connect } from 'react-redux';
import { toggleLoseMessageCreateModal } from '../../redux/actions/loseMessage/toggleLoseMessageCreateModal';
import { createLoseMessage } from "../../redux/actions/loseMessage/createLoseMessage";
import CreateMessageModal from "../Message/CreateMessageModal";

class CreateLoseMessageModal extends React.Component {
      render() {
            return (
                  <CreateMessageModal
                        formId="create-lose-msg-form"
                        modalKey="create-lose-msg-modal"
                        title="Add New Message"
                        onClose={this.props.onClose}
                        onAdd={this.props.onAdd}
                        token={this.props.token}
                        error={this.props.error}
                        visible={this.props.visible}
                        loading={this.props.loading}
                        message={this.props.message} />
            );
      }
}

const mapStateToProps = state => ({
      visible: state.loseMessage.createVisible,
      loading: state.loseMessage.loading,
      token: state.auth.token,
      error: state.loseMessage.error,
      message: state.loseMessage.createMessage,
})

const mapDispatchToProps = dispatch => ({
      onAdd: (obj, token) => dispatch(createLoseMessage(obj, token)),
      onClose: () => dispatch(toggleLoseMessageCreateModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateLoseMessageModal);