const createLeagueIds = (leagues) => {
    let idArray = [];
    if (leagues) {
        leagues.map(league => {
            idArray.push(`${league.id}`)
            return void 0;
        });
    }
    return idArray;
}

export default createLeagueIds;