import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';

import {actionTypes} from '../actions/winMessage/actionTypes';

const {
    CREATE_WIN_MESSAGE_START,
    CREATE_WIN_MESSAGE_SUCCESS,
    CREATE_WIN_MESSAGE_FAIL,
    UPDATE_WIN_MESSAGE_START,
    UPDATE_WIN_MESSAGE_SUCCESS,
    UPDATE_WIN_MESSAGE_FAIL,
    GET_WIN_MESSAGES_START,
    GET_WIN_MESSAGES_SUCCESS,
    GET_WIN_MESSAGES_FAIL,
    TOGGLE_CREATE_WIN_MESSAGE_MODAL,
    TOGGLE_UPDATE_WIN_MESSAGE_MODAL,
} = actionTypes;

const initialState = {
    loading: false,
    updateVisible: false,
    createVisible: false,
    createMessage: null,
    updateMessage:null,
    error: null,
    all: [],
    toUpdate: null
}

const createWinMessage = (state, action) => {
    return update(state, {
        loading: false,
        all: [...state.all, action.payload],
        createMessage: 'created successfully'
    })
}

const getWinMessages = (state, action) => {
    return update(state, {
        loading: false,
        all: action.payload
    })
}

const updateWinMessage = (state, {payload}) => {
    return update(state, {
        loading: false,
        all: state.all.map(v => +v.id === +payload.id ? payload : v),
        updateMessage:"updated successfully"
    })
}

const toggleUpdateModal = (state, {payload}) => {
    return update(state, {
        updateVisible: !state.updateVisible,
        toUpdate: payload
    })
}

const toggleCreateModal = (state, action) => {
    return update(state, {
        createVisible: !state.createVisible
    })
}

const winMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_WIN_MESSAGE_START:
            return loading(state, action);
        case CREATE_WIN_MESSAGE_SUCCESS:
            return createWinMessage(state, action);
        case CREATE_WIN_MESSAGE_FAIL:
            return error(state, action);
        case GET_WIN_MESSAGES_START:
            return loading(state, action);
        case GET_WIN_MESSAGES_SUCCESS:
            return getWinMessages(state, action);
        case GET_WIN_MESSAGES_FAIL:
            return error(state, action);
        case UPDATE_WIN_MESSAGE_START:
            return loading(state, action);
        case UPDATE_WIN_MESSAGE_SUCCESS:
            return updateWinMessage(state, action);
        case UPDATE_WIN_MESSAGE_FAIL:
            return error(state, action);
        case TOGGLE_CREATE_WIN_MESSAGE_MODAL:
            return toggleCreateModal(state, action);
        case TOGGLE_UPDATE_WIN_MESSAGE_MODAL:
            return toggleUpdateModal(state, action);
        default:
            return state;
    }
}

export default winMessageReducer;