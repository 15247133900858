import React from 'react';
import { Form, Input, Button, Row, Col, DatePicker } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/collection/collection';
import CollectionTable from './CollectionTable';

class Collection extends React.Component {
   state = {
      isMobile: false
   }
   componentDidMount() {
      const query = window.matchMedia(`(max-width: 480px)`);
      query.onchange = (e) => {
         this.setState(prevState => {
            return {
               ...prevState,
               isMobile: e.matches
            }
         })
      }
      this.setState({
         isMobile: query.matches
      })
      this.props.onLoad(this.props.token);
   }
   onFinish = async (values) => {
      let request = {
         id: values.collectionId,
         date: values.date.utc().format()
      }
      await this.props.onCreate(request, this.props.token);
      await this.props.onLoad(this.props.token);
   };

   onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };

   render() {
      const { isMobile } = this.state;
      return (
         <>
            <Row>
               <Col span={24}>
                  <h2>Collection</h2>
                  <Form
                     name="basic"
                     layout={isMobile ? "vertical" : "inline"}
                     onFinish={this.onFinish}
                     onFinishFailed={this.onFinishFailed}
                     initialValues={{
                        date: moment.utc()
                     }}>
                     <Row gutter={[20, 20]} style={{ width: '100%' }}>
                        <Col lg={{ span: 8 }}
                           md={{ span: 12 }}
                           sm={{ span: 12 }}
                           xs={{ span: 24 }}>
                           <Form.Item
                              name="collectionId"
                              rules={[
                                 {
                                    required: true,
                                    message: 'require',
                                 },
                              ]}>
                              <Input placeholder="Collection ID" span={24} />
                           </Form.Item>
                        </Col>
                        <Col lg={{ span: 4 }}
                           md={{ span: 12 }}
                           sm={{ span: 12 }}
                           xs={{ span: 12 }}>
                           <Form.Item
                              name="date"
                              rules={[
                                 {
                                    required: true,
                                    message: 'require',
                                 },
                              ]}>
                              <DatePicker format="DD/MM/YYYY" />
                           </Form.Item>
                        </Col>
                        <Col lg={{ span: 8 }}
                           md={{ span: 12 }}
                           sm={{ span: 12 }}
                           xs={{ span: 12 }}>
                           <Form.Item>
                              <Button type="primary" htmlType="submit">
                                 Create
                              </Button>
                           </Form.Item>
                        </Col>
                     </Row>
                  </Form>
                  <Row style={{ marginTop: '20px' }}>
                     <Col span={24} style={{ overflowX: 'auto' }}>
                        <CollectionTable collection={this.props.collection} loading={this.props.loading} />
                     </Col>
                  </Row>
               </Col>
            </Row >
         </>
      )
   }
}

const mapStateToProps = state => ({
   token: state.auth.token,
   collection: state.collection.collection,
   loading: state.collection.loading
})

const mapDispatchToProps = dispatch => ({
   onCreate: (request, token) => dispatch(actions.createCollection(request, token)),
   onLoad: (token) => dispatch(actions.getCollection(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Collection);