import { Button, Layout } from 'antd';
import React from 'react';
import brand from '../assets/brand.svg';
import { withRouter } from 'react-router-dom';
import LeagueModal from '../components/Lsport/LeagueModal';
import UpdatePriceModal from '../components/Collection/UpdatePriceModal';
import UpdateGoalScoreModal from '../components/Collection/UpdateGoalScoreModal';
import UpdateStartTime from '../components/Collection/UpdateStartTime';
import UpdateCloseTime from '../components/Collection/UpdateCloseTime';
import UpdateLeagueModal from '../components/League/UpdateLeagueModal';
import FinishMatchModal from '../components/Collection/FinishMatchModal';
import UpdateTeamModal from '../components/Team/UpdateTeamModal';
import UpdateAnnouncementModal from '../components/Announcement/UpdateAnnouncementModal';
import UpdateRuleModal from '../components/Rule/UpdateRuleModal';
import AddRule from '../components/Rule/AddRule';
import UpdateTwoDModal from '../components/TwoDCollection/UpdateTwoDModal';
import Update23LimitModal from '../components/23Limits/Update23LimitModal';
import Update23CommissionModal from '../components/Partner/Update23CommissionModal';
import UpdateCommissionModal from '../components/Partner/UpdateCommissionModal';
import UpdateSingleCommissionModal from '../components/Partner/UpdateSingleCommissionModal';
import CreateCommissionModal from '../components/Partner/CreateCommissionModal';
import UpdateBasicInfoModal from '../components/SeniorMaster/UpdateBasicInfoModal';
import UpdateShareDetailModal from '../components/SeniorMaster/UpdateShareDetailModal';
import UpdatePartnerShareModal from '../components/SeniorMaster/UpdatePartnerShareModal';
import UpdateSingleBetCommissionModal from '../components/SeniorMaster/UpdateSingleBetCommissionModal';
import UpdateBetLimitationModal from '../components/SeniorMaster/UpdateBetLimitationModal';
import UpdateSMCommissionModal from '../components/SeniorMaster/UpdateSMCommissionModal';
import UpdatePriceGapModal from '../components/PriceGap/UpdatePriceGapModal';
import UpdateThreeDWinPercentModal from '../components/3DWinPercent/UpdateThreeDWinPercentModal';
import AddThreeDCollectionModal from '../components/3DCollection/AddThreeDCollectionModal';
import AddTwoDCollectionModal from '../components/TwoDCollection/AddTwoDCollectionModal';
import Update3DModal from '../components/3DCollection/UpdateThreeDModal';
import CreateTransactionModal from '../components/SeniorMaster/CreateTransactionModal';
import FinishNumberModal from '../components/TwoDCollection/FinishNumberModal';
import Finish3DCollectionModal from '../components/3DCollection/Finish3DCollectionModal';
import Update2DCommissionModal from '../components/SeniorMaster/Update2DCommissionModal';
import Update3DCommissionModal from '../components/SeniorMaster/Update3DCommissionModal';
import Update4DCommissionModal from '../components/SeniorMaster/Update4DCommissionModal';
import AddCommissionModal from '../components/SeniorMaster/AddCommissionModal';
import ResetPasswordModal from '../components/SeniorMaster/ResetPasswordModal';
import UpdateAgreementModal from '../components/Agreement/UpdateAgreementModal';
import AddPaymentModal from '../components/Payment/AddPaymentModal';
import { connect } from 'react-redux';
import DefaultMenu from './DefaultMenu';
import MobileMenu from './MobileMenu';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import UpdatePartner3DShareModal from "../components/SeniorMaster/UpdatePartner3DShareModal";
import UpdatePartner4DShareModal from "../components/SeniorMaster/UpdatePartner4DShareModal";
import AddFourDCollectionModal from "../components/4DCollection/AddFourDCollectionModal";
import Update4DModal from "../components/4DCollection/Update4DModal";
import Finish1stPrizeModal from '../components/4DCollection/Finish1stPrizeModal';
import Finish2ndPrizeModal from '../components/4DCollection/Finish2ndPrizeModal';
import Finish3rdPrizeModal from '../components/4DCollection/Finish3rdPrizeModal';
import FinishStarterPrizeModal from '../components/4DCollection/FinishStarterPrizeModal';
import FinishConsolPrizeModal from '../components/4DCollection/FinishConsolPrizeModal';
import FinishSubDigitPrizeModal from '../components/4DCollection/FinishSubDigitPrizeModal';
import CreateWinMessageModal from '../components/WinMessage/CreateWinMessageModal';
import UpdateWinMessageModal from "../components/WinMessage/UpdateWinMessageModal";
import CreateLoseMessageModal from "../components/LoseMessage/CreateLoseMessageModal";
import UpdateLoseMessageModal from "../components/LoseMessage/UpdateLoseMessageModal";
import { CalculateSSE } from '../components/Collection/CalculateSSE';
import { DeleteDataSSE } from '../components/Bets/DeleteDataSSE';

const { Header, Content, Sider } = Layout;

class App extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  visible: false,
                  isMobile: false,
                  isTablet: false,
            }
            this.toggleMenu = this.toggleMenu.bind(this);
            this.headerRef = React.createRef();
      }

      async componentDidMount() {
            const mobileQuery = window.matchMedia(`(max-width: 480px)`);
            const tabletQuery = window.matchMedia(`(min-width: 481px) 
      and (max-width: 1024px)`);
            mobileQuery.onchange = (e) => {
                  console.log('max-width changed in mobile');
                  this.setState(prevState => {
                        return {
                              ...prevState,
                              isMobile: e.matches
                        }
                  })
            }
            tabletQuery.onchange = (e) => {
                  console.log('max-width changed in tablet')
                  this.setState(prevState => {
                        return {
                              ...prevState,
                              isTablet: e.matches
                        }
                  })
            }
            this.setState(prevState => {
                  return {
                        ...prevState,
                        isTablet: tabletQuery.matches,
                        isMobile: mobileQuery.matches,
                  }
            })
      }

      toggleMenu() {
            this.setState({ visible: !this.state.visible })
      }

      render() {
            const { isMobile, isTablet } = this.state;
            const computedHeight = `calc(100vh - ${this.headerRef?.current?.offsetHeight ?? 0}px)`;
            return (
                  <Layout style={{ minHeight: '100vh' }}>
                        <CalculateSSE />
                        <DeleteDataSSE />
                        <Header>
                              <div ref={this.headerRef}
                                    style={{
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          alignItems: 'center',
                                          color: '#B5BAC7',
                                          fontSize: '16px'
                                    }}>
                                    <span>
                                          <img src={brand} alt="555Mix" style={{
                                                marginRight: '10px',
                                                width: 90
                                          }} /> Admin
                                    </span>
                                    {(isMobile && this.props.token) ? <Button style={styles.toggleButton}
                                          onClick={this.toggleMenu}>
                                          {this.state.visible ? <MenuFoldOutlined /> :
                                                <MenuUnfoldOutlined />}
                                    </Button> : null}
                              </div>
                        </Header>
                        <Layout>
                              <LeagueModal />
                              <UpdatePriceModal />
                              <UpdateGoalScoreModal />
                              <UpdateStartTime />
                              <UpdateCloseTime />
                              <UpdateLeagueModal />
                              <FinishMatchModal />
                              <UpdateTeamModal />
                              <UpdateAnnouncementModal />
                              <UpdateRuleModal />
                              <AddRule />
                              <UpdateTwoDModal />
                              <Update23LimitModal />
                              <Update23CommissionModal />
                              <UpdateSingleCommissionModal />
                              <UpdateCommissionModal />
                              <CreateCommissionModal />
                              <UpdateBasicInfoModal />
                              <UpdateShareDetailModal />
                              <UpdatePartnerShareModal />
                              <UpdatePartner3DShareModal />
                              <UpdateSingleBetCommissionModal />
                              <UpdateBetLimitationModal />
                              <UpdateSMCommissionModal />
                              <UpdatePriceGapModal />
                              <UpdateThreeDWinPercentModal />
                              <AddThreeDCollectionModal />
                              <AddTwoDCollectionModal />
                              <Update3DModal />
                              <CreateTransactionModal />
                              <FinishNumberModal />
                              <Finish3DCollectionModal />
                              <Update2DCommissionModal />
                              <Update3DCommissionModal />
                              <AddCommissionModal />
                              <ResetPasswordModal />
                              <UpdateAgreementModal />
                              <AddPaymentModal />
                              <AddFourDCollectionModal />
                              <Update4DModal />
                              <Update4DCommissionModal />
                              <UpdatePartner4DShareModal />
                              <Finish1stPrizeModal />
                              <Finish2ndPrizeModal />
                              <Finish3rdPrizeModal />
                              <FinishStarterPrizeModal />
                              <FinishConsolPrizeModal />
                              <FinishSubDigitPrizeModal />
                              <CreateWinMessageModal />
                              <UpdateWinMessageModal />
                              <CreateLoseMessageModal />
                              <UpdateLoseMessageModal />
                              {(isMobile && this.props.token) ? <MobileMenu
                                    toggleMenu={this.toggleMenu}
                                    visible={this.state.visible} /> : isTablet ?
                                    <Sider
                                          style={{
                                                overflow: 'auto',
                                                height: computedHeight
                                          }}
                                          key="tablet-sider"
                                          defaultCollapsed={true}
                                          collapsible>
                                          <DefaultMenu />
                                    </Sider> : <Sider
                                          style={{
                                                overflow: 'auto',
                                                height: computedHeight,
                                          }}
                                          key="default-sider"
                                          collapsible>
                                          <DefaultMenu />
                                    </Sider>}
                              <Content
                                    className="content"
                                    style={{
                                          boxSizing: 'border-box',
                                          margin: '0',
                                          height: computedHeight,
                                          padding: 24,
                                          background: '#f7f7f7',
                                          overflow: 'auto',
                                    }}>
                                    {this.props.children}
                              </Content>
                        </Layout>
                  </Layout>
            );
      }
}


const styles = {
      toggleButton: {
            margin: 0,
            marginLeft: 'auto',
            padding: 0,
            fontSize: '18px',
            backgroundColor: 'transparent',
            border: 0,
            outline: 'none',
            color: 'white'
      }
}

const mapStateToProps = state => ({
      token: state.auth.token,
})

export default withRouter(connect(mapStateToProps, null)(App));