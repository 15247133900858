import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
   CREATE_PAYMENT_FAIL,
   CREATE_PAYMENT_START,
   CREATE_PAYMENT_SUCCESS,
   GET_PAYMENT_FAIL,
   GET_PAYMENT_START,
   GET_PAYMENT_SUCCESS,
   TOGGLE_ADD_PAYMENT_MODAL
} from "../actions/payment/actionTypes";

const initialState = {
   loading: false,
   message: null,
   error: null,
   all: [],
   addVisible: false,
}

const getPayments = (state, action) => {
   return update(state, {
      loading: false,
      all: action.payload
   })
}

const createPayment = (state, action) => {
   return update(state, {
      loading: false,
      all: [...state.all, action.payload]
   })
}

const toggleAddPaymentModal = (state, action) => {
   return update(state, {
      addVisible: !state.addVisible
   })
}

const paymentReducer = (state = initialState, action) => {
   switch (action.type) {
      case CREATE_PAYMENT_START:
         return loading(state, action);
      case CREATE_PAYMENT_SUCCESS:
         return createPayment(state, action);
      case CREATE_PAYMENT_FAIL:
         return error(state, action);
      case GET_PAYMENT_START:
         return loading(state, action);
      case GET_PAYMENT_SUCCESS:
         return getPayments(state, action);
      case GET_PAYMENT_FAIL:
         return error(state, action);
      case TOGGLE_ADD_PAYMENT_MODAL:
         return toggleAddPaymentModal(state, action);

      default:
         return state;
   }
}

export default paymentReducer;