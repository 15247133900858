import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const updateStartTime = (startTime, matchId, token) => async dispatch => {
    dispatch({
        type: actionTypes.UPDATE_START_TIME_START
    });
    const options = {
        url: `${api}/matches/${matchId}/updateStartTime`,
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: {
            startTime
        }
    }
    await axios(options)
        .then(response => {
            dispatch({
            type: actionTypes.UPDATE_START_TIME_SUCCESS,
            payload: response.data
        })})
        .catch(error => dispatch({
            type: actionTypes.UPDATE_START_TIME_FAIL,
            payload: getMessage(error)
        }))
}

export const updateCloseTime = (closeTime, matchId, token) => async dispatch => {
    dispatch({
        type: actionTypes.UPDATE_CLOSE_TIME_START
    });
    const options = {
        url: `${api}/matches/${matchId}/updateCloseTime`,
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: {
            closeTime
        }
    }
    await axios(options)
        .then(response => {
            dispatch({
            type: actionTypes.UPDATE_CLOSE_TIME_SUCCESS,
            payload: response.data
        })})
        .catch(error => dispatch({
            type: actionTypes.UPDATE_CLOSE_TIME_FAIL,
            payload: getMessage(error)
        }))
}