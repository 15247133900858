export const CREATE_3D_COLLECTION_START='CREATE_3D_COLLECTION_START';
export const CREATE_3D_COLLECTION_SUCCESS='CREATE_3D_COLLECTION_SUCCESS';
export const CREATE_3D_COLLECTION_FAIL='CREATE_3D_COLLECTION_FAIL';

export const GET_3D_COLLECTIONS_START='GET_3D_COLLECTIONS_START';
export const GET_3D_COLLECTIONS_SUCCESS='GET_3D_COLLECTIONS_SUCCESS';
export const GET_3D_COLLECTIONS_FAIL='GET_3D_COLLECTIONS_FAIL';

export const GET_3D_COLLECTION_START='GET_3D_COLLECTION_START';
export const GET_3D_COLLECTION_SUCCESS='GET_3D_COLLECTION_SUCCESS';
export const GET_3D_COLLECTION_FAIL='GET_3D_COLLECTION_FAIL';

export const TOGGLE_3D_COLLECTION_ADD='TOGGLE_3D_COLLECTION_ADD';
export const TOGGLE_3D_STATUS_UPDATE='TOGGLE_3D_STATUS_UPDATE';

export const UPDATE_THREE_D_START='UPDATE_THREE_D_START';
export const UPDATE_THREE_D_SUCCESS='UPDATE_THREE_D_SUCCESS';
export const UPDATE_THREE_D_FAIL='UPDATE_THREE_D_FAIL';

export const SET_3D_NUMBER_TO_UPDATE='SET_3D_NUMBER_TO_UPDATE';

export const FINISH_3D_COLLECTION_START='FINISH_3D_COLLECTION_START';
export const FINISH_3D_COLLECTION_SUCCESS='FINISH_3D_COLLECTION_SUCCESS';
export const FINISH_3D_COLLECTION_FAIL='FINISH_3D_COLLECTION_FAIL';

export const TOGGLE_3D_FINISH_MODAL='TOGGLE_3D_FINISH_MODAL';

export const CALCULATE_3D_REQUEST='CALCULATE_3D_REQUEST';
export const CALCULATE_3D_SUCCESS='CALCULATE_3D_SUCCESS';
export const CALCULATE_3D_FAIL='CALCULATE_3D_FAIL';

export const BETS_OF_3D_NUMBER_START='BETS_OF_3D_NUMBER_START';
export const BETS_OF_3D_NUMBER_SUCCESS='BETS_OF_3D_NUMBER_SUCCESS';
export const BETS_OF_3D_NUMBER_FAIL='BETS_OF_3D_NUMBER_FAIL';