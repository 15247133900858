import React from 'react';
import {connect} from 'react-redux';
import {DatePicker, Form, InputNumber, message, Modal} from 'antd';
import {create4DCollection, toggleAdd4DCollectionModal} from '../../redux/actions/4DCollection';
import moment from 'moment';

const layout = {
    labelCol: {span: 10},
    wrapperCol: {span: 24},
};

class AddFourDCollectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    onFinish = async (values) => {
        await this.props.onCreate({
                ...values,
                date: values.date.format("YYYY-MM-DD")
            },
            this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
            this.form.current.resetFields();
            this.props.onClose();
        }
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }

    render() {
        const dateFormat = "DD/MM/YYYY";
        return (
            <Modal
                width={800}
                title="Create 4D Collection"
                key="c4dc"
                centered
                visible={this.props.visible}
                okButtonProps={{form: 'create-4dc-form', key: 'submit', htmlType: 'submit'}}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    {...layout}
                    ref={this.form}
                    style={{margin: 25}}
                    id="create-4dc-form"
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    initialValues={{
                        date: moment().utc()
                    }}
                >
                    <Form.Item
                        name="collectionId"
                        label="Collection ID"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{width: 200}}/>
                    </Form.Item>
                    <Form.Item
                        name="date"
                        label="Date"
                        rules={[
                            {
                                required: true,
                                message: 'require',
                            },
                        ]}
                    >
                        <DatePicker format={dateFormat}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.fourDCollection.addCollectionVisible,
    loading: state.fourDCollection.loading,
    token: state.auth.token,
    error: state.fourDCollection.error,
    message: state.fourDCollection.message,
})

const mapDispatchToProps = dispatch => ({
    onCreate: (obj, token) => dispatch(create4DCollection(obj, token)),
    onClose: () => dispatch(toggleAdd4DCollectionModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddFourDCollectionModal);