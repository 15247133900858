import {actionTypes} from "./actionTypes";
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const save4DResult=(request,token)=>async dispatch=>{
      console.log('request',request)
    dispatch({
        type:actionTypes.SAVE_4D_RESULT_START
    });
    const options={
        url:`${api}/fourDResults/${request.id}`,
        method:'PUT',
        headers:{
            Authorization:`Bearer ${token}`
        },
        data:request
    }
    await axios(options)
        .then(response=>dispatch({
            type:actionTypes.SAVE_4D_RESULT_SUCCESS,
            payload:response.data
        }))
        .catch(error=>dispatch({
            type:actionTypes.SAVE_4D_RESULT_FAIL,
            payload:getMessage(error)
        }))
}