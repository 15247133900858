import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
export const createCollectionStart=()=> ({
    type:actionTypes.CREATE_COLLECTION_START
});

export const createCollectionSuccess=(response)=>({
    type:actionTypes.CREATE_COLLECTION_SUCCESS,
    payload:response
});

export const createCollectionFail=(response)=>({
    type:actionTypes.CREATE_COLLECTION_FAIL,
    payload:response
});

export const createCollection=(request,token)=>async dispatch=>{
    dispatch(createCollectionStart());
    const url=`${api}/collections`;
    const options={
        url: url,
        method: 'POST',
        headers: {
            'Authorization': 'Bearer '+token
        },
        data:request
    };
    await axios(options)
        .then(response=>dispatch(createCollectionSuccess(response.data)))
        .catch(error=>dispatch(createCollectionFail(error)));
};


export const getCollectionStart=()=>({
    type:actionTypes.GET_COLLECTIONS_START
})

export const getCollectionSuccess=(collections)=>({
    type:actionTypes.GET_COLLECTIONS_SUCCESS,
    payload:collections
})

export const getCollectionFail=(error)=>({
    type:actionTypes.GET_COLLECTIONS_FAIL,
    payload:error
})

export const getCollection=(token)=>async dispatch=>{
    dispatch(getCollectionStart());
    const url=`${api}/collections`;
    const options={
        url: url,
        method: 'GET',
        headers: {
            'Authorization': 'Bearer '+token
        },
        data:{}
    };
    await axios(options)
        .then(response=>dispatch(getCollectionSuccess(response.data)))
        .catch(error=>dispatch(getCollectionFail(error)));
}