import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, InputNumber, message } from 'antd';
import {closeUpdateGoalScore} from '../../redux/actions/collection/toggleModals';
import {updateGoalScore} from '../../redux/actions/collection/updateGoalScore';

class UpdateGoalScoreModal extends React.Component {
    constructor(props){
        super(props);
        this.form=React.createRef();
    }
    layout = {
        wrapperCol: {
            span: 12,
        },
    };
    tailLayout = {
        wrapperCol: {
            offset: 12,
            span: 16,
        },
    };
    onFinish = async (values) => {
        await this.props.onUpdate({...values, matchId:this.props.match?.id},this.props.token);
        if(this.props.error){
            message.error(this.props.error);
        }
        if(this.props.message){
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        return (
            <Modal
                title="Update Goal Scores"
                key={this.props.match?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'update-scores-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    layout="vertical"
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="update-scores-form"
                    {...this.layout}
                    name="basic"
                    initialValues={{
                        homeScore:this.props.match?.homeScore,
                        awayScore:this.props.match?.awayScore,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                   
                    <Form.Item
                        name="homeScore"
                        label="Home Score"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{width:'100%'}}/>
                    </Form.Item>
                    <Form.Item
                        name="awayScore"
                        label="Away Score"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{width:'100%'}}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.collection.updateGoalScoreVisible,
    loading: state.collection.loading,
    match: state.collection.match,
    token: state.auth.token,
    error:state.collection.error,
    message:state.collection.message
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (goalScores,matchId, token) => dispatch(updateGoalScore(goalScores,matchId, token)),
    onClose: () => dispatch(closeUpdateGoalScore()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateGoalScoreModal);