import {actionTypes} from "./actionTypes";
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const calculate4d=(id,token)=>async dispatch=>{
    dispatch({
        type:actionTypes.CALCULATE_4D_REQUEST
    });
    const options={
        url:`${api}/fourDCollections/${id}/calculateCommissions`,
        method:'POST',
        headers:{
            Authorization:`Bearer ${token}`
        },
    }
    await axios(options)
        .then(response=>dispatch({
            type:actionTypes.CALCULATE_4D_SUCCESS,
            payload:response.data
        }))
        .catch(error=>dispatch({
            type:actionTypes.CALCULATE_4D_FAIL,
            payload:getMessage(error)
        }))
}