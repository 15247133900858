import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const createTwoDcollection = ({
    collectionId,
    period,
    copyFromPreviousCollection,
    date
},token) => async dispatch => {
    dispatch({
        type: actionTypes.CREATE_TWOD_COLLECTION_START,
    })
    const options = {
        url: `${api}/twoDCollections`,
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: {
            collectionId,
            period,
            copyFromPreviousCollection,
            date
        }
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.CREATE_TWOD_COLLECTION_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.CREATE_TWOD_COLLECTION_FAIL,
                payload:getMessage(error)
            }))
}