import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    GET_COLLECTION_REPORT_START,
    GET_COLLECTION_REPORT_SUCCESS,
    GET_COLLECTION_REPORT_FAIL,
    GET_SMASTER_REPORT_START,
    GET_SMASTER_REPORT_SUCCESS,
    GET_SMASTER_REPORT_FAIL,
    SMASTER_RP_BY_COLLECTION_START,
    SMASTER_RP_BY_COLLECTION_SUCCESS,
    SMASTER_RP_BY_COLLECTION_FAIL,
    MASTER_REPORT_START,
    MASTER_REPORT_SUCCESS,
    MASTER_REPORT_FAIL,
    AGENT_BY_COLLECTION_AND_MASTER,
    AGENT_BY_COLLECTION_AND_MASTER_SUCCESS,
    AGENT_BY_COLLECTION_AND_MASTER_FAIL,
    MEMBER_BY_COLLECTION_AND_AGENT,
    MEMBER_BY_COLLECTION_AND_AGENT_SUCCESS,
    MEMBER_BY_COLLECTION_AND_AGENT_FAIL,
    BET_REPORT_START,
    BET_REPORT_SUCCESS,
    BET_REPORT_FAIL,
    SET_BET_TYPE,
    MATCH_COUNT_SUCCESS,
    MATCH_COUNT_START,
    MATCH_COUNT_FAIL
} from '../actions/report/actionTypes';

const initialState={
    loading:false,
    error:null,
    message:null,
    collection:[],
    smaster:[],
    smasterByCollection:[],
    master:[],
    members:[],
    agents:[],
    bets:[],
    type:'SOCCER',
    matchCounts:[]
}

const getCollectionReportStart=(state,action)=>{
      return update(state,{
            loading:true,
            collection:[],
            smaster:[],
            smasterByCollection:[],
            master:[],
            members:[],
            agents:[],
            bets:[],
            error:null,
            message:null,
      })
}

const getCollectionReport=(state,action)=>{
    return update(state,{
        loading:false,
        collection:action.payload
    })
}

const getSmasterReportStart=(state,action)=>{
      return update(state,{
            loading:true,
            smaster:[],
            error:null,
            message:null
      })
}

const getSmasterReport=(state,action)=>{
    return update(state,{
        loading:false,
        smaster:action.payload
    })
}

const smasterReportByCollection=(state,action)=>{
    return update(state,{
        loading:false,
        smasterByCollection:action.payload
    })
}

const masterReport=(state,action)=>{
    return update(state,{
        loading:false,
        master:action.payload
    })
}

const agentByCollectionAndMaster=(state,action)=>{
    return update(state,{
        loading:false,
        agents:action.payload
    })
}

const getMemberReport=(state,action)=>{
    return update(state,{
        loading:false,
        members:action.payload
    })
}

const getBetReport=(state,action)=>{
    return update(state,{
        loading:false,
        bets:action.payload
    })
}

const getMatchCountStart=(state,action)=>{
      return update(state,{
            loading:true,
            matchCounts:[],
            error:null,
            message:null,
      })
}

const getMatchCount=(state,action)=>{
    return update(state,{
        loading:false,
        matchCounts: action.payload
    })
}

const reportReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case GET_COLLECTION_REPORT_START:
            return getCollectionReportStart(state,action);
        case GET_COLLECTION_REPORT_SUCCESS:
            return getCollectionReport(state,action);
        case GET_COLLECTION_REPORT_FAIL:
            return error(state,action);
        case GET_SMASTER_REPORT_START:
            return getSmasterReportStart(state,action);
        case GET_SMASTER_REPORT_SUCCESS:
            return getSmasterReport(state,action);
        case GET_SMASTER_REPORT_FAIL:
            return error(state,action);
        case SMASTER_RP_BY_COLLECTION_START:
            return loading(state,action);
        case SMASTER_RP_BY_COLLECTION_SUCCESS:
            return smasterReportByCollection(state,action);
        case SMASTER_RP_BY_COLLECTION_FAIL:
            return error(state,action);
        case MASTER_REPORT_START:
            return loading(state,action);
        case MASTER_REPORT_SUCCESS:
            return masterReport(state,action);
        case MASTER_REPORT_FAIL:
            return error(state,action);
        case AGENT_BY_COLLECTION_AND_MASTER:
            return loading(state,action);
        case AGENT_BY_COLLECTION_AND_MASTER_SUCCESS:
            return agentByCollectionAndMaster(state,action);
        case AGENT_BY_COLLECTION_AND_MASTER_FAIL:
            return error(state,action);
        case MEMBER_BY_COLLECTION_AND_AGENT:
            return loading(state,action);
        case MEMBER_BY_COLLECTION_AND_AGENT_SUCCESS:
            return getMemberReport(state,action);
        case MEMBER_BY_COLLECTION_AND_AGENT_FAIL:
            return error(state,action);
        case BET_REPORT_START:
            return loading(state,action);
        case BET_REPORT_SUCCESS:
            return getBetReport(state,action);
        case BET_REPORT_FAIL:
            return error(state,action);
        case SET_BET_TYPE:
            return update(state,{type:action.payload});
        case MATCH_COUNT_START:
            return getMatchCountStart(state,action);
        case MATCH_COUNT_SUCCESS:
            return getMatchCount(state,action);
        case MATCH_COUNT_FAIL:
            return error(state,action);
        default:
            return state;
    }
}

export default reportReducer;