import { Table, Checkbox } from 'antd';
import React from 'react';
import moment from 'moment';

class LsportTable extends React.Component {
   columns = [
      {
         title: 'Match Time',
         dataIndex: 'matchTime',
         key: 'matchTime',
         render: (text, record) => (
            <span>{moment.unix(record?.matchTime).format('YYYY-MM-DD HH:mm a')}</span>
         )
      },
      {
         title: 'League Name',
         dataIndex: 'leagueName',
         key: 'leagueName'
      },
      {
         title: 'Home Team',
         dataIndex: 'homeName',
         key: 'homeName'
      },
      {
         title: 'Away Team',
         dataIndex: 'awayName',
         key: 'awayName'
      },
      {
         title: 'Odds',
         dataIndex: 'odds',
         key: 'odds'

      },
      {
         title: 'Price',
         dataIndex: 'price',
         key: 'price'

      },
      {
         title: 'Goal Total',
         dataIndex: 'goals',
         key: 'goals'

      },
      {
         title: 'Goal Price',
         dataIndex: 'goalsPrice',
         key: 'goalsPrice'

      },
      {
         title: 'Single Bet',
         dataIndex: 'singleBet',
         key: 'singleBet',
         render: (text, record) => (
            <Checkbox checked={record.singleBet} onChange={() => this.onSingleBetChange(record)} />
         )
      },
      {
         title: 'High Tax',
         dataIndex: 'highTax',
         key: 'highTax',
         render: (text, record) => (
            <Checkbox checked={record.highTax} onChange={() => this.onHighTaxChange(record)} />
         )
      },
   ]

   onSingleBetChange = (record) => {
      let newData = this.props.data?.map(value => {
         if (value.matchId === record.matchId) {
            return {
               ...value,
               singleBet: !record.singleBet
            };
         }
         return value;
      });
      this.props.updateLsport(newData);
   }

   onHighTaxChange = (record) => {
      let newData = this.props.data?.map(value => {
         if (value.matchId === record.matchId) {
            return {
               ...value,
               highTax: !record.highTax
            };
         }
         return value;
      });
      this.props.updateLsport(newData);
   }

   render() {
      const { selectedRowKeys, onSelectChange } = this.props;
      const rowSelection = {
         selectedRowKeys,
         onChange: onSelectChange
      };

      let dataSource = this.props.data?.map(value => ({
         key: value.matchId,
         ...value
      }));

      return <Table
         rowSelection={rowSelection}
         columns={this.columns}
         dataSource={dataSource}
         loading={this.props.loading}
         pagination={false}
      />;
   }
}
export default LsportTable;