import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const getSMTransactionDetail = ({ id, date }, token) => async dispatch => {
   dispatch({
      type: actionTypes.GET_SM_TRANSACTION_DETAIL_START,
   })
   const options = {
      method: 'GET',
      url: `${api}/transactions/smaster/${id}/${date}`,
      headers: {
         Authorization: `Bearer ${token}`
      },
   }
   await axios(options)
      .then(response => dispatch({
         type: actionTypes.GET_SM_TRANSACTION_DETAIL_SUCCESS,
         payload: response.data
      }))
      .catch(error => dispatch({
         type: actionTypes.GET_SM_TRANSACTION_DETAIL_FAIL,
         payload: getMessage(error)
      }))
}