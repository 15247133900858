import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const finish3DCollection=({collectionId,threeDNumber},token)=>async dispatch=>{
    dispatch({
        type:actionTypes.FINISH_3D_COLLECTION_START
    })
    const options={
        url:`${api}/threeDCollections/${collectionId}/finish`,
        method:'POST',
        headers:{
            Authorization:`Bearer ${token}`
        },
        data:{
            threeDNumber
        }
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.FINISH_3D_COLLECTION_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.FINISH_3D_COLLECTION_FAIL,
                payload:getMessage(error)
            }))
}