import { useEffect } from 'react';
import { Table, Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBetDetail } from '../../redux/actions/betPattern/getBetDetail';
import BetInformations from './BetInformations';

const columns = [
    {
        title: 'ထိုးဂဏန်း',
        key: 'threeDNumber',
        render: (text, record) =>record.threeDNumber.number,
        align:'center'
    },
    {
        title:'အဆ',
        key:'winPercent',
        dataIndex:'winPercent',
        align:'center'
    },
    {
        title:'ထိုးငွေ',
        key:'userBetAmount',
        dataIndex:'userBetAmount',
        align:'right'
    },
    {
        title:'ပြန်ရငွေ',
        key:'userWinAmount',
        dataIndex:'userWinAmount',
        align:'right'
    }
]

const ThreeDDetail = () => {
    const { id } = useParams();
    const token = useSelector(state => state.auth.token);
    const loading = useSelector(state => state.betPattern.loading);
    const detail = useSelector(state => state.betPattern.betDetail);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getBetDetail(id, token));
    }, [])
    return <>
        <Row justify="left">
            <Col lg={24} md={24} sm={24}>
                <BetInformations
                    id={detail?.id}
                    partnerBetAmount={detail?.partnerBetAmount}
                    smasterBetAmount={detail?.smasterBetAmount}
                    userBetAmount={detail?.userBetAmount}
                    partnerCommissionAmount={detail?.partnerCommissionAmount}
                    smasterCommissionAmount={detail?.smasterCommissionAmount}
                    userCommissionAmount={detail?.userCommissionAmount}
                    partnerWinAmount={detail?.partnerWinAmount}
                    smasterWinAmount={detail?.smasterWinAmount}
                    userWinAmount={detail?.userWinAmount}
                    status={detail?.betStatus}
                    date={detail?.betTime}
                />
                <Table
                    columns={columns}
                    dataSource={
                        detail?.betThreeDNumbers
                    }
                    loading={loading}
                />
            </Col>
        </Row>
    </>
}

export default ThreeDDetail;