import {Form, Input, message as antdMessage, Modal} from "antd";
import React, {useEffect, useRef} from "react";

export default function UpdateMessageModal({
                                               title,
                                               loading,
                                               visible,
                                               onUpdate,
                                               token,
                                               error,
                                               message,
                                               toUpdate,
                                               onClose,
                                               modalKey,
                                               formId
                                           }) {
    const formRef = useRef();
    useEffect(() => {
        if (error) {
            antdMessage.error(message);
        }
    }, [error])
    const onFinish = async (values) => {
        await onUpdate({
            id: toUpdate?.id,
            message: values.message,
        }, token);
        formRef?.current?.resetFields();
            onClose();
    }
    const onFinishFailed = (errors) => {
        console.log(errors)
    }
    return (
        <Modal
            title={title}
            key={modalKey}
            centered
            visible={visible}
            okButtonProps={{
                form: formId,
                key: 'submit',
                htmlType: 'submit'
            }}
            confirmLoading={loading}
            onCancel={onClose}
            okText="Save"
            loading={loading}
        >
            <Form
                ref={formRef}
                style={{margin: 25}}
                id={formId}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{message: toUpdate?.message}}
            >
                <Form.Item
                    name="message"
                    rules={[
                        {
                            required: true,
                            message: 'Require!',
                        }
                    ]}
                    hasFeedback
                >
                    <Input.TextArea/>
                </Form.Item>
            </Form>
        </Modal>
    );
}