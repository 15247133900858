import axios from "axios";
import api from "../../utilities/api";
import * as actionTypes from "./actionTypes";
import { getMessage } from '../../utilities/getMessage';

export const searchBetByBetAndAmount = (bet, token) => async dispatch => {
    dispatch(request());
    const url = `${api}/bets/search`;
    const options = {
        url: url,
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        data: bet
    };
    await axios(options)
        .then(response => {
            dispatch(success(response.data));
        })
        .catch(error => dispatch(fail(error)));
}

function request() {
    return {
        type: actionTypes.SEARCH_BET_BY_BET_AND_AMOUNT_REQUEST
    }
}

function success(data) {
    return {
        type: actionTypes.SEARCH_BET_BY_BET_AND_AMOUNT_SUCCESS,
        payload: data
    }
}

function fail(err) {
    return {
        type: actionTypes.SEARCH_BET_BY_BET_AND_AMOUNT_FAIL,
        payload:getMessage(err)
    }
}