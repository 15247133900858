import React from 'react';
import {connect} from 'react-redux';
import {Form, message, Modal, Select} from 'antd';
import {toggleUpdate4DModal, updateFourD} from '../../redux/actions/4DCollection';

class Update4DModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 12,
        },
    };
    tailLayout = {
        wrapperCol: {
            offset: 12,
            span: 16,
        },
    };

    onFinish = async (values) => {
        await this.props.onUpdate(
            this.props.number?.id,
            values.status
            , this.props.token);
        this.form.current.resetFields();
        this.props.onClose();
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
    }

    onFinishFailed = (errors) => {
        console.log(errors)
    }

    handleCancel = () => {
        this.props.onClose();
    }

    render() {
        const {Option} = Select;
        return (
            <Modal
                title="Update Status"
                key={this.props.number?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{form: 'update-4d-status-form', key: 'submit', htmlType: 'submit'}}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    ref={this.form}
                    style={{margin: 25}}
                    id="update-4d-status-form"
                    {...this.layout}
                    name="basic"
                    initialValues={{
                        status: this.props.number?.status
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="status"
                        label="Status"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                    >
                        <Select style={{width: 200}}>
                            <Option value={1}>Normal</Option>
                            <Option value={3}>Closed</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.fourDCollection.updateNumberVisible,
    loading: state.fourDCollection.loading,
    token: state.auth.token,
    error: state.fourDCollection.error,
    message: state.fourDCollection.message,
    number: state.fourDCollection.numberToUpdate
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (id, status, token) => dispatch(updateFourD(id, status, token)),
    onClose: () => dispatch(toggleUpdate4DModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Update4DModal);