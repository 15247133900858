import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const resetPassword = (request, token) => async dispatch => {
    dispatch({
        type: actionTypes.RESET_EMPLOYEE_PASSWORD_START
    })
    const options = {
        url: `${api}/employees/${request.id}/resetPassword`,
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: request
    }
    await axios(options)
        .then(response => dispatch({
            type: actionTypes.RESET_EMPLOYEE_PASSWORD_SUCCESS,
            payload: response.data
        }))
        .catch(error => dispatch({
            type: actionTypes.RESET_EMPLOYEE_PASSWORD_FAIL,
            payload: getMessage(error)
        }))
}