import React from 'react';
import { Row, Col, Table, message, Input  } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSeniormaster } from '../../redux/actions/seniormaster/getSeniormaster';
import {
   EyeOutlined
} from '@ant-design/icons';

class SeniorMasterList extends React.Component {
   state = {
      searchKey: "",
      searchResult: []
   }
   async componentDidMount() {
      const { token } = this.props;
      await this.props.onGetSmasters(token);
   }
   componentDidUpdate() {
      if (this.props.error) {
         message.error(this.props.error);
      }
   }
   onSearch = ({ target: { value } }) => {
      const result = this.props.smasters?.filter(el => `${el.username}`.toLowerCase().includes(value.toLowerCase())
      || `${el.name}`.toLowerCase().includes(value.toLowerCase()));
      this.setState(oldState => ({
         ...oldState,
         searchResult: result,
         searchKey: value
      }))
   }
   render() {
      const columns = [
         {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
         },
         {
            title: 'Username',
            dataIndex: 'username',
            key: 'username'
         },
         {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance'
         },
         {
            title: 'Detail',
            render: (text, record) => (<EyeOutlined onClick={
               () => this.props.history.push(`/senior_masters/${record.id}`)
            } />)
         }
      ]
      return (<>
         <Row gutter={[16, 16]}>
            <Col span={{ lg: 6, md: 10, sm: 20 }}>
               <Input.Search
                   style={{ width: '220px' }}
                   placeholder="Search"
                   onChange={this.onSearch}
                   enterButton />
            </Col>
            <Col span={24} style={{
               width: '100%',
               overflowX: 'auto',
            }}>
               <Table columns={columns} dataSource={this.state.searchKey ?
                   this.state.searchResult : this.props.smasters}
                      loading={this.props.loading} />
            </Col>
         </Row>
      </>)
   }
}

const mapStateToProps = state => ({
   loading: state.seniorMaster.loading,
   token: state.auth.token,
   smasters: state.seniorMaster.all,
   error: state.seniorMaster.error
})

const mapDispatchToProps = dispatch => ({
   onGetSmasters: (token) => dispatch(getSeniormaster(token))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeniorMasterList));