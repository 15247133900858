import { Form, Row, Col, Input, Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOldData } from '../../redux/actions/bets/deleteOldData';
import axios from "axios";
import api from '../../redux/utilities/api';

export const Delete3MonthsData = () => {

    const [betCountLoading, setBetCountLoading] = useState(false);

    const [isMobile, setIsMobile] = useState(false);

    const dispatch = useDispatch();

    const token = useSelector(state => state.auth.token);

    const { loading, deleting } = useSelector(state => state.bets);

    useEffect(() => {
        const query = window.matchMedia(`(max-width: 480px)`);
        query.onchange = (e) => {
            setIsMobile(e.matches);
        }
    }, []);

    const onFinish = async (values) => {
        if (values.confirm === 'DELETE') {
            dispatch(deleteOldData(token));
        }
    }

    const onFinishFailed = (error) => {
        console.error(error);
    }

    const getBetsCount = (token) => {
        setBetCountLoading(true);

        const options = {
            url: `${api}/bets/count`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            },
        }

        axios(options).then(response => {
            setBetCountLoading(false);
            Modal.info({
                title: 'Total bets count',
                content: (
                    <h1>{response.data.betCount}</h1>
                ),
                onOk() { },
            });
        }).catch(error=>{
            console.log(error);
            setBetCountLoading(false);
        });
    }

    return (
        <>
            {deleting ? <h1>Deleting in progress ..</h1> : <h1>Delete 3 Months Old Data</h1>}
            <Form
                loading={loading || deleting}
                name="basic"
                layout={isMobile ? "vertical" : "inline"}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>
                <Row gutter={[20, 20]} style={{ width: '100%' }}>
                    <Col lg={{ span: 8 }}
                        md={{ span: 12 }}
                        sm={{ span: 12 }}
                        xs={{ span: 24 }}>
                        <Form.Item
                            name="confirm"
                            rules={[
                                {
                                    required: true,
                                    message: 'require',
                                },
                            ]}>
                            <Input span={24} placeholder='type DELETE' />
                        </Form.Item>
                    </Col>
                    <Col lg={{ span: 8 }}
                        md={{ span: 12 }}
                        sm={{ span: 12 }}
                        xs={{ span: 12 }}>
                        <Form.Item>
                            <Button disabled={loading || deleting} type="primary" htmlType="submit">
                                Delete
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Button disabled={betCountLoading} loading={betCountLoading} onClick={() => getBetsCount(token)}>{betCountLoading ? "Loading Bets Count" : "Show Bets Count"}</Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}