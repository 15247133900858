import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const update3DWinPercent = ({
    winPercent,
    specialNumberWinPercent
}, token) => async dispatch => {
    dispatch({
        type: actionTypes.UPDATE_THREE_D_WIN_PERCENT_START
    })
    const options = {
        url: `${api}/threeDWinPercent`,
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: {
            winPercent,
            specialNumberWinPercent
        }
    }
    await axios(options)
        .then(response => dispatch({
            type: actionTypes.UPDATE_THREE_D_WIN_PERCENT_SUCCESS,
            payload: response.data
        }))
        .catch(error => dispatch({
            type: actionTypes.UPDATE_THREE_D_WIN_PERCENT_FAIL,
            payload: getMessage(error)
        }))
}