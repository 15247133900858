import { Tabs } from 'antd';
import BetPatterns from './BetPatterns';
import SingleBetPattern from './SingleBetPattern';
const Dashboard = () => {
    const { TabPane } = Tabs;
    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Mix Bet" key="1">
                <BetPatterns />
            </TabPane>
            <TabPane tab="Single Bet" key="2">
                <SingleBetPattern />
            </TabPane>
        </Tabs>
    );
}

export default Dashboard;