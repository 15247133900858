import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const getAgentsByCollectionAndMaster=({
    date,
    masterName,
    type
},token)=>async dispatch=>{
    dispatch({
        type:actionTypes.AGENT_BY_COLLECTION_AND_MASTER,
    })
    const options={
        url:`${api}/reports/daily/agents?masterName=${masterName}&type=${type}&date=${date}`,
        method:'GET',
        headers:{
            'Authorization':`Bearer ${token}`
        },
        data:{}
    }
    await axios(options)
            .then(response=> dispatch({
                type:actionTypes.AGENT_BY_COLLECTION_AND_MASTER_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.AGENT_BY_COLLECTION_AND_MASTER_FAIL,
                payload:getMessage(error)
            }))
}