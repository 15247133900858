import React, { Component } from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Layout, Typography, message } from 'antd';
import { connect } from "react-redux";
import { withRouter, Redirect } from 'react-router-dom';
import * as actions from '../redux/actions/auth/auth';
import OTPVerification from './OTPVerification';
class Login extends Component {
    state = {
        formState: {}
    }
    componentDidUpdate() {
        if (this.props.error) {
            message.error(this.props.error);
        }
    }
    layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };
    tailLayout = {
        wrapperCol: { lg: { offset: 6, span: 16 }, sm: { offset: 2 }, md: { offset: 6 } },
    };

    onFinish = async (values) => {
        const { username, password } = values;
        this.setState({
            formState: {
                username,
                password
            }
        })
        this.onLogin(username, password);
    }

    onLogin = async (username, password) => {
        const { success, requestId } = await actions.login(username, password);
        if (success) {
            const params = new URLSearchParams({ requestId });
            this.props.history.push({ pathname: this.props.location.pathname, search: params.toString() });
        } else {
            console.error('Error');
        }
    }

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    onSubmitOTP = async (otp) => {
        const params = new URLSearchParams(this.props.location.search);
        await this.props.onAuth(params.get('requestId'), otp)
    }
    render() {
        const params = new URLSearchParams(this.props.location.search);
        if (params.get('requestId')) {
            return (
                <Wrapper
                    authenticated={this.props.isAuthenticated}
                    from={this.props.location.state?.from}>
                    <OTPVerification
                        onSubmit={this.onSubmitOTP}
                        formState={this.state.formState}
                        onLogin={this.onLogin} />
                </Wrapper>
            )
        }
        return (
            <Wrapper
                authenticated={this.props.isAuthenticated}
                from={this.props.location.state?.from}>
                <Row justify="center" align="middle">
                    <Col lg={8} sm={20}>
                        <Form
                            style={styles.form}
                            align="left"
                            {...this.layout}
                            justify="center"
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                        >
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your username!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item {...this.tailLayout} name="remember" valuePropName="checked">
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>

                            <Form.Item {...this.tailLayout}>
                                <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Wrapper>
        )
    }
}

const Wrapper = ({ authenticated, children, from }) => {
    return (
        <Layout style={styles.container}>
            {authenticated && <Redirect to={from || '/'} />}
            <Layout.Header>
                <div style={styles.head}>
                    <Typography.Text type="success" style={styles.header}>555MIX Admin</Typography.Text>
                </div>
            </Layout.Header>
            <Layout.Content>
                {children}
            </Layout.Content>
        </Layout>

    )
}
const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (requestId, code) =>
            dispatch(actions.auth(requestId, code))
    };
};

const styles = {
    container: {
        height: '100vh'
    },
    form: {
        marginTop: '100px'
    },
    head: {
        display: 'flex',
        backgroundColor: '#001529',
        justifyContent: 'center'
    },
    header: {
        fontSize: 24
    },
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));