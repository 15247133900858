import * as actionTypes from './actionTypes';
import axios from 'axios';
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

const refreshGoalScoreStart=()=>({
      type:actionTypes.REFRESH_GOAL_SCORE_START
})

const refreshGoalScoreSuccess=(data)=>({
      type:actionTypes.REFRESH_GOAL_SCORE_SUCCESS,
      payload:data
})

const refreshGoalScoreFail=(error)=>({
      type:actionTypes.REFRESH_GOAL_SCORE_FAIL,
      payload:getMessage(error)
})

export const refreshGoalScore=(id,token)=>async dispatch=>{
      dispatch(refreshGoalScoreStart());
      const options={
            url:`${api}/collections/${id}/updateGoalScore`,
            method:'POST',
            headers:{
                  Authorization:`Bearer ${token}`
            }
      }
      await axios(options)
            .then(response=>dispatch(refreshGoalScoreSuccess(response.data)))
            .catch(error=>dispatch(refreshGoalScoreFail(error)))
}