import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

const getCollectionByIdStart = () => ({
    type: actionTypes.GET_COLLECTION_START,
})

const getCollectionByIdSuccess = (data) => ({
    type: actionTypes.GET_COLLECTION_SUCCESS,
    payload: data
})

const getCollectionByIdFail = (error) => ({
    type: actionTypes.GET_COLLECTION_FAIL,
    payload: error
})

export const getCollectionById = (id, token) => async dispatch => {
    dispatch(getCollectionByIdStart());
    const options = {
        url: `${api}/collections/${id}`,
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: {}
    }
    await axios(options)
        .then(response => dispatch(
            getCollectionByIdSuccess(response.data)
        ))
        .catch(error => dispatch(
            getCollectionByIdFail(
                getMessage(error)
            )
        ))
}