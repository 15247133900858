import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import ReduxThunk from 'redux-thunk';
import announcementReducer from "./reducers/announcementReducer";
import authReducer from './reducers/authReducer';
import collectionReducer from "./reducers/collectionReducer";
import leagueReducer from "./reducers/leagueReducer";
import lsportReducer from "./reducers/lsportReducer";
import createMatchReducer from "./reducers/matchReducer";
import ruleReducer from "./reducers/ruleReducer";
import teamReducer from './reducers/teamReducer';
import twoDCollectionReducer from "./reducers/twoDCollectionReducer";
import seniormasterReducer from './reducers/seniormasterReducer';
import twothreeLimitReducer from "./reducers/23LimitReducer";
import partnerReducer from './reducers/partnerReducer';
import priceGapReducer from "./reducers/priceGapReducer";
import threeDWinPercentReducer from './reducers/3DWinPercentReducer';
import threeDCollectionReducer from './reducers/3DCollectionReducer';
import transactionReducer from './reducers/transactionReducer';
import reportReducer from "./reducers/reportReducer";
import betPatternReducer from "./reducers/betPatternReducer";
import agreementReducer from "./reducers/agreementReducer";
import paymentReducer from "./reducers/paymentReducer";
import fourDCollectionReducer from "./reducers/4DCollectionReducer";
import winMessageReducer from "./reducers/winMessageReducer";
import loseMessageReducer from "./reducers/loseMessageReducer";
import employeeReducer from "./reducers/employeeReducer";
import betsReducer from "./reducers/betsReducer";
import searchBetReducer from "./reducers/searchBetReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    auth: authReducer,
    collection:collectionReducer,
    lsport:lsportReducer,
    createMatch:createMatchReducer,
    league:leagueReducer,
    team:teamReducer,
    announcement:announcementReducer,
    rule:ruleReducer,
    twoDCollection:twoDCollectionReducer,
    seniorMaster:seniormasterReducer,
    twothreeLimits:twothreeLimitReducer,
    partner:partnerReducer,
    priceGap:priceGapReducer,
    threeDWinPercent:threeDWinPercentReducer,
    threeDCollection:threeDCollectionReducer,
    transaction:transactionReducer,
    report:reportReducer,
    betPattern:betPatternReducer,
    agreement:agreementReducer,
    payment:paymentReducer,
    fourDCollection:fourDCollectionReducer,
    winMessage:winMessageReducer,
    loseMessage:loseMessageReducer,
    employee:employeeReducer,
    bets:betsReducer,
    searchBet:searchBetReducer,
  });
  
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(ReduxThunk))
  );
  
  export default store;