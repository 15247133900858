import React from 'react';
import { Row, Col, Table, message, PageHeader, Card, Switch, Button } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getOneSmaster } from '../../redux/actions/seniormaster/getOneSmaster';
import * as actionTypes from '../../redux/actions/seniormaster/actionTypes';
import { updateExtraCommission, updateTwoThreeBet } from '../../redux/actions/seniormaster/updateSeniormaster';
import { setCommissionToUpdate } from '../../redux/actions/seniormaster/setCommissionToUpdate';
import { toggleTransactionAddModal } from '../../redux/actions/transaction/toggleTransactionAddModal';
import { updateSuspend } from '../../redux/actions/seniormaster/updateSuspend';
import {toggle4DCommissionUpdate} from '../../redux/actions/seniormaster/toggle4DCommissionUpdate';
import {togglePartner4DShareUpdate} from '../../redux/actions/seniormaster/togglePartner4DShareUpdate';
import {
   EditOutlined,
   CheckOutlined,
   CloseOutlined,
   ArrowLeftOutlined,
   TransactionOutlined,
   LockOutlined,
   UnlockOutlined,
   PlusSquareOutlined,
   RetweetOutlined
} from '@ant-design/icons';

class SeniorMasterDetail extends React.Component {
   state = {
      isMobile: false,
   }
   async componentDidMount() {
      const query = window.matchMedia(`(max-width: 480px)`);
      query.onchange = (e) => {
         this.setState(prevState => {
            return {
               ...prevState,
               isMobile: e.matches
            }
         })
      }
      this.setState({
         isMobile: query.matches
      })
      const { match: { params } } = this.props;
      const { token } = this.props;
      await this.props.onGetSmaster(params.id, token);
      if (this.props.error) {
            message.error(this.props.error);
         }
   }

   addCommaToNumber = (num) => `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

   updateExtraCommission = async (value) => {
      await this.props.onUpdateExtraCommssion({
         id: this.props.smaster?.id,
         extraCommission: value
      }, this.props.token);
      if (this.props.message) {
         message.success(this.props.message);
      }
      if (this.props.error) {
         message.error(this.props.error);
      }
   }
   updateTwoThreeBet = async (value) => {
      await this.props.onUpdateTwoThreeBet({
         id: this.props.smaster?.id,
         twoThreeBet: value
      }, this.props.token);
      if (this.props.message) {
         message.success(this.props.message);
      }
      if (this.props.error) {
         message.error(this.props.error);
      }
   }
   updateCommission = async (record) => {
      await this.props.onSetCommission(record);
      await this.props.onOpenCommissionUpdate();
   }
   handleSuspend = async () => {
      await this.props.onUpdateSuspend({
         smasterId: this.props.smaster?.id,
         suspend: !this.props.smaster?.suspended
      }, this.props.token);
      if (this.props.message) {
         message.success(this.props.message);
      }
   }
   render() {
      const columns = [
         {
            title: 'MatchCount',
            dataIndex: 'matchCount',
            key: 'matchCount'
         },
         {
            title: 'Tax',
            dataIndex: 'winCommission',
            key: 'winCommission'
         },
         {
            title: 'Commission',
            dataIndex: 'betCommission',
            key: 'betCommission'
         },

         {
            title: 'Action',
            render: (text, record) => (<EditOutlined onClick={
               () => this.updateCommission(record)
            } />)
         }
      ];
      const {
         name,
         username,
         mobile,
         balance,
         betLimitation: {
            smasterMixMax,
            smasterSingleMax,
            masterMixMax,
            masterSingleMax,
            agentMixMax,
            agentSingleMax,
            maxForSingleBet,
            maxForMixBet,
            maxForTwoD,
            maxForThreeD,
            // maxForFourD,
         },
         singleBetCommission: {
            betCommission,
            highBetCommission,
            winCommission,
            highWinCommission
         },
         shareDetail: {
            sharePercentage,
            threeDSharePercentage,
            // fourDSharePercentage,
         },
         partnerShare,
         partnerThreeDShare,
         // partnerFourDShare,
         extraCommissionAllowed,
         twoDCommission,
         threeDCommission,
         // fourDCommission,
      } = this.props.smaster;
      const BUTTONS = {
         marginTop: '20px',
         display: 'flex',
         flexDirection: this.state.isMobile ? "column" : "row",
         gap: 15
      }
      return (<>
         <Row style={{ marginBottom: 10 }}>
            <Col span={24}>
               <PageHeader
                  style={{
                     display: 'flex',
                     flexDirection: 'row',
                     justifyContent: 'space-between',
                     border: '1px solid rgb(235, 237, 240)',
                  }}
                  title={<><ArrowLeftOutlined onClick={this.props.history.goBack} />
                  <span style={{ marginLeft: '20px' }}>{`Senior Master Detail`}</span></>}
               />
               <Row style={{ marginBottom: 10 }}>
                  <Col span={24}>
                     <Card
                        type="inner"
                        title={<div style={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                           width: '100%'
                        }}>
                           <h3>Basic Info</h3>
                           <EditOutlined onClick={() => this.props.onOpenBasicUpdate()} />
                        </div>}
                        loading={this.props.loading}
                     >
                        <div>Name - {name}</div>
                        <div>Username - {username}</div>
                        <div>Mobile - {mobile}</div>
                        <div>Balance - {this.addCommaToNumber(balance)}</div>
                        <div style={BUTTONS}>
                           <Button style={{
                              backgroundColor: '#8d99ae',
                              color: 'white'
                           }}
                              onClick={this.props.onOpenPaswordResetModal}
                           >
                              <RetweetOutlined /> Reset Password
                           </Button>
                           <Button style={{
                              backgroundColor: '#e5383b',
                              color: 'white'
                           }}
                              onClick={this.props.onOpenTransactionAdd}
                           >
                              <TransactionOutlined /> Manage Balance
                           </Button>

                           <Button style={{
                              backgroundColor: this.props.smaster?.suspended ? '#e5383b' : '#2dc653',
                              color: 'white'
                           }}
                              onClick={this.handleSuspend}
                              loading={this.props.loading}
                           >
                              {this.props.smaster?.suspended ? <LockOutlined /> : <UnlockOutlined />} {this.props.smaster?.suspended ? 'Unsuspend' : ' Suspend'}
                           </Button>
                        </div>
                     </Card>
                  </Col>
               </Row>
               <Row style={{ marginBottom: 10 }}>
                  <Col span={24}>
                     <Card
                        type="inner"
                        title={<div style={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                           width: '100%'
                        }}>
                           <h3>Advanced Info</h3>
                        </div>}
                        loading={this.props.loading}
                     >
                        <div>Partner Share - {partnerShare} &nbsp; &nbsp;<EditOutlined
                           onClick={
                              () => this.props.onOpenPartnerShareUpdate()
                           }
                        /></div>
                        <div>Partner Three D Share - {partnerThreeDShare} &nbsp; &nbsp;<EditOutlined
                            onClick={
                               () => this.props.onOpenPartner3dShareUpdate()
                            }
                        /></div>
                        {/* <div>Partner Four D Share - {partnerFourDShare??0} &nbsp; &nbsp;<EditOutlined
                            onClick={
                               () => this.props.onOpenPartner4dShareUpdate()
                            }
                        /></div> */}
                        <div>Extra Commission Allowed <Switch
                           style={{ marginLeft: 20 }}
                           checkedChildren={<CheckOutlined />}
                           unCheckedChildren={<CloseOutlined />}
                           checked={extraCommissionAllowed}
                           onChange={this.updateExtraCommission}
                           loading={this.props.loading}
                        /></div>

                     </Card>
                  </Col>
               </Row>
               <Row style={{ marginBottom: 10 }}>
                  <Col span={24}>
                     <Card
                        type="inner"
                        title={<div style={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                           width: '100%'
                        }}>
                           <h3>Share Detail</h3>
                           <EditOutlined onClick={() => this.props.onOpenShareDetailUpdate()} />
                        </div>}
                        loading={this.props.loading}
                     >
                        <div>Share Percentage - {sharePercentage}</div>
                        <div>Three D Share Percentage - {threeDSharePercentage??0}</div>
                        {/* <div>Four D Share Percentage - {fourDSharePercentage??0}</div> */}
                     </Card>
                  </Col>
               </Row>

               <Row style={{ marginBottom: 10 }}>
                  <Col span={24}>
                     <Card
                        type="inner"
                        title={<div style={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                           width: '100%'
                        }}>
                           <h3>Bet Limitations</h3>
                           <EditOutlined onClick={() => this.props.onOpenBetLimit()} />
                        </div>}
                        loading={this.props.loading}
                     >
                        <div style={FINANCE_LIMIT_STYLE}>
                           <div style={LIMIT_STYLE}>Smaster Mix Max </div>
                           <div style={LIMIT_STYLE}>{this.addCommaToNumber(smasterMixMax)}</div>
                           <div style={LIMIT_STYLE}>Smaster Single Max </div>
                           <div style={LIMIT_STYLE}>{this.addCommaToNumber(smasterSingleMax)}</div>
                        </div>
                        <div style={FINANCE_LIMIT_STYLE}>
                           <div style={LIMIT_STYLE}>Master Mix Max </div>
                           <div style={LIMIT_STYLE}>{this.addCommaToNumber(masterMixMax)}</div>
                           <div style={LIMIT_STYLE}>Master Single Max </div>
                           <div style={LIMIT_STYLE}>{this.addCommaToNumber(masterSingleMax)}</div>
                        </div>
                        <div style={FINANCE_LIMIT_STYLE}>
                           <div style={LIMIT_STYLE}>Agent Mix Max </div>
                           <div style={LIMIT_STYLE}>{this.addCommaToNumber(agentMixMax)}</div>
                           <div style={LIMIT_STYLE}>Agent Single Max</div>
                           <div style={LIMIT_STYLE}> {this.addCommaToNumber(agentSingleMax)}</div>
                        </div>
                        <div style={FINANCE_LIMIT_STYLE}>
                           <div style={LIMIT_STYLE}>Member Mix Max </div>
                           <div style={LIMIT_STYLE}>{this.addCommaToNumber(maxForMixBet)}</div>
                           <div style={LIMIT_STYLE}>Member Single Max </div>
                           <div style={LIMIT_STYLE}>{this.addCommaToNumber(maxForSingleBet)}</div>
                        </div>
                        <div style={FINANCE_LIMIT_STYLE}>
                           <div style={LIMIT_STYLE}>Max For Two D</div>
                           <div style={LIMIT_STYLE}> {this.addCommaToNumber(maxForTwoD)}</div>
                           <div style={LIMIT_STYLE}>Max For Three D </div>
                           <div style={LIMIT_STYLE}>{this.addCommaToNumber(maxForThreeD)}</div>
                        </div>
                        {/* <div style={FINANCE_LIMIT_STYLE}>
                           <div style={LIMIT_STYLE}>Max For Four D</div>
                           <div style={LIMIT_STYLE}> {this.addCommaToNumber(maxForFourD??0)}</div>
                           <div style={LIMIT_STYLE}></div>
                           <div style={LIMIT_STYLE}></div>
                        </div> */}
                     </Card>
                  </Col>
               </Row>
               <Row style={{ marginBottom: 10 }}>
                  <Col span={24}>
                     <Card
                        type="inner"
                        title={<div style={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                           width: '100%'
                        }}>
                           <h3>Single Bet Commission</h3>
                           <EditOutlined onClick={() => this.props.onOpenSingleBetCommission()} />
                        </div>}
                        loading={this.props.loading}
                     >
                        <div style={FINANCE_LIMIT_STYLE}>
                           <div style={LIMIT_STYLE}>Commission</div>
                           <div style={LIMIT_STYLE}> {this.addCommaToNumber(betCommission)}</div>
                           <div style={LIMIT_STYLE}>High Commission </div>
                           <div style={LIMIT_STYLE}>{this.addCommaToNumber(highBetCommission)}</div>
                        </div>
                        <div style={FINANCE_LIMIT_STYLE}>
                           <div style={LIMIT_STYLE}>Tax</div>
                           <div style={LIMIT_STYLE}> {this.addCommaToNumber(winCommission)}</div>
                           <div style={LIMIT_STYLE}>High Tax </div>
                           <div style={LIMIT_STYLE}>{this.addCommaToNumber(highWinCommission)}</div>
                        </div>
                     </Card>
                  </Col>
               </Row>
               <Row style={{ marginBottom: 10 }}>
                  <Col span={24}>
                     <Card
                        type="inner"
                        title={<div style={{
                           display: 'flex',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                           width: '100%'
                        }}>
                           <h3>Three D Commission</h3>
                        </div>}
                        loading={this.props.loading}
                     >
                        {/* <div>TwoD Commission - {twoDCommission} &nbsp; &nbsp;<EditOutlined
                           onClick={
                              () => this.props.onOpen2DCommissionUpdate()
                           }
                        /></div> */}
                        <div>ThreeD Commission - {threeDCommission} &nbsp; &nbsp;<EditOutlined
                           onClick={
                              () => this.props.onOpen3DCommissionUpdate()
                           }
                        /></div>
                        {/* <div>FourD Commission - {fourDCommission??0} &nbsp; &nbsp;<EditOutlined
                           onClick={
                              () => this.props.onOpen4DCommissionUpdate()
                           }
                        /></div> */}
                     </Card>
                  </Col>
               </Row>
               <PageHeader
                  style={{
                     display: 'flex',
                     flexDirection: 'row',
                     justifyContent: 'space-between',
                     border: '1px solid rgb(235, 237, 240)',
                  }}
                  title={<h5>Mix Bet Commissions</h5>}
               >
                  <PlusSquareOutlined
                     onClick={
                        () => this.props.onOpenAddCommissionModal()
                     }
                  />
               </PageHeader>
               <Col span={24} style={{ overflowX: 'scroll' }}>
                  <Table columns={columns} dataSource={this.props.smaster?.commissions} loading={this.props.loading} />
               </Col>
            </Col>
         </Row>
      </>)
   }
}

const mapStateToProps = state => ({
   loading: state.seniorMaster.loading,
   token: state.auth.token,
   smaster: state.seniorMaster.one,
   error: state.seniorMaster.error,
   message: state.seniorMaster.message
})

const mapDispatchToProps = dispatch => ({
   onGetSmaster: (id, token) => dispatch(getOneSmaster(id, token)),
   onOpenBasicUpdate: () => dispatch({
      type: actionTypes.TOGGLE_BASIC_INFO_UPDATE
   }),
   onOpenShareDetailUpdate: () => dispatch({
      type: actionTypes.TOGGLE_SHARE_DETAIL_UPDATE
   }),
   onOpenPartnerShareUpdate: () => dispatch({
      type: actionTypes.TOGGLE_PARTNER_SHARE_UPDATE
   }),
   onOpenPartner3dShareUpdate: () => dispatch({
      type: actionTypes.TOGGLE_PARTNER_3D_SHARE_UPDATE
   }),
   onUpdateExtraCommssion: (obj, token) => dispatch(updateExtraCommission(obj, token)),
   onUpdateTwoThreeBet: (obj, token) => dispatch(updateTwoThreeBet(obj, token)),
   onOpenSingleBetCommission: () => dispatch({
      type: actionTypes.TOGGLE_SINGLE_BET_COMMISSION_UPDATE
   }),
   onOpenBetLimit: () => dispatch({
      type: actionTypes.TOGGLE_BET_LIMIT_UPDATE
   }),
   onOpenCommissionUpdate: () => dispatch({
      type: actionTypes.TOGGLE_COMMISSION_UPDATE
   }),
   onOpen2DCommissionUpdate: () => dispatch({
      type: actionTypes.TOGGLE_2D_COMMISSION_UPDATE
   }),
   onOpen3DCommissionUpdate: () => dispatch({
      type: actionTypes.TOGGLE_3D_COMMISSION_UPDATE
   }),
   onOpen4DCommissionUpdate:()=>dispatch(toggle4DCommissionUpdate()),
   onOpenAddCommissionModal: () => dispatch({
      type: actionTypes.TOGGLE_ADD_COMMISSION_MODAL
   }),
   onOpenPaswordResetModal: () => dispatch({
      type: actionTypes.TOGGLE_RESET_PASSWORD_MODAL
   }),
   onOpenPartner4dShareUpdate:()=>dispatch(togglePartner4DShareUpdate()),
   onSetCommission: (commission) => dispatch(setCommissionToUpdate(commission)),
   onOpenTransactionAdd: () => dispatch(toggleTransactionAddModal()),
   onUpdateSuspend: (obj, token) => dispatch(updateSuspend(obj, token))
})

const FINANCE_LIMIT_STYLE = {
   display: 'flex',
   flexDirection: 'row'
}

const LIMIT_STYLE = {
   width: '33%'
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeniorMasterDetail));