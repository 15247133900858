import {
   LogoutOutlined,
   UnorderedListOutlined,
   OrderedListOutlined,
   CalendarOutlined,
   ClusterOutlined,
   SettingOutlined,
   TeamOutlined,
   InteractionOutlined,
   AreaChartOutlined,
   DashboardOutlined,
   FileSearchOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;

export const MenuItems = [
   <Menu.Item key="17" icon={<DashboardOutlined />}>
      <Link to="/">Dashboard</Link>
   </Menu.Item>,
   <SubMenu key="sub7" icon={<FileSearchOutlined />} title="Search Bets">
      <Menu.Item key="91">
         <Link to="/searchBetByMatch">By Match</Link>
      </Menu.Item>
      <Menu.Item key="90">
         <Link to="/searchBetByUsername">By Username</Link>
      </Menu.Item>
   </SubMenu>,
   <SubMenu key="sub4" icon={<UnorderedListOutlined />} title="Collections">
      <Menu.Item key="1">
         <Link to="/soccer_collections" >Soccer</Link>
      </Menu.Item>
      {/* <Menu.Item key="8" >
         <Link to="/twoDCollections">Two D</Link>
      </Menu.Item> */}
      <Menu.Item key="7">
         <Link to="/threeDCollections">Three D</Link>
      </Menu.Item>
      {/* <Menu.Item key="97">
         <Link to="/fourDCollections">Four D</Link>
      </Menu.Item> */}
   </SubMenu>,

   <Menu.Item key="2" icon={<CalendarOutlined />}>
      <Link to="/lsport" >Lsport</Link>
   </Menu.Item>,
   <Menu.Item key="3" icon={<OrderedListOutlined />}>
      <Link to="/leagues">Leagues</Link>
   </Menu.Item>,
   <Menu.Item key="4" icon={<ClusterOutlined />}>
      <Link to="/teams">Teams</Link>
   </Menu.Item>,
   <SubMenu key="sub6" icon={<TeamOutlined />} title="Employees">
      <Menu.Item key="94">
         <Link to="/employees/signup">Sign Up</Link>
      </Menu.Item>
      <Menu.Item key="93">
         <Link to="/employees">List</Link>
      </Menu.Item>
   </SubMenu>,
   <SubMenu key="sub3" icon={<TeamOutlined />} title="Senior Master">
      <Menu.Item key="10">
         <Link to="/senior_masters/new">Create</Link>
      </Menu.Item>
      <Menu.Item key="13">
         <Link to="/senior_masters">List</Link>
      </Menu.Item>
   </SubMenu>,
   <Menu.Item key="12" icon={<InteractionOutlined />} >
      <Link to="/partner23commissions">Partner</Link>
   </Menu.Item>,
   <SubMenu key="sub5" icon={<AreaChartOutlined />} title="Report">
      <Menu.Item key="16">
         <Link to="/collection_report">Daily</Link>
      </Menu.Item>
      <Menu.Item key="18">
         <Link to="/smaster_report">Smaster</Link>
      </Menu.Item>
      <Menu.Item key="98">
         <Link to="/report/match-count">Match Count</Link>
      </Menu.Item>
   </SubMenu>,
   <SubMenu key="sub1" icon={<SettingOutlined />} title="Setting">
      <Menu.Item key="92">
         <Link to="/delete/3months-data">
            Delete 3Months Data
         </Link>
      </Menu.Item>
      <Menu.Item key="5">
         <Link to="/announcement">Announcement</Link>
      </Menu.Item>
      <Menu.Item key="24">
         <Link to="/agreements">Agreement</Link>
      </Menu.Item>
      <Menu.Item key="6">
         <Link to="/rules">Rules</Link>
      </Menu.Item>
      {/* <Menu.Item key="9">
         <Link to="/winPercent">2D Win Percent</Link>
      </Menu.Item> */}
      <Menu.Item key="15">
         <Link to="/3DWinPercent">3D Win Percent</Link>
      </Menu.Item>
      <Menu.Item key="11">
         <Link to="/twothreeLimits">Three D Limits</Link>
      </Menu.Item>
      <Menu.Item key="14">
         <Link to="/priceGaps">Price Gaps</Link>
      </Menu.Item>
      <Menu.Item key="99">
         <Link to="/payments">Payments</Link>
      </Menu.Item>
      <Menu.Item key="96">
         <Link to="/winMessages">Win Message</Link>
      </Menu.Item>
      <Menu.Item key="95">
         <Link to="/loseMessages">Lose Message</Link>
      </Menu.Item>
   </SubMenu>,
   <Menu.Item key="100" icon={<LogoutOutlined />}>
      <Link to="/logout" >Logout</Link>
   </Menu.Item>
];