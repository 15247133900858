import React from 'react';
import { Row, Col, Table, PageHeader, Button, message } from 'antd';
import {
   PlusOutlined,
} from '@ant-design/icons';
import { getPayment } from '../../redux/actions/payment/getPayment';
import { connect } from 'react-redux';
import { toggleAddPaymentModal } from '../../redux/actions/payment/toggleModal';

class Payment extends React.Component {
   async componentDidMount() {
      await this.props.onGetPayments(this.props.token);
   }
   componentDidUpdate() {
      if (this.props.error) {
         message.error(this.props.error);
      }
   }
   columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id'
      },
      {
         title: 'Name',
         dataIndex: 'name',
         key: 'name'
      },
      {
         title: 'Image',
         key: 'image',
         render: (text, record) => <img src={record?.imageUrl} width="50px" />
      }
   ]

   render() {
      return (
         <>
            <Row>
               <Col span={24}>
                  <PageHeader
                     style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        border: '1px solid rgb(235, 237, 240)',
                     }}
                     title={<h5>Payments</h5>}
                  >
                     <Button onClick={this.props.onOpenAddModal} icon={<PlusOutlined />}>New</Button>
                  </PageHeader>
                  <Row>
                     <Col span={24} style={{ overflow: 'auto' }}>
                        <Table
                           pagination={false}
                           key="p-table"
                           dataSource={this.props.payments}
                           columns={this.columns}
                           loading={this.props.loading} />
                     </Col>
                  </Row>
               </Col>
            </Row>
         </>
      );
   }
}

const mapStateToProps = state => ({
   token: state.auth.token,
   payments: state.payment.all,
   error: state.payment.error,
   loading: state.payment.loading
});

const mapDispatchToProps = dispatch => ({
   onGetPayments: (token) => dispatch(getPayment(token)),
   onOpenAddModal: () => dispatch(toggleAddPaymentModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);