import React from 'react';
import { Row, Col, Form, Button, Input, Radio, message } from 'antd';
import { connect } from 'react-redux';
import { signupEmployee } from '../../redux/actions/employee/signupEmployee';
import { containsOnlyAlphanumeric } from '../../helpers/containsOnlyAlphanumeric';

const COLUMN_WIDTH = {
    lg: 12,
    md: 12,
    sm: 24,
    xs: 24
}

const layout = {
    wrapperCol: { span: 24 },
};

class EmployeeSignUp extends React.Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    onFinish = async (values) => {
        const request = {
            name: values.name,
            username: values.username,
            password: values.password,
            mobile: values.mobile,
            role:values.role,
        }
        await this.props.onCreate(request, this.props.token);

        if (this.props.message) {
            message.success(this.props.message);
            this.form.current.resetFields();
        }
        if (this.props.error) {
            message.error(this.props.error)
        }
    }
    onFinishFailed = (errors) => {
        console.error(errors);
    }
    render() {
        return (
            <Row>
                <Col xxl={{ span: 18 }}
                    xl={{ span: 18 }}
                    lg={{ span: 18 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}>
                    <h2>Employee</h2>
                    <Form
                        layout="vertical"
                        key="sm"
                        ref={this.form}
                        {...layout}
                        loading={this.props.loading}
                        name="basic"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        initialValues={{
                            role:'EMP'
                        }}
                    >
                        <h3>Basic Info</h3>
                        <Row gutter={20}>
                            <Col lg={{ span: COLUMN_WIDTH.lg }}
                                md={{ span: COLUMN_WIDTH.md }}
                                sm={{ span: COLUMN_WIDTH.sm }}
                                xs={{ span: COLUMN_WIDTH.xs }}>
                                <Form.Item
                                    name="username"
                                    label="Username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Requires 8 Character',
                                            max: 8,
                                            min: 8
                                        },
                                        () => ({
                                            validator(_, value) {
                                               if (value && containsOnlyAlphanumeric(value)) {
                                                  return Promise.resolve();
                                               }
                                               return Promise.reject(new Error('Can only use A-Za-z0-9 characters.'));
                                            },
                                         })
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={{ span: COLUMN_WIDTH.lg }}
                                md={{ span: COLUMN_WIDTH.md }}
                                sm={{ span: COLUMN_WIDTH.sm }}
                                xs={{ span: COLUMN_WIDTH.xs }}>
                                <Form.Item
                                    name="name"
                                    label="Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'require',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col lg={{ span: COLUMN_WIDTH.lg }}
                                md={{ span: COLUMN_WIDTH.md }}
                                sm={{ span: COLUMN_WIDTH.sm }}
                                xs={{ span: COLUMN_WIDTH.xs }}>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'require',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={{ span: COLUMN_WIDTH.lg }}
                                md={{ span: COLUMN_WIDTH.md }}
                                sm={{ span: COLUMN_WIDTH.sm }}
                                xs={{ span: COLUMN_WIDTH.xs }}>
                                <Form.Item
                                    name="mobile"
                                    label="Mobile"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'require',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col
                                lg={{ span: COLUMN_WIDTH.lg }}
                                md={{ span: COLUMN_WIDTH.md }}
                                sm={{ span: COLUMN_WIDTH.sm }}
                                xs={{ span: COLUMN_WIDTH.xs }}>
                                <Form.Item
                                    name="role"
                                    label="Role"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'require',
                                        },
                                    ]}>
                                    <Radio.Group
                                        options={[
                                            { value: 'EMP', label: 'Employee' },
                                            { value: 'SUP', label: 'Supervisor' }
                                        ]}
                                        optionType="button" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                Sign Up
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        );
    }

}

const mapStateToProps = state => ({
    token: state.auth.token,
    message: state.employee.message,
    error: state.employee.error,
    loading: state.employee.loading
})

const mapDispatchToProps = dispatch => ({
    onCreate: (request, token) => dispatch(signupEmployee(request, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSignUp);