import * as actionTypes from './actionTypes';

export const openUpdatePriceModal=()=>({
    type:actionTypes.OPEN_UPDATE_PRICE
})

export const closeUpdatePriceModal=()=>({
    type:actionTypes.CLOSE_UPDATE_PRICE,
})

export const openUpdateGoalScore=()=>({
    type:actionTypes.OPEN_UPDATE_GOAL_SCORE
})

export const closeUpdateGoalScore=()=>({
    type:actionTypes.CLOSE_UPDATE_GOAL_SCORE
})

export const openUpdateStartTime=()=>({
    type:actionTypes.OPEN_UPDATE_START_TIME
})

export const closeUpdateStartTime=()=>({
    type:actionTypes.CLOSE_UPDATE_START_TIME
})

export const openUpdateCloseTime=()=>({
    type:actionTypes.OPEN_UPDATE_CLOSE_TIME
})

export const closeUpdateCloseTime=()=>({
    type:actionTypes.CLOSE_UPDATE_CLOSE_TIME
})

export const toggleFinishMatch=(state)=>{
    switch(state){
        case true:
            return {
                type:actionTypes.OPEN_FINISH_MATCH
            };
        case false:
            return {
                type:actionTypes.CLOSE_FINISH_MATCH
            };
        default:
            return {
                type:actionTypes.TOGGLE_FINISH_MATCH
            }
    }
}