import React from 'react';
import { Row, Col, Table, DatePicker, Form, Button, message } from 'antd';
import { connect } from 'react-redux';
import currency from 'currency.js';
import {getMatchCount} from "../../redux/actions/report/getMatchCount";

class MatchCount extends React.Component {
    state = {
        startDate: null,
        endDate: null
    }
    dateHandler = (value, dateString) => {
        this.setState(prevState => {
            return {
                ...prevState,
                startDate: dateString[0],
                endDate: dateString[1]
            }
        });
    }
    onFinish = async (values) => {
        const { startDate, endDate } = this.state;
        await this.props.onGetReport({ startDate, endDate, type: this.props.type }, this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
    }
    onFinishFailed = (errors) => {
        console.warn(errors);
    }

    render() {
        const { RangePicker } = DatePicker;
        const { Column, ColumnGroup } = Table;
        return (
            <Row>
                <h1>Match Count Report</h1>
                <Col span={24}>
                    <Form
                        name="dates"
                        layout={window.innerWidth <= 480 ? "vertical" : "inline"}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}>
                        <Row gutter={[16, 16]}>
                            <Col>

                                <Form.Item
                                    name="dates"
                                    validateStatus="warning"
                                >
                                    <RangePicker onChange={this.dateHandler} format="YYYY-MM-DD" />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item >
                                    <Button type="primary" htmlType="submit">
                                        Load
                                    </Button>
                                </Form.Item>

                            </Col>

                        </Row>
                    </Form>
                    <Row style={{ marginTop: '10px' }}>
                        <Col span={24} style={{ overflowX: 'auto' }}>
                            <Table
                                bordered
                                loading={this.props.loading}
                                dataSource={this.props.matchCounts}
                                pagination={false}>
                                <Column
                                    title="Match Count"
                                    dataIndex="matchCount"
                                    key="matchCount"
                                    align="center"
                                />
                                <ColumnGroup title="Partner">
                                    <Column title="Bet" dataIndex="partnerBetAmount" key="partnerBetAmount" align="right" />
                                    <Column title="Commission" dataIndex="partnerCommissionAmount" key="partnerCommissionAmount" align="right" />
                                    <Column
                                        title="W/L"
                                        key="p_win_lose"
                                        render={(text, record) => currency(record.partnerBetAmount)
                                            .subtract(currency(record.partnerWinAmount))
                                            .subtract(currency(record.partnerCommissionAmount))
                                            .value
                                        }
                                        align="right"
                                    />
                                </ColumnGroup>
                                <ColumnGroup title="Company">
                                    <Column title="Bet" dataIndex="smasterBetAmount" key="smasterBetAmount" align="right" />
                                    <Column title="Commission" dataIndex="smasterCommissionAmount" key="smasterCommissionAmount" align="right" />
                                    <Column
                                        title="W/L"
                                        key="s_win_lose"
                                        render={(text, record) => currency(record.smasterBetAmount)
                                            .subtract(currency(record.smasterWinAmount))
                                            .subtract(currency(record.smasterCommissionAmount))
                                            .value}
                                        align="right"
                                    />
                                </ColumnGroup>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.report.loading,
    token: state.auth.token,
    error: state.report.error,
    matchCounts: state.report.matchCounts,
})

const mapDispatchToProps = dispatch => ({
    onGetReport: (dates, token) => dispatch(getMatchCount(dates, token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MatchCount);