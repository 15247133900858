import { actionTypes } from "./actionTypes";
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const finish1stPrize = ({ number, id }, token) => async dispatch => {
      dispatch({
            type: actionTypes.FINISH_1ST_PRIZE_START,
            payload: { id, number }
      });
      await makeRequest({ number, token }, `/firstPrizes/${id}/finish`)
            .then(response => dispatch({
                  type: actionTypes.FINISH_1ST_PRIZE_SUCCESS,
                  payload: response.data
            }))
            .catch(err => dispatch({
                  type: actionTypes.FINISH_1ST_PRIZE_FAIL,
                  payload: getMessage(err)
            }))
}

export const finish2ndPrize = ({ number, id }, token) => async dispatch => {
      dispatch({
            type: actionTypes.FINISH_2ND_PRIZE_START,
            payload: { id, number }
      });
      await makeRequest({ number, token }, `/secondPrizes/${id}/finish`)
            .then(response => dispatch({
                  type: actionTypes.FINISH_2ND_PRIZE_SUCCESS,
                  payload: response.data
            }))
            .catch(err => dispatch({
                  type: actionTypes.FINISH_2ND_PRIZE_FAIL,
                  payload: getMessage(err)
            }))
}

export const finish3rdPrize = ({ number, id }, token) => async dispatch => {
      dispatch({
            type: actionTypes.FINISH_3RD_PRIZE_START,
            payload: { id, number }
      });
      await makeRequest({ number, token }, `/thirdPrizes/${id}/finish`)
            .then(response => dispatch({
                  type: actionTypes.FINISH_3RD_PRIZE_SUCCESS,
                  payload: response.data
            }))
            .catch(err => dispatch({
                  type: actionTypes.FINISH_3RD_PRIZE_FAIL,
                  payload: getMessage(err)
            }))
}

export const finishStarterPrize = ({ number, id }, token) => async dispatch => {
      dispatch({
            type: actionTypes.FINISH_STARTER_PRIZE_START,
            payload: { id, number }
      });
      await makeRequest({ number, token }, `/starterPrizes/${id}/finish`)
            .then(response => dispatch({
                  type: actionTypes.FINISH_STARTER_PRIZE_SUCCESS,
                  payload: response.data
            }))
            .catch(err => dispatch({
                  type: actionTypes.FINISH_STARTER_PRIZE_FAIL,
                  payload: getMessage(err)
            }))
}

export const finishConsolPrize = ({ number, id }, token) => async dispatch => {
      dispatch({
            type: actionTypes.FINISH_CONSOL_PRIZE_START,
            payload: { id, number }
      });
      await makeRequest({ number, token }, `/consolationPrizes/${id}/finish`)
            .then(response => dispatch({
                  type: actionTypes.FINISH_CONSOL_PRIZE_SUCCESS,
                  payload: response.data
            }))
            .catch(err => dispatch({
                  type: actionTypes.FINISH_CONSOL_PRIZE_FAIL,
                  payload: getMessage(err)
            }))
}


export const finishSubDigitPrize=({type_digit,number,id},token)=>{
      const data={number,id};
      switch(type_digit)
      {
            case "FIRST_THREE":
                  return finish1stThreeDigitPrize(data,token);
            case "FIRST_TWO":
                  return finish1stTwoDigitPrize(data,token);
            case "SECOND_TWO":
                  return finish2ndTwoDigitPrize(data,token);
            case "THIRD_TWO":
                  return finish3rdTwoDigitPrize(data,token);
            default:
                  console.warn("No Match found");
      }
}

const finish1stThreeDigitPrize=({number,id},token)=>async dispatch=>{
      console.log('>>>',number,id);
      dispatch({
            type:actionTypes.FINISH_1ST_PRIZE_THREE_DIGIT_START,
      });
      await makeRequest({number,token},`/firstPrizes/${id}/finishThreeDigits`)
            .then(response=>{console.log(response.data);dispatch({
                  type:actionTypes.FINISH_1ST_PRIZE_THREE_DIGIT_SUCCESS,
                  payload:response.data
            })})
            .catch(err=>dispatch({
                  type:actionTypes.FINISH_1ST_PRIZE_THREE_DIGIT_FAIL,
                  payload:getMessage(err)
            }))
}

const finish1stTwoDigitPrize=({number,id},token)=>async dispatch=>{
      dispatch({
            type:actionTypes.FINISH_1ST_PRIZE_TWO_DIGIT_START,
      });
      await makeRequest({number,token},`/firstPrizes/${id}/finishTwoDigits`)
            .then(response=>dispatch({
                  type:actionTypes.FINISH_1ST_PRIZE_TWO_DIGIT_SUCCESS,
                  payload:response.data
            }))
            .catch(err=>dispatch({
                  type:actionTypes.FINISH_1ST_PRIZE_TWO_DIGIT_FAIL,
                  payload:getMessage(err)
            }))
}

const finish2ndTwoDigitPrize=({number,id},token)=>async dispatch=>{
      dispatch({
            type:actionTypes.FINISH_2ND_PRIZE_TWO_DIGIT_START,
      });
      await makeRequest({number,token},`/secondPrizes/${id}/finishTwoDigits`)
            .then(response=>dispatch({
                  type:actionTypes.FINISH_2ND_PRIZE_TWO_DIGIT_SUCCESS,
                  payload:response.data
            }))
            .catch(err=>dispatch({
                  type:actionTypes.FINISH_2ND_PRIZE_TWO_DIGIT_FAIL,
                  payload:getMessage(err)
            }))
}

const finish3rdTwoDigitPrize=({number,id},token)=>async dispatch=>{
      dispatch({
            type:actionTypes.FINISH_3RD_PRIZE_TWO_DIGIT_START,
      });
      await makeRequest({number,token},`/thirdPrizes/${id}/finishTwoDigits`)
            .then(response=>dispatch({
                  type:actionTypes.FINISH_3RD_PRIZE_TWO_DIGIT_SUCCESS,
                  payload:response.data
            }))
            .catch(err=>dispatch({
                  type:actionTypes.FINISH_3RD_PRIZE_TWO_DIGIT_FAIL,
                  payload:getMessage(err)
            }))
}

function makeRequest({ number, token }, url) {
      const options = {
            url: api + url,
            method: 'POST',
            headers: {
                  Authorization: `Bearer ${token}`
            },
            data: { number }
      }
      return axios(options);
}