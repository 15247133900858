import React from 'react';
import {getLoseMessages} from "../../redux/actions/loseMessage/getLoseMessages";
import {connect} from "react-redux";
import {message} from "antd";
import {toggleLoseMessageUpdateModal} from "../../redux/actions/loseMessage/toggleLoseMessageUpdateModal";
import {toggleLoseMessageCreateModal} from "../../redux/actions/loseMessage/toggleLoseMessageCreateModal";
import MessageList from "../Message/MessageList";

class LoseMessages extends React.Component {
    async componentDidMount() {
        await this.props.onGetAll(this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
    }

    render() {
        return (
            <>
                <MessageList title="Lose Messages"
                             loading={this.props.loading}
                             all={this.props.all}
                             onOpenUpdateModal={this.props.onOpenUpdateModal}
                             onOpenCreateModal={this.props.onOpenCreateModal}/>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token,
    error: state.loseMessage.error,
    all: state.loseMessage.all,
    loading: state.loseMessage.loading,
});

const mapDispatchToProps = dispatch => ({
    onGetAll: (token) => dispatch(getLoseMessages(token)),
    onOpenUpdateModal: (payload) => dispatch(toggleLoseMessageUpdateModal(payload)),
    onOpenCreateModal: () => dispatch(toggleLoseMessageCreateModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoseMessages);