import * as actionTypes from './actionTypes';
import axios from 'axios';
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const cancelMatch = ({ id }, token) => async dispatch => {
   dispatch({
      type: actionTypes.CANCEL_MATCH_START
   })
   const options = {
      url: `${api}/matches/${id}/cancelMatch`,
      method: 'PUT',
      headers: {
         'Authorization': `Bearer ${token}`
      },
   }
   await axios(options)
      .then(response => dispatch({
         type: actionTypes.CANCEL_MATCH_SUCCESS,
         payload: response.data
      }))
      .catch(error => dispatch({
         type: actionTypes.CANCEL_MATCH_FAIL,
         payload: getMessage(error)
      }))
}