import React from 'react';
import {connect} from 'react-redux';
import {toggleWinMessageCreateModal} from '../../redux/actions/winMessage/toggleWinMessageCreateModal';
import {createWinMessage} from "../../redux/actions/winMessage/createWinMessage";
import CreateMessageModal from "../Message/CreateMessageModal";

class CreateWinMessageModal extends React.Component {
    render() {
        return (
            <CreateMessageModal
                                formId="create-win-msg-form"
                                modalKey="create-win-msg-modal"
                                title="Add New Message"
                                onClose={this.props.onClose}
                                onAdd={this.props.onAdd}
                                token={this.props.token}
                                error={this.props.error}
                                visible={this.props.visible}
                                loading={this.props.loading}
                                message={this.props.message}/>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.winMessage.createVisible,
    loading: state.winMessage.loading,
    token: state.auth.token,
    error: state.winMessage.error,
    message: state.winMessage.createMessage,
})

const mapDispatchToProps = dispatch => ({
    onAdd: (obj, token) => dispatch(createWinMessage(obj, token)),
    onClose: () => dispatch(toggleWinMessageCreateModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateWinMessageModal);