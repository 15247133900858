import {
    GET_PARTNER23C_START,
    GET_PARTNER23C_SUCCESS,
    GET_PARTNER23C_FAIL,
    UPDATE_PARTNER23C_START,
    UPDATE_PARTNER23C_SUCCESS,
    UPDATE_PARTNER23C_FAIL,
    TOGGLE_P23C_UPDATE,
    GET_PARTNER_SINGLE_COMMISSION_START,
    GET_PARTNER_SINGLE_COMMISSION_SUCCESS,
    GET_PARTNER_SINGLE_COMMISSION_FAIL,
    UPDATE_PARTNER_SINGLE_COMMISSION_START,
    UPDATE_PARTNER_SINGLE_COMMISSION_SUCCESS,
    UPDATE_PARTNER_SINGLE_COMMISSION_FAIL,
    GET_PARTNER_COMMISSIONS_START,
    GET_PARTNER_COMMISSIONS_SUCCESS,
    GET_PARTNER_COMMISSIONS_FAIL,
    CREATE_PARTNER_COMMISSION_START,
    CREATE_PARTNER_COMMISSION_SUCCESS,
    CREATE_PARTNER_COMMISSION_FAIL,
    UPDATE_PARTNER_COMMISSION_START,
    UPDATE_PARTNER_COMMISSION_SUCCESS,
    UPDATE_PARTNER_COMMISSION_FAIL,
    DELETE_PARTNER_COMMISSION_START,
    DELETE_PARTNER_COMMISSION_SUCCESS,
    DELETE_PARTNER_COMMISSION_FAIL,
    TOGGLE_PSC_UPDATE,
    TOGGLE_PC_CREATE,
    TOGGLE_PC_UPDATE,
    SET_PARTNER_COMMISSION_TO_UPDATE
} from '../actions/partner/actionTypes';
import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';

const initialState = {
    loading: false,
    error: null,
    message: null,
    twothreeCommission: {
        id: 0,
        twoDCommission: 0,
        threeDCommission: 0
    },
    singleCommission: {
        id: 0,
        commission: 0,
        highCommission: 0
    },
    commissions: [],
    commissionToUpdate: null,
    updateP23CVisible: false,
    updatePSCVisible: false,
    updatePCVisible: false,
    addPCVisible: false,
    pcToUpdate: null
}

const getPartner23Commission = (state, action) => {
    return update(state, {
        loading: false,
        twothreeCommission: action.payload
    })
}

const updatePartner23Commission = (state, action) => {
    return update(state, {
        loading: false,
        twothreeCommission: action.payload,
        message: 'update success'
    })
}

const toggleUpdate = (state, action) => {
    return update(state, {
        updateVisible: !state.updateVisible
    })
}

const getPartnerSingleCommission = (state, action) => {
    return update(state, {
        loading: false,
        singleCommission: action.payload
    })
}

const updatePartnerSingleCommission = (state, action) => {
    return update(state, {
        loading: false,
        message: 'update success',
        singleCommission: action.payload
    })
}

const togglePSCUpdate = (state, action) => {
    return update(state, {
        updatePSCVisible: !state.updatePSCVisible
    })
}

const togglePCUpdate = (state, action) => {
    return update(state, {
        updatePCVisible: !state.updatePCVisible
    })
}

const togglePCAdd = (state, action) => {
    return update(state, {
        addPCVisible: !state.addPCVisible
    })
}

const createPartnerCommission = (state, action) => {
    return update(state, {
        loading: false,
        commissions: [...state.commissions, action.payload],
        message: 'create success'
    })
}

const getPartnerCommissions = (state, action) => {
    return update(state, {
        loading: false,
        commissions: action.payload
    })
}

const updatePartnerCommission = (state, action) => {
    return update(state, {
        loading: false,
        commissions: state.commissions.map(obj => obj.id === action.payload.id ? action.payload : obj),
        message:'update success'
    })
}

const deletePartnerCommission=(state,action)=>{
    return update(state,{
        loading:false,
        message:'delete success',
        commissions:state.commissions.filter(obj=>obj.id!==action.payload)
    })
}

const setPCToUpdate=(state,action)=>{
    return update(state,{
        pcToUpdate:action.payload
    })
}

const partnerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PARTNER23C_START:
            return loading(state, action);
        case GET_PARTNER23C_SUCCESS:
            return getPartner23Commission(state, action);
        case GET_PARTNER23C_FAIL:
            return error(state, action);
        case UPDATE_PARTNER23C_START:
            return loading(state, action);
        case UPDATE_PARTNER23C_SUCCESS:
            return updatePartner23Commission(state, action);
        case UPDATE_PARTNER23C_FAIL:
            return error(state, action);
        case TOGGLE_P23C_UPDATE:
            return toggleUpdate(state, action);
        case GET_PARTNER_SINGLE_COMMISSION_START:
            return loading(state,action);
        case GET_PARTNER_SINGLE_COMMISSION_SUCCESS:
            return getPartnerSingleCommission(state,action);
        case GET_PARTNER_SINGLE_COMMISSION_FAIL:
            return error(state,action);
        case UPDATE_PARTNER_SINGLE_COMMISSION_START:
            return loading(state,action);
        case UPDATE_PARTNER_SINGLE_COMMISSION_SUCCESS:
            return updatePartnerSingleCommission(state,action);
        case UPDATE_PARTNER_SINGLE_COMMISSION_FAIL:
            return error(state,action);
        case TOGGLE_PSC_UPDATE:
            return togglePSCUpdate(state,action);
        case CREATE_PARTNER_COMMISSION_START:
            return loading(state,action);
        case CREATE_PARTNER_COMMISSION_SUCCESS:
            return createPartnerCommission(state,action);
        case CREATE_PARTNER_COMMISSION_FAIL:
            return error(state,action);
        case GET_PARTNER_COMMISSIONS_START:
            return loading(state,action);
        case GET_PARTNER_COMMISSIONS_SUCCESS:
            return getPartnerCommissions(state,action);
        case GET_PARTNER_COMMISSIONS_FAIL:
            return error(state,action);
        case UPDATE_PARTNER_COMMISSION_START:
            return loading(state,action);
        case UPDATE_PARTNER_COMMISSION_SUCCESS:
            return updatePartnerCommission(state,action);
        case UPDATE_PARTNER_COMMISSION_FAIL:
            return error(state,action);
        case DELETE_PARTNER_COMMISSION_START:
            return loading(state,action);
        case DELETE_PARTNER_COMMISSION_SUCCESS:
            return deletePartnerCommission(state,action);
        case DELETE_PARTNER_COMMISSION_FAIL:
            return error(state,action);
        case TOGGLE_PC_CREATE:
            return togglePCAdd(state,action);
        case TOGGLE_PC_UPDATE:
            return togglePCUpdate(state,action);
        case SET_PARTNER_COMMISSION_TO_UPDATE:
            return setPCToUpdate(state,action);
        default:
            return state;
    }
}

export default partnerReducer;