import React from 'react';
import { Row, Col, message, Card, Space, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getRules } from '../../redux/actions/rule/getRules';
import { setRuleToUpdate } from '../../redux/actions/rule/setRuleToUpdate';
import { deleteRule } from '../../redux/actions/rule/deleteRule';
import {
   EditOutlined,
   PlusOutlined,
   DeleteOutlined,
   ExclamationCircleOutlined
} from '@ant-design/icons';
import { toggleUpdateRule } from '../../redux/actions/rule/toggleUpdateRule';
import { toggleAddRule } from '../../redux/actions/rule/toggleAddRule';


class RuleList extends React.Component {
   componentDidMount() {
      this.props.onGetAll(this.props.token);
   }
   componentDidUpdate() {
      if (this.props.error) {
         message.error(this.props.error);
      }
   }
   _openEditModal = async (rule) => {
      await this.props.onSet(rule);
      this.props.onOpen();
   }
   handleDelete = (id) => {
      Modal.confirm({
         title: 'Delete Confirmation',
         icon: <ExclamationCircleOutlined />,
         content: 'Are you sure to delete?',
         okText: 'OK',
         cancelText: 'Cancel',
         onOk: () => this.props.onDelete(id, this.props.token)
      });
   }
   render() {
      return (
         <Row>
            <Col span={24}>
               <Card span={24} title={
                  <div style={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                     width: '100%',
                  }}>
                     <span>Rules</span>
                     <PlusOutlined onClick={() => this.props.onOpenAdd()} />
                  </div>
               }>
                  {
                     this.props.rules?.map(rule => (
                        <Card
                           style={{ marginTop: '15px' }}
                           key={rule?.id}
                           type="inner"
                           title={<div style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                           }}>
                              <span>Rule {rule?.id}</span>
                              <Space direction="horizontal" size="middle">
                                 <EditOutlined onClick={() => this._openEditModal(rule)} />
                                 <DeleteOutlined onClick={() => this.handleDelete(rule?.id)} />
                              </Space>
                           </div>}
                           loading={this.props.loading}
                        >
                           {rule?.statement}
                        </Card>
                     ))
                  }
               </Card>
            </Col>
         </Row>
      );
   }
}

const mapStateToProps = state => ({
   token: state.auth.token,
   rules: state.rule.all,
   error: state.rule.error,
   message: state.rule.message,
   loading: state.rule.loading
})

const mapDispatchToProps = dispatch => ({
   onGetAll: (token) => dispatch(getRules(token)),
   onSet: (rule) => dispatch(setRuleToUpdate(rule)),
   onOpen: () => dispatch(toggleUpdateRule()),
   onOpenAdd: () => dispatch(toggleAddRule()),
   onDelete: (id, token) => dispatch(deleteRule(id, token))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RuleList));