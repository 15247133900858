import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import {
   EyeOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

function CollectionTable(props) {
   const history = useHistory();
   let dataSource = props.collection?.map(value => ({
      ...value,
      date: moment(value.date).format('DD-MM-YYYY'),
      key: value.id
   }));

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
      },
      {
         title: 'Date',
         dataIndex: 'date',
         key: 'date',
      },
      {
         title: 'Detail',
         render: (text, record) => (<EyeOutlined onClick={() => history.push(`/collections/${record.id}`)} />)
      }
   ]
   return <Table key="collections"
      dataSource={dataSource}
      columns={columns}
      loading={props.loading}
      pagination={false} />
}

export default CollectionTable;