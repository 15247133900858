import { Breadcrumb, message } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getOne } from '../../redux/actions/twoDcollection/getOne';
import { EditOutlined } from '@ant-design/icons';
import { toggleTwoDUpdate } from '../../redux/actions/twoDcollection/toggleModals';
import { setToUpdate } from '../../redux/actions/twoDcollection/setToUpdate';
import './style.css';

class TwoDNumbers extends React.Component {
      state = {
            hover: false
      }

      componentDidMount() {
            this.props.onGet(this.props.match.params.id, this.props.token);
      }

      componentDidUpdate() {
            if (this.props.error) {
                  message.error(this.props.error);
            }
            if (this.props.message) {
                  message.success(this.props.message);
            }
      }

      _toggleUpdate = async (number) => {
            await this.props.onSet(number);
            this.props.onOpen();
      }

      render() {
            return <>
                  <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        margin: '16px 20px 20px 20px'
                  }}>
                        <Breadcrumb>
                              <Breadcrumb.Item><Link to="/twoDCollections">Two D Collections</Link></Breadcrumb.Item>
                              <Breadcrumb.Item>Two D Numbers</Breadcrumb.Item>
                        </Breadcrumb>
                        <div>
                              Total Amount : {parseInt(this.props.total)}
                        </div>
                        <div>
                              Max : {parseInt(this.props.max)}$ | <span style={{ color: 'red' }}>{parseInt(this.props.maxNumber)}</span>
                        </div>
                        <div>
                              Average : {parseInt(this.props.twoDAverage)}
                        </div>
                  </div>
                  <div style={{ width: '100%', overflow: 'auto' }}>
                        <div style={{
                              display: 'grid',
                              gridTemplateColumns: "repeat(10,100px)",
                              gridTemplateRows: "repeat(10,100px)",
                              gridGap: '5px',
                              justifyContent: 'start',
                              overflow: 'auto'
                        }}>
                              {
                                    this.props.collection?.twoDNumbers?.map(obj => <div style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          padding: 5,
                                          border: '1px solid gray',
                                          borderBottom: (obj.status == 1) ? '5px solid green' : obj.status == 2 ? '5px solid orange' : '5px solid red',
                                          justifyContent: 'center',
                                          color: '#0e0e0e'
                                    }}
                                          className="number"
                                    >
                                          <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between'
                                          }}>
                                                <span>
                                                      {obj.number}
                                                </span>
                                                <EditOutlined onClick={() => this._toggleUpdate(obj)} />
                                          </div>
                                          <span style={{
                                                fontSize: '0.6rem',
                                                color: 'gray'
                                          }}>
                                                Amount
                                          </span>
                                          <span>
                                                {`${obj.totalAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                          </span>
                                    </div>)
                              }
                        </div>
                  </div>
            </>
      }
}

const mapStateToProps = state => ({
      token: state.auth.token,
      collection: state.twoDCollection.one,
      error: state.twoDCollection.error,
      message: state.twoDCollection.message,
      twoDAverage: state.twoDCollection.twoDAverage,
      max: state.twoDCollection.max,
      maxNumber: state.twoDCollection.maxNumber,
      total: state.twoDCollection.total
})

const mapDispatchToProps = dispatch => ({
      onGet: (id, token) => dispatch(getOne(id, token)),
      onOpen: () => dispatch(toggleTwoDUpdate()),
      onSet: (number) => dispatch(setToUpdate(number))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TwoDNumbers));