import { Breadcrumb } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { get3DCollection } from '../../redux/actions/3DCollection/get3DCollection';
import { set3DNumberToUpdate } from '../../redux/actions/3DCollection/set3DNumberToUpdate';
import * as actionTypes from '../../redux/actions/3DCollection/actionTypes';
import { EditOutlined } from '@ant-design/icons';
import Pagination from '../Pagination/Pagination';

class ThreeDNumbers extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  hover: false,
                  paginationKey: 1,
            }
      }
      async componentDidMount() {
            await this.props.onGet(this.props.match.params.id, this.props.token);
      }
      paginate = (array, page_size, page_number) => {
            return array.slice((page_number - 1) * page_size, page_number * page_size);
      }

      render() {
            return <>
                  <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        margin: '0 20px 10px 20px'
                  }}>
                        <Breadcrumb>
                              <Breadcrumb.Item><Link to="/threeDCollections">Three D Collections</Link></Breadcrumb.Item>
                              <Breadcrumb.Item>Three D Numbers</Breadcrumb.Item>
                        </Breadcrumb>
                        <div>
                              Total Amount : {parseInt(this.props.total)}
                        </div>
                        <div>
                              Max : {parseInt(this.props.max)}$ | <span style={{ color: 'red' }}>{parseInt(this.props.maxNumber)}</span>
                        </div>
                        <div>
                              Average : {parseInt(this.props.threeDAverage)}
                        </div>
                  </div>
                  <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        margin: '0 20px 10px 20px',
                        overflow: 'auto',
                  }}>
                        <div
                              className="pagination"
                              style={{
                                    display: 'flex',
                                    overflowX: 'auto'
                              }}>
                              <Pagination
                                    numbers={10}
                                    onChange={(key) => {
                                          this.setState({
                                                paginationKey: key
                                          });
                                    }}
                              />
                        </div>
                  </div>
                  <div style={{ width: '100%', overflow: 'auto' }}>
                        <div
                              className="numbers"
                              style={{
                                    display: 'grid',
                                    gridTemplateColumns: "repeat(10,100px)",
                                    gridTemplateRows: "repeat(10,100px)",
                                    gridGap: '5px',
                                    justifyContent: 'start',
                                    overflowX: 'auto',
                              }}>
                              {
                                    this.paginate(this.props.collection?.threeDNumbers || [], 100, this.state.paginationKey)
                                    .map(obj => <div style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          padding: 5,
                                          border: '1px solid gray',
                                          borderBottom: (obj.status == 1) ? '5px solid green' : obj.status == 2 ? '5px solid orange' : '5px solid red',
                                          justifyContent: 'center',
                                          color: '#0e0e0e',
                                          cursor:'pointer'
                                    }}
                                          key={obj?.id}
                                          onClick={()=>this.props.history.push(`/threed-bets/${obj?.id}`)}
                                          className="number"
                                    >
                                          <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between'
                                          }}>
                                                <span>
                                                      {obj.number}
                                                </span>
                                                <EditOutlined onClick={async () => {
                                                      await this.props.onSet(obj);
                                                      await this.props.onOpenUpdateModal();
                                                }} />
                                          </div>
                                          <span style={{
                                                fontSize: '0.6rem',
                                                color: 'gray'
                                          }}>
                                                Amount
                                          </span>
                                          <span>
                                                {`${obj.totalAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                          </span>
                                    </div>)
                              }
                        </div>
                  </div>
            </>
      }
}

const mapStateToProps = state => ({
      token: state.auth.token,
      collection: state.threeDCollection.one,
      error: state.threeDCollection.error,
      message: state.threeDCollection.message,
      threeDAverage: state.threeDCollection.threeDAverage,
      total: state.threeDCollection.total,
      max: state.threeDCollection.max,
      maxNumber: state.threeDCollection.maxNumber
})

const mapDispatchToProps = dispatch => ({
      onGet: (id, token) => dispatch(get3DCollection(id, token)),
      onSet: (number) => dispatch(set3DNumberToUpdate(number)),
      onOpenUpdateModal: () => dispatch({
            type: actionTypes.TOGGLE_3D_STATUS_UPDATE
      })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ThreeDNumbers));