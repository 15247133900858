import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
   CREATE_SENIOR_MASTER_START,
   CREATE_SENIOR_MASTER_SUCCESS,
   CREATE_SENIOR_MASTER_FAIL,
   GET_SENIOR_MASTER_START,
   GET_SENIOR_MASTER_SUCCESS,
   GET_SENIOR_MASTER_FAIL,
   SET_COMMISSION_TO_UPDATE,
   UPDATE_SENIOR_MASTER_START,
   UPDATE_SENIOR_MASTER_SUCCESS,
   UPDATE_SENIOR_MASTER_FAIL,
   TOGGLE_BASIC_INFO_UPDATE,
   TOGGLE_BET_LIMIT_UPDATE,
   TOGGLE_COMMISSION_UPDATE,
   TOGGLE_SHARE_DETAIL_UPDATE,
   TOGGLE_SINGLE_BET_COMMISSION_UPDATE,
   TOGGLE_SINGLE_BET_UPDATE,
   TOGGLE_EXTRA_COMMISSION_UPDATE,
   GET_ONE_SMASTER_START,
   GET_ONE_SMASTER_SUCCESS,
   GET_ONE_SMASTER_FAIL,
   TOGGLE_PARTNER_SHARE_UPDATE,
   UPDATE_SUSPEND_START,
   UPDATE_SUSPEND_SUCCESS,
   UPDATE_SUSPEND_FAIL,
   TOGGLE_2D_COMMISSION_UPDATE,
   TOGGLE_3D_COMMISSION_UPDATE,
   TOGGLE_4D_COMMISSION_UPDATE,
   ADD_COMMISSION_START,
   ADD_COMMISSION_SUCCESS,
   ADD_COMMISSION_FAIL,
   TOGGLE_ADD_COMMISSION_MODAL,
   RESET_PASSWORD_START,
   RESET_PASSWORD_SUCCESS,
   RESET_PASSWORD_FAIL,
   TOGGLE_RESET_PASSWORD_MODAL,
   UPDATE_SMASTER_BALANCE,
   GET_SM_TRANSACTIONS_START,
   GET_SM_TRANSACTIONS_SUCCESS,
   GET_SM_TRANSACTIONS_FAIL,
   GET_SM_TRANSACTION_DETAIL_START,
   GET_SM_TRANSACTION_DETAIL_SUCCESS,
   GET_SM_TRANSACTION_DETAIL_FAIL,
   TOGGLE_PARTNER_3D_SHARE_UPDATE,
   TOGGLE_PARTNER_4D_SHARE_UPDATE
} from '../actions/seniormaster/actionTypes';

const initialState = {
   loading: false,
   error: null,
   message: null,
   transactions: [],
   transactionDetail: [],
   all: [],
   one: {
      name: null,
      username: null,
      mobile: null,
      commissions: [],
      twoDCommission: 0,
      threeDCommission: 0,
      fourDCommission:0,
      betLimitation: {
         smasterMixMax: 0,
         smasterSingleMax: 0,
         masterMixMax: 0,
         masterSingleMax: 0,
         agentMixMax: 0,
         agentSingleMax: 0,
         maxForSingleBet: 0,
         maxForMixBet: 0,
         maxForTwoD: 0,
         maxForThreeD: 0,
         maxForFourD:0
      },
      singleBetCommission: {
         betCommission: 0,
         highBetCommission: 0,
         winCommission: 0,
         highWinCommission: 0
      },
      shareDetail: {
         sharePercentage: 0,
         threeDSharePercentage:0,
         fourDSharePercentage:0
      },
      partnerShare: 0,
      partnerThreeDShare:0,
      partnerFourDShare:0,
      extraCommissionAllowed: false,
      singleBetAllowed: false
   },
   commissionToUpdate: null,
   updateBasicInfoVisible: false,
   updateBetLimitVisible: false,
   updateCommissionVisible: false,
   updateShareDetailVisible: false,
   updateSingleBetCommissionVisible: false,
   updateSingleBetVisible: false,
   updateExtraCommissionVisible: false,
   updatePartnerShareVisible: false,
   updatePartner3dShareVisible:false,
   updatePartner4dShareVisible:false,
   update2dCommissionVisible: false,
   update3dCommissionVisible: false,
   update4dCommissionVisible:false,
   addCommissionVisible: false,
   resetPasswordVisible: false,
}


const create = (state, action) => {
   return update(state, {
      loading: false,
      all: [...state.all, action.payload],
      message: 'create success'
   })
}

const updateSeniorMaster = (state, action) => {
   return update(state, {
      loading: false,
      all: state.all
         .map(
            sm => sm.id === action.payload.id ? action.payload : sm
         ),
      message: 'update success',
      one: action.payload
   })
}

const setCommissionToUpdate = (state, action) => {
   return update(state, {
      commissionToUpdate: action.payload
   })
}

const getSmasters = (state, action) => {
   return update(state, {
      loading: false,
      all: action.payload
   })
}

const getSmaster = (state, action) => {
   return update(state, {
      loading: false,
      one: action.payload
   })
}

const updateSuspend = (state, action) => {
   return update(state, {
      loading: false,
      message: action.payload?.suspended ? 'suspended successfully' : 'unsuspended successfully',
      one: action.payload
   })
}

const addCommission = (state, action) => {
   return update(state, {
      loading: false,
      message: 'added commission successfully',
      one: {
         ...state.one,
         commissions: [
            ...state.one.commissions,
            action.payload
         ]
      }
   })
}

const resetPassword = (state, action) => {
   return update(state, {
      loading: false,
      message: 'Reset Password Successfully'
   })
}

const updateSmasterBalance = (state, action) => {
   console.log('response', action.payload);
   let amount = 0;
   if (action.payload?.transactionType === "OUT") {
      amount = state.one?.balance + action.payload?.amount;
   } else {
      amount = state.one?.balance - action.payload?.amount;
   }
   return update(state, {
      one: {
         ...state.one,
         balance: amount
      }
   })
}

const getSMTransactions = (state, action) => {
   return update(state, {
      loading: false,
      transactions: action.payload
   })
}

const getSMTransactionDetail = (state, action) => {
   return update(state, {
      loading: false,
      transactionDetail: action.payload
   })
}

const toggle4DCommissionUpdate=(state,action)=>{
      return update(state,{
            update4dCommissionVisible:!state.update4dCommissionVisible
      })
}

const togglePartner4DShareUpdate=(state,action)=>{
      return update(state,{
            updatePartner4dShareVisible:!state.updatePartner4dShareVisible
      })
}

const seniormasterReducer = (state = initialState, action) => {
   switch (action.type) {
      case CREATE_SENIOR_MASTER_START:
         return loading(state, action);
      case CREATE_SENIOR_MASTER_SUCCESS:
         return create(state, action);
      case CREATE_SENIOR_MASTER_FAIL:
         return error(state, action);
      case GET_SENIOR_MASTER_START:
         return loading(state, action);
      case GET_SENIOR_MASTER_SUCCESS:
         return getSmasters(state, action);
      case GET_SENIOR_MASTER_FAIL:
         return error(state, action);
      case UPDATE_SENIOR_MASTER_START:
         return loading(state, action);
      case UPDATE_SENIOR_MASTER_SUCCESS:
         return updateSeniorMaster(state, action);
      case UPDATE_SENIOR_MASTER_FAIL:
         return error(state, action);
      case SET_COMMISSION_TO_UPDATE:
         return setCommissionToUpdate(state, action);
      case RESET_PASSWORD_START:
         return loading(state, action);
      case RESET_PASSWORD_SUCCESS:
         return resetPassword(state, action);
      case RESET_PASSWORD_FAIL:
         return error(state, action);
      case TOGGLE_BASIC_INFO_UPDATE:
         return update(state, {
            updateBasicInfoVisible: !state.updateBasicInfoVisible
         });
      case TOGGLE_BET_LIMIT_UPDATE:
         return update(state, {
            updateBetLimitVisible: !state.updateBetLimitVisible
         });
      case TOGGLE_COMMISSION_UPDATE:
         return update(state, {
            updateCommissionVisible: !state.updateCommissionVisible
         });
      case TOGGLE_SHARE_DETAIL_UPDATE:
         return update(state, {
            updateShareDetailVisible: !state.updateShareDetailVisible
         });
      case TOGGLE_SINGLE_BET_COMMISSION_UPDATE:
         return update(state, {
            updateSingleBetCommissionVisible: !state.updateSingleBetCommissionVisible
         });
      case TOGGLE_SINGLE_BET_UPDATE:
         return update(state, {
            updateSingleBetVisible: !state.updateSingleBetVisible
         });
      case TOGGLE_EXTRA_COMMISSION_UPDATE:
         return update(state, {
            updateExtraCommissionVisible: !state.updateExtraCommissionVisible
         });
      case TOGGLE_2D_COMMISSION_UPDATE:
         return update(state, {
            update2dCommissionVisible: !state.update2dCommissionVisible
         });
      case TOGGLE_3D_COMMISSION_UPDATE:
         return update(state, {
            update3dCommissionVisible: !state.update3dCommissionVisible
         });
      case TOGGLE_4D_COMMISSION_UPDATE:
            return toggle4DCommissionUpdate(state,action);
      case TOGGLE_ADD_COMMISSION_MODAL:
         return update(state, {
            addCommissionVisible: !state.addCommissionVisible
         });
      case TOGGLE_PARTNER_4D_SHARE_UPDATE:
            return togglePartner4DShareUpdate(state,action);
      case GET_ONE_SMASTER_START:
         return loading(state, action);
      case GET_ONE_SMASTER_SUCCESS:
         return getSmaster(state, action);
      case GET_ONE_SMASTER_FAIL:
         return error(state, action);
      case TOGGLE_PARTNER_SHARE_UPDATE:
         return update(state, {
            updatePartnerShareVisible: !state.updatePartnerShareVisible
         });
      case TOGGLE_PARTNER_3D_SHARE_UPDATE:
         return update(state,{
            updatePartner3dShareVisible:!state.updatePartner3dShareVisible
         })
      case TOGGLE_RESET_PASSWORD_MODAL:
         return update(state, {
            resetPasswordVisible: !state.resetPasswordVisible
         });
      case UPDATE_SUSPEND_START:
         return loading(state, action);
      case UPDATE_SUSPEND_SUCCESS:
         return updateSuspend(state, action);
      case UPDATE_SUSPEND_FAIL:
         return error(state, action);
      case ADD_COMMISSION_START:
         return loading(state, action);
      case ADD_COMMISSION_SUCCESS:
         return addCommission(state, action);
      case ADD_COMMISSION_FAIL:
         return error(state, action);
      case UPDATE_SMASTER_BALANCE:
         return updateSmasterBalance(state, action);
      case GET_SM_TRANSACTIONS_START:
         return loading(state, action);
      case GET_SM_TRANSACTIONS_SUCCESS:
         return getSMTransactions(state, action);
      case GET_SM_TRANSACTIONS_FAIL:
         return error(state, action);
      case GET_SM_TRANSACTION_DETAIL_START:
         return loading(state, action);
      case GET_SM_TRANSACTION_DETAIL_SUCCESS:
         return getSMTransactionDetail(state, action);
      case GET_SM_TRANSACTION_DETAIL_FAIL:
         return error(state, action);
      default:
         return state;
   }
}

export default seniormasterReducer;