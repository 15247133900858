import { getSelectedKey } from './getSelectedKey';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { MenuItems } from './MenuItems';

function DefaultMenu() {
   const location = useLocation();
   return (<Menu
      theme="dark"
      defaultSelectedKeys={getSelectedKey(location)}
      mode="inline"
   >
      {MenuItems}
   </Menu>);
}

export default DefaultMenu;