import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { searchBetByBetAndAmount } from "../../redux/actions/searchBet/searchBetByBetAndAmount";
import { Table, Row, Col, Button, Input, InputNumber, Switch, Select, message } from "antd";
import {
    EyeOutlined
} from '@ant-design/icons';
import currency from "currency.js";
import { useEffect, useState } from "react";
import { clearBetByMatch } from "../../redux/actions/searchBet/clearBets";

const { Column, ColumnGroup } = Table;
const {Option}=Select;

const SearchByBet = () => {
    const history = useHistory();
    const token = useSelector(state => state.auth.token);
    const loading = useSelector(state => state.searchBet.loading);
    const bets = useSelector(state => state.searchBet.resultByBet);

    const [homeUpper,setHomeUpper] = useState(false);
    const [matchId,setMatchId] = useState("");
    const [betType,setBetType] = useState("GOALS");
    const [odds,setOdds] = useState(null);
    const [price,setPrice] = useState(null);
    const [amount,setAmount] = useState(null);

    const dispatch = useDispatch();

    const handleSearch = () => {
        const bet = {
            matchId,
            odds,
            price,
            homeUpper,
            betType,
            amount,
        };
        if(amount<100000){
            message.error("Minimum amount is 100,000")
            return;
        }
        dispatch(searchBetByBetAndAmount(bet, token))
    }

    useEffect(()=>{
        dispatch(clearBetByMatch());
    },[])

    return (
        <>
            <h1 style={{ marginBlockEnd: 20 }}>Search Bets By Match</h1>
            <div style={{ display: 'flex', alignItems:'center', gap: 10, flexWrap: 'wrap', marginBlock: 20 }}>
                <Input value={matchId} onChange={e=>setMatchId(e.target.value)} style={{ width:100 }} placeholder="Match ID" />
                <InputNumber value={odds} onChange={value=>setOdds(value)} placeholder="odds" />
                <InputNumber value={price} onChange={value=>setPrice(value)} placeholder="price" />
                <Switch checkedChildren="Home Upper" unCheckedChildren="Home Lower" onChange={value=>setHomeUpper(value)} />
                <Select value={betType} onChange={value=>setBetType(value)} style={{width:200}}>
                            <Option value="BODY">BODY</Option>
                            <Option value="GOALS">GOALS</Option>
                </Select>
                <InputNumber value={amount} onChange={value=>setAmount(value)} style={{ minWidth:200 }} placeholder="Bet Amount" />
                <Button type="primary" onClick={handleSearch}>
                    Search
                </Button>
            </div>
            <Row gutter={[24, 24]} justify="left">
                <Col lg={24} md={24} sm={24}>
                    <Table
                        bordered
                        dataSource={bets}
                        loading={loading}
                    >
                        <Column
                            title="Bet Id"
                            dataIndex="id"
                            key="id"
                            align="center"
                        />
                        <Column
                            title="Username"
                            dataIndex="username"
                            key="username"
                        />
                        <ColumnGroup title="Partner">
                            <Column
                                title="Bet"
                                dataIndex="partnerBetAmount"
                                key="partnerBetAmount"
                                align="right"
                            />
                            <Column
                                title="Commission"
                                dataIndex="partnerCommissionAmount"
                                key="partnerCommissionAmount"
                                align="right"
                            />
                            <Column
                                title="W/L"
                                render={(text, {
                                    partnerBetAmount,
                                    partnerWinAmount,
                                    partnerCommissionAmount }) =>
                                    currency(partnerBetAmount).subtract(currency(partnerWinAmount).add(currency(partnerCommissionAmount))).value
                                }
                            />
                        </ColumnGroup>

                        <ColumnGroup title="Senior Master">
                            <Column
                                title="Bet"
                                dataIndex="smasterBetAmount"
                                key="smasterBetAmount"
                                align="right"
                            />
                            <Column
                                title="Commission"
                                dataIndex="smasterCommissionAmount"
                                key="smasterCommissionAmount"
                                align="right"
                            />
                            <Column
                                title="W/L"
                                render={(text, {
                                    smasterBetAmount,
                                    smasterWinAmount,
                                    smasterCommissionAmount }) =>
                                    currency(smasterBetAmount).subtract(currency(smasterWinAmount).add(smasterCommissionAmount)).value
                                }
                            />
                        </ColumnGroup>
                        <Column
                            title="Detail"
                            key="detail"
                            render={(text, record) => <EyeOutlined
                                onClick={() => {
                                    history.push(`/bet_detail/${record.id}`)
                                }}
                            />}
                        />
                    </Table>
                </Col>
            </Row>
        </>
    )
}

export default SearchByBet;