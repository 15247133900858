import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const getSMTransactions = ({ id,startDate, endDate }, token) => async dispatch => {
   dispatch({
      type: actionTypes.GET_SM_TRANSACTIONS_START,
   })
   const options = {
      method: 'GET',
      url: `${api}/transactions/filter/smasters/${id}/${startDate}/${endDate}`,
      headers: {
         Authorization: `Bearer ${token}`
      },
   }
   await axios(options)
      .then(response => dispatch({
         type: actionTypes.GET_SM_TRANSACTIONS_SUCCESS,
         payload: response.data
      }))
      .catch(error => dispatch({
         type: actionTypes.GET_SM_TRANSACTIONS_FAIL,
         payload: getMessage(error)
      }))
}