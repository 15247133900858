import React from 'react';
import { Form, Row, Col, DatePicker, Button, Table, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { getSMTransactions } from '../../redux/actions/seniormaster/getSMTransactions';
import {
   EyeOutlined,
} from '@ant-design/icons';


class SMTransactions extends React.Component {
   state = {
      isMobile: false,
      startDate: null,
      endDate: null
   }
   componentDidMount() {
      const query = window.matchMedia(`(max-width: 480px)`);
      query.onchange = (e) => {
         this.setState(prevState => {
            return {
               ...prevState,
               isMobile: e.matches
            }
         })
      }
      this.setState({
         isMobile: query.matches
      })
   }
   dateHandler = (value, dateString) => {
      this.setState(prevState => {
         return {
            ...prevState,
            startDate: dateString[0],
            endDate: dateString[1]
         }
      });
   }
   onFinish = async (values) => {
      const { startDate, endDate } = this.state;
      const { id } = this.props.match.params;
      await this.props.onGetTransaction({ id, startDate, endDate }, this.props.token);
      if (this.props.error) {
         message.error(this.props.error);
      }
   }
   onFinishFailed = (errors) => {
      console.warn(errors);
   }
   render() {
      const { isMobile } = this.state;
      const { RangePicker } = DatePicker;
      const columns = [
         {
            title: 'Date',
            dataIndex: 'transactionDate',
            key: 'transactionDate',
            render: (text, record) => moment(record.transactionDate).format('YYYY-MM-DD')
         },
         {
            title: 'Transfer In',
            dataIndex: 'transferInAmount',
            key: 'transferInAmount',
            align: 'right',
            render: (text, record) => `${record.transferInAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
         },
         {
            title: 'Transfer Out',
            dataIndex: 'transferOutAmount',
            key: 'transferOutAmount',
            align: 'right',
            render: (text, record) => `${record.transferOutAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
         },
         {
            title: 'Bet',
            dataIndex: 'betAmount',
            key: 'betAmount',
            align: 'right',
            render: (text, record) => `${record.betAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
         },
         {
            title: 'Win',
            dataIndex: 'winAmount',
            key: 'winAmount',
            align: 'right',
            render: (text, record) => `${record.winAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
         },
         {
            title: 'Commission',
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            align: 'right',
            render: (text, record) => `${record.commissionAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
         },
         {
            title: 'Balance',
            dataIndex: 'closingBalance',
            key: 'closingBalance',
            align: 'right',
            render: (text, record) => `${record.closingBalance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
         },
         {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text, record) => <EyeOutlined onClick={
               () => this.props.history.push(`/senior_masters/${this.props.match.params.id}/${record.transactionDate}`)} />
         },
      ]
      return (
         <>
            <Row>
               <Col span={24}>
                  <Form
                     name="dates"
                     layout={isMobile ? "vertical" : "inline"}
                     onFinish={this.onFinish}
                     onFinishFailed={this.onFinishFailed}>
                     <Row gutter={[16, 16]}>
                        <Col>
                           <Form.Item
                              name="dates"
                              validateStatus="warning"
                           >
                              <RangePicker onChange={this.dateHandler} format="YYYY-MM-DD" />
                           </Form.Item>
                        </Col>
                        <Col>
                           <Form.Item >
                              <Button type="primary" htmlType="submit">
                                 Load
                              </Button>
                           </Form.Item>
                        </Col>
                     </Row>
                  </Form>
                  <Row>
                     <Col span={24} style={{ overflowX: 'scroll' }}>
                        <Table columns={columns}
                           dataSource={this.props.transactions}
                           loading={this.props.loading} />
                     </Col>
                  </Row>
               </Col>
            </Row>
         </>
      )
   }
}

const mapStateToProps = state => ({
   token: state.auth.token,
   transactions: state.seniorMaster.transactions,
   loading: state.seniorMaster.loading,
})

const mapDispatchToProps = dispatch => ({
   onGetTransaction: (obj, token) => dispatch(getSMTransactions(obj, token))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SMTransactions));