import { useState, useEffect } from "react";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useNetwork } from "@mantine/hooks";

function useSSE({ url, eventName, options = {} } = {}) {
    const [event, setEvent] = useState(null);
    const [error, setError] = useState(null);
    const networkStatus = useNetwork();
    useEffect(() => {
        let source;
        try {
            if (window && "EventSource" in window) {
                if (networkStatus.online) {
                    source = new EventSourcePolyfill(url, options);
                    source.addEventListener(eventName, (e) => {
                        const data = JSON.parse(e.data);
                        if (window.log) {
                            console.log("event data", data);
                        }
                        setEvent(data);
                        setError(null);
                    });
                }
            }
        } catch (error) {
            console.log(error);
            setError(error.message);
        }
        return () => {
            if (source) {
                source.close();
            }
        };
    }, [networkStatus.online]);
    return [event, error];
}

export default useSSE;
