import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import { actionTypes } from "../actions/4DCollection/actionTypes";

const {
      CREATE_4D_COLLECTION_START,
      CREATE_4D_COLLECTION_SUCCESS,
      CREATE_4D_COLLECTION_FAIL,
      GET_4D_COLLECTIONS_START,
      GET_4D_COLLECTIONS_FAIL,
      GET_4D_COLLECTIONS_SUCCESS,
      GET_4D_COLLECTION_START,
      GET_4D_COLLECTION_FAIL,
      GET_4D_COLLECTION_SUCCESS,
      TOGGLE_ADD_4D_COLLECTION_MODAL,
      SET_4D_NUMBER_TO_UPDATE,
      UPDATE_4D_START,
      UPDATE_4D_SUCCESS,
      UPDATE_4D_FAIL,
      TOGGLE_UPDATE_4D_MODAL,
      GET_4D_RESULT_START,
      GET_4D_RESULT_SUCCESS,
      GET_4D_RESULT_FAIL,
      SAVE_4D_RESULT_START,
      SAVE_4D_RESULT_SUCCESS,
      SAVE_4D_RESULT_FAIL,
      TOGGLE_1ST_PRIZE_FINISH_MODAL,
      TOGGLE_2ND_PRIZE_FINISH_MODAL,
      TOGGLE_3RD_PRIZE_FINISH_MODAL,
      TOGGLE_STARTER_PRIZE_FINISH_MODAL,
      TOGGLE_CONSOL_PRIZE_FINISH_MODAL,
      FINISH_1ST_PRIZE_START,
      FINISH_1ST_PRIZE_SUCCESS,
      FINISH_1ST_PRIZE_FAIL,
      FINISH_2ND_PRIZE_START,
      FINISH_2ND_PRIZE_SUCCESS,
      FINISH_2ND_PRIZE_FAIL,
      FINISH_3RD_PRIZE_START,
      FINISH_3RD_PRIZE_SUCCESS,
      FINISH_3RD_PRIZE_FAIL,
      FINISH_STARTER_PRIZE_START,
      FINISH_STARTER_PRIZE_SUCCESS,
      FINISH_STARTER_PRIZE_FAIL,
      FINISH_CONSOL_PRIZE_START,
      FINISH_CONSOL_PRIZE_SUCCESS,
      FINISH_CONSOL_PRIZE_FAIL,
      FINISH_1ST_PRIZE_THREE_DIGIT_START,
      FINISH_1ST_PRIZE_THREE_DIGIT_SUCCESS,
      FINISH_1ST_PRIZE_THREE_DIGIT_FAIL,
      FINISH_1ST_PRIZE_TWO_DIGIT_START,
      FINISH_1ST_PRIZE_TWO_DIGIT_SUCCESS,
      FINISH_1ST_PRIZE_TWO_DIGIT_FAIL,
      FINISH_2ND_PRIZE_TWO_DIGIT_START,
      FINISH_2ND_PRIZE_TWO_DIGIT_SUCCESS,
      FINISH_2ND_PRIZE_TWO_DIGIT_FAIL,
      FINISH_3RD_PRIZE_TWO_DIGIT_START,
      FINISH_3RD_PRIZE_TWO_DIGIT_SUCCESS,
      FINISH_3RD_PRIZE_TWO_DIGIT_FAIL,
      TOGGLE_SUB_DIGIT_FINISH,
      CALCULATE_4D_REQUEST,
      CALCULATE_4D_SUCCESS,
      CALCULATE_4D_FAIL,
} = actionTypes;

const initialState = {
      loading: false,
      error: null,
      message: null,
      all: [],
      one: null,
      result: null,
      addCollectionVisible: false,
      finishVisible: false,
      collectionId: null,
      numberToUpdate: null,
      updateNumberVisible: false,
      finish1stPrizeVisible: false,
      finish2ndPrizeVisible: false,
      finish3rdPrizeVisible: false,
      finishStarterPrizeVisible: false,
      finishConsolPrizeVisible: false,
      finishSubDigitPrizeVisible: false,
      type_digit: null,
      starter: null,
      consolation: null,
      calculateResponse:null
}

const create4DCollection = (state, action) => {
      return update(state, {
            loading: false,
            all: [...state.all, action.payload],
            message: 'Created Successfully'
      })
}

const get4DCollections = (state, action) => {
      return update(state, {
            loading: false,
            all: action.payload
      })
}

const get4DCollection = (state, action) => {
      return update(state, {
            loading: false,
            one: action.payload
      })
}

const toggleAdd4DCollectionModal = (state, action) => {
      return update(state, {
            addCollectionVisible: !state.addCollectionVisible
      })
}

const toggleUpdate4DModal = (state, action) => {
      return update(state, {
            updateNumberVisible: !state.updateNumberVisible
      })
}

const update4DNumber = (state, action) => {
      return update(state, {
            loading: false,
            message: 'update success',
            one: {
                  ...state.one,
                  fourDNumbers: state?.one?.fourDNumbers?.map(obj => {
                        if (obj?.id === action?.payload?.id) {
                              return action.payload;
                        }
                        return obj;
                  })
            }
      });
}

const set4DNumberToUpdate = (state, action) => {
      return update(state, {
            numberToUpdate: action.payload
      })
}

const get4DResult = (state, action) => {
      return update(state, {
            loading: false,
            result: action.payload
      })
}

const save4DResult = (state, action) => {
      return update(state, {
            loading: false,
            message: 'saved successfully',
            result: action.payload
      })
}

const finish1stPrize = (state, action) => {
      return update(state, {
            loading: false,
            message: "finished successfully",
            result: {
                  ...state.result,
                  firstPrize: action.payload
            }
      })
}

const finish2ndPrize = (state, action) => {
      return update(state, {
            loading: false,
            message: "finished successfully",
            result: {
                  ...state.result,
                  secondPrize: action.payload
            }
      })
}

const finish3rdPrize = (state, action) => {
      return update(state, {
            loading: false,
            message: "finished successfully",
            result: {
                  ...state.result,
                  thirdPrize: action.payload
            }
      })
}

const finishStarterPrize = (state, action) => {
      const starterPrizes = state.result.starterPrizes.map(prize => {
            if (+prize.id === +action.payload.id) {
                  return action.payload;
            }
            return prize;
      })
      return update(state, {
            loading: false,
            message: "finished successfully",
            result: {
                  ...state.result,
                  starterPrizes
            }
      })
}

const finishConsolationPrize = (state, action) => {
      const consolationPrizes = state.result.consolationPrizes.map(prize => {
            if (+prize.id === +action.payload.id) {
                  return action.payload;
            }
            return prize;
      })
      return update(state, {
            loading: false,
            message: "finished successfully",
            result: {
                  ...state.result,
                  consolationPrizes
            }
      })
}

const toggleSubDigitPrizeModal = (state, action) => {
      return update(state, {
            finishSubDigitPrizeVisible: !state.finishSubDigitPrizeVisible,
            type_digit: action.payload.type_digit
      })
}

const toggleFinish1stPrizeModal = (state, action) => {
      return update(state, {
            finish1stPrizeVisible: !state.finish1stPrizeVisible
      })
}

const toggleFinish2ndPrizeModal = (state, action) => {
      return update(state, {
            finish2ndPrizeVisible: !state.finish2ndPrizeVisible
      })
}

const toggleFinish3rdPrizeModal = (state, action) => {
      return update(state, {
            finish3rdPrizeVisible: !state.finish3rdPrizeVisible
      })
}

const toggleFinishStarterPrizeModal = (state, action) => {
      return update(state, {
            finishStarterPrizeVisible: !state.finishStarterPrizeVisible,
            starter: action.payload
      })
}

const toggleFinishConsolPrizeModal = (state, action) => {
      return update(state, {
            finishConsolPrizeVisible: !state.finishConsolPrizeVisible,
            consolation: action.payload
      })
}

const calculate4dRequest=(state,action)=>{
      return update(state,{
            loading:true,
            error:null,
            message:null,
            calculateResponse:null,
      })
}

const calculate4d=(state,action)=>{
      return update(state,{
            loading:false,
            calculateResponse:action.payload
      })
}

const fourDCollectionReducer = (state = initialState, action) => {
      switch (action.type) {
            case CREATE_4D_COLLECTION_START:
                  return loading(state, action);
            case CREATE_4D_COLLECTION_SUCCESS:
                  return create4DCollection(state, action);
            case CREATE_4D_COLLECTION_FAIL:
                  return error(state, action);
            case GET_4D_COLLECTIONS_START:
                  return loading(state, action);
            case GET_4D_COLLECTIONS_SUCCESS:
                  return get4DCollections(state, action);
            case GET_4D_COLLECTIONS_FAIL:
                  return error(state, action);
            case GET_4D_COLLECTION_START:
                  return loading(state, action);
            case GET_4D_COLLECTION_SUCCESS:
                  return get4DCollection(state, action);
            case GET_4D_COLLECTION_FAIL:
                  return error(state, action);
            case TOGGLE_ADD_4D_COLLECTION_MODAL:
                  return toggleAdd4DCollectionModal(state, action);
            case SET_4D_NUMBER_TO_UPDATE:
                  return set4DNumberToUpdate(state, action);
            case UPDATE_4D_START:
                  return loading(state, action);
            case UPDATE_4D_SUCCESS:
                  return update4DNumber(state, action);
            case UPDATE_4D_FAIL:
                  return error(state, action);
            case TOGGLE_UPDATE_4D_MODAL:
                  return toggleUpdate4DModal(state, action);
            case GET_4D_RESULT_START:
                  return loading(state, action);
            case GET_4D_RESULT_SUCCESS:
                  return get4DResult(state, action);
            case GET_4D_RESULT_FAIL:
                  return error(state, action);
            case SAVE_4D_RESULT_START:
                  return loading(state, action);
            case SAVE_4D_RESULT_SUCCESS:
                  return save4DResult(state, action);
            case SAVE_4D_RESULT_FAIL:
                  return error(state, action);
            case TOGGLE_1ST_PRIZE_FINISH_MODAL:
                  return toggleFinish1stPrizeModal(state, action);
            case TOGGLE_2ND_PRIZE_FINISH_MODAL:
                  return toggleFinish2ndPrizeModal(state, action);
            case TOGGLE_3RD_PRIZE_FINISH_MODAL:
                  return toggleFinish3rdPrizeModal(state, action);
            case TOGGLE_STARTER_PRIZE_FINISH_MODAL:
                  return toggleFinishStarterPrizeModal(state, action);
            case TOGGLE_CONSOL_PRIZE_FINISH_MODAL:
                  return toggleFinishConsolPrizeModal(state, action);
            case FINISH_1ST_PRIZE_START:
                  return loading(state, action);
            case FINISH_1ST_PRIZE_SUCCESS:
                  return finish1stPrize(state, action);
            case FINISH_1ST_PRIZE_FAIL:
                  return error(state, action);
            case FINISH_2ND_PRIZE_START:
                  return loading(state, action);
            case FINISH_2ND_PRIZE_SUCCESS:
                  return finish2ndPrize(state, action);
            case FINISH_2ND_PRIZE_FAIL:
                  return error(state, action);
            case FINISH_3RD_PRIZE_START:
                  return loading(state, action);
            case FINISH_3RD_PRIZE_SUCCESS:
                  return finish3rdPrize(state, action);
            case FINISH_3RD_PRIZE_FAIL:
                  return error(state, action);
            case FINISH_STARTER_PRIZE_START:
                  return loading(state, action);
            case FINISH_STARTER_PRIZE_SUCCESS:
                  return finishStarterPrize(state, action);
            case FINISH_STARTER_PRIZE_FAIL:
                  return error(state, action);
            case FINISH_CONSOL_PRIZE_START:
                  return loading(state, action);
            case FINISH_CONSOL_PRIZE_SUCCESS:
                  return finishConsolationPrize(state, action);
            case FINISH_CONSOL_PRIZE_FAIL:
                  return error(state, action);
            case TOGGLE_SUB_DIGIT_FINISH:
                  return toggleSubDigitPrizeModal(state, action);
            case FINISH_1ST_PRIZE_THREE_DIGIT_START:
                  return loading(state, action);
            case FINISH_1ST_PRIZE_THREE_DIGIT_SUCCESS:
                  return finish1stPrize(state, action);
            case FINISH_1ST_PRIZE_THREE_DIGIT_FAIL:
                  return error(state, action);
            case FINISH_1ST_PRIZE_TWO_DIGIT_START:
                  return loading(state, action);
            case FINISH_1ST_PRIZE_TWO_DIGIT_SUCCESS:
                  return finish1stPrize(state, action);
            case FINISH_1ST_PRIZE_TWO_DIGIT_FAIL:
                  return error(state, action);
            case FINISH_2ND_PRIZE_TWO_DIGIT_START:
                  return loading(state, action);
            case FINISH_2ND_PRIZE_TWO_DIGIT_SUCCESS:
                  return finish2ndPrize(state, action);
            case FINISH_2ND_PRIZE_TWO_DIGIT_FAIL:
                  return error(state, action);
            case FINISH_3RD_PRIZE_TWO_DIGIT_START:
                  return loading(state, action);
            case FINISH_3RD_PRIZE_TWO_DIGIT_SUCCESS:
                  return finish3rdPrize(state, action);
            case FINISH_3RD_PRIZE_TWO_DIGIT_FAIL:
                  return error(state, action);
            case CALCULATE_4D_REQUEST:
                  return calculate4dRequest(state,action);
            case CALCULATE_4D_SUCCESS:
                  return calculate4d(state,action);
            case CALCULATE_4D_FAIL:
                  return error(state,action);
            default:
                  return state;
      }
}

export default fourDCollectionReducer;