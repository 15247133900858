import { getSelectedKey } from './getSelectedKey';
import './mobile-menu.css';
import { useLocation } from 'react-router-dom';
import { Menu, Drawer } from 'antd';
import { MenuItems } from './MenuItems';

function DefaultMenu({
   visible,
   toggleMenu
}) {
   const location = useLocation();
   return (
      <Drawer placement="left"
         width='80%'
         closeIcon={null}
         onClose={toggleMenu}
         visible={visible}>
         <Menu
            defaultSelectedKeys={getSelectedKey(location)}
            mode="inline"
            style={styles.menu}>
            {MenuItems}
         </Menu>
      </Drawer>
   );
}

const styles = {
   menu: {
      width: '100%',
      height: '100%',
      padding: 0
   }
}

export default DefaultMenu;