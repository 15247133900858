import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, InputNumber } from 'antd';
import { togglePartner23cUpdate } from '../../redux/actions/partner/toggleModals';
import { updatePartner23Commission } from '../../redux/actions/partner/updatePartner23Commission';


class Update23CommissionModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    onFinish = async (values) => {
        await this.props.onUpdate(values,
            this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
            this.form.current.resetFields();
            this.props.onClose();
        }
        
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        const {
            twoDCommission,
            threeDCommission,
            // fourDCommission,
        } = this.props.twothreeCommission;
        return (
            <Modal
                title="Update Two Three Limits"
                key={this.props.twothreeCommission ?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'update-23limits-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    layout="vertical"
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="update-23limits-form"
                    name="basic"
                    initialValues={{
                        twoDCommission:twoDCommission??0,
                        threeDCommission:threeDCommission??0,
                        // fourDCommission:fourDCommission??0
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="twoDCommission"
                        label="Two D Commission"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{width:150}}/>
                    </Form.Item>
                    <Form.Item
                        name="threeDCommission"
                        label="Three D Commission"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{width:150}}/>
                    </Form.Item>
                    {/* <Form.Item
                        name="fourDCommission"
                        label="Four D Commission"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{width:150}}/>
                    </Form.Item> */}
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.partner.updateVisible,
    loading: state.partner.loading,
    token: state.auth.token,
    error: state.partner.error,
    message: state.partner.message,
    twothreeCommission: state.partner.twothreeCommission
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (commissions, token) => dispatch(updatePartner23Commission(commissions, token)),
    onClose: () => dispatch(togglePartner23cUpdate()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Update23CommissionModal);