import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const markAsCheated = ({ id, cheated }, token) => async dispatch => {
    dispatch({
        type: actionTypes.MARK_AS_CHEATED_START,
    })
    const options = {
        url: `${api}/bets/${id}/marked`,
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: { cheated }
    }
    await axios(options)
        .then(response => dispatch({
            type: actionTypes.MARK_AS_CHEATED_SUCCESS,
            payload: response.data
        }))
        .catch(error => dispatch({
            type: actionTypes.MARK_AS_CHEATED_FAIL,
            payload: getMessage(error)
        }))
}