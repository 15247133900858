import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
      CREATE_3D_COLLECTION_START,
      CREATE_3D_COLLECTION_SUCCESS,
      CREATE_3D_COLLECTION_FAIL,
      GET_3D_COLLECTION_START,
      GET_3D_COLLECTION_SUCCESS,
      GET_3D_COLLECTION_FAIL,
      GET_3D_COLLECTIONS_START,
      GET_3D_COLLECTIONS_SUCCESS,
      GET_3D_COLLECTIONS_FAIL,
      TOGGLE_3D_COLLECTION_ADD,
      UPDATE_THREE_D_START,
      UPDATE_THREE_D_SUCCESS,
      UPDATE_THREE_D_FAIL,
      TOGGLE_3D_STATUS_UPDATE,
      SET_3D_NUMBER_TO_UPDATE,
      FINISH_3D_COLLECTION_START,
      FINISH_3D_COLLECTION_SUCCESS,
      FINISH_3D_COLLECTION_FAIL,
      TOGGLE_3D_FINISH_MODAL,
      CALCULATE_3D_REQUEST,
      CALCULATE_3D_SUCCESS,
      CALCULATE_3D_FAIL,
      BETS_OF_3D_NUMBER_START,
      BETS_OF_3D_NUMBER_SUCCESS,
      BETS_OF_3D_NUMBER_FAIL,
} from '../actions/3DCollection/actionTypes';

const initialState = {
      loading: false,
      error: null,
      message: null,
      all: [],
      one: null,
      addCollectionVisible: false,
      updateVisible: false,
      toUpdate: null,
      finishVisible: false,
      collectionId: null,
      threeDAverage: 0,
      total: 0,
      max: 0,
      maxNumber: null,
      calculateResponse: null,
      betsOf3dNumber:[],
}

const create3DCollection = (state, action) => {
      return update(state, {
            loading: false,
            all: [...state.all, action.payload],
            message: 'create success'
      })
}

const get3DCollection = (state, action) => {
      let arrLength = action.payload?.threeDNumbers.length;
      let responseArr = action.payload?.threeDNumbers;
      let threeDAverage = 0;
      let total = 0;
      let max = responseArr[0]?.totalAmount || 0;
      let maxNumber = responseArr[0]?.number;
      for (let i = 0; i < arrLength; i++) {
            total += (responseArr[i]?.totalAmount);
            if (max < (responseArr[i]?.totalAmount)) {
                  max = (responseArr[i]?.totalAmount);
                  maxNumber = responseArr[i]?.number;
            }
      }
      if (max != 0) {
            threeDAverage = total / max;
      }
      return update(state, {
            loading: false,
            one: action.payload,
            threeDAverage: threeDAverage.toFixed(),
            total,
            max,
            maxNumber
      })
}

const get3DCollections = (state, action) => {
      return update(state, {
            loading: false,
            all: action.payload
      })
}

const toggleAddModal = (state, action) => {
      return update(state, {
            addCollectionVisible: !state.addCollectionVisible
      })
}

const update3D = (state, action) => {
      console.log('action.payload', action.payload)
      return update(state, {
            loading: false,
            one: {
                  ...state.one,
                  threeDNumbers: state.one?.threeDNumbers?.map(obj => {
                        if (obj.id === action.payload.id) {
                              return action.payload
                        }
                        return obj
                  })
            },
            message: 'update success'
      })
}

const toggleUpdate = (state, action) => {
      return update(state, {
            updateVisible: !state.updateVisible
      })
}

const set3DNumberToUpdate = (state, action) => {
      return update(state, {
            toUpdate: action.payload
      })
}

const finish3DCollection = (state, action) => {
      return update(state, {
            loading: false,
            message: 'finish success'
      })
}

const toggleFinishModal = (state, action) => {
      return update(state, {
            finishVisible: !state.finishVisible,
            collectionId: action.payload
      })
}

const calculate3dRequest=(state,action)=>{
      return update(state,{
            loading:true,
            calculateResponse:null,
            error:null,
            message:null,
      })
}

const calculate3d = (state, action) => {
      return update(state, {
            loading: false,
            calculateResponse: action.payload
      })
}

const betsOf3dNumber=(state,action)=>{
      return update(state,{
            loading:false,
            betsOf3dNumber:action.payload
      })
}

const threeDCollectionReducer = (state = initialState, action) => {
      switch (action.type) {
            case CREATE_3D_COLLECTION_START:
                  return loading(state, action);
            case CREATE_3D_COLLECTION_SUCCESS:
                  return create3DCollection(state, action);
            case CREATE_3D_COLLECTION_FAIL:
                  return error(state, action);
            case GET_3D_COLLECTION_START:
                  return loading(state, action);
            case GET_3D_COLLECTION_SUCCESS:
                  return get3DCollection(state, action);
            case GET_3D_COLLECTION_FAIL:
                  return error(state, action);
            case GET_3D_COLLECTIONS_START:
                  return loading(state, action);
            case GET_3D_COLLECTIONS_SUCCESS:
                  return get3DCollections(state, action);
            case GET_3D_COLLECTIONS_FAIL:
                  return error(state, action);
            case TOGGLE_3D_COLLECTION_ADD:
                  return toggleAddModal(state, action);
            case UPDATE_THREE_D_START:
                  return loading(state, action);
            case UPDATE_THREE_D_SUCCESS:
                  return update3D(state, action);
            case UPDATE_THREE_D_FAIL:
                  return error(state, action);
            case TOGGLE_3D_STATUS_UPDATE:
                  return toggleUpdate(state, action);
            case SET_3D_NUMBER_TO_UPDATE:
                  return set3DNumberToUpdate(state, action);
            case FINISH_3D_COLLECTION_START:
                  return loading(state, action);
            case FINISH_3D_COLLECTION_SUCCESS:
                  return finish3DCollection(state, action);
            case FINISH_3D_COLLECTION_FAIL:
                  return error(state, action);
            case TOGGLE_3D_FINISH_MODAL:
                  return toggleFinishModal(state, action);
            case CALCULATE_3D_REQUEST:
                  return calculate3dRequest(state, action);
            case CALCULATE_3D_SUCCESS:
                  return calculate3d(state, action);
            case CALCULATE_3D_FAIL:
                  return error(state, action);
            case BETS_OF_3D_NUMBER_START:
                  return loading(state,action);
            case BETS_OF_3D_NUMBER_SUCCESS:
                  return betsOf3dNumber(state,action);
            case BETS_OF_3D_NUMBER_FAIL:
                  return error(state,action);
            default:
                  return state;
      }
}

export default threeDCollectionReducer;