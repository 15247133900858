import React from 'react';
import { Button, Col, DatePicker, Form, message, Row, Select, Table, Input } from 'antd';
import { connect } from 'react-redux';
import { getSmasterReport } from '../../redux/actions/report/getSmasterReport';
import { setBetType } from '../../redux/actions/report/setBetType';
import currency from 'currency.js';
import moment from 'moment';
import { ParseIntAndFormat } from "./ParseIntAndFormat";
import './index.css'
import generatePDF from './Service/generatePDF';
import { ExportExcelButton } from './ExportExcelButton';
const { Search } = Input;


class SmasterReport extends React.Component {
    state = {
        startDate: null,
        endDate: null,
        searchKey: "",
        searchResult: []
    }
    dateHandler = (value, dateString) => {
        this.setState(prevState => {
            return {
                ...prevState,
                startDate: dateString[0],
                endDate: dateString[1]
            }
        });
    }
    onFinish = async (values) => {
        const { startDate, endDate } = this.state;
        await this.props.onGetReport({ startDate, endDate, type: this.props.type }, this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
    }
    onFinishFailed = (errors) => {
        console.warn(errors);
    }
    onSearch = ({ target: { value } }) => {
        const result = this.props.smasterReport?.filter(el => `${el.name}`.toLowerCase().includes(value.toLowerCase()))
        this.setState(prevState => {
            return {
                ...prevState,
                searchResult: result,
                searchKey: value
            }
        })
    }

    render() {
        const { RangePicker } = DatePicker;
        const { Column, ColumnGroup } = Table;
        const { Option } = Select;
        const tableData = (this.state.searchKey ? this.state.searchResult : this.props.smasterReport) ?? [];
        let totalWinLose = currency(0);
        const excelData = tableData.map((it) => {
            const winlose = currency(it.smasterBetAmount).subtract(currency(it.smasterCommissionAmount).add(it.smasterWinAmount));
            totalWinLose = totalWinLose.add(winlose)
            return {
                name: it.name,
                username: it.smasterName,
                winlose: ParseIntAndFormat(winlose)
            }
        })
        return (
            <Row>
                <h1>Smaster Report</h1>
                <Col span={24}>
                    <Row gutter={[5, 5]}>
                        <Col>
                            <Form
                                name="dates"
                                layout={window.innerWidth <= 480 ? "vertical" : "inline"}
                                onFinish={this.onFinish}
                                onFinishFailed={this.onFinishFailed}>
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <Select
                                            defaultValue={this.props.type}
                                            onChange={(value) => this.props.onSetBetType(value)}>
                                            <Option value="SOCCER">Soccer</Option>
                                            {/* <Option value="TWOD">Two D</Option> */}
                                            <Option value="THREED">Three D</Option>
                                            {/* <Option value="FOURD">Four D</Option> */}
                                        </Select>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name="dates"
                                            validateStatus="warning"
                                        >
                                            <RangePicker onChange={this.dateHandler} format="YYYY-MM-DD" />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Load
                                            </Button>
                                        </Form.Item>

                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col className='search-col'>
                            <Button onClick={() => {
                                generatePDF(tableData);
                            }
                            }
                            >Export as PDF</Button>
                        </Col>
                        <Col>
                            <ExportExcelButton
                                data={[...excelData, { name:"Total",username:"",winlose:ParseIntAndFormat(totalWinLose)}]}
                                fileName={`Smaster Report ${moment().locale('my').format('DD/MM/YYYY hh:mm a')}`} />
                        </Col>
                        <Col>
                            <Search
                                allowClear
                                onChange={this.onSearch}
                            />
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '10px' }}>
                        <Col span={24} style={{ overflowX: 'auto' }}>
                            <Table
                                bordered
                                loading={this.props.loading}
                                dataSource={tableData}
                                pagination={false}
                                rowKey={(row) => row.id}
                                summary={
                                    (data) => {
                                        let partnerBetAmount = currency(0);
                                        let partnerCommissionAmount = currency(0);
                                        let partnerWinLoseAmount = currency(0);
                                        let smasterBetAmount = currency(0);
                                        let smasterCommissionAmount = currency(0);
                                        let smasterWinLoseAmount = currency(0);
                                        data?.map(obj => {
                                            partnerBetAmount = partnerBetAmount.add(currency(obj.partnerBetAmount));
                                            partnerCommissionAmount = partnerCommissionAmount.add(currency(obj.partnerCommissionAmount));
                                            partnerWinLoseAmount = partnerWinLoseAmount.add(currency(obj.partnerBetAmount)
                                                .subtract(currency(obj.partnerWinAmount))
                                                .subtract(currency(obj.partnerCommissionAmount)));
                                            smasterBetAmount = smasterBetAmount.add(currency(obj.smasterBetAmount));
                                            smasterCommissionAmount = smasterCommissionAmount.add(currency(obj.smasterCommissionAmount));
                                            smasterWinLoseAmount = smasterWinLoseAmount.add(currency(obj.smasterBetAmount)
                                                .subtract(currency(obj.smasterWinAmount))
                                                .subtract(currency(obj.smasterCommissionAmount)));
                                        });
                                        return <Table.Summary.Row style={{ textAlign: 'right' }}>
                                            <Table.Summary.Cell>Total</Table.Summary.Cell>
                                            <Table.Summary.Cell>{ParseIntAndFormat(partnerBetAmount.value)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{ParseIntAndFormat(partnerCommissionAmount.value)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{ParseIntAndFormat(partnerWinLoseAmount.value)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{ParseIntAndFormat(smasterBetAmount.value)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{ParseIntAndFormat(smasterCommissionAmount.value)}</Table.Summary.Cell>
                                            <Table.Summary.Cell>{ParseIntAndFormat(smasterWinLoseAmount.value)}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    }
                                }>
                                <Column title="Smaster Name"
                                    sorter={(a, b) => a.smasterName?.toLowerCase() > b.smasterName?.toLowerCase()}
                                    sortDirections={['descend']}
                                    dataIndex="smasterName"
                                    render={(text, record) => `${record.name}-${record.smasterName}`}
                                    key="smasterName" align="center" />
                                <ColumnGroup title="Partner">
                                    <Column title="Bet" dataIndex="partnerBetAmount"
                                        render={(text, record) => ParseIntAndFormat(record.partnerBetAmount ?? 0)}
                                        key="partnerBetAmount"
                                        align="right" />
                                    <Column title="Commission" dataIndex="partnerCommissionAmount"
                                        render={(text, record) => ParseIntAndFormat(record.partnerCommissionAmount ?? 0)}
                                        key="partnerCommissionAmount" align="right" />
                                    <Column
                                        title="W/L"
                                        key="p_win_lose"
                                        render={(text, record) => {
                                            let sum = currency(record.partnerBetAmount)
                                                .subtract(currency(record.partnerWinAmount))
                                                .subtract(currency(record.partnerCommissionAmount))
                                                .value;
                                            return ParseIntAndFormat(sum);
                                        }
                                        }
                                        align="right"
                                    />
                                </ColumnGroup>
                                <ColumnGroup title="Company">
                                    <Column title="Bet" dataIndex="smasterBetAmount"
                                        render={(text, record) => ParseIntAndFormat(record.smasterBetAmount ?? 0)}
                                        key="smasterBetAmount"
                                        align="right" />
                                    <Column title="Commission" dataIndex="smasterCommissionAmount"
                                        render={(text, record) => ParseIntAndFormat(record.smasterCommissionAmount ?? 0)}
                                        key="smasterCommissionAmount" align="right" />
                                    <Column
                                        title="W/L"
                                        key="s_win_lose"
                                        render={(text, record) => {
                                            let sum = currency(record.smasterBetAmount)
                                                .subtract(currency(record.smasterWinAmount))
                                                .subtract(currency(record.smasterCommissionAmount))
                                                .value;
                                            return ParseIntAndFormat(sum);
                                        }}
                                        align="right"
                                    />
                                </ColumnGroup>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.report.loading,
    token: state.auth.token,
    error: state.report.error,
    smasterReport: state.report.smaster,
    type: state.report.type
})

const mapDispatchToProps = dispatch => ({
    onGetReport: (dates, token) => dispatch(getSmasterReport(dates, token)),
    onSetBetType: (type) => dispatch(setBetType(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(SmasterReport);