import { Form, Button, Row, Col } from "antd";
import { InputOTP } from "antd-input-otp";
import { useEffect, useState } from "react";

const OTPVerification = ({ onSubmit, onLogin, formState }) => {

    const [enableResend, setEnableResend] = useState(false);
    const [timer, setTimer] = useState(undefined);
    const [restartCoundown, setRestartCountdown] = useState(false);

    useEffect(() => {
        function countdown() {
            var seconds = 59;
            function tick() {
                seconds--;
                setTimer("0:" + (seconds < 10 ? "0" : "") + seconds)
                if (seconds > 0) {
                    setTimeout(tick, 1000);
                } else {
                    setEnableResend(true);
                    setTimer(undefined)
                }
            }
            tick();
        }
        countdown();
    }, [restartCoundown]);

    const [form] = Form.useForm();

    const handleFinish = (values) => {
        const { otp } = values;
        if (!otp || otp.includes(undefined) || otp.includes("") || otp.length !== 6) {
            return form.setFields([
                {
                    name: "otp",
                    errors: ["OTP is invalid."]
                }
            ]);
        }

        onSubmit(otp.join(''));
    };
    return (
        <Row justify="center" align="middle" gutter={[16, 16]}>
            <Col lg={8} sm={20}>
                <p style={{ marginBlockStart: 40, textAlign: 'center' }}>We have sent OTP code to your phone number.</p>
                <h2 style={{ textAlign: 'center' }}>Please enter OTP Code</h2>
                <Form form={form} onFinish={handleFinish}>
                    <Form.Item
                        name="otp"
                        style={{ textAlign: 'center' }}
                    >
                        <InputOTP autoFocus inputType="numeric" length={6} />
                    </Form.Item>

                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item noStyle>
                                <Button htmlType="submit" type="primary" style={{ width: '100%' }}>
                                    Confirm
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item noStyle>
                                <Button
                                    disabled={!enableResend}
                                    style={{ width: '100%' }}
                                    onClick={async () => {
                                        await onLogin(formState.username, formState.password);
                                        setEnableResend(false);
                                        setRestartCountdown(prev => !prev);
                                    }}>
                                    Resend OTP {timer ? <>({timer})</> : null}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Col>
        </Row>
    )
}

export default OTPVerification;