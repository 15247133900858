import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    GET_LEAGUE_START,
    GET_LEAGUE_SUCCESS,
    GET_LEAGUE_FAIL,
    UPDATE_LEAGUE_START,
    UPDATE_LEAGUE_SUCCESS,
    UPDATE_LEAGUE_FAIL,
    OPEN_UPDATE_LEAGUE,
    CLOSE_UPDATE_LEAGUE,
    SET_ID_TO_UPDATE
} from '../actions/league/actionTypes';

const initialState={
    loading:false,
    error:null,
    message:null,
    all:null,
    updateLeagueVisible:false,
    idToUpdate:null
}

const getLeagueSuccess=(state,action)=>{
    return update(state,{
        loading:false,
        all:action.payload,
        message:'Get Success'
    })
}

const updateLeagueSuccess=(state,action)=>{
    let updated=action.payload;
    let updatedArr=state.all?.map(el=>{
        if(el.id===updated.id){
            return updated
        }
        return el
    })
    return update(state,{
        loading:false,
        all:updatedArr,
        message:'Update Success'
    })
}

const toggleUpdateLeague=(state,action)=>{
    return update(state,{
        updateLeagueVisible:!state.updateLeagueVisible
    })
}

const setIdToUpdate=(state,action)=>{
    return update(state,{
        idToUpdate:action.payload
    })
}

const leagueReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case GET_LEAGUE_START:
            return loading(state,action);
        case GET_LEAGUE_SUCCESS:
            return getLeagueSuccess(state,action);
        case GET_LEAGUE_FAIL:
            return error(state,action);
        case UPDATE_LEAGUE_START:
            return loading(state,action);
        case UPDATE_LEAGUE_SUCCESS:
            return updateLeagueSuccess(state,action);
        case UPDATE_LEAGUE_FAIL:
            return error(state,action);
        case OPEN_UPDATE_LEAGUE:
            return toggleUpdateLeague(state,action);
        case CLOSE_UPDATE_LEAGUE:
            return toggleUpdateLeague(state,action);
        case SET_ID_TO_UPDATE:
            return setIdToUpdate(state,action);
        default:
            return state;
    }
}

export default leagueReducer;