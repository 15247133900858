import * as actionTypes from './actionTypes';
import axios from 'axios';
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const checkActiveBet = (id, token) => async dispatch => {
   dispatch({
      type: actionTypes.CHECK_ACTIVE_BET_START
   });
   const options = {
      url: `${api}/bets/${id}/activeBets`,
      method: 'GET',
      headers: {
         'Authorization': `Bearer ${token}`
      },
   }
   await axios(options)
      .then(response => dispatch({
         type: actionTypes.CHECK_ACTIVE_BET_SUCCESS,
         payload: response.data
      }))
      .catch(error => dispatch({
         type: actionTypes.CHECK_ACTIVE_BET_FAIL,
         payload: getMessage(error)
      }))
}