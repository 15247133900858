import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, InputNumber,Row,Col } from 'antd';
import * as actionTypes from '../../redux/actions/seniormaster/actionTypes';
import { updateBetLimit } from '../../redux/actions/seniormaster/updateSeniormaster';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 24 },
};

class UpdateBetLimitationModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    onFinish = async (values) => {
        await this.props.onUpdate({
            id: this.props.smaster ?.id,
            limitId: this.props.smaster ?.betLimitation ?.id,
            smasterMixMax: values.smasterMixMax,
            smasterSingleMax: values.smasterSingleMax,
            masterMixMax: values.masterMixMax,
            masterSingleMax: values.masterSingleMax,
            agentMixMax: values.agentMixMax,
            agentSingleMax: values.agentSingleMax,
            maxForSingleBet: values.maxForSingleBet,
            maxForMixBet: values.maxForMixBet,
            maxForTwoD: values.maxForTwoD,
            maxForThreeD: values.maxForThreeD,
            // maxForFourD:values.maxForFourD,
        },
            this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        return (
            <Modal
                width={800}
                title="Update Bet Limitation"
                key={this.props.smaster?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'update-bl-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    {...layout}
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="update-bl-form"
                    name="basic"
                    initialValues={{
                        smasterMixMax: this.props.smaster ?.betLimitation ?.smasterMixMax,
                        smasterSingleMax: this.props.smaster ?.betLimitation ?.smasterSingleMax,
                        masterMixMax: this.props.smaster ?.betLimitation ?.masterMixMax,
                        masterSingleMax: this.props.smaster ?.betLimitation ?.masterSingleMax,
                        agentMixMax: this.props.smaster ?.betLimitation ?.agentMixMax,
                        agentSingleMax: this.props.smaster ?.betLimitation ?.agentSingleMax,
                        maxForSingleBet: this.props.smaster ?.betLimitation ?.maxForSingleBet,
                        maxForMixBet: this.props.smaster ?.betLimitation ?.maxForMixBet,
                        maxForTwoD: this.props.smaster ?.betLimitation ?.maxForTwoD,
                        maxForThreeD: this.props.smaster ?.betLimitation ?.maxForThreeD,
                        // maxForFourD: this.props.smaster ?.betLimitation ?.maxForFourD ??0,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="smasterMixMax"
                                label="Smaster Mix Max"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Require!',
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{ width: 150 }} />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="smasterSingleMax"
                                label="Smaster Single Max"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Require!',
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{ width: 150 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="masterMixMax"
                                label="Master Mix Max"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Require!',
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{ width: 150 }} />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="masterSingleMax"
                                label="Master Single Max"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Require!',
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{ width: 150 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="agentMixMax"
                                label="Agent Mix Max"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Require!',
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{ width: 150 }} />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="agentSingleMax"
                                label="Agent Single Max"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Require!',
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{ width: 150 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                    <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="maxForMixBet"
                                label="Member Mix Max"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Require!',
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{ width: 150 }} />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="maxForSingleBet"
                                label="Member Single Max"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Require!',
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{ width: 150 }} />
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="maxForTwoD"
                                label="Max For Two D"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Require!',
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{ width: 150 }} />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="maxForThreeD"
                                label="Max For Three D"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Require!',
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{ width: 150 }} />
                            </Form.Item>
                        </Col>
                        {/* <Col lg={12} md={12} sm={12}>
                            <Form.Item
                                name="maxForFourD"
                                label="Max For Four D"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Require!',
                                    }
                                ]}
                                hasFeedback
                            >
                                <InputNumber style={{ width: 150 }} />
                            </Form.Item>
                        </Col> */}
                    </Row>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.seniorMaster.updateBetLimitVisible,
    loading: state.seniorMaster.loading,
    token: state.auth.token,
    error: state.seniorMaster.error,
    message: state.seniorMaster.message,
    smaster: state.seniorMaster.one
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (obj, token) => dispatch(updateBetLimit(obj, token)),
    onClose: () => dispatch({
        type: actionTypes.TOGGLE_BET_LIMIT_UPDATE
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBetLimitationModal);