import { Button } from "antd";
import ReactExport from "react-export-excel";
import moment from "moment";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportExcelButton = ({ data, fileName }) => {
  
    return (
        <ExcelFile element={<Button>Export as Excel</Button>} filename={fileName}>
            <ExcelSheet data={data} name={`Senior Master Report ${moment().locale('my').format('DD/MM/YYYY hh:mm a')}`}>
                <ExcelColumn label="Name" value="name" />
                <ExcelColumn label="Username" value="username" />
                <ExcelColumn label="W/L" value="winlose" />
            </ExcelSheet>
        </ExcelFile>
    );
};
