import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import { success } from './shared/success';
import {
    SIGNUP_EMPLOYEE_START,
    SIGNUP_EMPLOYEE_SUCCESS,
    SIGNUP_EMPLOYEE_FAIL,
    GET_EMPLOYEES_START,
    GET_EMPLOYEES_SUCCESS,
    GET_EMPLOYEES_FAIL,
    UPDATE_EMPLOYEE_START,
    UPDATE_EMPLOYEE_SUCCESS,
    UPDATE_EMPLOYEE_FAIL,
    RESET_EMPLOYEE_PASSWORD_START,
    RESET_EMPLOYEE_PASSWORD_SUCCESS,
    RESET_EMPLOYEE_PASSWORD_FAIL,
} from '../actions/employee/actionTypes';

const initialState = {
    loading: false,
    error: null,
    message: null,
    all: []
}


const create = (state, action) => {
    return update(state, {
        loading: false,
        all: [...state.all, action.payload],
        message: 'create success'
    })
}

const getEmployees = (state, action) => {
    return update(state, {
        loading: false,
        all: action.payload
    })
}

const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGNUP_EMPLOYEE_START:
            return loading(state, action);
        case SIGNUP_EMPLOYEE_SUCCESS:
            return create(state, action);
        case SIGNUP_EMPLOYEE_FAIL:
            return error(state, action);
        case GET_EMPLOYEES_START:
            return loading(state, action);
        case GET_EMPLOYEES_SUCCESS:
            return getEmployees(state, action);
        case GET_EMPLOYEES_FAIL:
            return error(state, action);
        case UPDATE_EMPLOYEE_START:
            return loading(state,action);
        case UPDATE_EMPLOYEE_SUCCESS:
            return success(state,'Updated employee information.');
        case UPDATE_EMPLOYEE_FAIL:
            return error(state,action);
        case RESET_EMPLOYEE_PASSWORD_START:
            return loading(state,action);
        case RESET_EMPLOYEE_PASSWORD_SUCCESS:
            return success(state,'Password was reset.');
        case RESET_EMPLOYEE_PASSWORD_FAIL:
            return error(state,action);
        default:
            return state;
    }
}

export default employeeReducer;