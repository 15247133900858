import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
 DELETE_OLD_DATA_START,
 DELETE_OLD_DATA_SUCCESS,
 DELETE_OLD_DATA_FAIL,
 UPDATE_DELETE_STATUS,
} from '../actions/bets/actionTypes';

const initialState = {
    loading: false,
    deleting:false,
}

const deleteOldData = (state, action) => {
    return update(state, {
        loading: false,
        deleting: action.payload
    })
}

const updateDeleteStatus = (state,action)=>{
    return update(state,{
        deleting:typeof action.payload === 'boolean' ? action.payload : action.payload === "false" ? false : true
    })
}

const betsReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_OLD_DATA_START:
            return loading(state, action);
        case DELETE_OLD_DATA_SUCCESS:
            return deleteOldData(state, action);
        case DELETE_OLD_DATA_FAIL:
            return error(state, action);
        case UPDATE_DELETE_STATUS:
            return updateDeleteStatus(state,action);
        default:
            return state;
    }
}

export default betsReducer;