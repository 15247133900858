import React from 'react';
import { connect } from 'react-redux';
import { toggleLoseMessageUpdateModal } from '../../redux/actions/loseMessage/toggleLoseMessageUpdateModal';
import { updateLoseMessage } from "../../redux/actions/loseMessage/updateLoseMessage";
import UpdateMessageModal from "../Message/UpdateMessageModal";

class UpdateLoseMessageModal extends React.Component {
      render() {
            return (
                  <UpdateMessageModal
                        formId="update-lose-msg-form"
                        modalKey={"update-lose-msg-modal"+this.props.toUpdate?.id}
                        loading={this.props.loading}
                        visible={this.props.visible}
                        token={this.props.token}
                        error={this.props.error}
                        message={this.props.message}
                        toUpdate={this.props.toUpdate}
                        onUpdate={this.props.onUpdate}
                        onClose={this.props.onClose} />
            );
      }
}

const mapStateToProps = state => ({
      visible: state.loseMessage.updateVisible,
      loading: state.loseMessage.loading,
      token: state.auth.token,
      error: state.loseMessage.error,
      message: state.loseMessage.updateMessage,
      toUpdate: state.loseMessage.toUpdate,
})

const mapDispatchToProps = dispatch => ({
      onUpdate: (obj, token) => dispatch(updateLoseMessage(obj, token)),
      onClose: () => dispatch(toggleLoseMessageUpdateModal({ id: null, message: null })),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLoseMessageModal);