import { useEffect } from 'react';
import { Breadcrumb, Col, Modal, Row, Table } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBetDetail } from '../../redux/actions/betPattern/getBetDetail';
import { rejectBet } from '../../redux/actions/betPattern/rejectBet';
import BetInformations from './BetInformations';
import moment from 'moment';
import { ParseIntAndFormat } from "../Report/ParseIntAndFormat";
import {
      ExclamationCircleOutlined,
} from '@ant-design/icons';

const { confirm } = Modal;

const columns = [
      {
            title: 'Home',
            key: 'home',
            render: (text, record) => {
                  return <>
                        {record?.match?.home.name}
                        {record?.betType === "BODY" ? (record?.homeUpper ?
                              `(${record?.odds}${record?.price >= 0 ?
                                    '+' + record?.price : record?.price})`
                              : null) : null}
                  </>
            }
      },
      {
            title: 'Goal Score',
            key: 'goalScore',
            render: (text, record) => {
                  return `${record?.match?.homeScore}-${record?.match?.awayScore}`
            },
            align: 'center'
      },
      {
            title: 'Away',
            key: 'away',
            render: (text, record) => {
                  return <>
                        {record?.match?.away.name}
                        {record?.betType === "BODY" ? (record?.homeUpper ?
                              null
                              : `(${record?.odds}${record?.price >= 0 ?
                                    '+' + record?.price : record?.price})`) : null}
                  </>
            },
            align: 'right'
      },
      {
            title: 'Goal Price',
            key: 'goalPrice',
            render: (text, record) => record?.betType === "BODY" ? '-' : `(${record?.odds}
            ${record?.price >= 0 ?
                        '+' + record?.price : record?.price})`,
            align: 'center'
      },
      {
            title: 'Bet Choice',
            align: 'right',
            render: (text, record) => {
                  return <>
                        {
                              record?.betType === "BODY" ? getBetChoice(record) : record?.betChoice
                        }
                  </>
            }
      }
]

const getBetChoice = (record) => {

      if (record?.homeUpper && record?.betChoice === "UPPER") {
            return record?.match?.home?.name
      } else if (!record?.homeUpper && record?.betChoice === "LOWER") {
            return record?.match?.home?.name
      } else
            return record?.match?.away?.name
}

const BetDetail = () => {

      const history = useHistory();

      const { id } = useParams();
      const token = useSelector(state => state.auth.token);
      const { betDetail, loading } = useSelector(state => state.betPattern);
      const dispatch = useDispatch();

      useEffect(() => {
            dispatch(getBetDetail(id, token));
      }, [])

      const rejectHandler = () => confirm({
            title: 'Are your sure to reject this bet?',
            icon: <ExclamationCircleOutlined />,
            content: null,
            onOk() {
                  return new Promise((resolve, reject) => {
                        (
                              async () => {
                                    await dispatch(rejectBet(id, token));
                                    resolve();
                              }
                        )()
                  });
            },
      });

      return <>
            <Breadcrumb>
                  <Breadcrumb.Item style={{ cursor:'pointer' }} onClick={()=>history.goBack()}>Bets by pattern</Breadcrumb.Item>
                  <Breadcrumb.Item>Bet detail</Breadcrumb.Item>
            </Breadcrumb>
            <Row justify="left">
                  <Col lg={24} md={24} sm={24}>
                        <BetInformations
                              id={betDetail?.id}
                              cheated={betDetail?.markAsCheated}
                              date={moment(betDetail?.betTime).format('YYYY-MM-DD hh:mm:ss a')}
                              partnerBetAmount={ParseIntAndFormat(betDetail?.partnerBetAmount ?? 0)}
                              smasterBetAmount={ParseIntAndFormat(betDetail?.smasterBetAmount ?? 0)}
                              userBetAmount={ParseIntAndFormat(betDetail?.userBetAmount ?? 0)}
                              partnerCommissionAmount={ParseIntAndFormat(betDetail?.partnerCommissionAmount ?? 0)}
                              smasterCommissionAmount={ParseIntAndFormat(betDetail?.smasterCommissionAmount ?? 0)}
                              userCommissionAmount={ParseIntAndFormat(betDetail?.userCommissionAmount ?? 0)}
                              partnerWinAmount={ParseIntAndFormat(betDetail?.partnerWinAmount ?? 0)}
                              smasterWinAmount={ParseIntAndFormat(betDetail?.smasterWinAmount ?? 0)}
                              userWinAmount={ParseIntAndFormat(betDetail?.userWinAmount ?? 0)}
                              status={betDetail?.betStatus}
                              rejectHandler={rejectHandler}
                        />
                        <Table
                              columns={columns}
                              dataSource={
                                    betDetail?.betMatches
                              }
                              loading={loading}
                        />
                  </Col>
            </Row>
      </>
}

export default BetDetail