import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const deleteOldData=(token)=>async dispatch=>{
    dispatch({
        type:actionTypes.DELETE_OLD_DATA_START
    })
    const options={
        url:`${api}/bets/delete`,
        method:'DELETE',
        headers:{
            Authorization:`Bearer ${token}`
        },
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.DELETE_OLD_DATA_SUCCESS,
                payload:response.data.deleting
            }))
            .catch(error=>dispatch({
                type:actionTypes.DELETE_OLD_DATA_FAIL,
                payload:getMessage(error)
            }))
}