import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, Input } from 'antd';
import { closeUpdateTeam } from '../../redux/actions/team/toggleUpdateTeam';
import { updateTeam } from '../../redux/actions/team/updateTeam';
import { getTeams } from '../../redux/actions/team/getTeams';

class UpdateTeamModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 12,
        },
    };
    tailLayout = {
        wrapperCol: {
            offset: 12,
            span: 16,
        },
    };
    onFinish = async (values) => {
        await this.props.onUpdate({
            id: this.props.team?.id,
            leagueId: this.props.team?.league?.id,
            name: values.name
        },
            this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        return (
            <Modal
                title="Update Team"
                key={this.props.team?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'update-team-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="update-team-form"
                    {...this.layout}
                    name="basic"
                    initialValues={{
                        name: this.props.team?.name
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.team.updateTeamVisible,
    loading: state.team.loading,
    token: state.auth.token,
    error: state.team.error,
    message: state.team.message,
    team: state.team.team
})

const mapDispatchToProps = dispatch => ({
    onUpdate: ({ id, leagueId, name }, token) => dispatch(updateTeam({ id, leagueId, name }, token)),
    onClose: () => dispatch(closeUpdateTeam()),
    onGetTeams: (token) => dispatch(getTeams(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTeamModal);