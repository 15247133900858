import update from '../utilities/update';
import { loading } from './shared/loading';
import { error } from './shared/error';
import {
   LSPORT_START,
   LSPORT_SUCCESS,
   LSPORT_FAIL,
   TOGGLE_LEAGUE,
   CREATE_LEAGUES,
   SET_SELECTED_LEAGUES,
   CREATE_LEAGUE_IDS,
   UPDATE_LSPORT,
   SET_LEAGUE_MODAL_STATE
} from '../actions/lsport/actionTypes';

const initialState = {
   error: null,
   message: null,
   loading: false,
   lsport: null,
   visibleLeague: false,
   leagues: null,
   selectedLeagues: [],
   leagueIds: [],
   leagueModalState: {
      checkAll: false,
      uncheckAll: true,
   }
}

const lsportSuccess = (state, action) => {
   return update(state, { error: null, message: 'Lsport success', lsport: action.payload, loading: false });
}

const toggleLeague = (state, action) => {
   return update(state, { visibleLeague: !state.visibleLeague });
}

const createLeagues = (state, action) => {
   return update(state, { leagues: action.payload });
}

const setSelectedLeagues = (state, action) => {
   return update(state, { selectedLeagues: action.payload });
}

const createLeagueIds = (state, action) => {
   return update(state, { leagueIds: action.payload });
}

const updateLsport = (state, action) => {
   return update(state, { lsport: action.payload });
}

const setLeagueModalState = (state, action) => {
   return update(state, {
      leagueModalState: action.payload
   });
}

const lsportReducer = (state = initialState, action) => {
   switch (action.type) {
      case LSPORT_START:
         return loading(state, action);
      case LSPORT_SUCCESS:
         return lsportSuccess(state, action);
      case LSPORT_FAIL:
         return error(state, action);
      case TOGGLE_LEAGUE:
         return toggleLeague(state, action);
      case CREATE_LEAGUES:
         return createLeagues(state, action);
      case SET_SELECTED_LEAGUES:
         return setSelectedLeagues(state, action);
      case CREATE_LEAGUE_IDS:
         return createLeagueIds(state, action);
      case UPDATE_LSPORT:
         return updateLsport(state, action);
      case SET_LEAGUE_MODAL_STATE:
         return setLeagueModalState(state, action);
      default:
         return state;
   }
}

export default lsportReducer;