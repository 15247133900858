import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const addCommission=({
    id,
    matchCount,
    betCommission,
    winCommission
},token)=>async dispatch=>{
    dispatch({
        type:actionTypes.ADD_COMMISSION_START
    })
    const options={
        url:`${api}/smasters/${id}/commissions`,
        method:'POST',
        headers:{
            'Authorization':`Bearer ${token}`
        },
        data:{
            matchCount,
            betCommission,
            winCommission
        }
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.ADD_COMMISSION_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.ADD_COMMISSION_FAIL,
                payload:getMessage(error)
            }))
}