import React from 'react';
import { Card, Breadcrumb,  } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import BasicInfo from './BasicInfo';
import BetPrice from './BetPrice';
import DateTime from './DateTime';
import {connect} from 'react-redux';
const tabList = [
    {
        key: 'basic',
        tab: 'Basic',
    },
    {
        key: 'betPrice',
        tab: 'Bet Price',
    },
    {
        key: 'dateTime',
        tab: 'Date Time'
    }
];

class MatchDetail extends React.Component {
    state = {
        key: 'basic'
    }
    onTabChange = (key, type) => {
        console.log(key, type);
        this.setState({ [type]: key });
    };
    render() {
        const contentList = {
            basic: <BasicInfo data={this.props.match}/>,
            betPrice:<BetPrice data={this.props.match}/>,
            dateTime:<DateTime data={this.props.match}/>
        };
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item><Link to="/">Collections</Link></Breadcrumb.Item>
                    <Breadcrumb.Item><a href="#" onClick={() => this.props.history.goBack()}>CollectionDetail</a></Breadcrumb.Item>
                    <Breadcrumb.Item>MatchDetail</Breadcrumb.Item>
                </Breadcrumb>
                <Card
                    style={{ width: '100%' }}
                    title="Match detail"
                    tabList={tabList}
                    activeTabKey={this.state.key}
                    onTabChange={key => {
                        this.onTabChange(key, 'key');
                    }}
                >
                    {contentList[this.state.key]}
                </Card>
            </>
        );
    }
}

const mapStateToProps=state=>({
    match:state.collection.match
})

export default withRouter(connect(mapStateToProps,null)(MatchDetail));