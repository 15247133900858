import React from 'react';
import LsportTable from './LsportTable';
import { connect } from 'react-redux';
import * as lsportActions from '../../redux/actions/lsport/lsport';
import * as matchActions from '../../redux/actions/match/match';
import { Button, Layout, Row, Col, message, DatePicker } from 'antd';
import {
   CREATE_LEAGUES,
   CREATE_LEAGUE_IDS,
   TOGGLE_LEAGUE,
   UPDATE_LSPORT
} from '../../redux/actions/lsport/actionTypes';
import { SET_SELECTED_MATCHES } from '../../redux/actions/match/actionTypes';
import moment from 'moment';
import { createLeagues } from './Utility/createLeague';
import filterMatches from './Utility/filterMatches';
import createLeagueIds from './Utility/createLeagueIds';
import { SET_SELECTED_LEAGUES } from '../../redux/actions/lsport/actionTypes';
import { setLeagueModalState } from '../../redux/actions/lsport/setLeagueModalState';

class Lsport extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         selectedRowKeys: [],
         isMobile: false,
      }
      this.onSelectChange = this.onSelectChange.bind(this);
   }

   async componentDidMount() {
      const query = window.matchMedia(`(max-width: 480px)`);
      query.onchange = (e) => {
         this.setState(prevState => {
            return {
               ...prevState,
               isMobile: e.matches
            }
         })
      }
      this.setState({
         isMobile: query.matches
      })
      let date = moment().locale('my').format('YYYY-MM-DD');
      await this.props.onLoad(date, this.props.token);
      await this.createLeaguesAndLeagueIds();
   }

   createLeaguesAndLeagueIds = async () => {
      let leagueArray = await createLeagues(this.props.lsport);
      await this.props.onCreate(leagueArray);
      let leagueIds = await createLeagueIds(this.props.leagues);
      await this.props.onCreateLeagueIds(leagueIds);
   }

   showLeague = () => {
      this.props.onShow();
   }

   lsportUpdater = (oldData, newData) => {
      let result = [];
      let found = null;
      let arrLength = oldData?.length;
      let arrLength2 = newData?.length;
      for (let i = 0; i < arrLength; i++) {
         for (let j = 0; j < arrLength2; j++) {
            if (oldData[i]?.matchId === newData[j]?.matchId) {
               found = newData[j];
            }
         }
         if (found) {
            result.push(found);
            found = null;
         } else {
            result.push(oldData[i]);
         }
      }
      this.props.onUpdateLsport(result);
   }

   onBtnSubmitClick = async () => {
      if (this.props.selectedMatches.length > 0) {
         await this.props.onCreateMatches(this.props.selectedMatches, this.props.token);
         if (this.props.message) {
            message.success(this.props.message);
            await this.props.unSelectLeagues();
            await this.props.onSetSelectedMatches([]);
            await this.props.setLeagueModalState({
               selectedLeagues: [],
               checkAll: false,
               uncheckAll: true,
            })
            this.setState({ selectedRowKeys: [] });
         }
         if (this.props.error) {
            message.error(this.props.error);
         }
      } else {
         message.info('Select Matches');
      }
   }

   handleChangeDate = async (date) => {
      await this.props.onSetSelectedMatches([]);
      await this.props.onLoad(date?.locale('my').format('YYYY-MM-DD'), this.props.token);
      await this.createLeaguesAndLeagueIds();
   }

   updateSelectedMatches = async (newData) => {
      if (this.props.selectedMatches) {
         let result = [];
         let { selectedMatches } = this.props;
         let arrLength = selectedMatches.length;
         let arrLength2 = newData?.length;
         let found = null;
         for (let i = 0; i < arrLength; i++) {
            for (let j = 0; j < arrLength2; j++) {
               if (selectedMatches[i]?.matchId === newData[j]?.matchId) {
                  found = newData[j];
               }
            }
            if (found) {
               result.push(found);
               found = null;
            } else {
               result.push(selectedMatches[i])
            }
         }
         await this.props.onSetSelectedMatches(result);
      }
   }

   onSelectChange(selectedRowKeys, selectedRows) {
      console.log('selectedRowKeys:', selectedRowKeys, 'SelectedRows', selectedRows)
      this.setState(prevState => {
         return {
            ...prevState,
            selectedRowKeys,
         }
      });
      this.props.onSetSelectedMatches(selectedRows);
   }

   render() {
      let filteredMatches = filterMatches(this.props.selectedLeagues, this.props.lsport);
      return (
         <Row>
            <Col span={24}>
               <Row gutter={[16, 10]}>
                  <Col span={{ lg: 12, md: 12, sm: 24, xs: 24 }}>
                     <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                     }}>
                        <Button type="primary"
                           onClick={this.showLeague}
                           ghost
                        >Show Leagues</Button>
                        <DatePicker format="YYYY-MM-DD"
                           onChange={this.handleChangeDate}
                           style={{
                              marginLeft: '20px'
                           }} />
                     </div>
                  </Col>
                  <Col span={12}>
                     <div style={{
                        display: 'flex',
                        justifyContent: window.innerWidth<=1024?'flex-start':'flex-end'
                     }}>
                        <Button type="primary"
                           onClick={this.onBtnSubmitClick}
                           loading={this.props.createLoading}
                        >Submit</Button>
                     </div>
                  </Col>
               </Row>
               <br />
               <Row span={24}>
                  <Col span={24} style={{
                     width: '100%',
                     overflowX: 'auto',
                  }}>
                     <LsportTable data={filteredMatches}
                        updateLsport={(newData) => {
                           this.lsportUpdater(this.props.lsport, newData)
                           this.updateSelectedMatches(newData);
                        }}
                        loading={this.props.loading}
                        selectedRowKeys={this.state.selectedRowKeys}
                        onSelectChange={this.onSelectChange}
                     />
                  </Col>
               </Row>
            </Col>
         </Row>
      );
   }
}
const mapStateToProps = state => ({
   token: state.auth.token,
   lsport: state.lsport.lsport,
   leagues: state.lsport.leagues,
   loading: state.lsport.loading,
   createLoading: state.createMatch.loading,
   selectedLeagues: state.lsport.selectedLeagues,
   error: state.createMatch.error,
   message: state.createMatch.message,
   selectedMatches: state.createMatch.selectedMatches,
});
const mapDispatchToProps = dispatch => ({
   onLoad: (date, token) => dispatch(lsportActions.lsport(date, token)),
   onShow: () => dispatch(fn => fn({ type: TOGGLE_LEAGUE })),
   onCreate: (leagues) => dispatch(fn => fn({ type: CREATE_LEAGUES, payload: leagues })),
   onCreateLeagueIds: (leagues) => dispatch(fn => fn({ type: CREATE_LEAGUE_IDS, payload: leagues })),
   onUpdateLsport: (lsport) => dispatch(fn => fn({ type: UPDATE_LSPORT, payload: lsport })),
   onCreateMatches: (matches, token) => dispatch(matchActions.createMatch(matches, token)),
   onSetSelectedMatches: (matches) => dispatch(fn => fn({ type: SET_SELECTED_MATCHES, payload: matches })),
   unSelectLeagues: () => dispatch({ type: SET_SELECTED_LEAGUES, payload: [] }),
   setLeagueModalState: (state) => dispatch(setLeagueModalState(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(Lsport);