import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const updateEmployee = (request, token) => async dispatch => {
    dispatch({
        type: actionTypes.UPDATE_EMPLOYEE_START
    })
    const options = {
        url: `${api}/employees/${request.id}`,
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: request
    }
    await axios(options)
        .then(response => dispatch({
            type: actionTypes.UPDATE_EMPLOYEE_SUCCESS,
            payload: response.data
        }))
        .catch(error => dispatch({
            type: actionTypes.UPDATE_EMPLOYEE_FAIL,
            payload: getMessage(error)
        }))
}