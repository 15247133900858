import * as actionTypes from './actionTypes';

export const togglePartner23cUpdate=()=>({
    type:actionTypes.TOGGLE_P23C_UPDATE
})

export const togglePSCUpdate=()=>({
    type:actionTypes.TOGGLE_PSC_UPDATE
})

export const togglePCUpdate=()=>({
    type:actionTypes.TOGGLE_PC_UPDATE
})

export const togglePCCreate=()=>({
    type:actionTypes.TOGGLE_PC_CREATE
})