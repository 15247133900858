import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, Radio } from 'antd';
import { updateEmployee } from '../../redux/actions/employee/updateEmployee';
import { getEmployees } from '../../redux/actions/employee/getEmployees';

class UpdateEmployeeModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 12,
        },
    };
    tailLayout = {
        wrapperCol: {
            offset: 12,
            span: 16,
        },
    };
    onFinish = async (values) => {
        await this.props.onUpdate({
            id: this.props.employee.id,
            ...values
        }, this.props.token);
        this.props.onGetEmployees(this.props.token);
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    render() {
        return (
            <Modal
                title='Update Employee'
                key={`update-employee-modal-${this.props.employee.id}`}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'update-employee-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.props.onClose}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="update-employee-form"
                    {...this.layout}
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    initialValues={{
                        name: this.props.employee.name,
                        mobile: this.props.employee.mobile,
                        role: this.props.employee.role === 'ROLE_EMPLOYEE' ? 'EMP' : 'SUP'
                    }}
                >

                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="mobile"
                        label="Mobile"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="role"
                        label="Role"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                    >
                        <Radio.Group
                            options={[
                                { value: 'EMP', label: 'Employee' },
                                { value: 'SUP', label: 'Supervisor' }
                            ]}
                            optionType="button" />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.employee.loading,
    token: state.auth.token,
    error: state.employee.error,
    message: state.employee.message,
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (request, token) => dispatch(updateEmployee(request, token)),
    onGetEmployees: (token) => dispatch(getEmployees(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmployeeModal);