export const CREATE_COLLECTION_START='CREATE_COLLECTION_START';
export const CREATE_COLLECTION_SUCCESS='CREATE_COLLECTION_SUCCESS';
export const CREATE_COLLECTION_FAIL='CREATE_COLLECTION_FAIL';

export const GET_COLLECTIONS_START='GET_COLLECTIONS_START';
export const GET_COLLECTIONS_SUCCESS='GET_COLLECTIONS_SUCCESS';
export const GET_COLLECTIONS_FAIL='GET_COLLECTIONS_FAIL';

export const GET_COLLECTION_START='GET_COLLECTION_START';
export const GET_COLLECTION_SUCCESS='GET_COLLECTION_SUCCESS';
export const GET_COLLECTION_FAIL='GET_COLLECTION_FAIL';

export const UPDATE_PRICE_START='UPDATE_PRICE_START';
export const UPDATE_PRICE_SUCCESS='UPDATE_PRICE_SUCCESS';
export const UPDATE_PRICE_FAIL='UPDATE_PRICE_FAIL';

export const OPEN_UPDATE_PRICE='OPEN_UPDATE_PRICE';
export const CLOSE_UPDATE_PRICE='CLOSE_UPDAT_PRICE';

export const OPEN_UPDATE_GOAL_SCORE='OPEN_UPDATE_GOAL_SCORE';
export const CLOSE_UPDATE_GOAL_SCORE='CLOSE_UPDATE_GOAL_SCORE';

export const OPEN_UPDATE_START_TIME='OPEN_UPDATE_START_TIME';
export const CLOSE_UPDATE_START_TIME='CLOSE_UPDATE_START_TIME';

export const OPEN_UPDATE_CLOSE_TIME='OPEN_UPDATE_CLOSE_TIME';
export const CLOSE_UPDATE_CLOSE_TIME='CLOSE_UPDATE_CLOSE_TIME';

export const UPDATE_GOAL_SCORE_START='UPDATE_GOAL_SCORE_START';
export const UPDATE_GOAL_SCORE_SUCCESS='UPDATE_GOAL_SCORE_SUCCESS';
export const UPDATE_GOAl_SCORE_FAIL='UPDATE_GOAl_SCORE_FAIL';

export const UPDATE_SINGLE_BET_START='UPDATE_SINGLE_BET_START';
export const UPDATE_SINGLE_BET_SUCCESS='UPDATE_SINGLE_BET_SUCCESS';
export const UPDATE_SINGLE_BET_FAIL='UPDATE_SINGLE_BET_FAIL';

export const UPDATE_HIGH_TAX_START='UPDATE_HIGH_TAX_START';
export const UPDATE_HIGH_TAX_SUCCESS='UPDATE_HIGH_TAX_SUCCESS';
export const UPDATE_HIGH_TAX_FAIL='UPDATE_HIGH_TAX_FAIL';

export const UPDATE_ACTIVE_START='UPDATE_ACTIVE_START';
export const UPDATE_ACTIVE_SUCCESS='UPDATE_ACTIVE_SUCCESS';
export const UPDATE_ACTIVE_FAIL='UPDATE_ACTIVE_FAIL';

export const UPDATE_START_TIME_START='UPDATE_START_TIME_START';
export const UPDATE_START_TIME_SUCCESS='UPDATE_START_TIME_SUCCESS';
export const UPDATE_START_TIME_FAIL='UPDATE_START_TIME_FAIL';


export const UPDATE_CLOSE_TIME_START='UPDATE_CLOSE_TIME_START';
export const UPDATE_CLOSE_TIME_SUCCESS='UPDATE_CLOSE_TIME_SUCCESS';
export const UPDATE_CLOSE_TIME_FAIL='UPDATE_CLOSE_TIME_FAIL';

export const SET_MATCH='SET_MATCH';

export const CHANGE_AUTO_UPDATE_START='CHANGE_AUTO_UPDATE';
export const CHANGE_AUTO_UPDATE_SUCCESS='CHANGE_AUTO_UPDATE_SUCCESS';
export const CHANGE_AUTO_UPDATE_FAIL='CHANGE_AUTO_UPDATE_FAIL';

export const CANCEL_MATCH_START='CANCEL_MATCH_START';
export const CANCEL_MATCH_SUCCESS='CANCEL_MATCH_SUCCESS';
export const CANCEL_MATCH_FAIL='CANCEL_MATCH_FAIL';

export const CHECK_ACTIVE_BET_START='CHECK_ACTIVE_BET_START';
export const CHECK_ACTIVE_BET_SUCCESS='CHECK_ACTIVE_BET_SUCCESS';
export const CHECK_ACTIVE_BET_FAIL='CHECK_ACTIVE_BET_FAIL';

export const RECALCULATE_START='RECALCULATE_START';
export const RECALCULATE_SUCCESS='RECALCULATE_SUCCESS';
export const RECALCULATE_FAIL='RECALCULATE_FAIL';

export const REFRESH_GOAL_SCORE_START='REFRESH_GOAL_SCORE_START';
export const REFRESH_GOAL_SCORE_SUCCESS='REFRESH_GOAL_SCORE_SUCCESS';
export const REFRESH_GOAL_SCORE_FAIL='REFRESH_GOAL_SCORE_FAIL';

export const FINISH_MATCH_START='FINISH_MATCH_START';
export const FINISH_MATCH_SUCCESS='FINISH_MATCH_SUCCESS';
export const FINISH_MATCH_FAIL='FINISH_MATCH_FAIL';

export const TOGGLE_FINISH_MATCH='TOGGLE_FINISH_MATCH';

export const OPEN_FINISH_MATCH = 'OPEN_FINISH_MATCH';
export const CLOSE_FINISH_MATCH = 'CLOSE_FINISH_MATCH';

export const UPDATE_RECALCULATE_STATUS='UPDATE_RECALCULATE_STATUS';