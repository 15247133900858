import React from 'react';
import { Row, Col, Table, message,Breadcrumb } from 'antd';
import { connect } from 'react-redux';
import { withRouter,Link } from 'react-router-dom';
import {
      EyeOutlined
} from '@ant-design/icons';
import { betsOf3dNumber } from '../../redux/actions/3DCollection/betsOf3dNumber';



class BetsOf3DNumber extends React.Component {

      componentDidMount() {
            const { token } = this.props;
            const { id } = this.props.match.params;
            this.props.onLoad(id, token);
      }

      componentDidUpdate() {
            if (this.props.error) {
                  message.error(this.props.error);
            }
      }

      render() {
            const columns = [
                  {
                        title: 'ID',
                        dataIndex: 'betId',
                        key: 'betId',
                  },
                  {
                        title: 'Amount',
                        dataIndex: 'amount',
                        key: 'amount',
                  },
                  {
                        title: 'Username',
                        dataIndex: 'username',
                        key: 'username',
                        align: 'center',
                  },
                  {
                        title: 'Action',
                        render: (text, record) => <EyeOutlined onClick={
                              () => this.props.history.push(`/3d_detail/${record.betId}`)} />
                  }
            ]
            return (
                  <Row gutter={[16, 16]}>
                        <Col span={{ lg: 6, md: 10, sm: 20 }}>
                              <Breadcrumb>
                                    <Breadcrumb.Item><Link to="/threeDCollections">Three D Collections</Link></Breadcrumb.Item>
                                    <Breadcrumb.Item><Link to="" onClick={()=>this.props.history.goBack()}>Three D Collections</Link></Breadcrumb.Item>
                                    <Breadcrumb.Item>Three D Number Bets</Breadcrumb.Item>
                              </Breadcrumb>
                        </Col>
                        <Col span={24} style={{
                              width: '100%',
                              overflowX: 'auto',
                        }}>
                              <Table columns={columns} 
                              dataSource={this.props.betsOf3dNumber}
                                    loading={this.props.loading} />
                        </Col>
                  </Row>
            )
      }
}

const mapStateToProps = state => {
      return {
            token: state.auth.token,
            error: state.threeDCollection.error,
            loading: state.threeDCollection.loading,
            betsOf3dNumber: state.threeDCollection.betsOf3dNumber,
      }
}

const mapDispatchToProps = dispatch => {
      return {
            onLoad: (id, token) => dispatch(betsOf3dNumber(id, token)),
      }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BetsOf3DNumber));