import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const updateThreeD=(id,status,token)=>async dispatch=>{
    dispatch({
        type:actionTypes.UPDATE_THREE_D_START
    })
    const options={
        url:`${api}/threeDNumbers/${id}`,
        method:'PUT',
        headers:{
            'Authorization':`Bearer ${token}`
        },
        data:{status}
    }
    await axios(options)
                .then(response=>dispatch({
                    type:actionTypes.UPDATE_THREE_D_SUCCESS,
                    payload:response.data
                }))
                .catch(error=>dispatch({
                    type:actionTypes.UPDATE_THREE_D_FAIL,
                    payload:getMessage(error)
                }))
}