import { connect } from 'react-redux';
import { getByMatchCount } from '../../redux/actions/betPattern/getByMatchCount';
import { useEffect } from 'react';
import { Table, Row, Col, PageHeader, message } from 'antd';
import { withRouter } from 'react-router-dom';
import {
    EyeTwoTone,
    ArrowLeftOutlined,
} from '@ant-design/icons';

const ByMatchCount = ({
    token,
    onGet,
    loading,
    byMatchCount,
    error,
    collection,
    history
}) => {
    useEffect(async () => {
        await onGet({
            matchCollectionId: collection?.collectionId,
            betMatchCount: collection?.betMatchCount
        }, token);
        if (error) {
            message.error(error);
        }
        return () => { }
    }, []);
    const columns = [
        {
            title: 'Pattern',
            dataIndex: 'pattern',
            key: 'pattern',
            align: 'left',
            width: '500px',
            render: (text, record) => {
                return {
                    children: <div style={{
                        width: 500,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>{text}</div>
                };
            }
        },
        {
            title: 'betMatchCount',
            dataIndex: 'betMatchCount',
            key: 'betMatchCount',
            align: 'right'
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            align: 'right',
            render: (text, record) => `${record.totalAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
        {
            title: 'Detail',
            key: 'detail',
            align: 'center',
            render: (text, record) => <EyeTwoTone onClick={() => history.push({
                pathname: '/pattern_detail',
                state: {
                    pattern: record.pattern,
                }
            })}
            />
        },
    ]
    return (
        <Row justify="left">
            <Col lg={24} md={12} sm={24}>
                <PageHeader
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                    title={<><ArrowLeftOutlined
                        onClick={history.goBack} /></>}
                />
                <Table
                    columns={columns}
                    dataSource={byMatchCount}
                    loading={loading}
                />
            </Col>
        </Row>
    );
}

const mapStateToProps = state => ({
    token: state.auth.token,
    loading: state.betPattern.loading,
    byMatchCount: state.betPattern.byMatchCount,
    error: state.betPattern.error,
    collection: state.betPattern.one
})

const mapDispatchToProps = dispatch => ({
    onGet: (obj, token) => dispatch(getByMatchCount(obj, token))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ByMatchCount));