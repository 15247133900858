import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    CREATE_TWOD_COLLECTION_START,
    CREATE_TWOD_COLLECTION_SUCCESS,
    CREATE_TWOD_COLLECTION_FAIL,
    GET_TWOD_COLLECTIONS_START,
    GET_TWOD_COLLECTIONS_SUCCESS,
    GET_TWOD_COLLECTIONS_FAIL,
    GET_TWOD_COLLECTION_START,
    GET_TWOD_COLLECTION_SUCCESS,
    GET_TWOD_COLLECTION_FAIL,
    TOGGLE_TWOD_UPDATE,
    TOGGLE_2D_COLLECTION_ADD,
    UPDATE_TWOD_START,
    UPDATE_TWOD_SUCCESS,
    UPDATE_TWOD_FAIL,
    SET_TO_UPDATE,
    GET_WIN_PERCENT_START,
    GET_WIN_PERCENT_SUCCESS,
    GET_WIN_PERCENT_FAIL,
    UPDATE_WIN_PERCENT_START,
    UPDATE_WIN_PERCENT_SUCCESS,
    UPDATE_WIN_PERCENT_FAIL,
    FINISH_TWO_D_COLLECTION_START,
    FINISH_TWO_D_COLLECTION_SUCCESS,
    FINISH_TWO_D_COLLECTION_FAIL,
    TOGGLE_FINISH_MODAL
} from '../actions/twoDcollection/actionTypes';

const initialState = {
    loading: false,
    error: null,
    all: [],
    one: null,
    updateVisible: false,
    toUpdate:null,
    winPercent:null,
    addVisible:false,
    finishVisible:false,
    collectionId:null,
    twoDAverage:0,
    total:0,
    max:0,
    maxNumber:0
}


const createTwoDcollection = (state, action) => {
    return update(state, {
        loading: false,
        message: 'create success',
        all: [...state.all, action.payload]
    })
}

const getTwoDCollections = (state, action) => {
    return update(state, {
        loading: false,
        all: action.payload,
    })
}

const getOne = (state, action) => {
    let arrLength=action.payload?.twoDNumbers?.length;
    let responseArr=action.payload?.twoDNumbers;
    let twoDAverage=0;
    let total=0;
    let max= responseArr[0]?.totalAmount || 0;
    let maxNumber=responseArr[0]?.number;
    for(let i=0;i<arrLength;i++){
        total+=(responseArr[i]?.totalAmount);
        if(max<(responseArr[i]?.totalAmount)){
            max=(responseArr[i]?.totalAmount);
            maxNumber=responseArr[i]?.number;
        }
    }
    if(max!=0){
        twoDAverage=total/max;
    }
    return update(state, {
        loading: false,
        one: action.payload,
        twoDAverage:twoDAverage.toFixed(),
        total,
        max,
        maxNumber
    })
}

const toggleUpdate = (state, action) => {
    return update(state, {
        updateVisible: !state.updateVisible
    })
}

const updateTwoD = (state, action) => {
    return update(state, {
        loading:false,
        one: {
            ...state.one,
            twoDNumbers: state.one?.twoDNumbers?.map(obj => {
                if (obj.id === action.payload.id) {
                    return action.payload
                }
                return obj
            })
        },
        message:'update success'
    })
}

const setToUpdate=(state,action)=>{
    return update(state,{
        toUpdate:action.payload
    })
}

const getWinPercent=(state,action)=>{
    return update(state,{
        loading:false,
        winPercent:action.payload
    })
}

const toggle2DCollectionAdd=(state,action)=>{
    return update(state,{
        addVisible:!state.addVisible
    })
}

const finishTwoDCollection=(state,action)=>{
    return update(state,{
        loading:false,
        message:'finish success'
    })
}

const toggleFinishModal=(state,action)=>{
    return update(state,{
        finishVisible:!state.finishVisible,
        collectionId:action.payload
    })
}

const twoDCollectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_TWOD_COLLECTION_START:
            return loading(state, action);
        case CREATE_TWOD_COLLECTION_SUCCESS:
            return createTwoDcollection(state, action);
        case CREATE_TWOD_COLLECTION_FAIL:
            return error(state, action);
        case GET_TWOD_COLLECTIONS_START:
            return loading(state, action);
        case GET_TWOD_COLLECTIONS_SUCCESS:
            return getTwoDCollections(state, action);
        case GET_TWOD_COLLECTIONS_FAIL:
            return error(state, action);
        case GET_TWOD_COLLECTION_START:
            return loading(state, action);
        case GET_TWOD_COLLECTION_SUCCESS:
            return getOne(state, action);
        case GET_TWOD_COLLECTION_FAIL:
            return error(state, action);
        case TOGGLE_TWOD_UPDATE:
            return toggleUpdate(state, action);
        case UPDATE_TWOD_START:
            return loading(state,action);
        case UPDATE_TWOD_SUCCESS:
            return updateTwoD(state,action);
        case UPDATE_TWOD_FAIL:
            return error(state,action);
        case SET_TO_UPDATE:
            return setToUpdate(state,action);
        case GET_WIN_PERCENT_START:
            return loading(state,action);
        case GET_WIN_PERCENT_SUCCESS:
            return getWinPercent(state,action);
        case GET_WIN_PERCENT_FAIL:
            return error(state,action);
        case UPDATE_WIN_PERCENT_START:
            return loading(state,action);
        case UPDATE_WIN_PERCENT_SUCCESS:
            return getWinPercent(state,action);
        case UPDATE_WIN_PERCENT_FAIL:
            return error(state,action);
        case TOGGLE_2D_COLLECTION_ADD:
            return toggle2DCollectionAdd(state,action);
        case FINISH_TWO_D_COLLECTION_START:
            return loading(state,action);
        case FINISH_TWO_D_COLLECTION_SUCCESS:
            return finishTwoDCollection(state,action);
        case FINISH_TWO_D_COLLECTION_FAIL:
            return error(state,action);
        case TOGGLE_FINISH_MODAL:
            return toggleFinishModal(state,action);
        default:
            return state;
    }
}

export default twoDCollectionReducer;