import React from 'react';
import { Row, Col, PageHeader, Table, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getTeams } from '../../redux/actions/team/getTeams';
import { setTeamToUpdate } from '../../redux/actions/team/setTeamToUpdate';
import {
   EditOutlined,
} from '@ant-design/icons';
import { openUpdateTeam } from '../../redux/actions/team/toggleUpdateTeam';



class TeamList extends React.Component {
   componentDidMount() {
      this.props.onGetAll(this.props.token);
   }
   componentDidUpdate() {
      if (this.props.error) {
         message.error(this.props.error);
      }
   }
   _openEditModal = async (record) => {
      await this.props.onSetTeam(record);
      this.props.onOpen();
   }
   render() {
      const columns = [
         {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
         },
         {
            title: 'League',
            dataIndex: 'league',
            key: 'league',
            render: (text, record) => (`${record.league.name}`)
         },
         {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
         },
         {
            title: 'Action',
            dataIndex: 'action',
            align: 'center',
            render: (text, record) => (
               <a onClick={() => this._openEditModal(record)}><EditOutlined /> Edit</a>
            )
         }
      ]
      return (
         <Row>
            <Col span={24}>
               <PageHeader title="Teams" />
               <Row>
                  <Col span={24} style={{
                     width: '100%',
                     overflowX: 'auto',
                  }}>
                     <Table dataSource={this.props.teams} columns={columns} loading={this.props.loading} />
                  </Col>
               </Row>
            </Col>
         </Row>
      );
   }
}

const mapStateToProps = state => ({
   token: state.auth.token,
   teams: state.team.all,
   error: state.team.error,
   message: state.team.message,
   loading: state.team.loading
})

const mapDispatchToProps = dispatch => ({
   onGetAll: (token) => dispatch(getTeams(token)),
   onOpen: () => dispatch(openUpdateTeam()),
   onSetTeam: (team) => dispatch(setTeamToUpdate(team))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamList));