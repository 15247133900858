import React from 'react';
import { connect } from 'react-redux';
import { toggleWinMessageUpdateModal } from '../../redux/actions/winMessage/toggleWinMessageUpdateModal';
import { updateWinMessage } from "../../redux/actions/winMessage/updateWinMessage";
import UpdateMessageModal from "../Message/UpdateMessageModal";

class UpdateWinMessageModal extends React.Component {
      render() {
            return (
                  <UpdateMessageModal
                        formId="update-win-msg-form"
                        modalKey={"update-win-msg-modal"+this.props.toUpdate?.id}
                        loading={this.props.loading}
                        visible={this.props.visible}
                        token={this.props.token}
                        error={this.props.error}
                        message={this.props.message}
                        toUpdate={this.props.toUpdate}
                        onUpdate={this.props.onUpdate}
                        onClose={this.props.onClose} />
            );
      }
}

const mapStateToProps = state => ({
      visible: state.winMessage.updateVisible,
      loading: state.winMessage.loading,
      token: state.auth.token,
      error: state.winMessage.error,
      message: state.winMessage.updateMessage,
      toUpdate: state.winMessage.toUpdate,
})

const mapDispatchToProps = dispatch => ({
      onUpdate: (obj, token) => dispatch(updateWinMessage(obj, token)),
      onClose: () => dispatch(toggleWinMessageUpdateModal({ id: null, message: null })),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateWinMessageModal);