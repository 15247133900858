import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    GET_PRICE_GAP_START,
    GET_PRICE_GAP_SUCCESS,
    GET_PRICE_GAP_FAIL,
    UPDATE_PRICE_GAP_START,
    UPDATE_PRICE_GAP_SUCCESS,
    UPDATE_PRICE_GAP_FAIL,
    TOGGLE_PRICE_GAP_UPDATE
} from '../actions/priceGap/actionTypes';

const initialState={
    loading:false,
    message:null,
    error:null,
    one:null,
    updateVisible:false,
}

const getPriceGap=(state,action)=>{
    return update(state,{
        loading:false,
        one:action.payload
    })
}

const updatePriceGap=(state,action)=>{
    return update(state,{
        loading:false,
        message:'update success',
        one:action.payload
    })
}

const toggleUpdate=(state,action)=>{
    return update(state,{
        updateVisible:!state.updateVisible
    })
}

const priceGapReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case GET_PRICE_GAP_START:
            return loading(state,action);
        case GET_PRICE_GAP_SUCCESS:
            return getPriceGap(state,action);
        case GET_PRICE_GAP_FAIL:
            return error(state,action);
        case UPDATE_PRICE_GAP_START:
            return loading(state,action);
        case UPDATE_PRICE_GAP_SUCCESS:
            return updatePriceGap(state,action);
        case UPDATE_PRICE_GAP_FAIL:
            return error(state,action);
        case TOGGLE_PRICE_GAP_UPDATE:
            return toggleUpdate(state,action);
        default:
            return state;
    }
}

export default priceGapReducer;