function filterMatches(selectedLeagues,matches){
    let filteredMatches=null;
    if(selectedLeagues.length>0){
        let leagueIdArray=selectedLeagues.map(league=>{
            return league.id;
        });
        filteredMatches=matches.filter(match=>{
            return leagueIdArray.includes(match.leagueName)
        });
    }
    return filteredMatches;
}

export default filterMatches;