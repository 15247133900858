import axios from 'axios';
import api from '../../utilities/api';
import * as actionTypes from './actionTypes';

const TOKEN_VALID_TIME_IN_DAY=1;

const TOKEN_VALID_TIME_IN_SECONDS=TOKEN_VALID_TIME_IN_DAY*24*60*60;

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        payload: error
    };
};

export const logout = () => {
    localStorage.removeItem('555mixToken');
    localStorage.removeItem('expirationDate');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const auth = (requestId, code) => {
    return dispatch => {
        //verify otp code
        dispatch(authStart());
        let url = `${api}/auth/admin/verify`;
        const options = {
            url: url,
            method: 'POST',
            headers: {},
            data: {
                requestId,
                code
            }
        };
        axios(options)
            .then(response => {
                const expirationDate = new Date(
                    new Date().getTime() + TOKEN_VALID_TIME_IN_SECONDS * 1000
                );
                localStorage.setItem("555mixToken", response.data.accessToken);
                localStorage.setItem("expirationDate", expirationDate);
                dispatch(authSuccess(response.data.accessToken));
                dispatch(checkAuthTimeout(TOKEN_VALID_TIME_IN_SECONDS));
            })
            .catch(err => {
                dispatch(authFail(err));
            });
    };
};

export const login = (username, password) => {
    let url = `${api}/auth/admin/login`;
    const options = {
        url: url,
        method: 'POST',
        headers: {},
        data: {
            username,
            password
        }
    };
    return axios(options)
            .then(response => response.data);
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem("555mixToken");
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem("expirationDate"));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                dispatch(authSuccess(token));
                dispatch(
                    checkAuthTimeout(
                        (expirationDate.getTime() - new Date().getTime()) / 1000
                    )
                );
            }
        }
    };
};