import React from 'react';
import { Row, Col, message, Card, InputNumber } from 'antd';
import { connect } from 'react-redux';
import {
   EditOutlined
} from '@ant-design/icons';
import { getWinPercent } from '../../redux/actions/twoDcollection/getWinPercent';
import { updateWinPercent } from '../../redux/actions/twoDcollection/updateWinPercent';


const WAIT_INTERVAL = 600
class WinPercent extends React.Component {
   state = {
      isWPReadOnly: true,
      isSNWPReadOnly: true,
   }
   async componentDidMount() {
      await this.props.onGet(this.props.token)
      this.timer = null;
   }
   componentDidUpdate() {
      if (this.props.error) {
         message.error(this.props.error);
      }
   }
   _handleWPPressed = (value) => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.props.onUpdate(value, this.props.winPercent?.specialNumberWinPercent, this.props.token), WAIT_INTERVAL)
   }
   _handleSNWPPressed = (value) => {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.props.onUpdate(this.props.winPercent?.winPercent, value, this.props.token), WAIT_INTERVAL)
   }
   render() {
      return (
         <>
            <Row>
               <Col lg={{ span: 8 }} md={{ span: 20 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Card
                     span={24}
                     type="inner"
                     title="Win Percent"
                     key={Math.random()}>
                     <h4>Win Percent &nbsp;<EditOutlined
                        style={this.state.isWPReadOnly ? undefined : { color: 'red' }}
                        onClick={() => this.setState({ isWPReadOnly: !this.state.isWPReadOnly })}
                     /> </h4>
                     <InputNumber
                        min={0}
                        max={100}
                        defaultValue={this.props.winPercent?.winPercent}
                        readOnly={this.state.isWPReadOnly}
                        style={{
                           width: 150,
                           color: this.state.isWPReadOnly ? 'gray' : '#0e0e0e',
                           background: this.state.isWPReadOnly ? '#f0f0f0' : 'white'
                        }}
                        onChange={this._handleWPPressed}
                     />
                     <h4>Special Number Win Percent &nbsp;
                        <EditOutlined
                           style={this.state.isSNWPReadOnly ? undefined : { color: 'red' }}
                           onClick={() => this.setState({ isSNWPReadOnly: !this.state.isSNWPReadOnly })} />
                     </h4>
                     <InputNumber
                        min={0}
                        max={100}
                        defaultValue={this.props.winPercent?.specialNumberWinPercent}
                        readOnly={this.state.isSNWPReadOnly}
                        style={{
                           width: 150,
                           color: this.state.isSNWPReadOnly ? 'gray' : '#0e0e0e',
                           background: this.state.isSNWPReadOnly ? '#f0f0f0' : 'white'
                        }}
                        onChange={this._handleSNWPPressed}
                     />
                  </Card>
               </Col>
            </Row>
         </>
      )
   }
}

const mapStateToProps = state => ({
   token: state.auth.token,
   loading: state.twoDCollection.loading,
   message: state.twoDCollection.message,
   error: state.twoDCollection.error,
   winPercent: state.twoDCollection.winPercent
})

const mapDispatchToProps = dispatch => ({
   onGet: (token) => dispatch(getWinPercent(token)),
   onUpdate: (winPercent, SpecialWinPercent, token) => dispatch(updateWinPercent(winPercent, SpecialWinPercent, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(WinPercent);