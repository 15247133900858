import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    GET_TEAMS_START,
    GET_TEAMS_SUCCESS,
    GET_TEAMS_FAIL,
    UPDATE_TEAM_START,
    UPDATE_TEAM_SUCCESS,
    UPDATE_TEAM_FAIL,
    SET_TEAM_TO_UPDATE,
    OPEN_UPDATE_TEAM,
    CLOSE_UPDATE_TEAM
} from '../actions/team/actionTypes';

const initialState={
    loading:false,
    error:null,
    message:null,
    all:null,
    team:null,
    updateTeamVisible:false
}


const getTeamsSuccess=(state,action)=>{
    return update(state,{
        loading:false,
        all:action.payload
    })
}

const updateTeamSuccess=(state,action)=>{
    const teams=state.all?.map(team=>{
        if(team.id===action.payload.id){
            return action.payload
        }
        return team
    })
    console.log('Inside reducer');
    console.log('Teams->',teams);
    console.log('payload->',action.payload);
    return update(state,{
        loading:false,
        all:teams
    })
}

const setTeamToUpdate=(state,action)=>{
    return update(state,{
        team:action.payload
    })
}

const toggleUpdateTeam=(state,action)=>{
    return update(state,{
        updateTeamVisible:!state.updateTeamVisible
    })
}

const teamReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case GET_TEAMS_START:
            return loading(state,action);
        case GET_TEAMS_SUCCESS:
            return getTeamsSuccess(state,action);
        case GET_TEAMS_FAIL:
            return error(state,action);
        case UPDATE_TEAM_START:
            return loading(state,action);
        case UPDATE_TEAM_SUCCESS:
            return updateTeamSuccess(state,action);
        case UPDATE_TEAM_FAIL:
            return error(state,action);
        case SET_TEAM_TO_UPDATE:
            return setTeamToUpdate(state,action);
        case OPEN_UPDATE_TEAM:
            return toggleUpdateTeam(state,action);
        case CLOSE_UPDATE_TEAM:
            return toggleUpdateTeam(state,action);
        default:
            return state;
    }
}

export default teamReducer;