import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const finishTwoDCollection=({collectionId,twoDNumber},token)=>async dispatch=>{
    dispatch({
        type:actionTypes.FINISH_TWO_D_COLLECTION_START
    })
    const options={
        url:`${api}/twoDCollections/${collectionId}/finish`,
        method:'POST',
        headers:{
            Authorization:`Bearer ${token}`
        },
        data:{
            twoDNumber
        }
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.FINISH_TWO_D_COLLECTION_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.FINISH_TWO_D_COLLECTION_FAIL,
                payload:getMessage(error)
            }))
}