export const actionTypes={
      CREATE_WIN_MESSAGE_START:"CREATE_WIN_MESSAGE_START",
      CREATE_WIN_MESSAGE_SUCCESS:"CREATE_WIN_MESSAGE_SUCCESS",
      CREATE_WIN_MESSAGE_FAIL:"CREATE_WIN_MESSAGE_FAIL",

      GET_WIN_MESSAGES_START:"GET_WIN_MESSAGES_START",
      GET_WIN_MESSAGES_SUCCESS:"GET_WIN_MESSAGES_SUCCESS",
      GET_WIN_MESSAGES_FAIL:"GET_WIN_MESSAGES_FAIL",

      UPDATE_WIN_MESSAGE_START:"UPDATE_WIN_MESSAGE_START",
      UPDATE_WIN_MESSAGE_SUCCESS:"UPDATE_WIN_MESSAGE_SUCCESS",
      UPDATE_WIN_MESSAGE_FAIL:"UPDATE_WIN_MESSAGE_FAIL",

      TOGGLE_UPDATE_WIN_MESSAGE_MODAL:"TOGGLE_UPDATE_WIN_MESSAGE_MODAL",
      TOGGLE_CREATE_WIN_MESSAGE_MODAL:"TOGGLE_CREATE_WIN_MESSAGE_MODAL",
}