import React from 'react';
import { Row, Col } from 'antd';
import {
    EditOutlined,
} from '@ant-design/icons';
import {connect} from 'react-redux';
import { openUpdatePriceModal } from '../../redux/actions/collection/toggleModals';
class BetPrice extends React.Component {
    handleClick=async ()=>{
        this.props.onOpen();
    }
    render() {
        const match = this.props.match;
        return (
            <Row>
                <Col span={24}>
                    <div>
                        <span style={{ fontSize: '15px', fontWeight: 500 }}>Bet Price Info</span>
                        <span style={{marginLeft:'20px',color:'#0a9396',fontSize:'18px'}}><EditOutlined onClick={this.handleClick}/></span>
                    </div>
                    <div style={{
                        marginTop: '30px'
                    }}>
                        <span>Odds: {match?.odds}</span>
                        <span style={{ marginLeft: '40px' }}>Price: {match?.price}</span>
                        <span style={{ marginLeft: '40px' }}>{match?.homeUpper ? 'Home' : 'Away'} Upper</span>
                    </div>
                    <div style={{
                        marginTop: '30px'
                    }}>
                        <span>Goal Total: {match?.goalTotal}</span>
                        <span style={{ marginLeft: '40px' }}>Goal Total Price: {match?.goalTotalPrice}</span>
                    </div>
                    <div style={{
                        marginTop: '30px'
                    }}>
                        <span>Body gap: {match?.bodyGap}</span>
                        <span style={{ marginLeft: '40px' }}>Goals gap: {match?.goalsGap}</span>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps=state=>({
    match:state.collection.match
})

const mapDispatchToProps=dispatch=>({
    onOpen:()=>dispatch(openUpdatePriceModal()),
})

export default connect(mapStateToProps,mapDispatchToProps)(BetPrice);