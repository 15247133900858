export const GET_BET_PATTERN_START='GET_BET_PATTERN_START';
export const GET_BET_PATTERN_SUCCCESS='GET_BET_PATTERN_SUCCCESS';
export const GET_BET_PATTERN_FAIL='GET_BET_PATTERN_FAIL';

export const GET_SINGLE_BET_PATTERN_START='GET_SINGLE_BET_PATTERN_START';
export const GET_SINGLE_BET_PATTERN_SUCCESS='GET_SINGLE_BET_PATTERN_SUCCESS';
export const GET_SINGLE_BET_PATTERN_FAIL='GET_SINGLE_BET_PATTERN_FAIL';

export const GET_PATTERN_BY_MATCH_COUNT_START='GET_PATTERN_BY_MATCH_COUNT_START';
export const GET_PATTERN_BY_MATCH_COUNT_SUCCESS='GET_PATTERN_BY_MATCH_COUNT_SUCCESS';
export const GET_PATTERN_BY_MATCH_COUNT_FAIL='GET_PATTERN_BY_MATCH_COUNT_FAIL';

export const GET_PATTERN_DETAIL_START='GET_PATTERN_DETAIL_START';
export const GET_PATTERN_DETAIL_SUCCESS='GET_PATTERN_DETAIL_SUCCESS';
export const GET_PATTERN_DETAIL_FAIL='GET_PATTERN_DETAIL_FAIL';

export const GET_BETS_BY_PATTERN_START='GET_BETS_BY_PATTERN_START';
export const GET_BETS_BY_PATTERN_SUCCESS='GET_BETS_BY_PATTERN_SUCCESS';
export const GET_BETS_BY_PATTERN_FAIL='GET_BETS_BY_PATTERN_FAIL';

export const GET_BET_DETAIL_START='GET_BET_DETAIL_START';
export const GET_BET_DETAIL_SUCCESS='GET_BET_DETAIL_SUCCESS';
export const GET_BET_DETAIL_FAIL='GET_BET_DETAIL_FAIL';

export const SET_ID_AND_COUNT='SET_ID_AND_COUNT';

export const REJECT_BET_START='REJECT_BET_START';
export const REJECT_BET_SUCCESS='REJECT_BET_SUCCESS';
export const REJECT_BET_FAIL='REJECT_BET_FAIL';

export const UPDATE_PAGINATION = 'UPDATE_PAGINATION';

export const MARK_AS_CHEATED_START = 'MARK_AS_CHEATED_START';
export const MARK_AS_CHEATED_SUCCESS = 'MARK_AS_CHEATED_SUCCESS';
export const MARK_AS_CHEATED_FAIL = 'MARK_AS_CHEATED_FAIL';

export const GET_CHEATED_BETS_START = 'GET_CHEATED_BETS_START';
export const GET_CHEATED_BETS_SUCCESS = 'GET_CHEATED_BETS_SUCCESS';
export const GET_CHEATED_BETS_FAIL = 'GET_CHEATED_BETS_FAIL';

