import React from 'react';
import { Row, Col, message, Card } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAnnouncements } from '../../redux/actions/announcement/getAnnouncements';
import {
   EditOutlined,
} from '@ant-design/icons';
import { toggleUpdateAnnouncement } from '../../redux/actions/announcement/toggleUpdateAnnouncement';



class AnnouncementList extends React.Component {
   componentDidMount() {
      this.props.onGetAll(this.props.token);
   }
   componentDidUpdate() {
      if (this.props.error) {
         message.error(this.props.error);
      }
   }
   _openEditModal = () => {
      this.props.onOpen();
   }
   render() {
      return (
         <Row>
            <Col span={24}>
               <Card
                  span={24}
                  type="inner"
                  title={<div style={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                     width: '100%'
                  }}>
                     <span>Announcement</span>
                     <EditOutlined onClick={this._openEditModal} />
                  </div>}
                  loading={this.props.loading}
               >
                  {this.props.announcement?.statement}
               </Card>
            </Col>
         </Row>
      );
   }
}

const mapStateToProps = state => ({
   token: state.auth.token,
   announcement: state.announcement.all,
   error: state.announcement.error,
   message: state.announcement.message,
   loading: state.announcement.loading
})

const mapDispatchToProps = dispatch => ({
   onGetAll: (token) => dispatch(getAnnouncements(token)),
   onOpen: () => dispatch(toggleUpdateAnnouncement()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnnouncementList));