import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, InputNumber } from 'antd';
import { togglePSCUpdate } from '../../redux/actions/partner/toggleModals';
import { updatePartnerSingleCommission } from '../../redux/actions/partner/updatePartnerSingleCommission';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

class UpdateSingleCommissionModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    onFinish = async (values) => {
        await this.props.onUpdate(values,
            this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        const {
            commission,
            highCommission
        } = this.props.singleCommission;
        return (
            <Modal
                title="Update Single Commission"
                key={this.props.singleCommission ?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'update-psc-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    {...layout}
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="update-psc-form"
                    name="basic"
                    initialValues={{
                        highCommission,
                        commission
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="commission"
                        label="Commission"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{ width: 150 }} />
                    </Form.Item>
                    <Form.Item
                        name="highCommission"
                        label="High Commission"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{ width: 150 }} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.partner.updatePSCVisible,
    loading: state.partner.loading,
    token: state.auth.token,
    error: state.partner.error,
    message: state.partner.message,
    singleCommission: state.partner.singleCommission
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (commissions, token) => dispatch(updatePartnerSingleCommission(commissions, token)),
    onClose: () => dispatch(togglePSCUpdate()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSingleCommissionModal);