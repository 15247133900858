import React from 'react';
import { Row, Col, Table, message, Input, Tag, Space } from 'antd';
import {
    UnlockOutlined,
    EditOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getEmployees } from '../../redux/actions/employee/getEmployees';
import ResetEmployeePasswordModal from './ResetEmployeePasswordModal';
import UpdateEmployeeModal from './UpdateEmployeeModal';

class EmployeeList extends React.Component {
    state = {
        searchKey: "",
        searchResult: [],
        resetPasswordVisible: false,
        updateVisible: false,
        employee: null
    }
    async componentDidMount() {
        const { token } = this.props;
        await this.props.onGetEmployees(token);
    }
    componentDidUpdate() {
        if (this.props.error) {
            message.error(this.props.error);
        }
    }
    onSearch = ({ target: { value } }) => {
        const result = this.props.employees?.filter(el => `${el.username}`.toLowerCase().includes(value.toLowerCase())
            || `${el.name}`.toLowerCase().includes(value.toLowerCase()));
        this.setState(oldState => ({
            ...oldState,
            searchResult: result,
            searchKey: value
        }))
    }

    handleResetModal = (record) => {
        this.setState(prevState => ({
            ...prevState,
            employee: record,
            resetPasswordVisible: true,
        }))
    }

    handleUpdateModal = (record) => {
        this.setState(prevState => ({
            ...prevState,
            employee: record,
            updateVisible: true,
        }))
    }

    render() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Username',
                dataIndex: 'username',
                key: 'username'
            },
            {
                title: 'Mobile',
                dataIndex: 'mobile',
                key: 'mobile'
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                render(text) {
                    return <Tag color={text === 'ROLE_EMPLOYEE' ? 'blue' : 'red'}>{text === 'ROLE_EMPLOYEE' ? 'Employee' : 'Supervisor'}</Tag>
                }
            },
            {
                title: 'Action',
                key: 'id',
                render: (_, record) => {
                    return (
                        <Space size="middle" direction="horizontal">
                            <EditOutlined color='blue' onClick={() => this.handleUpdateModal(record)} />
                            <UnlockOutlined color='red' onClick={() => this.handleResetModal(record)} />
                        </Space>
                    )
                }
            }
        ]
        return (<>
            {this.state.employee ? (
                <>
                    <ResetEmployeePasswordModal
                        visible={this.state.resetPasswordVisible}
                        employee={this.state.employee}
                        onClose={() => this.setState(prevState => ({
                            ...prevState,
                            employeeId: null,
                            resetPasswordVisible: false
                        }))} />
                    <UpdateEmployeeModal
                        visible={this.state.updateVisible}
                        employee={this.state.employee}
                        onClose={() => this.setState(prevState => ({
                            ...prevState,
                            employeeId: null,
                            updateVisible: false
                        }))} />
                </>
            ) : null}
            <Row gutter={[16, 16]}>
                <Col span={{ lg: 6, md: 10, sm: 20 }}>
                    <Input.Search
                        style={{ width: '220px' }}
                        placeholder="Search"
                        onChange={this.onSearch}
                        enterButton />
                </Col>
                <Col span={24} style={{
                    width: '100%',
                    overflowX: 'auto',
                }}>
                    <Table columns={columns} dataSource={this.state.searchKey ?
                        this.state.searchResult : this.props.employees}
                        loading={this.props.loading} />
                </Col>
            </Row>
        </>)
    }
}

const mapStateToProps = state => ({
    loading: state.employee.loading,
    token: state.auth.token,
    employees: state.employee.all,
    error: state.employee.error
})

const mapDispatchToProps = dispatch => ({
    onGetEmployees: (token) => dispatch(getEmployees(token))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeList));