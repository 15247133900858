import * as actionTypes from './actionTypes';
import axios from 'axios';
import api from '../../utilities/api';
import {getMessage} from '../../utilities/getMessage';

export const lsportStart=()=>({type:actionTypes.LSPORT_START});

export const lsportSuccess=(response)=>({type:actionTypes.LSPORT_SUCCESS,payload:response});

export const lsportFail=(error)=>({type:actionTypes.LSPORT_FAIL,payload:getMessage(error)});

export const lsport=(date,token)=>async dispatch=>{
    dispatch(lsportStart());
    const url=`${api}/isport/preMatches/${date}`;
    const options={
        url: url,
        method: 'GET',
        headers: {
            'Authorization': 'Bearer '+token
        },
        data:{}
    };
    await axios(options)
        .then(response=>dispatch(lsportSuccess(response.data)))
        .catch(error=>dispatch(lsportFail(error)));
}