import React from 'react';
import { Row, Col, Switch, Button, Divider, Modal } from 'antd';
import { openUpdateGoalScore } from '../../redux/actions/collection/toggleModals';
import { connect } from 'react-redux';
import { updateSingleBet } from '../../redux/actions/collection/updateSingleBet';
import { updateHighTax } from '../../redux/actions/collection/updateHighTax';
import { updateActive } from '../../redux/actions/collection/updateActive';
import { changeAutoUpdate } from '../../redux/actions/collection/changeAutoUpdate';
import { cancelMatch } from '../../redux/actions/collection/cancelMatch';
import {
   EditOutlined,
   ExclamationCircleOutlined
} from '@ant-design/icons';
class BasicInfo extends React.Component {
   state = {
      isMobile: false,
   }
   componentDidMount() {
      const query = window.matchMedia(`(max-width: 480px)`);
      query.onchange = (e) => {
         this.setState(prevState => {
            return {
               ...prevState,
               isMobile: e.matches
            }
         })
      }
      this.setState({
         isMobile: query.matches
      })
   }

   _handleClick = () => {
      this.props.onOpen();
   }

   render() {
      const match = this.props.match;
      const that = this;
      const { isMobile } = this.state;
      const SWITCH={
         width:isMobile?'120px':'auto',
         display:'flex',
         flexDirection:isMobile?"column-reverse":"row",
         alignItems:'center',
         gap:'10px',
      }
      return (
         <Row>
            <Col span={24}>
               <h3>Basic Info</h3>
               <div style={{
                  marginTop: '30px'
               }}>
                  Match ID: {match?.id}
               </div>
               <div style={{
                  marginTop: '30px'
               }}>
                  Match League: {match?.league.name}
               </div>
               <div style={{
                  marginTop: '30px',
               }}>
                  <span>Home: {match?.home.name}</span>
                  <span style={{ marginLeft: '40px' }}>Away: {match?.away.name}</span>
               </div>
               <div style={{
                  marginTop: '30px',
                  display: 'flex',
                  alignItems: 'center'
               }}>
                  <span>Home Score: {match?.homeScore}</span>
                  <span style={{ marginLeft: '40px' }}>Away Score: {match?.awayScore}</span>
                  <span style={{
                     marginLeft: '30px',
                     color: '#0a9396',
                     fontSize: '20px'
                  }}><EditOutlined onClick={this._handleClick} /></span>
               </div>
               <div style={{
                  marginTop: '30px',
                  display: 'flex',
                  flexWrap:'wrap',
                  width:'100%',
                  gap:'20px',
                  justifyContent:'flex-start'
               }}>
                  <div style={SWITCH}>
                     <Switch
                        defaultChecked={match?.singleBet}
                        onChange={(value) => {
                           this.props.onUpdateSingleBet(match?.id, value, this.props.token)
                        }}
                     /> Single Bet
                  </div>
                  <div style={SWITCH}>
                     <Switch
                        defaultChecked={match?.active}
                        onChange={(value) => {
                           this.props.onUpdateActive(match?.id, value, this.props.token)
                        }} /> Active
                  </div>
                  <div style={SWITCH}>
                     <Switch
                        defaultChecked={match?.highTax}
                        onChange={(value) => {
                           this.props.onUpdateHighTax(match?.id, value, this.props.token)
                        }}
                     /> High Tax
                  </div>
                  <div style={SWITCH}>
                     <Switch
                        defaultChecked={match?.autoUpdate}
                        onChange={(value) => {
                           this.props.onChangeAutoUpdate({
                              id: match?.id,
                              autoUpdate: value
                           }, this.props.token)
                        }}
                     /> Auto Update
                  </div>
               </div>
               <div style={{
                  marginTop: '30px',
               }}>
                  is Canceled: {match?.canceled ? 'Yes' : 'No'}
               </div>
               <div style={{
                  marginTop: '30px',
                  display: 'flex',
                  flexDirection: isMobile ? "column" : "row",
                  gap: '20px'
               }}>
                  {match?.canceled || <Button type="danger"
                     loading={this.props.loading}
                     onClick={() =>
                        Modal.confirm({
                           title: 'Confirm',
                           icon: <ExclamationCircleOutlined />,
                           content: 'Are you sure to cancel this match?',
                           onOk: () => {
                              that.props.onCancelMatch({ id: match?.id }, this.props.token);
                           }
                        })
                     }>
                     CANCEL MATCH
                  </Button>}
                  <Button type="primary">RE-CALCULATE</Button>
               </div>
            </Col>
         </Row>
      );
   }
}

const mapStateToProps = state => ({
   token: state.auth.token,
   match: state.collection.match,
   loading: state.collection.loading
})

const mapDispatchToProps = dispatch => ({
   onOpen: () => dispatch(openUpdateGoalScore()),
   onUpdateSingleBet: (matchId, singleBet, token) => dispatch(updateSingleBet(matchId, singleBet, token)),
   onUpdateHighTax: (matchId, highTax, token) => dispatch(updateHighTax(matchId, highTax, token)),
   onUpdateActive: (matchId, active, token) => dispatch(updateActive(matchId, active, token)),
   onChangeAutoUpdate: (obj, token) => dispatch(changeAutoUpdate(obj, token)),
   onCancelMatch: (obj, token) => dispatch(cancelMatch(obj, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);