export const GET_TEAMS_START='GET_TEAMS_START';
export const GET_TEAMS_SUCCESS='GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAIL='GET_TEAMS_FAIL';

export const UPDATE_TEAM_START='UPDATE_TEAM_START';
export const UPDATE_TEAM_SUCCESS='UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_FAIL='UPDATE_TEAM_FAIL';

export const OPEN_UPDATE_TEAM='OPEN_UPDATE_TEAM';
export const CLOSE_UPDATE_TEAM='CLOSE_UPDATE_TEAM';

export const SET_TEAM_TO_UPDATE='SET_TEAM_TO_UPDATE';

