import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import {
    EditOutlined,
} from '@ant-design/icons';
import {connect} from 'react-redux';
import {openUpdateCloseTime, openUpdateStartTime} from '../../redux/actions/collection/toggleModals';

class DateTime extends React.Component {
    render() {
        const match = this.props.data;
        return (
            <Row justify="left" align="middle">
                <Col lg={20} sm={20}>
                    <h3>Date Time Info</h3>
                    <div style={{
                        marginTop: '30px'
                    }}>
                        Start Date and Time {moment(match?.startTime).locale('my').format('DD-MM-YYYY hh:mm A')}
                    </div>
                    <div style={{
                        marginTop: '30px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#0a9396',
                        fontSize: '14px',
                    }}>
                        <span style={{
                            marginLeft: '20px',
                            cursor: 'pointer'
                        }} onClick={()=>this.props.onOpenStartTimeUpdate()}
                        ><EditOutlined  /> EDIT DATE AND TIME</span>
                    </div>
                    <div style={{
                        marginTop: '30px'
                    }}>
                        Close Date and Time {moment(match?.closeTime).locale('my').format('DD-MM-YYYY hh:mm A')}
                    </div>
                    <div style={{
                        marginTop: '30px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#0a9396',
                        fontSize: '14px',
                    }}>
                        <span style={{
                            marginLeft: '20px',
                            cursor: 'pointer'
                        }} onClick={()=>this.props.onOpenCloseTimeUpdate()}
                        ><EditOutlined  /> EDIT DATE AND TIME</span>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps=dispatch=>({
    onOpenStartTimeUpdate:()=>dispatch(openUpdateStartTime()),
    onOpenCloseTimeUpdate:()=>dispatch(openUpdateCloseTime())
})

export default connect(null,mapDispatchToProps)(DateTime);