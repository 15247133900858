import React from 'react';
import { Row, Col, Table,  message } from 'antd';
import {
    EyeTwoTone,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { getBetPattern } from '../../redux/actions/betPattern/getBetPattern';
import {setIdAndCount} from '../../redux/actions/betPattern/setIdAndCount';
import {withRouter} from 'react-router-dom';

class BetPatterns extends React.Component {
    async componentDidMount(){
        await this.props.onGet(this.props.token);
        if(this.props.error){
            message.error(this.props.error);
        }
    }
    render() {
        let columns = [
            {
                title: 'Collection ID',
                dataIndex: 'collectionId',
                key: 'collectionId',
                align: 'center'
            },
            {
                title: 'Bet Match Count',
                dataIndex: 'betMatchCount',
                key: 'betMatchCount',
                align: 'center',
                render:(text,record)=>`${record.betMatchCount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            },
            {
                title:'Bet Pattern Count',
                dataIndex: 'betPatternCount',
                key: 'betPatternCount',
                align: 'center',
                render:(text,record)=>`${record.betPatternCount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            },
            {
                title: 'Max Amount',
                dataIndex: 'maxAmount',
                key: 'maxAmount',
                align: 'center',
                render:(text,record)=>`${record.maxAmount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            },
            {
                title: 'Detail',
                key: 'detail',
                align: 'center',
                render:(text,record)=><EyeTwoTone onClick={async ()=>{
                    await this.props.onSet(record)
                    this.props.history.push("/patternByBetMatchCount")
                }}/>
            },
        ]
        return (
            <Row>
                <Col span={24} style={{overflowX:'auto'}}>
                    <Table
                        loading={this.props.loading}
                        dataSource={this.props.betPatterns}
                        columns={columns} />
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    loading: state.betPattern.loading,
    token: state.auth.token,
    betPatterns: state.betPattern.all,
    error: state.betPattern.error,
})

const mapDispatchToProps = dispatch => ({
    onGet: (token) => dispatch(getBetPattern(token)),
    onSet:(one)=>dispatch(setIdAndCount(one))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BetPatterns));