import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, message } from 'antd';
import {finishTwoDCollection} from '../../redux/actions/twoDcollection/finishTwoDCollection';
import {toggleFinishModal} from '../../redux/actions/twoDcollection/toggleFinishModal';

class FinishNumberModal extends React.Component {
    constructor(props){
        super(props);
        this.form=React.createRef();
    }
    layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 12,
        },
    };
    tailLayout = {
        wrapperCol: {
            offset: 12,
            span: 16,
        },
    };
    onFinish = async (values) => {
        await this.props.onFinish({collectionId:this.props.collectionId,...values},this.props.token);
        if(this.props.message){
            message.success(this.props.message);
            this.props.onClose();
        }
        if(this.props.error){
            message.error(this.props.error);
        }
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        return (
            <Modal
                title="Finish TwoD Collection"
                key="finish-2d-c"
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'finish-2d-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="finish-2d-form"
                    {...this.layout}
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                   
                    <Form.Item
                        name="twoDNumber"
                        label="Two D Number"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                    >
                        <Input type="number"/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.twoDCollection.finishVisible,
    loading: state.twoDCollection.loading,
    token: state.auth.token,
    error:state.twoDCollection.error,
    message:state.twoDCollection.message,
    collectionId:state.twoDCollection.collectionId
})

const mapDispatchToProps = dispatch => ({
    onFinish: (obj, token) => dispatch(finishTwoDCollection(obj, token)),
    onClose: () => dispatch(toggleFinishModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FinishNumberModal);