import { Tabs } from 'antd';
import SMTransactions from './SMTransactions';
import SeniorMasterDetail from './SeniorMasterDetail';
const SMTabContainer = () => {
   const { TabPane } = Tabs;
   return (
      <Tabs defaultActiveKey="1">
         <TabPane tab="Detail" key="1">
            <SeniorMasterDetail />
         </TabPane>
         <TabPane tab="Transaction" key="2">
            <SMTransactions />
         </TabPane>
      </Tabs>
   );
}

export default SMTabContainer;