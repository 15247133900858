export const LSPORT_START='LSPORT_START';
export const LSPORT_SUCCESS='LSPORT_SUCCESS';
export const LSPORT_FAIL='LSPORT_FAIL';

export const UPDATE_LSPORT='UPDATE_LSPORT';

export const TOGGLE_LEAGUE='TOGGLE_LEAGUE';
export const CREATE_LEAGUES='CREATE_LEAGUES';
export const SET_SELECTED_LEAGUES='SET_SELECTED_LEAGUES';
export const CREATE_LEAGUE_IDS='CREATE_LEAGUE_IDS';

export const SET_LEAGUE_MODAL_STATE='SET_LEAGUE_MODAL_STATE';
