import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, InputNumber } from 'antd';
import { toggle23LimitUpdate } from '../../redux/actions/23limits/toggle23LimitUpdate';
import { update23Limit } from '../../redux/actions/23limits/update23Limit';

class Update23LimitModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    onFinish = async (values) => {
        await this.props.onUpdate(values,
            this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        const {
            twoSpecialNumberLimit,
            twoClosedNumberLimit,
            threeSpecialNumberLimit,
            threeClosedNumberLimit,
            // fourDClosedNumberLimit,
        }=this.props.limits;
        return (
            <Modal
                title="Update Two Three Limits"
                key={this.props.limits?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'update-23limits-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="update-23limits-form"
                    name="basic"
                    initialValues={{
                        twoSpecialNumberLimit,
                        twoClosedNumberLimit,
                        threeSpecialNumberLimit,
                        threeClosedNumberLimit,
                        // fourDClosedNumberLimit
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="twoSpecialNumberLimit"
                        label="Two Special Number Limit"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        name="twoClosedNumberLimit"
                        label="Two Closed Number Limit"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        name="threeSpecialNumberLimit"
                        label="Three Special Number Limit"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        name="threeClosedNumberLimit"
                        label="Three Closed Number Limit"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber />
                    </Form.Item>
                    {/* <Form.Item
                        name="fourDClosedNumberLimit"
                        label="FourD Closed Number Limit"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber />
                    </Form.Item> */}
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.twothreeLimits.updateVisible,
    loading: state.twothreeLimits.loading,
    token: state.auth.token,
    error: state.twothreeLimits.error,
    message: state.twothreeLimits.message,
    limits: state.twothreeLimits.all
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (limits, token) => dispatch(update23Limit(limits, token)),
    onClose: () => dispatch(toggle23LimitUpdate()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Update23LimitModal);