import * as actionTypes from './actionTypes';

export const updateRecalculateStatus=({matchId,calculating})=>{
    return {
        type:actionTypes.UPDATE_RECALCULATE_STATUS,
        payload:{
            matchId,
            calculating
        }
    }
}