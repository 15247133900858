import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const get3DWinPercent=(token)=>async dispatch=>{
    dispatch({
        type:actionTypes.GET_THREE_D_WIN_PERCENT_START
    })
    const options={
        url:`${api}/threeDWinPercent`,
        method:'GET',
        headers:{
            'Authorization':`Bearer ${token}`
        },
        data:{}
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.GET_THREE_D_WIN_PERCENT_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.GET_THREE_D_WIN_PERCENT_FAIL,
                payload:getMessage(error)
            }))
}