import { Checkbox, Col } from 'antd';
const createCheckboxes = (leagues) => {
   let checkboxes = null;
   if (leagues) {
      checkboxes = leagues.map(league => (
         <Col key={`col${league.id}`} lg={{ span: 12 }}
            md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Checkbox key={league.id} value={league.id}>{league.id}</Checkbox>
         </Col>));
   }
   return checkboxes;
}

export default createCheckboxes;