import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, message, Input } from 'antd';
import * as actionTypes from '../../redux/actions/seniormaster/actionTypes';
import { resetPassword } from '../../redux/actions/seniormaster/resetPassword';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

class ResetPasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }
    onFinish = async (values) => {
        await this.props.onReset({
            id: this.props.smaster?.id,
            newPassword:values.password
        },
            this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }
    render() {
        return (
            <Modal
                title="Reset Password"
                key={this.props.smaster?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{ form: 'reset-password-form', key: 'submit', htmlType: 'submit' }}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    {...layout}
                    ref={this.form}
                    style={{ margin: 25 }}
                    id="reset-password-form"
                    name="basic"
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="password"
                        label="New Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input new password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.seniorMaster.resetPasswordVisible,
    loading: state.seniorMaster.loading,
    token: state.auth.token,
    error: state.seniorMaster.error,
    message: state.seniorMaster.message,
    smaster: state.seniorMaster.one
})

const mapDispatchToProps = dispatch => ({
    onReset: (obj, token) => dispatch(resetPassword(obj, token)),
    onClose: () => dispatch({
        type: actionTypes.TOGGLE_RESET_PASSWORD_MODAL
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal);