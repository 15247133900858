import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const createPartnerCommission = ({
    matchCount,
    commission
}, token) => async dispatch => {
    dispatch({
        type: actionTypes.CREATE_PARTNER_COMMISSION_START
    })
    const options = {
        url: `${api}/partnerCommissions`,
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: {
            matchCount,
            commission
        }
    }
    await axios(options)
        .then(response => dispatch({
            type: actionTypes.CREATE_PARTNER_COMMISSION_SUCCESS,
            payload: response.data
        }))
        .catch(error => dispatch({
            type: actionTypes.CREATE_PARTNER_COMMISSION_FAIL,
            payload: getMessage(error)
        }))
}