import update from "../utilities/update";
import { loading } from "./shared/loading";
import { error } from "./shared/error";
import {
    CREATE_COLLECTION_START,
    CREATE_COLLECTION_SUCCESS,
    CREATE_COLLECTION_FAIL,
    GET_COLLECTIONS_START,
    GET_COLLECTIONS_SUCCESS,
    GET_COLLECTIONS_FAIL,
    GET_COLLECTION_START,
    GET_COLLECTION_SUCCESS,
    GET_COLLECTION_FAIL,
    UPDATE_PRICE_START,
    UPDATE_PRICE_SUCCESS,
    UPDATE_PRICE_FAIL,
    OPEN_UPDATE_PRICE,
    CLOSE_UPDATE_PRICE,
    SET_MATCH,
    OPEN_UPDATE_GOAL_SCORE,
    CLOSE_UPDATE_GOAL_SCORE,
    UPDATE_GOAL_SCORE_START,
    UPDATE_GOAL_SCORE_SUCCESS,
    UPDATE_GOAl_SCORE_FAIL,
    UPDATE_SINGLE_BET_START,
    UPDATE_SINGLE_BET_SUCCESS,
    UPDATE_SINGLE_BET_FAIL,
    UPDATE_HIGH_TAX_START,
    UPDATE_HIGH_TAX_SUCCESS,
    UPDATE_ACTIVE_START,
    UPDATE_HIGH_TAX_FAIL,
    UPDATE_ACTIVE_SUCCESS,
    UPDATE_ACTIVE_FAIL,
    UPDATE_START_TIME_START,
    UPDATE_START_TIME_SUCCESS,
    UPDATE_START_TIME_FAIL,
    OPEN_UPDATE_START_TIME,
    CLOSE_UPDATE_START_TIME,
    UPDATE_CLOSE_TIME_START,
    UPDATE_CLOSE_TIME_FAIL,
    CLOSE_UPDATE_CLOSE_TIME,
    UPDATE_CLOSE_TIME_SUCCESS,
    OPEN_UPDATE_CLOSE_TIME,
    FINISH_MATCH_START,
    FINISH_MATCH_SUCCESS,
    FINISH_MATCH_FAIL,
    CHANGE_AUTO_UPDATE_START,
    CHANGE_AUTO_UPDATE_SUCCESS,
    CHANGE_AUTO_UPDATE_FAIL,
    CANCEL_MATCH_START,
    CANCEL_MATCH_SUCCESS,
    CANCEL_MATCH_FAIL,
    CHECK_ACTIVE_BET_START,
    CHECK_ACTIVE_BET_SUCCESS,
    CHECK_ACTIVE_BET_FAIL,
    RECALCULATE_START,
    RECALCULATE_SUCCESS,
    RECALCULATE_FAIL,
    REFRESH_GOAL_SCORE_START,
    REFRESH_GOAL_SCORE_SUCCESS,
    REFRESH_GOAL_SCORE_FAIL,
    TOGGLE_FINISH_MATCH,
    UPDATE_RECALCULATE_STATUS,
    OPEN_FINISH_MATCH,
    CLOSE_FINISH_MATCH,
} from "../actions/collection/actionTypes";

const initialState = {
    token: null,
    error: null,
    message: null,
    loading: false,
    one: null,
    updatePriceVisible: false,
    updateGoalScoreVisible: false,
    updateStartTimeVisible: false,
    updateCloseTimeVisible: false,
    finishMatchVisible: false,
    match: null,
    response: null,
    checkActiveBetLoading: false,
    recalculateLoading: false,
    refreshLoading: false,
    finishType:null,
};

const createCollectionSuccess = (state, action) => {
    return update(state, { loading: false, message: `${action.payload}` });
};

const getCollectionSuccess = (state, action) => {
    return update(state, {
        loading: false,
        collection: action.payload,
        message: "Got collections",
    });
};

const getCollectionByIdSuccess = (state, action) => {
    return update(state, {
        loading: false,
        message: "get by Id success",
        one: action.payload,
    });
};

const updateMatchSuccess = (state, action) => {
    return update(state, {
        loading: false,
        message: "update success",
        match: action.payload,
    });
};

const toggleUpdatePriceModal = (state, action) => {
    return update(state, {
        updatePriceVisible: !state.updatePriceVisible,
    });
};

const setMatch = (state, action) => {
    return update(state, {
        match: action.payload.match,
        finishType:action.payload.finishType
    });
};

const toggleUpdateGoalScore = (state, action) => {
    return update(state, {
        updateGoalScoreVisible: !state.updateGoalScoreVisible,
    });
};

const toggleUpdateStartTime = (state, action) => {
    return update(state, {
        updateStartTimeVisible: !state.updateStartTimeVisible,
    });
};

const toggleUpdateCloseTime = (state, action) => {
    return update(state, {
        updateCloseTimeVisible: !state.updateCloseTimeVisible,
    });
};

const toggleFinishMatch = (state, action) => {
    return update(state, {
        finishMatchVisible: !state.finishMatchVisible,
    });
};

const finishMatch = (state, action) => {
    return update(state, {
        message: "finish success",
        loading: false,
        one: {
            ...state.one,
            matches: state.one?.matches.map((obj) =>
                obj.id === action.payload.id ? action.payload : obj
            ),
        },
    });
};

const checkActiveBetStart = (state, action) => {
    return update(state, {
        checkActiveBetLoading: true,
        response: null,
    });
};

const checkActiveBet = (state, action) => {
    return update(state, {
        checkActiveBetLoading: false,
        response: action.payload,
    });
};

const checkActiveBetFail = (state, action) => {
    return update(state, {
        checkActiveBetLoading: false,
        error: action.payload,
    });
};

const recalculateStart = (state, action) => {
    return update(state, {
        recalculateLoading: true,
        response: null,
    });
};

const recalculate = (state, action) => {
    return update(state, {
        recalculateLoading: false,
        response: action.payload,
    });
};

const recalculateFail = (state, action) => {
    return update(state, {
        recalculateLoading: false,
        error: action.payload,
    });
};

const refreshGoalScoreStart = (state, action) => {
    return update(state, {
        refreshLoading: true,
    });
};

const refreshGoalScore = (state, action) => {
    return update(state, {
        refreshLoading: false,
        one: action.payload,
    });
};

const refreshGoalScoreFail = (state, action) => {
    return update(state, {
        refreshLoading: false,
    });
};

const updateRecalculateStatus = (state, action) => {
    state.one = state.one ?? {};
    let newArray = state.one.matches ? [...state.one.matches] : [];
    const index = newArray.findIndex(
        (it) => +it.id === +action.payload.matchId
    );
    if (index != -1) {
        newArray[index] = {
            ...newArray[index],
            calculating: false,
        };
    }
    state.one.matches = newArray;
    return update(state, {
        one: {
            ...state.one,
        },
    });
};

const openFinishMatchModal = (state,action)=>{
    return update(state,{
        finishMatchVisible:true
    });
}

const closeFinishMatchModal = (state,action) =>{
    return update(state,{
        finishMatchVisible:false
    });
}

const collectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_COLLECTION_START:
            return loading(state, action);
        case CREATE_COLLECTION_SUCCESS:
            return createCollectionSuccess(state, action);
        case CREATE_COLLECTION_FAIL:
            return error(state, action);
        case GET_COLLECTIONS_START:
            return loading(state, action);
        case GET_COLLECTIONS_SUCCESS:
            return getCollectionSuccess(state, action);
        case GET_COLLECTIONS_FAIL:
            return error(state, action);
        case GET_COLLECTION_START:
            return loading(state, action);
        case GET_COLLECTION_SUCCESS:
            return getCollectionByIdSuccess(state, action);
        case GET_COLLECTION_FAIL:
            return error(state, action);
        case UPDATE_PRICE_START:
            return loading(state, action);
        case UPDATE_PRICE_SUCCESS:
            return updateMatchSuccess(state, action);
        case UPDATE_PRICE_FAIL:
            return error(state, action);
        case OPEN_UPDATE_PRICE:
            return toggleUpdatePriceModal(state, action);
        case CLOSE_UPDATE_PRICE:
            return toggleUpdatePriceModal(state, action);
        case SET_MATCH:
            return setMatch(state, action);
        case OPEN_UPDATE_GOAL_SCORE:
            return toggleUpdateGoalScore(state, action);
        case CLOSE_UPDATE_GOAL_SCORE:
            return toggleUpdateGoalScore(state, action);
        case UPDATE_GOAL_SCORE_START:
            return loading(state, action);
        case UPDATE_GOAL_SCORE_SUCCESS:
            return updateMatchSuccess(state, action);
        case UPDATE_GOAl_SCORE_FAIL:
            return error(state, action);
        case UPDATE_SINGLE_BET_START:
            return loading(state, action);
        case UPDATE_SINGLE_BET_SUCCESS:
            return updateMatchSuccess(state, action);
        case UPDATE_SINGLE_BET_FAIL:
            return error(state, action);
        case UPDATE_HIGH_TAX_START:
            return loading(state, action);
        case UPDATE_HIGH_TAX_SUCCESS:
            return updateMatchSuccess(state, action);
        case UPDATE_HIGH_TAX_FAIL:
            return error(state, action);
        case UPDATE_ACTIVE_START:
            return loading(state, action);
        case UPDATE_ACTIVE_SUCCESS:
            return updateMatchSuccess(state, action);
        case UPDATE_ACTIVE_FAIL:
            return error(state, action);
        case UPDATE_START_TIME_START:
            return loading(state, action);
        case UPDATE_START_TIME_SUCCESS:
            return updateMatchSuccess(state, action);
        case UPDATE_START_TIME_FAIL:
            return error(state, action);
        case OPEN_UPDATE_START_TIME:
            return toggleUpdateStartTime(state, action);
        case CLOSE_UPDATE_START_TIME:
            return toggleUpdateStartTime(state, action);
        case UPDATE_CLOSE_TIME_START:
            return loading(state, action);
        case UPDATE_CLOSE_TIME_SUCCESS:
            return updateMatchSuccess(state, action);
        case UPDATE_CLOSE_TIME_FAIL:
            return error(state, action);
        case OPEN_UPDATE_CLOSE_TIME:
            return toggleUpdateCloseTime(state, action);
        case CLOSE_UPDATE_CLOSE_TIME:
            return toggleUpdateCloseTime(state, action);
        case FINISH_MATCH_START:
            return loading(state, action);
        case FINISH_MATCH_SUCCESS:
            return finishMatch(state, action);
        case FINISH_MATCH_FAIL:
            return error(state, action);
        case CHANGE_AUTO_UPDATE_START:
            return loading(state, action);
        case CHANGE_AUTO_UPDATE_SUCCESS:
            return updateMatchSuccess(state, action);
        case CHANGE_AUTO_UPDATE_FAIL:
            return error(state, action);
        case CANCEL_MATCH_START:
            return loading(state, action);
        case CANCEL_MATCH_SUCCESS:
            return updateMatchSuccess(state, action);
        case CANCEL_MATCH_FAIL:
            return error(state, action);
        case CHECK_ACTIVE_BET_START:
            return checkActiveBetStart(state, action);
        case CHECK_ACTIVE_BET_SUCCESS:
            return checkActiveBet(state, action);
        case CHECK_ACTIVE_BET_FAIL:
            return checkActiveBetFail(state, action);
        case RECALCULATE_START:
            return recalculateStart(state, action);
        case RECALCULATE_SUCCESS:
            return recalculate(state, action);
        case RECALCULATE_FAIL:
            return recalculateFail(state, action);
        case REFRESH_GOAL_SCORE_START:
            return refreshGoalScoreStart(state, action);
        case REFRESH_GOAL_SCORE_SUCCESS:
            return refreshGoalScore(state, action);
        case REFRESH_GOAL_SCORE_FAIL:
            return refreshGoalScoreFail(state, action);
        case TOGGLE_FINISH_MATCH:
            return toggleFinishMatch(state, action);
        case UPDATE_RECALCULATE_STATUS:
            return updateRecalculateStatus(state, action);
        case OPEN_FINISH_MATCH:
            return openFinishMatchModal(state,action);
        case CLOSE_FINISH_MATCH:
            return closeFinishMatchModal(state,action);
        default:
            return state;
    }
};

export default collectionReducer;
