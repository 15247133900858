import React from 'react';
import { Modal, Checkbox, Row, Col, Form, Divider } from 'antd';
import { connect } from 'react-redux';
import { SET_SELECTED_LEAGUES, TOGGLE_LEAGUE } from '../../redux/actions/lsport/actionTypes';
import { setLeagueModalState } from '../../redux/actions/lsport/setLeagueModalState';
import createCheckboxes from './Utility/createCheckboxes';

class LeagueModal extends React.Component {
   handleCancel = () => {
      this.props.onClose();
   }
   onChangeCheckbox = (selectedValues) => {
      if (selectedValues?.length) {
         if (selectedValues?.length === this.props.leagueIds?.length) {
            this.props.onSetState({
               selectedLeagues: selectedValues,
               checkAll: true,
               uncheckAll: false,
            });
         } else {
            this.props.onSetState({
               selectedLeagues: selectedValues,
               checkAll: false,
               uncheckAll: false,
            });
         }
      } else {
         this.props.onSetState({
            selectedLeagues: selectedValues,
            checkAll: false,
            uncheckAll: true,
         });
      }
   }
   onFinish = (values) => {
      let selectedLeagues = [];
      if (this.props.leagues) {
         selectedLeagues = this.props.leagues.filter(league =>
            this.props.modalState?.selectedLeagues?.includes(`${league.id}`));
      }
      this.props.onSubmit(selectedLeagues);
      this.props.onClose();
   }
   onSelectAll = () => {
      this.props.onSetState({
         selectedLeagues: this.props.leagueIds,
         checkAll: true,
         uncheckAll: false,
      });
   }
   onUnselectAll = () => {
      this.props.onSetState({
         selectedLeagues: [],
         checkAll: false,
         uncheckAll: true
      });
   }
   render() {
      let checkboxes = createCheckboxes(this.props.leagues);
      return (
         <Modal
            width={window.innerWidth <= 480 ? '100%' : 850}
            title="Select League"
            key="leagueModal"
            centered
            visible={this.props.leagues && this.props.visible}
            okButtonProps={{ form: 'league-form', key: 'submit', htmlType: 'submit' }}
            onCancel={this.handleCancel}
            okText="Ok"
         >
            <Row lg={24} md={24} sm={24}>
               <Col key="checkAll" lg={12} md={12} sm={24}>
                  <Checkbox onChange={this.onSelectAll} checked={this.props.modalState?.checkAll}>Select All</Checkbox>
               </Col>
               <Col key="uncheckAll" lg={12} md={12} sm={24}>
                  <Checkbox onChange={this.onUnselectAll} checked={this.props.modalState?.uncheckAll}>Deselect All</Checkbox>
               </Col>
            </Row>
            <Divider />
            <Form
               id="league-form"
               name="basic"
               onFinish={this.onFinish}
            >
            </Form>
            <Checkbox.Group style={{ width: '100%' }} onChange={this.onChangeCheckbox} value={this.props.modalState?.selectedLeagues}>
               <Row lg={24} md={24} sm={24}>
                  {checkboxes}
               </Row>
            </Checkbox.Group>

         </Modal>
      );
   }
}

const mapStateToProps = state => ({
   visible: state.lsport.visibleLeague,
   leagues: state.lsport.leagues,
   leagueIds: state.lsport.leagueIds,
   modalState: state.lsport.leagueModalState
});

const mapDispatchToProps = dispatch => ({
   onClose: () => dispatch(fn => fn({ type: TOGGLE_LEAGUE })),
   onSubmit: (leagues) => dispatch(fn => fn({ type: SET_SELECTED_LEAGUES, payload: leagues })),
   onSetState: (state) => dispatch(setLeagueModalState(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(LeagueModal);