import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const resetPassword=({newPassword,id},token)=>async dispatch=>{
    dispatch({
        type:actionTypes.RESET_PASSWORD_START,
    });
    const options={
        url:`${api}/smasters/${id}/resetPassword/`,
        method:'POST',
        headers:{
            'Authorization':`Bearer ${token}`
        },
        data:{
            newPassword
        }
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.RESET_PASSWORD_SUCCESS,
            }))
            .catch(error=>dispatch({
                type:actionTypes.RESET_PASSWORD_FAIL,
                payload:getMessage(error)
            }))
}