import {actionTypes} from "./actionTypes";
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const create4DCollection=(request,token)=>async dispatch=>{
    dispatch({
        type:actionTypes.CREATE_4D_COLLECTION_START
    });
    const options={
        url:`${api}/fourDCollections`,
        method:'POST',
        headers:{
            Authorization:`Bearer ${token}`
        },
        data:request
    }
    await axios(options)
        .then(response=>dispatch({
            type:actionTypes.CREATE_4D_COLLECTION_SUCCESS,
            payload:response.data
        }))
        .catch(error=>dispatch({
            type:actionTypes.CREATE_4D_COLLECTION_FAIL,
            payload:getMessage(error)
        }))
}