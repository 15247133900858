import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import * as actions from '../redux/actions/auth/auth';
import Login from '../screens/Login';
import Logout from '../screens/Logout';
import App from './App';
import Collection from '../components/Collection/Collection';
import Lsport from '../components/Lsport/Lsport';
import CollectionDetail from '../components/Collection/CollectionDetail';
import MatchDetail from '../components/Collection/MatchDetail';
import LeagueList from '../components/League/LeagueList';
import TeamList from '../components/Team/TeamList';
import AnnouncementList from '../components/Announcement/AnnouncementList';
import RuleList from '../components/Rule/RuleList';
import TwoDCollection from '../components/TwoDCollection/TwoDCollection';
import TwoDNumbers from '../components/TwoDCollection/TwoDNumbers';
import WinPercent from '../components/TwoDCollection/WinPercent';
import CreateSeniorMaster from '../components/SeniorMaster/CreateSeniorMaster';
import TwoThreeLimits from '../components/23Limits/TwoThreeLimits';
import Partner from '../components/Partner/Partner';
import SeniorMasterList from '../components/SeniorMaster/SeniorMasterList';
import PriceGap from '../components/PriceGap/PriceGap';
import ThreeDWinPercent from '../components/3DWinPercent/ThreeDWinPercent';
import ThreeDCollections from '../components/3DCollection/ThreeDCollections';
import ThreeDNumbers from '../components/3DCollection/ThreeDNumbers';
import CollectionReport from '../components/Report/CollectionReport';
import Dashboard from '../components/BetPattern/Dashboard';
import SmasterReport from '../components/Report/SmasterReport';
import ByMatchCount from '../components/BetPattern/ByMatchCount';
import PatternDetail from '../components/BetPattern/PatternDetail';
import BetsByPattern from '../components/BetPattern/BetsByPattern';
import BetDetail from '../components/BetPattern/BetDetail';
import SmasterByCollection from '../components/Report/SmasterByCollection';
import MasterReport from '../components/Report/MasterReport';
import AgentReport from '../components/Report/AgentReport';
import MemberReport from '../components/Report/MemberReport';
import BetReport from '../components/Report/BetReport';
import ThreeDDetail from '../components/BetPattern/ThreeDDetail';
import FourDDetail from '../components/BetPattern/FourDDetail';
import Agreements from '../components/Agreement/Agreements';
import Payment from '../components/Payment/Payment';
import SMTabContainer from '../components/SeniorMaster/SMTabContainer';
import SMTransactionDetail from '../components/SeniorMaster/SMTransactionDetail';
import MatchCount from "../components/Report/MatchCount";
import FourDCollection from "../components/4DCollection/FourDCollection";
import FourDNumbers from "../components/4DCollection/FourDNumbers";
import { FourDResult } from '../components/4DCollection/FourDResult';
import WinMessages from "../components/WinMessage/WinMessages";
import LoseMessages from "../components/LoseMessage/LoseMessages";
import BetsOf3DNumber from '../components/3DCollection/BetsOf3DNumber';
import EmployeeSignUp from '../components/Employee/EmployeeSignUp';
import EmployeeList from '../components/Employee/EmployeeList';
import { Delete3MonthsData } from '../components/Bets/Delete3MonthsData';
import CheatedBets from '../components/BetPattern/CheatedBets';
import SearchByBet from '../components/SearchBet/SearchByBet';
import SearchByUsername from '../components/SearchBet/SearchByUsername';

class ConfigRoute extends React.Component {
      async componentDidMount() {
            await this.props.onAuthStateCheck();
      }

      render() {
            return (<>
                  <Switch>
                        <PrivateRoute exact path="/">
                              <App>
                                    <Dashboard />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/soccer_collections">
                              <App>
                                    <Collection />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/lsport">
                              <App>
                                    <Lsport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/collections/:id">
                              <App>
                                    <CollectionDetail />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/matches/:id">
                              <App>
                                    <MatchDetail />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/leagues">
                              <App>
                                    <LeagueList />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/teams">
                              <App>
                                    <TeamList />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/announcement">
                              <App>
                                    <AnnouncementList />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/rules">
                              <App>
                                    <RuleList />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/twoDCollections">
                              <App>
                                    <TwoDCollection />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/twoDCollections/:id">
                              <App>
                                    <TwoDNumbers />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/winPercent">
                              <App>
                                    <WinPercent />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/senior_masters/new">
                              <App>
                                    <CreateSeniorMaster />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/senior_masters">
                              <App>
                                    <SeniorMasterList />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/senior_masters/:id">
                              <App>
                                    <SMTabContainer />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/senior_masters/:id/:date">
                              <App>
                                    <SMTransactionDetail />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/twothreeLimits">
                              <App>
                                    <TwoThreeLimits />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/partner23commissions">
                              <App>
                                    <Partner />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/priceGaps">
                              <App>
                                    <PriceGap />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/3DWinPercent">
                              <App>
                                    <ThreeDWinPercent />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/threeDCollections/:id">
                              <App>
                                    <ThreeDNumbers />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/threeDCollections">
                              <App>
                                    <ThreeDCollections />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/fourDCollections/:id">
                              <App>
                                    <FourDNumbers />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/fourDCollections">
                              <App>
                                    <FourDCollection />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/fourDCollections:id">
                              <App>
                                    <FourDNumbers />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/fourDResults/:id">
                              <App>
                                    <FourDResult />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/collection_report">
                              <App>
                                    <CollectionReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/report/daily/:date/smasters">
                              <App>
                                    <SmasterByCollection />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/report/daily/:date/smasters/:smasterName/masters">
                              <App>
                                    <MasterReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/report/daily/:date/masters/:masterName/agents">
                              <App>
                                    <AgentReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/report/daily/:date/agents/:agentName/members">
                              <App>
                                    <MemberReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute exact path="/report/daily/:date/members/:memberName/bets">
                              <App>
                                    <BetReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/smaster_report">
                              <App>
                                    <SmasterReport />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/patternByBetMatchCount">
                              <App>
                                    <ByMatchCount />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/pattern_detail">
                              <App>
                                    <PatternDetail />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/betsByPattern">
                              <App>
                                    <BetsByPattern />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/bet_detail/:id">
                              <App>
                                    <BetDetail />
                              </App>
                        </PrivateRoute>
                        {/* <PrivateRoute path="/2d_detail/:id">
                    <App>
                        <TwoDDetail/>
                    </App>
                </PrivateRoute> */}
                        <PrivateRoute path="/3d_detail/:id">
                              <App>
                                    <ThreeDDetail />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/4d_detail/:id">
                              <App>
                                    <FourDDetail />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/agreements">
                              <App>
                                    <Agreements />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/payments">
                              <App>
                                    <Payment />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/report/match-count">
                              <App>
                                    <MatchCount />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/winMessages">
                              <App>
                                    <WinMessages />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/loseMessages">
                              <App>
                                    <LoseMessages />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/threed-bets/:id">
                              <App>
                                    <BetsOf3DNumber />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/employees/signup">
                              <App>
                                    <EmployeeSignUp />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/employees">
                              <App>
                                    <EmployeeList />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/delete/3months-data">
                              <App>
                                    <Delete3MonthsData />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/cheatedBets/:betId">
                              <App>
                                    <CheatedBets />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/searchBetByMatch">
                              <App>
                                    <SearchByBet />
                              </App>
                        </PrivateRoute>
                        <PrivateRoute path="/searchBetByUsername">
                              <App>
                                    <SearchByUsername />
                              </App>
                        </PrivateRoute>
                        <Route path="/login">
                              <Login />
                        </Route>
                        <Route path="/logout">
                              <Logout />
                        </Route>
                  </Switch>
            </>);
      }
}

const mapStateToProps = state => ({
      token: state.auth.token
});

const mapDispatchToProps = dispatch => ({
      onAuthStateCheck: () => dispatch(actions.authCheckState()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfigRoute));