import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';
import {updateSmasterBalance} from '../seniormaster/updateSmasterBalance';

export const createTransaction=({
    smasterId,
    command,
    amount,
    credit
},token)=>async dispatch=>{
    dispatch({
        type:actionTypes.CREATE_TRANSACTION_START
    })
    const options={
        url:`${api}/transactions/smasters/${smasterId}`,
        method:'POST',
        headers:{
            Authorization:`Bearer ${token}`
        },
        data:{
            command,
            amount,
            credit
        }
    }
    await axios(options)
            .then(response=>{
               dispatch({
                  type:actionTypes.CREATE_TRANSACTION_SUCCESS,
                  payload:response.data
              });
              dispatch(updateSmasterBalance(response.data));
            })
            .catch(error=>dispatch({
                type:actionTypes.CREATE_TRANSACTION_FAIL,
                payload:getMessage(error)
            }))
}