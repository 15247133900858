import { Button, Checkbox } from "antd";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { markAsCheated } from "../../redux/actions/betPattern/markAsCheated";
import { getCheatedBets } from "../../redux/actions/betPattern/getCheatedBets";

const BetInformations = ({
    id,
    partnerBetAmount,
    smasterBetAmount,
    userBetAmount,
    partnerCommissionAmount = 0,
    smasterCommissionAmount = 0,
    userCommissionAmount = 0,
    partnerWinAmount,
    smasterWinAmount,
    userWinAmount,
    status,
    date,
    rejectHandler,
    loading,
    cheated,
}) => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);

    useEffect(() => {
        id && dispatch(getCheatedBets(id, token))
    }, [id])

    return <>
        <div style={styles.container}>
            <div style={styles.child}>
                <div>BET ID : {id}</div>
                {date && <div>Time : {(new Date(date)).toLocaleString()}</div>}
            </div>
            <div style={styles.child}>
                <div>Partner Bet : {partnerBetAmount}</div>
                <div>Company Bet : {smasterBetAmount}</div>
                <div>User Bet : {userBetAmount}</div>
            </div>
            <div style={styles.child}>
                <div>Partner % : {partnerCommissionAmount}</div>
                <div>Company % : {smasterCommissionAmount}</div>
                <div>User % : {userCommissionAmount}</div>
            </div>
            <div style={styles.child}>
                <div>Partner Win : {partnerWinAmount}</div>
                <div>Company Win : {smasterWinAmount}</div>
                <div>User Win : {userWinAmount}</div>
            </div>
            <div style={styles.child}>Status : {status}</div>
            <div style={styles.flexbox}>
                <div style={{ display: 'flex', gap: 20 }}>
                    <div style={{ display:'flex', gap:10 }}>
                        <label>Cheated</label>
                        <Checkbox
                            onChange={e => dispatch(markAsCheated({
                                id,
                                cheated: e.target.checked
                            }, token))}
                            disabled={loading}
                            checked={cheated} />
                    </div>
                    <Link to={`/cheatedBets/${id}`}>See Cheated Bets</Link>
                </div>
                <Button
                    type="primary"
                    onClick={rejectHandler}
                    loading={loading}
                    disabled={status === "REJECTED"}
                    danger>
                    Reject
                </Button>
            </div>
        </div>
    </>
}

const styles = {
    container: {
        width: '80%',
        padding: '30px',
        display: 'flex',
        color: '#666',
        lineHeight: '2rem',
        flexWrap: 'wrap',
        gap: 20
    },
    child: {
        minWidth: '28ch',
        background: 'white',
        whiteSpace: 'nowrap',
        padding: '10px'
    },
    flexbox: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    }
}

export default BetInformations;