import { actionTypes } from "./actionTypes";

export const toggle1stPrizeFinishModal=()=>{
      return {
            type:actionTypes.TOGGLE_1ST_PRIZE_FINISH_MODAL
      }
}

export const toggle2ndPrizeFinishModal=()=>{
      return {
            type:actionTypes.TOGGLE_2ND_PRIZE_FINISH_MODAL
      }
}

export const toggle3rdPrizeFinishModal=()=>{
      return {
            type:actionTypes.TOGGLE_3RD_PRIZE_FINISH_MODAL
      }
}

export const toggleStarterPrizeFinishModal=({id,number})=>{
      return {
            type:actionTypes.TOGGLE_STARTER_PRIZE_FINISH_MODAL,
            payload:{id,number}
      }
}

export const toggleConsolPrizeFinishModal = ({id,number}) => {
      return {
            type: actionTypes.TOGGLE_CONSOL_PRIZE_FINISH_MODAL,
            payload:{id,number}
      }
}