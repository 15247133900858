import * as actionTypes from './actionTypes';
import axios from 'axios';
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

export const finishMatch=({
    id,
    homeScore,
    awayScore,
    type
},token)=>async dispatch=>{
    dispatch({
        type:actionTypes.FINISH_MATCH_START
    })
    const options={
        url:`${api}/matches/${id}/${type}`,
        method:'POST',
        headers:{
            'Authorization':`Bearer ${token}`
        },
        data:{homeScore,awayScore}
    }
    await axios(options)
            .then(response=>dispatch({
                type:actionTypes.FINISH_MATCH_SUCCESS,
                payload:response.data
            }))
            .catch(error=>dispatch({
                type:actionTypes.FINISH_MATCH_FAIL,
                payload:getMessage(error)
            }))
}