import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

const updateGoalScoreStart = () => ({
    type: actionTypes.UPDATE_GOAL_SCORE_START
})

const updateGoalScoreSuccess = (data) => ({
    type: actionTypes.UPDATE_GOAL_SCORE_SUCCESS,
    payload: data
})

const updateGoalScoreFail = (error) => ({
    type: actionTypes.UPDATE_GOAl_SCORE_FAIL,
    payload: error
})

export const updateGoalScore = ({ matchId, homeScore, awayScore }, token) => async dispatch => {
    console.log('homeScore & awayScore ',homeScore,awayScore);
    console.log('token-',token);
    dispatch(updateGoalScoreStart());
    const options = {
        url: `${api}/matches/${matchId}/updateGoalScore`,
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: {
            homeScore,
            awayScore
        }
    }
    await axios(options)
        .then(response => dispatch(
            updateGoalScoreSuccess(response.data)
        ))
        .catch(error => dispatch(
            updateGoalScoreFail(
                getMessage(error)
            )
        ))
}