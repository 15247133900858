import {
    GET_23LIMITS_START,
    GET_23LIMITS_SUCCESS,
    GET_23LIMITS_FAIL,
    UPDATE_23LIMIT_START,
    UPDATE_23LIMIT_SUCCESS,
    UPDATE_23LIMIT_FAIL,
    TOGGLE_23LIMIT_UPDATE
} from '../actions/23limits/actionTypes';
import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';

const initialState={
    loading:false,
    error:null,
    message:null,
    updateVisible:false,
    all:{
        id:'limit-default',
        twoSpecialNumberLimit:0,
        twoClosedNumberLimit:0,
        threeSpecialNumberLimit:0,
        threeClosedNumberLimit:0,
        fourDClosedNumberLimit:0,
    }
}

const get23Limits=(state,action)=>{
    return update(state,{
        loading:false,
        all:action.payload
    })
}

const update23Limit=(state,action)=>{
    return update(state,{
        loading:false,
        all:action.payload,
        message:'update success'
    })
}

const toggleUpdate=(state,action)=>{
    return update(state,{
        updateVisible:!state.updateVisible
    })
}

const twothreeLimitReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case GET_23LIMITS_START:
            return loading(state,action);
        case GET_23LIMITS_SUCCESS:
            return get23Limits(state,action);
        case GET_23LIMITS_FAIL:
            return error(state,action);
        case UPDATE_23LIMIT_START:
            return loading(state,action);
        case UPDATE_23LIMIT_SUCCESS:
            return update23Limit(state,action);
        case UPDATE_23LIMIT_FAIL:
            return error(state,action);
        case TOGGLE_23LIMIT_UPDATE:
            return toggleUpdate(state,action);
        default:
            return state;
    }
}

export default twothreeLimitReducer;