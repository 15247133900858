import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { get4DCollection, set4DNumberToUpdate, toggleUpdate4DModal } from "../../redux/actions/4DCollection";
import Pagination from "../Pagination/Pagination";
import { EditOutlined } from "@ant-design/icons";
import './FourDNumbers.css';

class FourDNumbers extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  hover: false,
                  paginationXKey: 1,
                  paginationYKey: 1
            }
      }

      async componentDidMount() {
            await this.props.onGet(this.props.match.params.id, this.props.token);
      }

      paginate = (array, page_size, page_number) => {
            return array.slice((page_number - 1) * page_size, page_number * page_size);
      }

      render() {
            const xPaginatedArray = this.paginate(this.props.collection?.fourDNumbers ?? [],
                  1000, this.state.paginationXKey);
            const yPaginatedArray = this.paginate(xPaginatedArray ?? [],
                  100, this.state.paginationYKey);
            return (
                  <>
                        <div style={styles.header} className="header">
                              {/* this is for showing Pagination X. */}
                              <div style={styles.paginationX}>
                                    <Pagination
                                          numbers={10}
                                          onChange={(key) => {
                                                this.setState({
                                                      paginationYKey: key
                                                });
                                          }}
                                    />
                              </div>
                        </div>
                        <div style={styles.body} className="body">
                              <div style={styles.paginationY} className="paginationY">
                                    {/* this is for showing Pagination Y.*/}
                                    <Pagination
                                          numbers={10}
                                          onChange={(key) => {
                                                this.setState({
                                                      paginationXKey: key
                                                });
                                          }}
                                    />
                              </div>
                              <div style={{ width: '100%', overflow: 'auto' }}>
                                    <div className="numbers" style={styles.numbers}>
                                          {/* this is for showing numbers*/}
                                          {yPaginatedArray.map(obj =>
                                                <div style={{
                                                      ...styles.number,
                                                      borderBottom: (obj.status == 1)
                                                            ? '5px solid green' : obj.status == 2
                                                                  ? '5px solid orange' : '5px solid red',
                                                }} className="number">
                                                      <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between'
                                                      }}>
                                                            <span>{obj.number} </span>
                                                            <EditOutlined onClick={async () => {
                                                                  await this.props.onSetNumber(obj);
                                                                  this.props.onOpenModal();
                                                            }} />
                                                      </div>
                                                      <span style={{
                                                            fontSize: '0.6rem',
                                                            color: 'gray'
                                                      }}>Amount</span>
                                                      <span>{`${obj.totalAmount}`
                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                                </div>)}
                                    </div>
                              </div>
                        </div>
                  </>
            );
      }
}

const mapStateToProps = state => ({
      token: state.auth.token,
      collection: state.fourDCollection.one,
      error: state.fourDCollection.error,
      message: state.fourDCollection.message,
})

const mapDispatchToProps = dispatch => ({
      onGet: (id, token) => dispatch(get4DCollection(id, token)),
      onSetNumber: (number) => dispatch(set4DNumberToUpdate(number)),
      onOpenModal: () => dispatch(toggleUpdate4DModal())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FourDNumbers));

const styles = {
      header: {
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: '60px',
      },
      body: {
            display: 'flex',
            paddingTop: '8px',
            justifyContent:'flex-start',
            gap: '15px',
      },
      paginationX: {
            display: 'flex',
            overflowX: 'auto'
      },
      paginationY: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
      },
      numbers: {
            paddingLeft: '8px',
            display: 'grid',
            gridTemplateColumns: "repeat(10,100px)",
            gridTemplateRows: "repeat(10,100px)",
            gridGap: '5px',
            justifyContent: 'start',
            overflow: 'auto',
      },
      number: {
            display: 'flex',
            flexDirection: 'column',
            padding: 5,
            border: '1px solid gray',
            justifyContent: 'center',
            color: '#0e0e0e'
      }
}