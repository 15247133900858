import {
    SEARCH_BET_BY_BET_AND_AMOUNT_FAIL,
    SEARCH_BET_BY_BET_AND_AMOUNT_REQUEST,
    SEARCH_BET_BY_BET_AND_AMOUNT_SUCCESS,
    SEARCH_BET_BY_USERNAME_AND_AMOUNT_REQUEST,
    SEARCH_BET_BY_USERNAME_AND_AMOUNT_SUCCESS,
    SEARCH_BET_BY_USERNAME_AND_AMOUNT_FAIL,
    CLEAR_BET_BY_USERNAME,
    CLEAR_BET_BY_MATCH,
} from "../actions/searchBet/actionTypes";
import update from "../utilities/update";
import { error } from "./shared/error";
import { loading } from "./shared/loading";

const initialState = {
    loading: false,
    error: undefined,
    resultByBet: undefined,
    resultByUsername: undefined,
}

const searchBetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_BET_BY_BET_AND_AMOUNT_REQUEST:
            return loading(state, action);
        case SEARCH_BET_BY_BET_AND_AMOUNT_SUCCESS:
            return update(state, {
                loading: false,
                resultByBet: action.payload
            });
        case SEARCH_BET_BY_BET_AND_AMOUNT_FAIL:
            return error(state, action);
        case SEARCH_BET_BY_USERNAME_AND_AMOUNT_REQUEST:
            return loading(state, action);
        case SEARCH_BET_BY_USERNAME_AND_AMOUNT_SUCCESS:
            return update(state, {
                loading: false,
                resultByUsername: action.payload
            });
        case SEARCH_BET_BY_USERNAME_AND_AMOUNT_FAIL:
            return error(state, action);
        case CLEAR_BET_BY_USERNAME:
            return update(state,{
                resultByUsername:undefined
            });
        case CLEAR_BET_BY_MATCH:
            return update(state,{
                resultByBet:undefined
            })
        default:
            return state;
    }
}

export default searchBetReducer;