import React from 'react';
import {connect} from 'react-redux';
import {Form, InputNumber, message, Modal} from 'antd';
import * as actionTypes from '../../redux/actions/seniormaster/actionTypes';
import {updateShareDetail} from '../../redux/actions/seniormaster/updateSeniormaster';


class UpdateShareDetailModal extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    onFinish = async (values) => {
        await this.props.onUpdate({
                id: this.props.smaster?.id,
                shareDetailId: this.props.smaster?.shareDetail?.id,
                sharePercentage: values.sharePercentage,
                threeDSharePercentage: values.threeDSharePercentage,
                // fourDSharePercentage:values.fourDSharePercentage,
            },
            this.props.token);
        if (this.props.error) {
            message.error(this.props.error);
        }
        if (this.props.message) {
            message.success(this.props.message);
        }
        this.form.current.resetFields();
        this.props.onClose();
    }
    onFinishFailed = (errors) => {
        console.log(errors)
    }
    handleCancel = () => {
        this.props.onClose();
    }

    render() {
        return (
            <Modal
                title="Update Share Detail"
                key={this.props.smaster?.id}
                centered
                visible={this.props.visible}
                okButtonProps={{form: 'update-sd-form', key: 'submit', htmlType: 'submit'}}
                confirmLoading={this.props.loading}
                onCancel={this.handleCancel}
                okText="Save"
                loading={this.props.loading}
            >
                <Form
                    ref={this.form}
                    style={{margin: 25}}
                    layout="vertical"
                    id="update-sd-form"
                    name="basic"
                    initialValues={{
                        sharePercentage: this.props.smaster?.shareDetail?.sharePercentage ??0,
                        threeDSharePercentage: this.props.smaster?.shareDetail?.threeDSharePercentage ??0,
                        // fourDSharePercentage:this.props.smaster?.shareDetail?.fourDSharePercentage ?? 0
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                >
                    <Form.Item
                        name="sharePercentage"
                        label="Share Percentage"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{width: 150}}/>
                    </Form.Item>
                    <Form.Item
                        name="threeDSharePercentage"
                        label="Three D Share Percentage"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{width: 150}}/>
                    </Form.Item>
                    {/* <Form.Item
                        name="fourDSharePercentage"
                        label="Four D Share Percentage"
                        rules={[
                            {
                                required: true,
                                message: 'Require!',
                            }
                        ]}
                        hasFeedback
                    >
                        <InputNumber style={{width: 150}}/>
                    </Form.Item> */}
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    visible: state.seniorMaster.updateShareDetailVisible,
    loading: state.seniorMaster.loading,
    token: state.auth.token,
    error: state.seniorMaster.error,
    message: state.seniorMaster.message,
    smaster: state.seniorMaster.one
})

const mapDispatchToProps = dispatch => ({
    onUpdate: (info, token) => dispatch(updateShareDetail(info, token)),
    onClose: () => dispatch({
        type: actionTypes.TOGGLE_SHARE_DETAIL_UPDATE
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateShareDetailModal);