import * as actionTypes from './actionTypes';
import axios from "axios";
import api from '../../utilities/api';
import { getMessage } from '../../utilities/getMessage';

const makeRequest = async (options, dispatch) => {
      dispatch({
            type: actionTypes.UPDATE_SENIOR_MASTER_START
      })
      await axios(options)
            .then(response => dispatch({
                  type: actionTypes.UPDATE_SENIOR_MASTER_SUCCESS,
                  payload: response.data
            }))
            .catch(error => dispatch({
                  type: actionTypes.UPDATE_SENIOR_MASTER_FAIL,
                  payload: getMessage(error)
            }))
}

export const updateBasicInfo = ({
      id,
      name,
      mobile
}, token) => async dispatch => {
      const options = {
            url: `${api}/smasters/${id}/basicInfo`,
            method: 'PUT',
            headers: {
                  'Authorization': `Bearer ${token}`
            },
            data: {
                  name,
                  mobile
            }
      }
      await makeRequest(options, dispatch);
}

export const updateBetLimit = ({
      id,
      limitId,
      smasterMixMax,
      smasterSingleMax,
      masterMixMax,
      masterSingleMax,
      agentMixMax,
      agentSingleMax,
      maxForSingleBet,
      maxForMixBet,
      maxForTwoD,
      maxForThreeD,
      maxForFourD,
}, token) => async dispatch => {
      const options = {
            url: `${api}/smasters/${id}/betLimitation/${limitId}`,
            method: 'PUT',
            headers: {
                  'Authorization': `Bearer ${token}`
            },
            data: {
                  smasterMixMax,
                  smasterSingleMax,
                  masterMixMax,
                  masterSingleMax,
                  agentMixMax,
                  agentSingleMax,
                  maxForSingleBet,
                  maxForMixBet,
                  maxForTwoD,
                  maxForThreeD,
                  maxForFourD,
            }
      }
      await makeRequest(options, dispatch);
}

export const updateCommission = ({
      id,
      commissionId,
      matchCount,
      betCommission,
      winCommission,
}, token) => async dispatch => {
      const options = {
            url: `${api}/smasters/${id}/commissions/${commissionId}`,
            method: 'PUT',
            headers: {
                  'Authorization': `Bearer ${token}`
            },
            data: {
                  matchCount,
                  betCommission,
                  winCommission,
            }
      }
      await makeRequest(options, dispatch);
}

export const updateShareDetail = ({
      id,
      shareDetailId,
      sharePercentage,
      threeDSharePercentage,
      fourDSharePercentage,
}, token) => async dispatch => {
      const options = {
            url: `${api}/smasters/${id}/shareDetail/${shareDetailId}`,
            method: 'PUT',
            headers: {
                  'Authorization': `Bearer ${token}`
            },
            data: {
                  sharePercentage,
                  threeDSharePercentage,
                  fourDSharePercentage,
            }
      }
      await makeRequest(options, dispatch);
}

export const updatePartnerShare = ({
      id,
      sharePercentage
}, token) => async dispatch => {
      const options = {
            url: `${api}/smasters/${id}/partnerShare`,
            method: 'PUT',
            headers: {
                  'Authorization': `Bearer ${token}`
            },
            data: {
                  sharePercentage
            }
      }
      await makeRequest(options, dispatch);
}


export const updatePartnerThreeDShare = ({
      id,
      sharePercentage
}, token) => async dispatch => {
      const options = {
            url: `${api}/smasters/${id}/partnerThreeDShare`,
            method: 'PUT',
            headers: {
                  'Authorization': `Bearer ${token}`
            },
            data: {
                  sharePercentage
            }
      }
      await makeRequest(options, dispatch);
}

export const updatePartnerFourDShare = ({
      id,
      sharePercentage
}, token) => async dispatch => {
      const options = {
            url: `${api}/smasters/${id}/partnerFourDShare`,
            method: 'PUT',
            headers: {
                  'Authorization': `Bearer ${token}`
            },
            data: {
                  sharePercentage
            }
      }
      await makeRequest(options, dispatch);
}

export const updateSingleBetCommission = ({
      id,
      singleBetCommissionId,
      betCommission,
      highBetCommission,
      winCommission,
      highWinCommission
}, token) => async dispatch => {
      const options = {
            url: `${api}/smasters/${id}/singleBetCommission/${singleBetCommissionId}`,
            method: 'PUT',
            headers: {
                  'Authorization': `Bearer ${token}`
            },
            data: {
                  betCommission,
                  highBetCommission,
                  winCommission,
                  highWinCommission
            }
      }
      await makeRequest(options, dispatch);
}

export const updateTwoThreeBet = ({
      id,
      twoThreeBet
}, token) => async dispatch => {
      const options = {
            url: `${api}/smasters/${id}/twoThreeBet`,
            method: 'PUT',
            headers: {
                  'Authorization': `Bearer ${token}`
            },
            data: {
                  twoThreeBet
            }
      }
      await makeRequest(options, dispatch);
}

export const updateExtraCommission = ({
      id,
      extraCommission
}, token) => async dispatch => {
      const options = {
            url: `${api}/smasters/${id}/extraCommission`,
            method: 'PUT',
            headers: {
                  'Authorization': `Bearer ${token}`
            },
            data: {
                  extraCommission
            }
      }
      await makeRequest(options, dispatch);
}

export const updateTwoDCommission = ({
      id,
      commission
}, token) => async dispatch => {
      const options = {
            url: `${api}/smasters/${id}/twoDCommission`,
            method: 'PUT',
            headers: {
                  'Authorization': `Bearer ${token}`
            },
            data: {
                  commission
            }
      }
      await makeRequest(options, dispatch);
}

export const updateThreeDCommission = ({
      id,
      commission
}, token) => async dispatch => {
      const options = {
            url: `${api}/smasters/${id}/threeDCommission`,
            method: 'PUT',
            headers: {
                  'Authorization': `Bearer ${token}`
            },
            data: {
                  commission
            }
      }
      await makeRequest(options, dispatch);
}

export const update4DCommission = ({
      id,
      commission
}, token) => async dispatch => {
      const options = {
            url: `${api}/smasters/${id}/fourDCommission`,
            method: 'PUT',
            headers: {
                  'Authorization': `Bearer ${token}`
            },
            data: {
                  commission
            }
      }
      await makeRequest(options, dispatch);
}

