import update from '../utilities/update';
import {loading} from './shared/loading';
import {error} from './shared/error';
import {
    CREATE_MATCHES_START,
    CREATE_MATCHES_SUCCESS,
    CREATE_MATCHES_FAIL,
    SET_SELECTED_MATCHES,
} from '../actions/match/actionTypes';

const initialState = {
    error: null,
    message: null,
    loading: false,
    selectedMatches: []
}

const createMatchSuccess = (state, action) => {
    return update(state, { error: null, message: 'create match success', loading: false });
}

const setSelectedMatches = (state, action) => {
    return update(state, { selectedMatches: action.payload });
}

const createMatchReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_MATCHES_START:
            return loading(state,action);
        case CREATE_MATCHES_SUCCESS:
            return createMatchSuccess(state, action);
        case CREATE_MATCHES_FAIL:
            return error(state, action);
        case SET_SELECTED_MATCHES:
            return setSelectedMatches(state, action);
        default:
            return state;
    }
}

export default createMatchReducer;