import React from 'react';
import { Row, Col, Table } from 'antd';
import { connect } from 'react-redux';
import { getSmasterReportByCollection } from '../../redux/actions/report/getSmasterReportByCollection';
import { withRouter } from 'react-router-dom';
import currency from 'currency.js';
import {
   EyeOutlined
} from '@ant-design/icons';
import {ParseIntAndFormat} from "./ParseIntAndFormat";

class SmasterReport extends React.Component {
   componentDidMount() {
      this.props.onGetReport({
         date: this.props.match.params.date,
         type: this.props.type
      }, this.props.token);
   }

   render() {
      const { Column, ColumnGroup } = Table;
      return (
         <Row>
            <Col span={24} style={{overflowX:'auto'}}>
               <Table
                  bordered
                  loading={this.props.loading}
                  dataSource={this.props.smasterReport} >
                  <Column title="Smaster Name"
                          dataIndex="smasterName" key="smasterName" align="center" />
                  <ColumnGroup title="Partner">
                     <Column title="Bet" dataIndex="partnerBetAmount"
                             render={(text,record)=>ParseIntAndFormat(record.partnerBetAmount??0)}
                             key="partnerBetAmount" align="right" />
                     <Column title="Commission" dataIndex="partnerCommissionAmount"
                             render={(text,record)=>ParseIntAndFormat(record.partnerCommissionAmount)}
                             key="partnerCommissionAmount" align="right" />
                     <Column
                        title="W/L"
                        key="p_win_lose"
                        render={(text, record) => {
                           let sum=currency(record.partnerBetAmount)
                               .subtract(currency(record.partnerWinAmount))
                               .subtract(currency(record.partnerCommissionAmount))
                               .value;
                           return ParseIntAndFormat(sum);
                        }
                        }
                        align="right"
                     />
                  </ColumnGroup>
                  <ColumnGroup title="Company">
                     <Column title="Bet" dataIndex="smasterBetAmount"
                             render={(text,record)=>ParseIntAndFormat(record.smasterBetAmount)}
                             key="smasterBetAmount" align="right" />
                     <Column title="Commission" dataIndex="smasterCommissionAmount"
                             render={(text,record)=>ParseIntAndFormat(record.smasterCommissionAmount)}
                             key="smasterCommissionAmount" align="right" />
                     <Column
                        title="W/L"
                        key="s_win_lose"
                        render={(text, record) => {
                           let sum=currency(record.smasterBetAmount)
                               .subtract(currency(record.smasterWinAmount))
                               .subtract(currency(record.smasterCommissionAmount))
                               .value;
                           return ParseIntAndFormat(sum);
                        }
                        }
                        align="right"
                     />
                     <Column title="Action" key="action" align="center" render={
                        (text, record) => <EyeOutlined onClick={
                           () => this.props.history.push(`/report/daily/${this.props.match.params.date}/smasters/${record.smasterName}/masters`)
                        } />
                     } />
                  </ColumnGroup>
               </Table>
            </Col>
         </Row>
      );
   }
}

const mapStateToProps = state => ({
   loading: state.report.loading,
   token: state.auth.token,
   error: state.report.error,
   smasterReport: state.report.smasterByCollection,
   type: state.report.type
})

const mapDispatchToProps = dispatch => ({
   onGetReport: (obj, token) => dispatch(getSmasterReportByCollection(obj, token)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SmasterReport));