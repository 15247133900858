import React from 'react';
import { Row, Col, PageHeader, Table, message, Breadcrumb, Button, Space, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
      TableOutlined,
      PlusSquareOutlined,
      CheckCircleOutlined,
      ExclamationCircleOutlined,
      CalculatorOutlined,
} from '@ant-design/icons';
import * as actionTypes from '../../redux/actions/3DCollection/actionTypes';
import { get3DCollections } from '../../redux/actions/3DCollection/get3DCollections';
import { toggleFinishModal } from '../../redux/actions/3DCollection/toggleFinishModal';
import { calculate3d } from '../../redux/actions/3DCollection/calculate3d';

const { confirm, success } = Modal;

class ThreeDCollections extends React.Component {
      componentDidMount() {
            this.props.onGetAll(this.props.token);
      }
      componentDidUpdate() {
            if (this.props.error) {
                  message.error(this.props.error);
            }
      }
      _showNumbers = (id) => {
            this.props.history.push(`/threeDCollections/${id}`);
      }

      calculate = (id) => {
            confirm({
                  title: 'Confirm',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Are you sure to calculate?',
                  okText: 'Yes',
                  cancelText: 'Cancel',
                  onOk: async () => {
                        await this.props.onCalculate(id, this.props.token);
                        if (this.props.response) {
                              success({
                                    content: `Calculated ${this.props.response.calculatedBetCount} bets`,
                              })
                        }
                  }
            })
      }

      render() {
            const columns = [
                  {
                        title: 'ID',
                        dataIndex: 'id',
                        key: 'id',
                  },
                  {
                        title: 'Collection ID',
                        dataIndex: 'collectionId',
                        key: 'collectionId',
                  },
                  {
                        title: 'Numbers',
                        align: 'center',
                        render: (text, record) => (
                              <Space size="middle" direction="horizontal" style={{ color: '#23ACFD' }}>
                                    <TableOutlined onClick={() => this._showNumbers(record.id)} />
                                    <CheckCircleOutlined onClick={() => this.props.onOpenFinish(record.id)} />
                                    <CalculatorOutlined onClick={() => this.calculate(record.id)} />
                              </Space>
                        )
                  }
            ]
            return (
                  <Row>
                        <Col span={24}>
                              <Breadcrumb style={{ margin: '16px 0' }}>
                                    <Breadcrumb.Item>Three D Collections</Breadcrumb.Item>
                              </Breadcrumb>
                              <PageHeader
                                    style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          border: '1px solid rgb(235, 237, 240)',
                                    }}
                                    title="Three D Collection"
                              >
                                    <Button
                                          icon={<PlusSquareOutlined />}
                                          style={{ background: '#3071b9', color: 'white' }}
                                          onClick={() => this.props.onOpenAdd()}
                                    />
                              </PageHeader>
                              <Row>
                                    <Col span={24} style={{ overflowX: 'auto' }}>
                                          <Table dataSource={this.props.collections} columns={columns} loading={this.props.loading} />
                                    </Col>
                              </Row>
                        </Col>
                  </Row>
            );
      }
}

const mapStateToProps = state => ({
      token: state.auth.token,
      collections: state.threeDCollection.all,
      error: state.threeDCollection.error,
      loading: state.threeDCollection.loading,
      response: state.threeDCollection.calculateResponse,
})

const mapDispatchToProps = dispatch => ({
      onGetAll: (token) => dispatch(get3DCollections(token)),
      onOpenAdd: () => dispatch({
            type: actionTypes.TOGGLE_3D_COLLECTION_ADD
      }),
      onOpenFinish: (id) => dispatch(toggleFinishModal(id)),
      onCalculate: (id, token) => dispatch(calculate3d(id, token)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ThreeDCollections));