import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Input, message } from 'antd';
import { finishConsolPrize } from '../../redux/actions/4DCollection/finish4D';
import { toggleConsolPrizeFinishModal } from '../../redux/actions/4DCollection/toggleFinish4DModal';

class FinishConsolPrizeModal extends React.Component {
      constructor(props) {
            super(props);
            this.form = React.createRef();
      }
      onFinish = async (values) => {
            await this.props.onFinish({ id: this.props.consolation?.id, ...values },
                  this.props.token);
            if (this.props.message) {
                  message.success(this.props.message);
                  this.props.onClose();
            }
            if (this.props.error) {
                  message.error(this.props.error);
            }
      }
      onFinishFailed = (errors) => {
            console.log(errors)
      }
      handleCancel = () => {
            this.props.onClose();
      }
      render() {
            const number = this.props.consolation?.number;
            return (
                  <Modal
                        title="Finish Consolation Prize"
                        key="finish-consolp-modal"
                        centered
                        visible={this.props.visible}
                        okButtonProps={{ form: 'finish-consolp-form', key: 'submit', htmlType: 'submit' }}
                        confirmLoading={this.props.loading}
                        onCancel={this.handleCancel}
                        okText="Save"
                        loading={this.props.loading}
                  >
                        <Form
                              key={number + "form"}
                              layout="vertical"
                              ref={this.form}
                              id="finish-consolp-form"
                              name="basic"
                              onFinish={this.onFinish}
                              onFinishFailed={this.onFinishFailed}>

                              <Form.Item
                                    key={number}
                                    name="number"
                                    label="Prize Number"
                                    rules={[
                                          {
                                                required: true,
                                                message: 'Require!',
                                          },
                                          {
                                                pattern: new RegExp(`^${number}$`),
                                                message: "Need to confirm the prize"
                                          }
                                    ]}>
                                    <Input />
                              </Form.Item>
                        </Form>
                  </Modal>
            );
      }
}

const mapStateToProps = state => ({
      visible: state.fourDCollection.finishConsolPrizeVisible,
      loading: state.fourDCollection.loading,
      token: state.auth.token,
      error: state.fourDCollection.error,
      message: state.fourDCollection.message,
      result: state.fourDCollection.result,
      consolation: state.fourDCollection.consolation
})

const mapDispatchToProps = dispatch => ({
      onFinish: (obj, token) => dispatch(finishConsolPrize(obj, token)),
      onClose: () => dispatch(toggleConsolPrizeFinishModal({ number: null, id: null })),
})

export default connect(mapStateToProps, mapDispatchToProps)(FinishConsolPrizeModal);